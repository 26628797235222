@include exports("dropdowntree/layout") {

    // Dropdown tree
    .k-dropdown-tree, .k-dropdowntree {
        .k-clear-value {
            right: 36px;
        }

        &.k-picker {
            .k-input-inner {
                padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
            }
        }

        &.k-input {
            .k-chip .k-icon.k-i-x-circle::before { content: "\e11b"; }

            .k-input-values {
                width: 100%;
                min-height: 36px;
                padding: $kendo-input-padding-y 0 $kendo-input-padding-y $kendo-input-padding-x;
                gap: ($kendo-input-padding-y * 0.5);
                cursor: text;
        
                .k-chip {
                    padding-top: 1px;
                    padding-bottom: 1px;
                    .k-icon {
                        font-size: $kendo-input-font-size;
                    }
                }
        
                .k-input-inner {
                    padding:  0px;
                    width: 1em;
                    height: ($kendo-input-font-size + 2px);
                }
            }

            .k-clear-value {
                top: 11px;
            }

            &::after {
                content: '';
                mask: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.75008 1.1665H2.91675C3.23758 1.1665 3.50008 1.429 3.50008 1.74984V2.9165C3.50008 3.23734 3.23758 3.49984 2.91675 3.49984H1.75008C1.42925 3.49984 1.16675 3.23734 1.16675 2.9165V1.74984C1.16675 1.429 1.42925 1.1665 1.75008 1.1665ZM11.0834 3.49984H5.25008C4.92925 3.49984 4.66675 3.23734 4.66675 2.9165V1.74984C4.66675 1.429 4.92925 1.1665 5.25008 1.1665H11.0834C11.4042 1.1665 11.6667 1.429 11.6667 1.74984V2.9165C11.6667 3.23734 11.4042 3.49984 11.0834 3.49984ZM12.2501 5.83317H5.25008C4.92925 5.83317 4.66675 6.09567 4.66675 6.4165V7.58317C4.66675 7.904 4.92925 8.1665 5.25008 8.1665H12.2501C12.5709 8.1665 12.8334 7.904 12.8334 7.58317V6.4165C12.8334 6.09567 12.5709 5.83317 12.2501 5.83317ZM1.75008 5.83317H2.91675C3.23758 5.83317 3.50008 6.09567 3.50008 6.4165V7.58317C3.50008 7.904 3.23758 8.1665 2.91675 8.1665H1.75008C1.42925 8.1665 1.16675 7.904 1.16675 7.58317V6.4165C1.16675 6.09567 1.42925 5.83317 1.75008 5.83317ZM9.91675 10.4998H5.25008C4.92925 10.4998 4.66675 10.7623 4.66675 11.0832V12.2498C4.66675 12.5707 4.92925 12.8332 5.25008 12.8332H9.91675C10.2376 12.8332 10.5001 12.5707 10.5001 12.2498V11.0832C10.5001 10.7623 10.2376 10.4998 9.91675 10.4998ZM1.75008 10.4998H2.91675C3.23758 10.4998 3.50008 10.7623 3.50008 11.0832V12.2498C3.50008 12.5707 3.23758 12.8332 2.91675 12.8332H1.75008C1.42925 12.8332 1.16675 12.5707 1.16675 12.2498V11.0832C1.16675 10.7623 1.42925 10.4998 1.75008 10.4998Z' fill='black'/%3E%3C/svg%3E%0A");
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                -webkit-mask-position: center;
                mask-position: center;
                height: 36px;
                width: 14px;
                line-height: ($kendo-input-line-height * $kendo-input-font-size);
                outline: 0;
                position: absolute;
                top: 0px;
                right: 8px;
        
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    /* IE10+ CSS styles go here */
                    mask: none;
                    background: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.75008 1.1665H2.91675C3.23758 1.1665 3.50008 1.429 3.50008 1.74984V2.9165C3.50008 3.23734 3.23758 3.49984 2.91675 3.49984H1.75008C1.42925 3.49984 1.16675 3.23734 1.16675 2.9165V1.74984C1.16675 1.429 1.42925 1.1665 1.75008 1.1665ZM11.0834 3.49984H5.25008C4.92925 3.49984 4.66675 3.23734 4.66675 2.9165V1.74984C4.66675 1.429 4.92925 1.1665 5.25008 1.1665H11.0834C11.4042 1.1665 11.6667 1.429 11.6667 1.74984V2.9165C11.6667 3.23734 11.4042 3.49984 11.0834 3.49984ZM12.2501 5.83317H5.25008C4.92925 5.83317 4.66675 6.09567 4.66675 6.4165V7.58317C4.66675 7.904 4.92925 8.1665 5.25008 8.1665H12.2501C12.5709 8.1665 12.8334 7.904 12.8334 7.58317V6.4165C12.8334 6.09567 12.5709 5.83317 12.2501 5.83317ZM1.75008 5.83317H2.91675C3.23758 5.83317 3.50008 6.09567 3.50008 6.4165V7.58317C3.50008 7.904 3.23758 8.1665 2.91675 8.1665H1.75008C1.42925 8.1665 1.16675 7.904 1.16675 7.58317V6.4165C1.16675 6.09567 1.42925 5.83317 1.75008 5.83317ZM9.91675 10.4998H5.25008C4.92925 10.4998 4.66675 10.7623 4.66675 11.0832V12.2498C4.66675 12.5707 4.92925 12.8332 5.25008 12.8332H9.91675C10.2376 12.8332 10.5001 12.5707 10.5001 12.2498V11.0832C10.5001 10.7623 10.2376 10.4998 9.91675 10.4998ZM1.75008 10.4998H2.91675C3.23758 10.4998 3.50008 10.7623 3.50008 11.0832V12.2498C3.50008 12.5707 3.23758 12.8332 2.91675 12.8332H1.75008C1.42925 12.8332 1.16675 12.5707 1.16675 12.2498V11.0832C1.16675 10.7623 1.42925 10.4998 1.75008 10.4998Z' fill='black'/%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-position: right 30%;
                }
            }
        }
    }

    // Dropdowntree popup
    .k-popup-dropdowntree {
        padding: 0;

        .k-check-all {
            padding: $dropdowntree-popup-padding-y $dropdowntree-popup-padding-x 0;
        }

        .k-treeview {
            padding: $dropdowntree-popup-padding-y $dropdowntree-popup-padding-x;
        }
    }

}
