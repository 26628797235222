// Appbar
$appbar-margin-y: null !default;
$appbar-margin-x: null !default;
$appbar-padding-y: map-get( $spacing, 2 ) !default;
$appbar-padding-x: map-get( $spacing, 4 ) !default;
$appbar-border-width: 0px !default;

$appbar-zindex: 1000 !default;

$appbar-font-size: $font-size !default;
$appbar-line-height: $line-height !default;
$appbar-font-family: $font-family !default;
$appbar-gap: map-get( $spacing, 2 ) !default;

$appbar-light-bg: $bg-color !default;
$appbar-light-text: $text-color !default;

$appbar-dark-bg: $dark !default;
$appbar-dark-text: contrast-wcag( $dark ) !default;

$appbar-box-shadow: 0px 1px 1px rgba(0, 0, 0, .16) !default;
$appbar-bottom-box-shadow: 0px -1px 1px rgba(0, 0, 0, .16) !default;
