@include exports( "dropzone/theme" ) {

    // Dropzone
    .k-dropzone-inner {
        @include fill(
            $dropzone-text,
            $dropzone-bg,
            $dropzone-border
        );

        > .k-icon,
        .k-dropzone-icon {
            color: $dropzone-icon-text;
        }
        .k-dropzone-hint {
            color: $dropzone-hint-text;
        }
        .k-dropzone-note {
            color: $dropzone-note-text;
        }
    }


    // Hover
    .k-external-dropzone-hover {

        .k-icon,
        .k-dropzone-icon {
            color: $dropzone-icon-hover-text;
        }
    }

}
