@include exports("window/theme") {

    // Window
    .k-window {
        @include fill(
            $window-text,
            $window-bg,
            $window-border
        );
        @include box-shadow( $window-shadow );
    }

    .k-window {
        &:focus,
        &.k-focus {
            @include box-shadow( $window-focused-shadow );
        }
    }


    // Titlebar
    .k-window-titlebar {
        @include fill(
            $window-titlebar-text,
            $window-titlebar-bg,
            $window-titlebar-border,
            $window-titlebar-gradient
        );
    }

}
