@include exports( "autocomplete/theme" ) {

    // Autocomplete
    .k-autocomplete.k-required, .k-autocomplete.mri-k-show-validation, td.mri-k-show-validation > .k-autocomplete {
        .k-input-inner {
            background-size: $kendo-input-font-size $kendo-input-font-size;
            background-repeat: no-repeat;
            background-position: calc(100% - #{$kendo-input-padding-x}) center;
        }
    }

    .k-autocomplete.k-required {
        .k-input-inner {
            padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
            background-image: $required-icon;
        }
    }

    .k-autocomplete.mri-k-show-validation, td.mri-k-show-validation > .k-autocomplete {
        // warning state
        &.k-warning .k-input-inner,
        .k-warning.k-input-inner {
            padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
            background-image: $warning-icon;
        }

        // Invalid state
        &.k-invalid .k-input-inner,
        &.ng-invalid.ng-touched .k-input-inner,
        &.ng-invalid.ng-dirty .k-input-inner, 
        .k-invalid.k-input-inner {
            padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
            background-image: $invalid-icon;
        }

        // valid state
        &.k-success .k-input-inner, 
        &.k-valid:not(.ng-pristine) .k-input-inner,
        &.ng-valid.ng-touched:not(.ng-pristine) .k-input-inner,
        &.ng-valid.ng-dirty:not(.ng-pristine) .k-input-inner,
        .k-valid.k-input-inner{
            padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
            background-image: $success-icon;
        }
    }

    .k-autocomplete.k-readonly, .k-autocomplete[disabled], .k-autocomplete.k-disabled {
        .k-input-inner {
            padding-right: $kendo-input-padding-x;
            background: none;
        }
    }

    .k-autocomplete.k-is-loading {
        position: relative;
        .k-input-inner {
            background-image: none;
            padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
        }

        &::after {
            position: absolute;
            top: 50%;
            right: 9px;
            display: inline-block;
            content: "";
            box-sizing: inherit;
            border-radius: 50%;
            border-width: 2px;
            border-style: solid;
            border-color: $loading-foreground-color;
            border-left-color: $loading-background-color;
            border-right-color: $loading-background-color;
            border-top-color: $loading-foreground-color;
            border-bottom-color: $loading-background-color;
            background-color: transparent;
            margin-top: -.5em;
            margin-left: -.5em;
            width: 1em;
            height: 1em;
            animation: k-loading-animation .5s linear infinite;
        }
    }
}
