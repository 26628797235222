// Chip

/// Width of the border around the button.
/// @group chip
$kendo-chip-border-width: 1px !default;
/// The spacing between the text and the icons of the chip.
/// @group chip
$kendo-chip-spacing: map-get( $spacing, 1 ) !default;

/// Horizontal padding of the chip.
/// @group chip
$kendo-chip-padding-x: map-get( $spacing, 1 ) !default;
$kendo-chip-padding-x-sm: map-get( $spacing, 1 ) !default;
$kendo-chip-padding-x-md: map-get( $spacing, 1 ) !default;
$kendo-chip-padding-x-lg: map-get( $spacing, 1 ) !default;

/// Vertical padding of the chip.
/// @group chip
$kendo-chip-padding-y: map-get( $spacing, 1 ) !default;
$kendo-chip-padding-y-sm: map-get( $spacing, 1 ) - map-get( $spacing, thin )  !default;
$kendo-chip-padding-y-md: map-get( $spacing, 1 ) !default;
$kendo-chip-padding-y-lg: map-get( $spacing, 1 ) + map-get( $spacing, thin ) !default;

/// Font sizes of the chip.
/// @group chip
$kendo-chip-font-size: $font-size-sm !default;
$kendo-chip-font-size-sm: $font-size-xs !default;
$kendo-chip-font-size-md: $font-size-sm !default;
$kendo-chip-font-size-lg: $font-size-md !default;

/// Line heights of the chip that are connected to the $font-size.
/// @group chip
$kendo-chip-line-height: calc( 16 / 12 ) !default;
$kendo-chip-line-height-sm: 1.2 !default;
$kendo-chip-line-height-md: $kendo-chip-line-height !default;
$kendo-chip-line-height-lg: $kendo-chip-line-height !default;

/// Sizes map for the chip.
/// @group chip
$kendo-chip-sizes: (
    sm: (
        padding-x: $kendo-chip-padding-x-sm,
        padding-y: $kendo-chip-padding-y-sm,
        font-size: $kendo-chip-font-size-sm,
        line-height: $kendo-chip-line-height-sm
    ),
    md: (
        padding-x: $kendo-chip-padding-x-md,
        padding-y: $kendo-chip-padding-y-md,
        font-size: $kendo-chip-font-size-md,
        line-height: $kendo-chip-line-height-md
    ),
    lg: (
        padding-x: $kendo-chip-padding-x-lg,
        padding-y: $kendo-chip-padding-y-lg,
        font-size: $kendo-chip-font-size-lg,
        line-height: $kendo-chip-line-height-lg
    )
) !default;

/// The base background of the chip.
/// @group chip
$kendo-chip-base-bg: $base-text !default;

/// Theme colors map for the chip.
/// @group chip
$kendo-chip-theme-colors: (
    "base": $kendo-chip-base-bg,
    "info": $info-txt,
    "success": $success-txt,
    "warning": $warning-txt,
    "error": $error-txt,
) !default;

/// The base background color of solid chip.
/// @group chip
$kendo-chip-solid-bg: transparent !default;
/// The base text color of solid chip.
/// @group chip
$kendo-chip-solid-text: $text-color !default;
/// The base border color of solid chip.
/// @group chip
$kendo-chip-solid-border: $component-border !default;
/// The base shadow of solid chip.
/// @group chip
$kendo-chip-solid-shadow: none !default;

/// The base background color of focused solid chip.
/// @group chip
$kendo-chip-solid-focus-bg: try-tint( $kendo-chip-base-bg, 92% ) !default;
/// The base text color of focused solid chip.
/// @group chip
$kendo-chip-solid-focus-text: null !default;

/// The base background color of hovered solid chip.
/// @group chip
$kendo-chip-solid-hover-bg: $hovered-bg !default;
/// The base text color of hovered solid chip.
/// @group chip
$kendo-chip-solid-hover-text: null !default;

/// The base background color of selected solid chip.
/// @group chip
$kendo-chip-solid-selected-bg: $pressed-bg !default;
/// The base text color of selected solid chip.
/// @group chip
$kendo-chip-solid-selected-text: null !default;

/// The base background color of outline chip.
/// @group chip
$kendo-chip-outline-bg: transparent !default;
/// The base text color of outline chip.
/// @group chip
$kendo-chip-outline-text: $text-color !default;
/// The base border color of outline chip.
/// @group chip
$kendo-chip-outline-border: transparent !default;
/// The base shadow of outline chip.
/// @group chip
$kendo-chip-outline-shadow: 0px 0px 1px rgba(0,0,0,0.5), 0px 1px 2px rgba(0,0,0,0.5) !default;

/// The base background color of hovered outline chip.
/// @group chip
$kendo-chip-outline-hover-bg: $hovered-bg !default;
/// The base text color of hovered outline chip.
/// @group chip
$kendo-chip-outline-hover-text: contrast-wcag( $kendo-chip-base-bg ) !default;

/// The base background color of selected outline chip.
/// @group chip
$kendo-chip-outline-selected-bg: $pressed-bg !default;
/// The base text color of selected outline chip.
/// @group chip
$kendo-chip-outline-selected-text: $kendo-chip-outline-hover-text !default;

// Chip List

/// The sizes of the chip list.
/// @group chip
$kendo-chip-list-sizes: (
    sm: map-get( $spacing, 1 ),
    md: map-get( $spacing, 1 ),
    lg: map-get( $spacing, 1 )
) !default;
