@include exports("colorpalette/layout") {

    // ColorPalette
    .k-colorpalette {
        border-width: 0;
        box-sizing: border-box;
        outline: 0;
        font-size: $colorpalette-font-size;
        font-family: $colorpalette-font-family;
        line-height: $colorpalette-line-height;
        display: inline-flex;
        position: relative;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        *,
        *::before,
        *::after {
            box-sizing: border-box;
        }
    }

    // Table
    .k-colorpalette-table {
        width: 100%;
        height: 100%;
        border-spacing: 4px;
        position: relative;
    }

    // Tile
    .k-colorpalette-tile {
        width: $colorpalette-tile-width;
        height: $colorpalette-tile-height;
        border-radius: $kendo-border-radius;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;
        -ms-high-contrast-adjust: none;

        &:hover,
        &.k-hover {
            position: relative;
            z-index: 101;
        }

        &:focus,
        &.k-focus,
        &.k-selected,
        &.k-selected:hover {
            position: relative;
            z-index: 100;
        }
    }

}
