// Slider
$slider-size: 200px !default;
$slider-tick-size: null !default;
$slider-alt-size: 30px !default;

$slider-font-family: $font-family !default;
$slider-font-size: $font-size !default;
$slider-line-height: $line-height !default;

$slider-button-offset: 10px !default;
$slider-button-size: ($slider-alt-size - 2) !default;
$slider-button-spacing: ($slider-button-size + $slider-button-offset) !default;
$slider-button-focused-shadow: null !default;

$slider-track-thickness: 4px !default;
$slider-draghandle-size: 14px !default;
$slider-draghandle-border-width: 1px !default;
$slider-draghandle-active-scale: 1 !default;
$slider-draghandle-active-size: null !default;

$slider-draghandle-bg: $primary !default;
$slider-draghandle-text: contrast-wcag( $primary  ) !default;
$slider-draghandle-border: $primary  !default;
$slider-draghandle-gradient: null !default;

$slider-draghandle-hovered-bg: try-shade( $primary , .5 ) !default;
$slider-draghandle-hovered-text: null !default;
$slider-draghandle-hovered-border: $primary  !default;
$slider-draghandle-hovered-gradient: null !default;

$slider-draghandle-pressed-bg: try-shade( $primary , 1.5 ) !default;
$slider-draghandle-pressed-text: null !default;
$slider-draghandle-pressed-border: try-shade( $primary , 1.5 ) !default;
$slider-draghandle-pressed-gradient: null !default;

$slider-draghandle-focused-shadow: 0 0 0 2px rgba( $primary , .3 ) !default;

$slider-transition-speed: .3s !default;
$slider-transition-function: ease-out !default;
$slider-draghandle-transition-speed: .4s !default;
$slider-draghandle-transition-function: cubic-bezier(.25, .8, .25, 1) !default;

$slider-track-bg: try-shade( $component-bg, 1 ) !default;
$slider-selection-bg: $primary !default;
$slider-disabled-opacity: null !default;
