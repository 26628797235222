@include exports( "input/layout" ) {

    // Generic input
    .k-input,
    .k-picker {
        @include border-radius( $kendo-input-border-radius );
        margin: 0;
        padding: 0;
        width: $kendo-input-default-width;
        min-width: 0;
        box-sizing: border-box;
        border-width: $kendo-input-border-width;
        border-style: solid;
        outline: 0;
        font-family: $kendo-input-font-family;
        font-size: $kendo-input-font-size;
        line-height: $kendo-input-line-height;
        font-weight: normal;
        text-align: start;
        box-shadow: none;
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: stretch;
        vertical-align: middle;
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-appearance: none;

        *,
        *::before,
        *::after {
            box-sizing: border-box;
        }

        .k-input,
        .k-picker {
            border-width: 0 !important; // sass-lint:disable-line no-important
            color: inherit;
            background: none;
            font: inherit;
            outline: 0;
            box-shadow: none;
        }

        // fix for Safari
        & > * {
            margin: 0;
        }

        &::placeholder {
            color: $kendo-input-placeholder-text;
            opacity: $kendo-input-placeholder-opacity;
            user-select: none;
        }
        &:-ms-input-placeholder {
            color: $kendo-input-placeholder-text;
        }

        &:hover, &.k-hover {
            .k-clear-value {
                display: inline-flex;
            }
        }
    
        // Clear value
        .k-clear-value {
            outline: 0;
            border-radius: 999px;
            color: $kendo-input-clear-value-text;
            flex: none;
            align-self: center;
            display: none;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background-color: #fff;
            opacity: $kendo-input-clear-value-opacity;
            position: absolute;
            z-index: 4;
        }
    }

    // Input and Textarea
    input.k-input,
    textarea.k-textarea {
        padding: $kendo-input-padding-y $kendo-input-padding-x;

        &:disabled,
        &[disabled] {
            @include disabled( $disabled-styling );

            [disabled] &,
            .k-disabled & {
                opacity: 1;
                filter: grayscale(0);
            }
        }
    }

    // Picker
    .k-picker, .k-multiselect, .k-multiselect .k-chip-list, .k-dropdowntree, .k-dropdowntree .k-chip-list {
        cursor: pointer;
    }

    select.k-picker {
        padding: $kendo-input-padding-y $kendo-input-padding-x;
        //appearance: auto;

        &:disabled,
        &[disabled] {
            @include disabled( $disabled-styling );

            [disabled] &,
            .k-disabled & {
                opacity: 1;
                filter: grayscale(0);
            }
        }
    }


    // Input inner
    .k-input-inner {
        padding: $kendo-input-padding-y $kendo-input-padding-x;
        width: 100%;
        border: 0;
        border-radius: 0px;
        outline: 0;
        color: inherit;
        background: none;
        font: inherit;
        flex: 1;
        position: relative;
        z-index: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-appearance: none;

        // Hide clear icon
        &::-ms-clear { display: none; }

        // Placeholder text
        &::placeholder {
            color: $kendo-input-placeholder-text;
            opacity: $kendo-input-placeholder-opacity;
            user-select: none;
        }
        &:-ms-input-placeholder {
            color: $kendo-input-placeholder-text;
        }

        // Invalid
        &:invalid {
            box-shadow: none;
        }
    }
    span.k-input-inner {
        white-space: nowrap;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
    textarea.k-input-inner {
        margin: 0;
    }


    // Input value
    .k-input-value-icon {
        flex: none;
    }
    .k-input-value-text {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .k-input-value-text::before {
        content: "\200b";
        width: 0px;
        overflow: hidden;
        flex: none;
        display: inline-block;
        vertical-align: top;
    }


    // Input multiple values
    .k-input-values {
        width: 100%;
        padding: $kendo-input-values-margin-y 0px $kendo-input-values-margin-y $kendo-input-values-margin-x;
        cursor: text;
    }

    // Icon picker
    .k-icon-picker {
        .k-input-inner {
            padding: $kendo-input-padding-y;
            // TODO we need better way
            // width: $kendo-input-inner-calc-height;
            // height: $kendo-input-inner-calc-height;
            justify-content: center;
        }
    }


    // Input prefix and suffix
    .k-input-prefix,
    .k-input-suffix {
        border-color: inherit;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        flex: none;

        > * {
            flex-shrink: 0;
        }
    }


    // Input separator
    .k-input-separator {
        margin: 0;
        width: 0;
        height: $icon-size;
        border-width: 0 0 0 1px;
        border-style: solid;
        border-color: inherit;
        align-self: center;
    }


    // Input with icon styles
    .k-input-icon,
    .k-input-validation-icon,
    .k-input-loading-icon,
    .k-input-prefix > .k-icon,
    .k-input-suffix > .k-icon {
        flex: none;
        align-self: center;
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
    }

    // Input button
    .k-input-button,
    .k-input-spinner,
    .k-input-prefix .k-button, 
    .k-input-suffix .k-button {
        width: $kendo-input-button-size;
        border-width: 0;
        border-radius: 0;
        border-inline-start-width: $kendo-input-button-border-width;
        flex: none;
        aspect-ratio: auto;
        box-shadow: none;

        > .k-button-icon {
            min-width: auto !important; // sass-lint:disable-line no-important
            // min-height: auto !important; // sass-lint:disable-line no-important
        }

        &:focus {
            box-shadow: none;
        }
    }

    .k-input-prefix .k-button, 
    .k-input-suffix .k-button {
        height: $kendo-input-button-size;
    }

    .k-picker .k-input-button {
        color: inherit;
        background: none;
        border-color: transparent;
    }

    .k-picker, 
    .k-combobox {
        .k-icon.k-i-arrow-s, .k-icon.k-i-caret-alt-down {
            font-size: 26px;
        }
    }


    // Input spinner
    .k-input-spinner {
        flex: none;
        display: flex;
        flex-flow: column nowrap;
        .k-icon {
            font-size: $kendo-input-spinner-font-size;
        }

        .k-spinner-increase,
        .k-spinner-decrease {
            border-width: 0;
            border-radius: 0;
            border-inline-start-width: $kendo-input-button-border-width;
            padding: 0px;
            flex: 1 1 50%;
            box-shadow: none;
            position: relative;
            aspect-ratio: auto;

            > .k-button-icon {
                margin: -1em 0;
                min-width: auto !important; // sass-lint:disable-line no-important
                min-height: auto !important; // sass-lint:disable-line no-important
            }
        }
        .k-spinner-increase .k-icon {
            bottom: if( $use-input-spinner-icon-offset, calc( -1 * #{$kendo-input-spinner-icon-offset}), auto );
        }
        .k-spinner-decrease .k-icon {
            top: if( $use-input-spinner-icon-offset, calc( -1 * #{$kendo-input-spinner-icon-offset}), auto );
        }
    }


    // Searchbar
    .k-searchbar {
        flex: 1 1 auto;
        display: flex;
        flex-flow: row nowrap;
    }


    // Legacy wrappers
    .k-picker-wrap,
    .k-dropdown-wrap,
    .k-dateinput-wrap,
    .k-multiselect-wrap,
    .k-numeric-wrap {
        width: 100%;
        border-width: 0;
        border-color: inherit;
        box-sizing: border-box;
        flex: 1 1 auto;
        display: flex;
        flex-flow: row nowrap;
        overflow: hidden;
        position: relative;
    }


    // Fill mode
    .k-input-flat,
    .k-picker-flat {
        @include border-radius( 0 !important ); // sass-lint:disable-line no-important
        border-width: $kendo-input-border-width 0;
        border-top-color: transparent !important; // sass-lint:disable-line no-important
        background-image: none !important; // sass-lint:disable-line no-important

        &:focus,
        &.k-focus {
            @include border-radius( $kendo-input-flat-border-radius !important ); // sass-lint:disable-line no-important
        }
        &:focus-within {
            @include border-radius( $kendo-input-flat-border-radius !important ); // sass-lint:disable-line no-important
        }
    }

    .k-input-outline {
        background: none !important; // sass-lint:disable-line no-important
    }

    .k-picker.k-picker-flat {
        @include border-radius( $kendo-input-flat-border-radius !important ); // sass-lint:disable-line no-important
    }

    // Sizing
    @each $size, $size-props in $kendo-input-sizes {
        $_padding-x: map-get( $size-props, padding-x );
        $_padding-y: map-get( $size-props, padding-y );
        $_font-size: map-get( $size-props, font-size );
        $_line-height: map-get( $size-props, line-height );
        $_input-inner-height: map-get( $size-props, input-inner-height );
        $_icon-size: map-get( $size-props, icon-size );
        $_input-values-size: map-get( $size-props, input-values-size );
        $_input-icon-offset-y: map-get( $size-props, input-icon-offset-y );
        $_input-button-offset-x: map-get( $size-props, input-button-offset-x );
        $_picker-caret-size: map-get( $size-props, picker-caret-size );
        $_button-padding-x: map-get( $size-props, button-padding-x );
        $_button-padding-y: map-get( $size-props, button-padding-y );
        $_color-picker-preview-size: map-get( $size-props, color-picker-preview-size );
        $_input-button-size: map-get( $size-props, input-button-size );
        $_input-spinner-font-size: map-get( $size-props, input-spinner-font-size );
        $_input-spinner-icon-offset: map-get( $size-props, input-spinner-icon-offset );

        .k-input-#{$size},
        .k-picker-#{$size} {
            font-size: $_font-size;
            line-height: $_line-height;

            .k-input-inner {
                padding: $_padding-y $_padding-x;
            }

            .k-input-button,
            .k-input-prefix .k-button, 
            .k-input-suffix .k-button {
                height: $_input-button-size;
                width: $_input-button-size;
                padding: 0px;
                .k-icon {
                    font-size: $_font-size;
                }
            }

            .k-input-spinner {
                width: $_input-button-size;
                padding: 0px;
                .k-icon {
                    font-size: $_input-spinner-font-size;
                }
        
                .k-spinner-increase .k-icon {
                    bottom: if( $use-input-spinner-icon-offset, calc( -1 * #{$_input-spinner-icon-offset}), auto );
                }
                .k-spinner-decrease .k-icon {
                    top: if( $use-input-spinner-icon-offset, calc( -1 * #{$_input-spinner-icon-offset}), auto );
                }
            }

            &:not(.k-dropdowntree.k-input):not(.k-multiselect):not(.k-textarea) {
                &.k-required, &.mri-k-show-validation {
                    .k-input-inner {
                        background-size: $_font-size $_font-size;
                        background-position: calc(100% - #{$_padding-x}) center;
                    }
                    &.k-numerictextbox.align-right .k-input-inner {
                        background-position: $_padding-x center !important;
                    }

                    &.k-required, 
                    &.k-warning,
                    &.k-invalid,
                    &.ng-invalid.ng-touched,
                    &.ng-invalid.ng-dirty,
                    &.k-success, &.k-valid:not(.ng-pristine),
                    &.ng-valid.ng-touched:not(.ng-pristine),
                    &.ng-valid.ng-dirty:not(.ng-pristine) {
                        .k-input-inner {
                            padding-right: calc( (#{$_padding-x} * 2)  + $_font-size );
                        }
                        &.k-numerictextbox.align-right .k-input-inner {
                            padding-left: calc( (#{$_padding-x} * 2)  + $_font-size ) !important;
                            padding-right: $_padding-x !important;
                        }
                    }
                }
            }

            &.k-is-loading {
                .k-input-inner {
                    padding-right: calc( (#{$_padding-x} * 2) + #{$_font-size} ) !important;
                }
                &.k-maskedtextbox::after {
                    right: $_padding-x !important;
                }
                &.k-textbox {
                    .k-input-suffix::before {
                        left: calc( #{$_font-size} * -1 ) !important;
                    }
                    .k-input-suffix:empty::before {
                        left: calc( #{$_font-size} * -1 ) !important;
                    }
                }
                &.k-numerictextbox {
                    .k-input-spinner::before {
                        left: calc( #{$_padding-x} * -2) !important;
                    }
                    .k-input-spinner:empty::before {
                        left: calc( #{$_padding-x} * -1) !important;
                    }
                }
                &.k-numerictextbox.align-right {
                    .k-input-inner {
                        padding-left: calc( (#{$_padding-x} * 2) + #{$_font-size} ) !important;
                        padding-right: $_padding-x !important;
                    }
                    .k-input-spinner::before {
                        right: -16px !important;
                        left: unset !important;
                    }
                    .k-input-spinner:empty::before {
                        right: calc( #{$_padding-x} * -1) !important;
                        left: unset !important;
                    }
                }
                &.k-textarea {
                    &.\!k-flex-row {
                        &::before {
                            top: calc( (#{$_font-size} * 0.5) + #{$_padding-y} + 2px ) !important;
                            right: calc( #{$_padding-x} + 28px ) !important;
                        }
                    }
        
                    &.\!k-flex-col {
                        &::before {
                            top: calc( (#{$_font-size} * 0.5) + #{$_padding-y} + 2px ) !important;
                            right: $_padding-x !important;
                        }
                    }
                }
            }

            &.k-dropdowntree.k-input, &.k-multiselect {
                padding-right: calc( (#{$_padding-x} * 2) + #{$_input-button-size} + #{$_font-size} ) !important;

                &.k-required, &.mri-k-show-validation {
                    background-size: $_font-size $_font-size !important;
                    background-position-x: calc(100% - (#{$_padding-x} + #{$_input-button-size} - #{$_input-button-offset-x})) !important;
                    background-position-y: $_input-icon-offset-y !important;
                }
    
                &::after {
                    mask: url("data:image/svg+xml,%3Csvg width='#{$_font-size}' height='#{$_font-size}' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.75008 1.1665H2.91675C3.23758 1.1665 3.50008 1.429 3.50008 1.74984V2.9165C3.50008 3.23734 3.23758 3.49984 2.91675 3.49984H1.75008C1.42925 3.49984 1.16675 3.23734 1.16675 2.9165V1.74984C1.16675 1.429 1.42925 1.1665 1.75008 1.1665ZM11.0834 3.49984H5.25008C4.92925 3.49984 4.66675 3.23734 4.66675 2.9165V1.74984C4.66675 1.429 4.92925 1.1665 5.25008 1.1665H11.0834C11.4042 1.1665 11.6667 1.429 11.6667 1.74984V2.9165C11.6667 3.23734 11.4042 3.49984 11.0834 3.49984ZM12.2501 5.83317H5.25008C4.92925 5.83317 4.66675 6.09567 4.66675 6.4165V7.58317C4.66675 7.904 4.92925 8.1665 5.25008 8.1665H12.2501C12.5709 8.1665 12.8334 7.904 12.8334 7.58317V6.4165C12.8334 6.09567 12.5709 5.83317 12.2501 5.83317ZM1.75008 5.83317H2.91675C3.23758 5.83317 3.50008 6.09567 3.50008 6.4165V7.58317C3.50008 7.904 3.23758 8.1665 2.91675 8.1665H1.75008C1.42925 8.1665 1.16675 7.904 1.16675 7.58317V6.4165C1.16675 6.09567 1.42925 5.83317 1.75008 5.83317ZM9.91675 10.4998H5.25008C4.92925 10.4998 4.66675 10.7623 4.66675 11.0832V12.2498C4.66675 12.5707 4.92925 12.8332 5.25008 12.8332H9.91675C10.2376 12.8332 10.5001 12.5707 10.5001 12.2498V11.0832C10.5001 10.7623 10.2376 10.4998 9.91675 10.4998ZM1.75008 10.4998H2.91675C3.23758 10.4998 3.50008 10.7623 3.50008 11.0832V12.2498C3.50008 12.5707 3.23758 12.8332 2.91675 12.8332H1.75008C1.42925 12.8332 1.16675 12.5707 1.16675 12.2498V11.0832C1.16675 10.7623 1.42925 10.4998 1.75008 10.4998Z' fill='black'/%3E%3C/svg%3E%0A");
                    -webkit-mask-repeat: no-repeat;
                    mask-repeat: no-repeat;
                    -webkit-mask-position: center;
                    mask-position: center;
                    height: calc( (#{$_line-height} * #{$_font-size}) + #{$_padding-y * 2} );
                    width: $_font-size;
                    top: 0;
                    right: $_padding-x;
                }

                .k-input-values {
                    width: 100%;
                    min-height: $_input-values-size;
                    padding: $_padding-y 0 $_padding-y $_padding-x;
                    gap: ($_padding-y * 0.5);
                    cursor: text;

                    .k-chip {
                        padding-top: 1px;
                        padding-bottom: 1px;
                        .k-icon {
                            font-size: $_font-size;
                        }
                    }

                    .k-input-inner {
                        padding:  0px;
                        width: 1em;
                        height: ($_font-size + 2px);
                    }
                }
    
                .k-clear-value {
                    right: calc( #{$_padding-x} + #{$_input-button-size} - #{$_input-button-offset-x});
                    top: calc( ((#{$_input-inner-height} - #{$_font-size}) * 0.5) + #{$_padding-y} );
                }
            }

            &.k-textarea {
                &.k-required, &.mri-k-show-validation {
                    .k-input-inner {
                        background-size: $_font-size $_font-size !important;
                        background-position: calc(100% - #{$_padding-x}) $_input-icon-offset-y !important;
                    }
                }

                &.k-required, 
                &.k-warning,
                &.k-invalid,
                &.ng-invalid.ng-touched,
                &.ng-invalid.ng-dirty,
                &.k-success, &.k-valid:not(.ng-pristine),
                &.ng-valid.ng-touched:not(.ng-pristine),
                &.ng-valid.ng-dirty:not(.ng-pristine) {
                    .k-input-inner {
                        padding-right: calc( (#{$_padding-x} * 2)  + $_font-size ) !important;
                    }
                }
            }

            &.k-dropdowntree.k-picker .k-clear-value {
                right: calc(#{$_padding-x} + #{$_input-button-size});
            }

            &.k-autocomplete {
                .k-input-inner {
                    padding-right: calc( (#{$_padding-x} * 2) + #{$_font-size}  ) !important;
                }
                .k-clear-value {
                    right: $_padding-x;
                }
            }

            &.k-combobox .k-clear-value {
                right: calc(#{$_padding-x} + #{$_input-button-size});
            }

            &.k-datepicker {
                .k-input-button .k-icon::before {
                    width: $_font-size;
                    height: $_font-size;
                    mask: url("data:image/svg+xml,%3Csvg width='#{$_font-size}' height='#{$_font-size}' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.2501 1.1665H1.75008C1.42925 1.1665 1.16675 1.429 1.16675 1.74984V12.2498C1.16675 12.5707 1.42925 12.8332 1.75008 12.8332H12.2501C12.5709 12.8332 12.8334 12.5707 12.8334 12.2498V1.74984C12.8334 1.429 12.5709 1.1665 12.2501 1.1665ZM6.41675 5.83317V6.99984H4.95841V5.83317H6.41675ZM7.58342 5.83317H9.04175V6.99984H7.58342V5.83317ZM9.04175 8.1665V9.33317H7.58342V8.1665H9.04175ZM10.2084 8.1665H11.6667V9.33317H10.2084V8.1665ZM10.2084 6.99984V5.83317H11.6667V6.99984H10.2084ZM3.79175 5.83317V6.99984H2.33341V5.83317H3.79175ZM2.33341 8.1665H3.79175V9.33317H2.33341V8.1665ZM4.95841 8.1665H6.41675V9.33317H4.95841V8.1665ZM6.41675 10.4998V11.6665H4.95841V10.4998H6.41675ZM7.58342 10.4998H9.04175V11.6665H7.58342V10.4998ZM2.33341 10.4998H3.79175V11.6665H2.33341V10.4998Z' fill='black'/%3E%3C/svg%3E%0A");
                }
            }

            &.k-datetimepicker {
                .k-input-button .k-icon.k-i-calendar::before { 
                    width: $_font-size;
                    height: $_font-size;
                    mask: url("data:image/svg+xml,%3Csvg width='#{$_font-size}' height='#{$_font-size}' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.2501 1.1665H1.75008C1.42925 1.1665 1.16675 1.429 1.16675 1.74984V12.2498C1.16675 12.5707 1.42925 12.8332 1.75008 12.8332H12.2501C12.5709 12.8332 12.8334 12.5707 12.8334 12.2498V1.74984C12.8334 1.429 12.5709 1.1665 12.2501 1.1665ZM6.41675 5.83317V6.99984H4.95841V5.83317H6.41675ZM7.58342 5.83317H9.04175V6.99984H7.58342V5.83317ZM9.04175 8.1665V9.33317H7.58342V8.1665H9.04175ZM10.2084 8.1665H11.6667V9.33317H10.2084V8.1665ZM10.2084 6.99984V5.83317H11.6667V6.99984H10.2084ZM3.79175 5.83317V6.99984H2.33341V5.83317H3.79175ZM2.33341 8.1665H3.79175V9.33317H2.33341V8.1665ZM4.95841 8.1665H6.41675V9.33317H4.95841V8.1665ZM6.41675 10.4998V11.6665H4.95841V10.4998H6.41675ZM7.58342 10.4998H9.04175V11.6665H7.58342V10.4998ZM2.33341 10.4998H3.79175V11.6665H2.33341V10.4998Z' fill='black'/%3E%3C/svg%3E%0A");
                }
        
                .k-input-button .k-icon.k-i-clock::before { 
                    width: $_font-size;
                    height: $_font-size;
                    mask: url("data:image/svg+xml,%3Csvg width='#{$_font-size}' height='#{$_font-size}' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.8334 6.99984C12.8334 10.2215 10.2217 12.8332 7.00008 12.8332C3.77842 12.8332 1.16675 10.2215 1.16675 6.99984C1.16675 3.77818 3.77842 1.1665 7.00008 1.1665C10.2217 1.1665 12.8334 3.77818 12.8334 6.99984ZM2.33341 6.99984C2.33341 9.57717 4.42275 11.6665 7.00008 11.6665C9.57741 11.6665 11.6667 9.57717 11.6667 6.99984C11.6667 4.42251 9.57741 2.33317 7.00008 2.33317C4.42275 2.33317 2.33341 4.42251 2.33341 6.99984ZM9.33342 7.2915C9.33342 7.45192 9.20217 7.58317 9.04175 7.58317H7.29175H6.70842C6.548 7.58317 6.41675 7.45192 6.41675 7.2915V6.70817V3.7915C6.41675 3.63109 6.548 3.49984 6.70842 3.49984H7.29175C7.45217 3.49984 7.58342 3.63109 7.58342 3.7915V6.4165H9.04175C9.20217 6.4165 9.33342 6.54775 9.33342 6.70817V7.2915Z' fill='black'/%3E%3C/svg%3E%0A");
                }
            }

            &.k-datetimepicker:not(span) {
                .k-input-button .k-icon.k-i-calendar::before {
                    width: $_font-size;
                    height: $_font-size;
                    mask: url("data:image/svg+xml,%3Csvg width='#{$_font-size}' height='#{$_font-size}' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6667 1.45817C11.6667 1.29709 11.5362 1.1665 11.3751 1.1665H1.45841C1.29733 1.1665 1.16675 1.29709 1.16675 1.45817V11.3748C1.16675 11.5359 1.29733 11.6665 1.45841 11.6665H5.54143C5.27735 11.3149 5.065 10.9223 4.91569 10.4998H4.66675V9.33317H4.67632C4.66997 9.23677 4.66675 9.13951 4.66675 9.0415C4.66675 8.74185 4.69687 8.44923 4.75426 8.1665H4.66675V6.99984H5.17136C5.58271 6.22165 6.22189 5.58247 7.00008 5.17111V4.6665H8.16675V4.75402C8.44947 4.69663 8.74209 4.6665 9.04175 4.6665C9.13976 4.6665 9.23701 4.66973 9.33342 4.67607V4.6665H10.5001V4.91545C10.9225 5.06476 11.3152 5.2771 11.6667 5.54118V1.45817ZM3.50008 8.1665H2.33341V6.99984H3.50008V8.1665ZM2.33341 9.33317H3.50008V10.4998H2.33341V9.33317ZM2.33341 5.83317V4.6665H3.50008V5.83317H2.33341ZM4.66675 4.6665H5.83342V5.83317H4.66675V4.6665Z' fill='black'/%3E%3Cpath d='M9.33342 6.99984C9.4945 6.99984 9.62508 7.13042 9.62508 7.2915V8.45817H10.2084C10.3695 8.45817 10.5001 8.58875 10.5001 8.74984V9.33317C10.5001 9.49425 10.3695 9.62484 10.2084 9.62484H8.75008C8.589 9.62484 8.45842 9.49425 8.45842 9.33317V7.2915C8.45842 7.13042 8.589 6.99984 8.75008 6.99984H9.33342Z' fill='black'/%3E%3Cpath d='M5.25008 9.0415C5.25008 6.94742 6.94767 5.24984 9.04175 5.24984C11.1358 5.24984 12.8334 6.94742 12.8334 9.0415C12.8334 11.1356 11.1358 12.8332 9.04175 12.8332C6.94767 12.8332 5.25008 11.1356 5.25008 9.0415ZM9.04175 6.4165C7.592 6.4165 6.41675 7.59176 6.41675 9.0415C6.41675 10.4913 7.592 11.6665 9.04175 11.6665C10.4915 11.6665 11.6667 10.4913 11.6667 9.0415C11.6667 7.59176 10.4915 6.4165 9.04175 6.4165Z' fill='black'/%3E%3C/svg%3E%0A");
                }
            }

            &.k-timepicker {
                .k-input-button .k-icon::before {
                    width: $_font-size;
                    height: $_font-size;
                    mask: url("data:image/svg+xml,%3Csvg width='#{$_font-size}' height='#{$_font-size}' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.8334 6.99984C12.8334 10.2215 10.2217 12.8332 7.00008 12.8332C3.77842 12.8332 1.16675 10.2215 1.16675 6.99984C1.16675 3.77818 3.77842 1.1665 7.00008 1.1665C10.2217 1.1665 12.8334 3.77818 12.8334 6.99984ZM2.33341 6.99984C2.33341 9.57717 4.42275 11.6665 7.00008 11.6665C9.57741 11.6665 11.6667 9.57717 11.6667 6.99984C11.6667 4.42251 9.57741 2.33317 7.00008 2.33317C4.42275 2.33317 2.33341 4.42251 2.33341 6.99984ZM9.33342 7.2915C9.33342 7.45192 9.20217 7.58317 9.04175 7.58317H7.29175H6.70842C6.548 7.58317 6.41675 7.45192 6.41675 7.2915V6.70817V3.7915C6.41675 3.63109 6.548 3.49984 6.70842 3.49984H7.29175C7.45217 3.49984 7.58342 3.63109 7.58342 3.7915V6.4165H9.04175C9.20217 6.4165 9.33342 6.54775 9.33342 6.70817V7.2915Z' fill='black'/%3E%3C/svg%3E%0A");
                }
            }
                
            &.k-color-picker .k-input-inner {
                padding: 2px !important;
                width: $_color-picker-preview-size !important;
                height: $_color-picker-preview-size !important;
            }

            .k-input-icon,
            .k-input-validation-icon,
            .k-input-loading-icon {
                width: $_icon-size;
                height: $_icon-size;
            }

            &.k-textbox {
                .k-input-prefix > .k-icon {
                    margin-left: $_padding-x;
                }

                .k-input-suffix > .k-icon {
                    margin-right: $_padding-x;
                }
            }

            .k-clear-value {
                height: $_font-size + 4px;
                line-height: $_font-size + 4px;
                width: $_font-size + 4px;
                margin: -2px;
                .k-icon {
                    font-size: $_font-size + 2px;
                }
            }

            &.k-icon-picker .k-input-inner {
                width: calc( #{$_line-height * 1em} );
                height: calc( #{$_line-height * 1em} );
                padding: $_padding-y;
                box-sizing: content-box;
            }

            .k-dropdown-operator.k-picker-#{$size} {
                .k-input-button {
                    padding: $_padding-y;
                }
            }
            &.k-picker, 
            &.k-combobox {
                .k-icon.k-i-arrow-s, .k-icon.k-i-caret-alt-down {
                    font-size: $_picker-caret-size;
                }
            }

            + .k-form-error {
                top: calc( (#{$_line-height} * #{$_font-size}) + #{$_padding-y * 2} + #{$kendo-input-border-width * 2});
                padding: $_padding-y $_padding-x;
                font-size: $_font-size;
            }
        
        }
    }


    // Angular specific
    .k-input > kendo-popup,
    .k-picker > kendo-popup {
        position: fixed;
    }
}

@include exports("input/layout/autofill-fix") {

    // This is needed for the textbox container to capture browser autofill
    @keyframes autoFillStart {
        from {}
        to {}
    }
    @keyframes autoFillEnd {
        from {}
        to {}
    }

    // Generic class for autofill animations
    .k-autofill:-webkit-autofill {
        animation-name: autoFillStart;
    }
    .k-autofill:not(:-webkit-autofill) {
        animation-name: autoFillEnd;
    }


    .k-input:-webkit-autofill {
        animation-name: autoFillStart;
    }
    .k-input:not(:-webkit-autofill) {
        animation-name: autoFillEnd;
    }

}
