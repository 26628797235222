@include exports("button/layout") {

    // Button
    .k-button {
        box-sizing: border-box;
        border-width: $kendo-button-border-width;
        border-style: solid;
        border-radius: $kendo-button-border-radius;
        color: inherit;
        background: none;
        font-family: $kendo-button-font-family;
        text-align: center;
        text-decoration: none;
        white-space: nowrap;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: $icon-spacing;
        vertical-align: middle;
        user-select: none;
        cursor: pointer;
        outline: none;
        -webkit-appearance: none;
        position: relative;
        transition: $kendo-button-transition;
        padding: $kendo-button-padding-y-md $kendo-button-padding-x-md;
        font-size: $kendo-button-font-size-md;
        line-height: $kendo-button-line-height-md;


        *,
        *::before,
        *::after {
            box-sizing: border-box;
        }

        &:disabled,
        &.k-disabled {
            @include disabled( $disabled-styling );
        }

        &::-moz-focus-inner {
            padding: 0;
            border: 0;
            outline: 0;
        }

        &:hover,
        &:focus {
            text-decoration: none;
            outline: 0;
        }
    }

    //Quiet button
    .k-button-flat {
        padding-left: calc( #{$kendo-button-padding-x-md} * 0.5);
        padding-right: calc( #{$kendo-button-padding-x-md} * 0.5);
    }


    // Icon Button
    .k-icon-button {
        gap: 0;

        .k-icon {
            display: inline-flex;
        }
    }

    .k-icon-button:not(.k-button-group .k-icon-button):not(.k-split-button .k-icon-button) {
        border-color: transparent !important; // sass-lint:disable-line no-important
    }

    // Content
    .k-button-icon {
        color: inherit;
        align-self: center;
        position: relative;
    }

    .k-button-text {
        display: inline-flex;
        align-items: center;
    }

    // Sizes
    @each $size, $size-props in $kendo-button-sizes {
        $_padding-x: map-get( $size-props, padding-x );
        $_padding-y: map-get( $size-props, padding-y );
        $_font-size: map-get( $size-props, font-size );
        $_line-height: map-get( $size-props, line-height );
        $_icon-font-size: map-get( $size-props, icon-font-size );

        .k-button-#{$size} {
            padding: $_padding-y $_padding-x;
            font-size: $_font-size;
            line-height: $_line-height;

            > .k-button-icon {
                font-size: $_icon-font-size;
            }
            
            .k-badge {
                font-size: $_font-size - 4px;
                line-height: $_line-height;
            }
        }

        .k-button-#{$size}.k-button-flat {
            padding-left: calc( #{$_padding-x} * 0.5);
            padding-right: calc( #{$_padding-x} * 0.5);
        }
    

        .k-button-#{$size}.k-icon-button {
            padding: $_padding-y;

            > .k-button-icon {
                font-size: $_icon-font-size;
                min-width: calc( #{$_font-size} * #{$_line-height} );
                min-height: calc( #{$_font-size} * #{$_line-height} );
            }
        }
    
        .k-button-group .k-icon-button {
            &.k-button-#{$size} {
                padding: $_padding-y $_padding-x;
                > .k-button-icon {
                    min-width: $_font-size;
                }
            }
        }
    }

    // Button shape
    .k-button-square {
        aspect-ratio: 1;
    }


    // Button group
    .k-button-group {
        margin: 0;
        padding: 0;
        border-width: 0;
        box-sizing: border-box;
        list-style: none;
        outline: 0;
        display: inline-flex;
        flex-flow: row nowrap;
        vertical-align: middle;
        position: relative;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        > .k-button + .k-button {
            margin-inline-start: if( $kendo-button-border-width == 0, null, -$kendo-button-border-width );
        }

        > .k-button:hover,
        > .k-button.k-hover,
        > .k-button:focus,
        > .k-button.k-focus,
        > .k-button:active,
        > .k-button.k-active,
        > .k-button.k-selected {
            z-index: 2;
        }

        .k-button:not(:first-child):not(:last-child) {
            border-start-end-radius: 0;
            border-end-end-radius: 0;
            border-start-start-radius: 0;
            border-end-start-radius: 0;
        }
        > .k-button:first-child:not(:only-child) {
            border-start-end-radius: 0;
            border-end-end-radius: 0;
        }
        > .k-button:last-child:not(:only-child) {
            border-start-start-radius: 0;
            border-end-start-radius: 0;
        }

        &:disabled,
        &[disabled],
        &.k-disabled {
            opacity: 1;
            filter: none;
        }

        &.mri-k-button-group-selector {
            box-sizing: border-box;
            border-width: 0px;
            border-style: solid;
            border-radius: $kendo-button-border-radius;
            padding: 4px;
            gap: 4px;
            > .k-button {
                border-radius: $kendo-button-border-radius !important;
            }
        }
    }

    .k-button-group-stretched {
        width: 100%;

        .k-button {
            flex: 1 0 0%;
            overflow: hidden;

            .k-button-text {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    // IE
    .k-ie .k-button-group {
        .k-button {
            @include border-radius( 0 );
        }
    }

}




@include exports( "button/layout/overlay" ) {

    // Button overlay
    .k-button-overlay {
        @include border-radius( inherit );
        content: "";
        background: currentColor;
        opacity: 0;
        display: none;
        pointer-events: none;
        position: absolute;
        left: -$kendo-button-border-width;
        right: -$kendo-button-border-width;
        top: -$kendo-button-border-width;
        bottom: -$kendo-button-border-width;
        z-index: 0;
        transition: opacity .2s ease-in-out;
    }


    .k-button {

        &::before {
            @extend .k-button-overlay;
        }

        // Hovered state
        &:hover,
        &.k-hover {
            &::before {
                opacity: $kendo-flat-button-hover-opacity;
            }
        }

        // Focused state
        &:focus,
        &.k-focus {
            &::before {
                opacity: $kendo-flat-button-focus-opacity;
            }
        }
        &.k-no-focus:not(:hover),
        &.k-no-focus:not(.k-hover) {
            &::before {
                opacity: 0;
            }
        }

        // Active state
        &:active,
        &.k-active {
            &::before {
                opacity: $kendo-flat-button-active-opacity;
            }
        }

        // Selected state
        &.k-selected {
            &::before {
                opacity: $kendo-flat-button-selected-opacity;
            }
        }

    }

}




@include exports( "button/layout/focus-ring" ) {

    .k-button-not-used {

        &::after {
            @include border-radius( inherit );
            content: "";
            opacity: 0;
            display: none;
            pointer-events: none;
            position: absolute;
            left: -$kendo-button-border-width;
            right: -$kendo-button-border-width;
            top: -$kendo-button-border-width;
            bottom: -$kendo-button-border-width;
            z-index: 0;
            transition: opacity .2s ease-in-out;
        }
    }
}
