// Input

/// Default width of input components.
/// @group input
$kendo-input-default-width: 100% !default;

/// Border width of input components.
/// @group input
$kendo-input-border-width: 1px !default;
$kendo-input-border-height: ( $kendo-input-border-width * 2 ) !default;
/// Border radius of input components.
/// @group input
$kendo-input-border-radius: null !default;

/// Horizontal padding of input components.
/// @group input
$kendo-input-padding-x: map-get( $spacing, 2 ) !default;
/// Vertical padding of input components.
/// @group input
$kendo-input-padding-y: map-get( $spacing, 2 ) !default;
/// Font family of input components.
/// @group input
$kendo-input-font-family: $font-family !default;
/// Font size of input components.
/// @group input
$kendo-input-font-size: $font-size-md !default;
/// Line height of input components.
/// @group input
$kendo-input-line-height: $line-height-md !default;

// Input actions
$kendo-input-button-size: calc( (#{$kendo-input-line-height} * #{$kendo-input-font-size}) + (#{$kendo-input-padding-y} * 2) ) !default;
$kendo-input-button-border-width: 1px !default;
$kendo-input-spinner-font-size: calc( #{$kendo-input-font-size}  + 2px) !default;
$kendo-input-spinner-icon-offset: 3px !default;
$kendo-color-picker-preview-size: calc( #{$kendo-input-border-width * 4} + #{$kendo-input-padding-x} + #{$kendo-input-line-height * 1em} ) !default;


$kendo-input-padding-x-sm: map-get( $spacing, 1 ) !default;
$kendo-input-padding-y-sm: map-get( $spacing, thin ) !default;
$kendo-input-font-size-sm: $font-size-sm !default;
$kendo-input-line-height-sm: $line-height-sm !default;
$kendo-input-button-size-sm: calc( (#{$kendo-input-line-height-sm} * #{$kendo-input-font-size-sm}) + #{$kendo-input-padding-y-sm * 2} ) !default;
$kendo-color-picker-preview-size-sm: calc( #{$line-height-sm * 1em} ) !default;
$kendo-input-spinner-font-size-sm: calc( #{$kendo-input-font-size-sm}  + 1px) !default;
$kendo-input-spinner-icon-offset-sm: 1px !default;

$kendo-input-padding-x-md: $kendo-input-padding-x !default;
$kendo-input-padding-y-md: $kendo-input-padding-y !default;
$kendo-input-font-size-md: $kendo-input-font-size !default;
$kendo-input-line-height-md: $kendo-input-line-height !default;
$kendo-input-button-size-md: $kendo-input-button-size !default;
$kendo-color-picker-preview-size-md: $kendo-color-picker-preview-size !default;
$kendo-input-spinner-font-size-md: $kendo-input-spinner-font-size !default;
$kendo-input-spinner-icon-offset-md: $kendo-input-spinner-icon-offset !default;

$kendo-input-padding-x-lg: map-get( $spacing, 2 ) !default;
$kendo-input-padding-y-lg: map-get( $spacing, 2 ) !default;
$kendo-input-font-size-lg: 16px !default;
$kendo-input-line-height-lg: $line-height-lg !default;
$kendo-input-button-size-lg: calc( (#{$kendo-input-line-height-lg} * #{$kendo-input-font-size-lg}) + #{$kendo-input-padding-y-lg * 2} ) !default;
$kendo-color-picker-preview-size-lg: calc( #{$kendo-input-border-width * 4} + #{$kendo-input-padding-y-lg} + #{$kendo-input-line-height-lg * 1em} ) !default;
$kendo-input-spinner-font-size-lg: $kendo-input-spinner-font-size !default;
$kendo-input-spinner-icon-offset-lg: $kendo-input-spinner-icon-offset !default;

$kendo-input-sizes: (
    sm: (
        padding-x: $kendo-input-padding-x-sm,
        padding-y: $kendo-input-padding-y-sm,
        font-size: $kendo-input-font-size-sm,
        line-height: $kendo-input-line-height-sm,
        input-inner-height: 16px,
        icon-size: calc( #{$icon-size} + #{$kendo-input-padding-y-sm * 2} ),
        picker-caret-size: 18px,
        input-values-size: 20px,
        input-icon-offset-y: 4px,
        input-button-offset-x: -1px,
        button-padding-x: $kendo-input-padding-y-sm,
        button-padding-y: $kendo-input-padding-y-sm,
        input-button-size: $kendo-input-button-size-sm,
        color-picker-preview-size: $kendo-color-picker-preview-size-sm,
        input-spinner-font-size: $kendo-input-spinner-font-size-sm,
        input-spinner-icon-offset: $kendo-input-spinner-icon-offset-sm
    ),
    md: (
        padding-x: $kendo-input-padding-x-md,
        padding-y: $kendo-input-padding-y-md,
        font-size: $kendo-input-font-size-md,
        line-height: $kendo-input-line-height-md,
        input-inner-height: 20px,
        icon-size: calc( #{$icon-size} + #{$kendo-input-padding-y-md * 2} ),
        picker-caret-size: 26px,
        input-values-size: 36px,
        input-icon-offset-y: 11px,
        input-button-offset-x: 0px,
        button-padding-x: $kendo-input-padding-y-md,
        button-padding-y: $kendo-input-padding-y-md,
        input-button-size: $kendo-input-button-size-md,
        color-picker-preview-size: $kendo-color-picker-preview-size-md,
        input-spinner-font-size: $kendo-input-spinner-font-size-md,
        input-spinner-icon-offset: $kendo-input-spinner-icon-offset-md
    ),
    lg: (
        padding-x: $kendo-input-padding-x-lg,
        padding-y: $kendo-input-padding-y-lg,
        font-size: $kendo-input-font-size-lg,
        line-height: $kendo-input-line-height-lg,
        input-inner-height: 23px,
        icon-size: calc( #{$icon-size} + #{$kendo-input-padding-y-lg * 2} ),
        picker-caret-size: 26px,
        input-values-size: 40px,
        input-icon-offset-y: 13px,
        input-button-offset-x: 0px,
        button-padding-x: $kendo-input-padding-y-lg,
        button-padding-y: $kendo-input-padding-y-lg,
        input-button-size: $kendo-input-button-size-lg,
        color-picker-preview-size: $kendo-color-picker-preview-size-lg,
        input-spinner-font-size: $kendo-input-spinner-font-size-md,
        input-spinner-icon-offset: $kendo-input-spinner-icon-offset-md
    )
) !default;

$kendo-input-bg: $component-bg !default;
$kendo-input-text: $component-text !default;
$kendo-input-border: $component-border !default;
$kendo-input-shadow: null !default;

$kendo-input-hover-bg: null !default;
$kendo-input-hover-text: null !default;
$kendo-input-hover-border: #607184 !default;
$kendo-input-hover-shadow: null !default;

$kendo-input-focus-bg: null !default;
$kendo-input-focus-text: null !default;
$kendo-input-focus-border: null !default;
$kendo-input-focus-shadow: $focused-shadow-outset !default;

$kendo-input-readonly-text: null !default;
$kendo-input-readonly-bg: #EFF3F3 !default;
$kendo-input-readonly-border: transparent !default;
$kendo-input-readonly-shadow: null !default;

$kendo-input-disabled-bg: #DFE7E8 !default;
$kendo-input-disabled-text: null !default;
$kendo-input-disabled-border: transparent !default;
$kendo-input-disabled-gradient: null !default;
$kendo-input-disabled-shadow: null !default;

$kendo-input-outline-bg: $kendo-input-bg !default;
$kendo-input-outline-text: $kendo-input-text !default;
$kendo-input-outline-border: $kendo-input-border !default;

$kendo-input-outline-hover-bg: $kendo-input-hover-bg !default;
$kendo-input-outline-hover-text: $kendo-input-hover-text !default;
$kendo-input-outline-hover-border: $kendo-input-hover-border !default;

$kendo-input-outline-focus-bg: $kendo-input-focus-bg !default;
$kendo-input-outline-focus-text: $kendo-input-focus-text !default;
$kendo-input-outline-focus-border: $kendo-input-focus-border !default;
$kendo-input-outline-focus-shadow: $kendo-input-focus-shadow !default;

$kendo-input-flat-bg: $kendo-input-bg !default;
$kendo-input-flat-text: $kendo-input-text !default;
$kendo-input-flat-border: $kendo-input-border !default;

$kendo-input-flat-hover-bg: null !default;
$kendo-input-flat-hover-text: null !default;
$kendo-input-flat-hover-border: $kendo-input-hover-border !default;

$kendo-input-flat-focus-bg: $kendo-input-focus-bg !default;
$kendo-input-flat-focus-text: $kendo-input-focus-text !default;
$kendo-input-flat-focus-border: $kendo-input-focus-border !default;
$kendo-input-flat-focus-shadow: $kendo-input-focus-shadow !default;
$kendo-input-flat-border-radius: $kendo-border-radius $kendo-border-radius 0px 0px !default;

$kendo-picker-bg: $kendo-input-bg !default;
$kendo-picker-text: $kendo-input-text !default;
$kendo-picker-border: $kendo-input-border !default;
$kendo-picker-gradient: null !default;
$kendo-picker-shadow: $kendo-input-shadow !default;

$kendo-picker-hover-bg: $kendo-input-hover-bg !default;
$kendo-picker-hover-text: $kendo-input-hover-text !default;
$kendo-picker-hover-border: $kendo-input-hover-border !default;
$kendo-picker-hover-gradient: null !default;
$kendo-picker-hover-shadow: $kendo-input-hover-shadow !default;

$kendo-picker-focus-bg: $kendo-input-focus-bg !default;
$kendo-picker-focus-text: $kendo-input-focus-text !default;
$kendo-picker-focus-border: $kendo-input-focus-border !default;
$kendo-picker-focus-gradient: null !default;
$kendo-picker-focus-shadow: $kendo-input-focus-shadow !default;

$kendo-picker-disabled-bg: $kendo-input-disabled-bg !default;
$kendo-picker-disabled-text: $kendo-input-disabled-text !default;
$kendo-picker-disabled-border: $kendo-input-disabled-border !default;
$kendo-picker-disabled-gradient: $kendo-input-disabled-gradient !default;
$kendo-picker-disabled-shadow: $kendo-input-disabled-shadow !default;

$kendo-picker-outline-bg: transparent !default;
$kendo-picker-outline-text: null !default;
$kendo-picker-outline-border: $kendo-button-text !default;

$kendo-picker-outline-hover-bg: $kendo-button-text !default;
$kendo-picker-outline-hover-text: $body-bg !default;
$kendo-picker-outline-hover-border: null !default;

$kendo-picker-outline-focus-bg: transparent !default;
$kendo-picker-outline-focus-text: $kendo-picker-outline-text !default;
$kendo-picker-outline-focus-border: $kendo-picker-outline-border !default;
$kendo-picker-outline-focus-shadow: $kendo-picker-focus-shadow !default;

$kendo-picker-outline-hover-focus-bg: $kendo-picker-outline-hover-bg !default;
$kendo-picker-outline-hover-focus-text: $kendo-picker-outline-hover-text !default;
$kendo-picker-outline-hover-focus-border: $kendo-picker-outline-hover-border !default;

$kendo-picker-flat-bg: transparent !default;
$kendo-picker-flat-text: $kendo-button-text !default;
$kendo-picker-flat-border: $kendo-button-border !default;

$kendo-picker-flat-hover-bg: rgba( $kendo-button-text, .04 ) !default;
$kendo-picker-flat-hover-text: null !default;
$kendo-picker-flat-hover-border: rgba( $kendo-button-border, .16 ) !default;

$kendo-picker-flat-focus-bg: null !default;
$kendo-picker-flat-focus-text: null !default;
$kendo-picker-flat-focus-border: null !default;
$kendo-picker-flat-focus-shadow: null !default;

$kendo-picker-flat-hover-focus-bg: null !default;
$kendo-picker-flat-hover-focus-text: null !default;
$kendo-picker-flat-hover-focus-border: null !default;

$kendo-input-placeholder-text: $text-color-muted !default;
$kendo-input-placeholder-opacity: 1 !default;

$kendo-input-selected-bg: null !default;
$kendo-input-selected-text: null !default;

$kendo-input-clear-value-text: $text-color-muted !default;
$kendo-input-clear-value-opacity: 1 !default;
$kendo-input-clear-value-hover-text: $text-color !default;
$kendo-input-clear-value-hover-opacity: 1 !default;

$kendo-input-values-margin-y: map-get( $spacing, thin ) !default;
$kendo-input-values-margin-x: $kendo-input-values-margin-y !default;

// Input separator
$kendo-input-separator-color: $kendo-input-text !default;
$kendo-input-separator-opacity: .5 !default;


// Invalid
$kendo-input-invalid-border: $invalid-border !default;
$kendo-input-invalid-shadow: $focused-invalid-shadow-outset !default;
