@include exports("toolbar/layout") {

    // Base
    .k-toolbar {
        margin: 0;
        padding: $toolbar-padding-y $toolbar-padding-x;
        border-width: $toolbar-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-family: $toolbar-font-family;
        font-size: $toolbar-font-size;
        line-height: $toolbar-line-height;
        list-style: none;
        display: flex;
        flex-flow: row wrap;
        gap: $toolbar-spacing;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        overflow: hidden;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        // Remove once we decide to not size empty containers
        &::before {
            content: "";
            height: $kendo-button-calc-size;
            margin-inline-start: -$toolbar-spacing;
        }

        &.k-toolbar-resizable {
            flex-wrap: nowrap;
        }

        > * {
            flex-shrink: 0;
            display: inline-flex;
            align-content: center;
            vertical-align: middle;

            > label {
                margin-inline-end: $toolbar-spacing;
                align-self: center;
            }
        }


        // Button
        .k-button {}


        // Toggle button
        .k-toggle-button {}


        // Button group
        .k-button-group {}


        // Split button
        .k-split-button {}


        // Input
        .k-input,
        .k-picker {
            width: $toolbar-input-width;
            .k-input-inner {
                padding: 4px 8px !important;
            }
            .k-input-prefix .k-button, .k-input-suffix .k-button {
                height: 100% !important;
            }
        }
        .k-color-picker {
            width: min-content;
        }

        // Overflow anchor
        .k-overflow-anchor {
            @extend .k-button-md;
            @extend .k-icon-button;
        }

        .k-overflow-anchor {
            margin: 0;
            position: absolute;
            top: 0;
            right: 0;

            &::before {
                display: block;
            }

            .k-icon.k-i-more-vertical::before {
                @extend .k-i-more-horizontal;
            }
        }


        // Separator
        .k-separator,
        .k-toolbar-separator {
            width: 0;
            height: $line-height-em;
            border-width: 0 0 0 1px;
            border-style: solid;
            align-self: center;
        }


        // Spacer
        .k-toolbar-spacer {
            height: $line-height-em;
            flex: 1 0 0%;
        }


        // Template
        .k-toolbar-template {
            align-self: center;
            align-items: center;
        }

        // Template item
        .k-toolbar-item:focus {
            text-decoration: none;
            outline: 0;
        }

    }


    // Toolbar group
    .k-toolbar-group {
        display: flex;
        flex-direction: inherit;
        flex-wrap: nowrap;
        gap: inherit;
    }


    // Angular specific
    kendo-toolbar-renderer {
        display: inline-block;
        border-color: inherit;
    }


    // Overflow container
    .k-overflow-container {
        font-size: $kendo-menu-popup-font-size-md;
        line-height: $kendo-menu-popup-line-height-md;

        > .k-item {
            border-color: inherit;
        }

        .k-separator {
            margin: map-get( $spacing, 1 ) 0;
        }

        // Group
        .k-overflow-tool-group {
            display: block;
        }

        // Button
        .k-overflow-button {
            padding: $kendo-menu-popup-item-padding-y-md $kendo-menu-popup-item-padding-x-md;
            width: 100%;
            height: auto;
            border-width: 0;
            border-radius: 0;
            aspect-ratio: auto;
            color: inherit;
            background-color: transparent;
            background-image: none;
            font: inherit;
            cursor: pointer;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            align-content: center;
            gap: $icon-spacing;
            justify-content: flex-start;
            position: relative;
        }

        // Button group
        .k-button-group {
            @include box-shadow( none );
            display: flex;
            flex-flow: column nowrap;

            .k-button {
                margin: if( $kendo-button-border-width == 0, null, 0);
            }
        }

        // Split button
        .k-split-button {
            display: flex;
            flex-direction: column;
        }

        // Hidden items
        .k-overflow-hidden {
            display: none;
        }
    }




    // RTL
    .k-toolbar {

        &.k-rtl,
        .k-rtl &,
        &[dir="rtl"],
        [dir="rtl"] & {
            .k-overflow-anchor {
                margin: 0;
                right: auto;
                left: 0;
            }
        }

    }

}




@include exports( "floating-toolbar/layout" ) {


    // sass-lint:disable class-name-format
    .k-floating-toolbar,
    .editorToolbarWindow.k-window-content {
        padding: $toolbar-padding-y $toolbar-padding-x !important; // sass-lint:disable-line no-important
        border-width: $toolbar-border-width;
        border-style: solid;
        display: flex;
        flex-flow: row nowrap;
        gap: $toolbar-spacing;
        align-items: center;

        .k-toolbar {
            padding: 0;
            border-width: 0;
            flex-shrink: 1;
            color: inherit;
            background: none;
        }
    }
    .k-editortoolbar-dragHandle {
        cursor: move;
        display: flex;
        align-items: center;
        align-self: stretch;
        flex-shrink: 0;
    }
    // sass-lint:enable class-name-format


}
