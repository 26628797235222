@include exports( "loader/theme" ) {

    @each $name, $color in $kendo-theme-colors {
        .k-loader-#{$name} {
            @if $name == "secondary" {
                color: $loader-secondary-bg;
            } @else {
                color: $color;
            }
        }
    }

    .k-loader-container-panel {
        background-color: $loader-container-panel-bg;
        border-color: $loader-container-panel-border-color;
    }

    .k-loader-container-inner {
        // sass-lint:disable-block no-important
        // sass-lint:disable-block class-name-format
        .k-text-secondary,
        .\!k-text-secondary {
            color: $loader-secondary-bg !important;
        }
    }

}
