@include exports( "numerictextbox/layout" ) {

    // Numeric textbox
    .k-numeric-textbox {}

    .align-right .k-numerictextbox,
    .align-right.k-numerictextbox {
        text-align: right;
        direction: rtl;
        
        .k-numeric-wrap .k-i-warning {
            align-self: center;
            margin-right: 0;
            margin-left: $spacer-x * 0.5;
        }
    }

    $multicurrency-picker-width: 90px !default;
    $multicurrency-popup-width: 200px !default;

    .k-multicurrency {
        position: relative;
        height: calc( #{$kendo-input-padding-y-md * 2} + #{$kendo-input-line-height-md * 1em} );

        .k-numerictextbox {
            position: absolute;
            left: 0;
            width: calc( 100% - #{$multicurrency-picker-width} - 2px) !important;
            z-index: 3;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-color: transparent;
            background-color: transparent;
            border-right-color: $kendo-input-border;
        }

        .k-combobox {
            position: absolute;
            right: 0;
            width: $multicurrency-picker-width !important;
            z-index: 2;
            background-color: transparent;
            border-color: transparent;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            .k-input-inner {
                background-image: none !important;
                padding-right: $kendo-input-padding-x !important;
            }
        }

        .k-multicurrency-popup {
            z-index: 4;
        }

        .k-multicurrency-border {
            position: absolute;
            left: 0;
            height: calc( #{$kendo-input-padding-y * 2} + #{$kendo-input-line-height * 1em} );
            border-radius: $kendo-border-radius-md;
            width: calc(100% - 2px) !important;
            border-width: 1px;
            border-style: solid;
            border-color: $kendo-input-border;
            z-index: 1;
        }
    }

    .k-multicurrency-value {
        display: inline-block !important;
        width: 40px;
        margin-right: 8px;
    }

    // Alias
    .k-numerictextbox { @extend .k-numeric-textbox !optional; }

}
