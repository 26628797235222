// Popup
$popup-padding-x: null !default;
$popup-padding-y: null !default;
$popup-border-width: 0px !default;
$popup-border-radius: $kendo-border-radius!default;
$popup-max-width: 400px !default;

$popup-font-size: $font-size !default;
$popup-line-height: $line-height !default;

$popup-content-padding-x: map-get( $spacing, 2 ) !default;
$popup-content-padding-y: map-get( $spacing, 2 ) !default;

$popup-bg: $bg-color !default;
$popup-text: $component-text !default;
$popup-border: transparent !default;
$popup-shadow: 0 0 2px rgba(0, 0, 0, 0.35), 0 4px 8px rgba(0, 0, 0, 0.35) !default;
