// Button

/// Width of the border around the button.
/// @group button
$kendo-button-border-width: 1px !default;

/// Border radius of the button.
/// @group button
$kendo-button-border-radius: 999px !default;

/// Horizontal padding of the button.
/// @group button
$kendo-button-padding-x: map-get( $spacing, 4 ) !default;

/// Vertical padding of the button.
/// @group button
$kendo-button-padding-y: map-get( $spacing, 1 ) !default;

/// Font family of the button.
/// @group button
$kendo-button-font-family: $font-family !default;

/// Font sizes of the button.
/// @group button
$kendo-button-font-size: $font-size-md !default;

/// Icon sizes of the button.
/// @group button
$kendo-button-icon-font-size: $font-size-mdx !default;

/// Line heights used along with $font-size.
/// @group button
$kendo-button-line-height: $line-height-md !default;
$kendo-button-padding-x-sm: map-get( $spacing, 2 ) !default;
$kendo-button-padding-y-sm: map-get( $spacing, 1 ) * 0.5 !default;
$kendo-button-font-size-sm: $font-size-sm !default;
$kendo-button-line-height-sm: $line-height-sm !default;
$kendo-button-icon-font-size-sm: $font-size-md !default;

$kendo-button-padding-x-md:map-get( $spacing, 4 ) !default;
$kendo-button-padding-y-md: map-get( $spacing, 1 ) !default;
$kendo-button-font-size-md: $font-size-md !default;
$kendo-button-line-height-md: $line-height-md !default;
$kendo-button-icon-font-size-md: $font-size-mdx !default;

$kendo-button-padding-x-lg: map-get( $spacing, 4 ) !default;
$kendo-button-padding-y-lg: map-get( $spacing, 1 ) !default;
$kendo-button-font-size-lg: $font-size-md !default;
$kendo-button-line-height-lg: $line-height-md !default;
$kendo-button-icon-font-size-lg: $font-size-mdx !default;

/// Calculated height of the button.
/// @group button
$kendo-button-calc-size: calc( #{$kendo-button-line-height * 1em} + #{$kendo-button-padding-y * 2} + #{$kendo-button-border-width * 2} ) !default;
$kendo-button-calc-size-sm: 22px !default;

/// Calculated inner height of the button. Without the border width.
/// @group button
$kendo-button-inner-calc-size: calc( #{$kendo-button-line-height * 1em} + #{$kendo-button-padding-y * 2} ) !default;

$kendo-button-sizes: (
    sm: (
        padding-x: $kendo-button-padding-x-sm,
        padding-y: $kendo-button-padding-y-sm,
        font-size: $kendo-button-font-size-sm,
        line-height: $kendo-button-line-height-sm,
        icon-font-size: $kendo-button-icon-font-size-sm
    ),
    md: (
        padding-x: $kendo-button-padding-x-md,
        padding-y: $kendo-button-padding-y-md,
        font-size: $kendo-button-font-size-md,
        line-height: $kendo-button-line-height-md,
        icon-font-size: $kendo-button-icon-font-size-md
    ),
    lg: (
        padding-x: $kendo-button-padding-x-lg,
        padding-y: $kendo-button-padding-y-lg,
        font-size: $kendo-button-font-size-lg,
        line-height: $kendo-button-line-height-lg,
        icon-font-size: $kendo-button-icon-font-size-lg
    )
) !default;

/// Theme colors map for the button.
/// @group button
$kendo-button-theme-colors: map-merge(
    $kendo-theme-colors,
    ( "base": #f5f5f5 )
) !default;

/// The base background of the button.
/// @group button
$kendo-button-bg: $base-bg !default;
/// The base text color of the button.
/// @group button
$kendo-button-text: $text-color !default;
/// The base border color of the button.
/// @group button
$kendo-button-border: $button-border-color !default;
/// The base background gradient of the button.
/// @group button
$kendo-button-gradient: $base-gradient !default;
/// The base shadow of the button.
/// @group button
$kendo-button-shadow: null !default;

/// The base background of hovered button.
/// @group button
$kendo-button-hover-bg: $hovered-bg !default;
/// The base text color of hovered button.
/// @group button
$kendo-button-hover-text: null !default;
/// The base border color of hovered button.
/// @group button
$kendo-button-hover-border: null !default;
/// The base background gradient of hovered button.
/// @group button
$kendo-button-hover-gradient: null !default;
/// The base shadow of hovered button.
/// @group button
$kendo-button-hover-shadow: null !default;

/// The base background color of active button.
/// @group button
$kendo-button-active-bg: $pressed-bg !default;
/// The base text color of active button.
/// @group button
$kendo-button-active-text: null !default;
/// The base border color of active button.
/// @group button
$kendo-button-active-border: null !default;
/// The base background gradient of active button.
/// @group button
$kendo-button-active-gradient: null !default;
/// The base shadow of active button.
/// @group button
$kendo-button-active-shadow: null !default;

/// The base background color of selected button.
/// @group button
$kendo-button-selected-bg: $pressed-bg !default;
/// The text color of selected buttons.
/// @group button
$kendo-button-selected-text: $kendo-button-active-text !default;
/// The border color of selected buttons.
/// @group button
$kendo-button-selected-border: $kendo-button-active-border !default;
/// The background gradient of selected buttons.
/// @group button
$kendo-button-selected-gradient: $kendo-button-active-gradient !default;
/// The base shadow of selected button.
/// @group button
$kendo-button-selected-shadow: $kendo-button-active-shadow !default;

/// The base background of focused button.
/// @group button
$kendo-button-focus-bg: null !default;
/// The base text color of focused button.
/// @group button
$kendo-button-focus-text: null !default;
/// The base border color of focused button.
/// @group button
$kendo-button-focus-border: null !default;
/// The base background gradient of focused button.
/// @group button
$kendo-button-focus-gradient: null !default;
/// The base shadow of focused button.
/// @group button
$kendo-button-focus-shadow: $focused-shadow-outset !default;
$kendo-button-group-focus-shadow: $focused-shadow-inset !default;

/// The base background of disabled button.
/// @group button
$kendo-button-disabled-bg: null !default;
/// The base text color of disabled button.
/// @group button
$kendo-button-disabled-text: null !default;
/// The base border color of disabled buttons.
/// @group button
$kendo-button-disabled-border: null !default;
/// The base background gradient of disabled button.
/// @group button
$kendo-button-disabled-gradient: null !default;
/// The base shadow of disabled button.
/// @group button
$kendo-button-disabled-shadow: null !default;

// Solid button
$kendo-solid-button-gradient: $kendo-button-gradient !default; //rgba( black, 0 ), rgba( black, .02 ) !default;
$kendo-solid-button-shade-function: "try-shade" !default;
$kendo-solid-button-shade-text-amount: 0 !default;
$kendo-solid-button-shade-bg-amount: 0 !default;
$kendo-solid-button-shade-border-amount: 0 !default;
$kendo-solid-button-hover-shade-text-amount: null !default;
$kendo-solid-button-hover-shade-bg-amount: .5 !default;
$kendo-solid-button-hover-shade-border-amount: .5 !default;
$kendo-solid-button-active-shade-text-amount: null !default;
$kendo-solid-button-active-shade-bg-amount: 1.5 !default;
$kendo-solid-button-active-shade-border-amount: 1.5 !default;
$kendo-solid-button-shadow: true !default;
$kendo-solid-button-shadow-blur: 0px !default;
$kendo-solid-button-shadow-spread: 2px !default;
$kendo-solid-button-shadow-opacity: .3 !default;

// Outline button
$kendo-outline-button-shadow: true !default;
$kendo-outline-button-shadow-blur: 0px !default;
$kendo-outline-button-shadow-spread: 2px !default;
$kendo-outline-button-shadow-opacity: .3 !default;

// Flat button
$kendo-flat-button-shadow: true !default;
$kendo-flat-button-shadow-blur: 0px !default;
$kendo-flat-button-shadow-spread: 2px !default;
$kendo-flat-button-shadow-opacity: .3 !default;

// Link button
$kendo-link-button-shadow: true !default;
$kendo-link-button-shadow-blur: 0px !default;
$kendo-link-button-shadow-spread: 2px !default;
$kendo-link-button-shadow-opacity: .3 !default;

// Clear button
$kendo-clear-button-focus-opacity: .1 !default;

/// The overlay opacity of hovered flat button. Used to create background for the flat button.
/// @group button
$kendo-flat-button-hover-opacity: .04 !default;
/// The overlay opacity of focused flat button. Used to create background for the flat button.
/// @group button
$kendo-flat-button-focus-opacity: null !default;
/// The overlay opacity of active flat button. Used to create background for the flat button.
/// @group button
$kendo-flat-button-active-opacity: .16 !default;
/// The overlay opacity of selected flat button. Used to create background for the flat button.
/// @group button
$kendo-flat-button-selected-opacity: .2 !default;

/// The color transition of the flat button.
/// @group button
$kendo-button-transition: color .2s ease-in-out !default;


///MRI theme colors
$base-button-border-dark: #C2CFD3 !default;
$base-button-color-dark: $text-color-dark !default;
$base-button-bg-dark: transparent !default;

$hovered-button-border-dark: #C2CFD3 !default;
$hovered-button-color-dark: $text-color-dark !default;
$hovered-button-bg-dark: $hovered-bg !default;

$pressed-button-border-dark: #C2CFD3 !default;
$pressed-button-color-dark: $text-color-dark !default;
$pressed-button-bg-dark: $pressed-bg !default;

// Button
/// The text color of the buttons.
/// @group buttons
$button-text-dark: $base-button-color-dark !default;

/// The background of the buttons.
/// @group buttons
$button-bg-dark: $base-button-bg-dark !default;

/// The border color of the buttons.
/// @group buttons
$button-border-dark: $base-button-border-dark !default;

/// The background gradient of the buttons.
/// @group buttons
$button-gradient-dark: none !default;
$button-shadow-dark: null !default;

/// The text color of hovered buttons.
/// @group buttons
$button-hovered-text-dark: $hovered-button-color-dark !default;

/// The background of hovered buttons.
/// @group buttons
$button-hovered-bg-dark: $hovered-button-bg-dark !default;

/// The border color of hovered buttons.
/// @group buttons
$button-hovered-border-dark: $hovered-button-border-dark !default;

/// The background gradient of hovered buttons.
/// @group buttons
$button-hovered-gradient-dark: none !default;
$button-hovered-shadow-dark: null !default;

/// The text color of pressed buttons.
/// @group buttons
$button-pressed-text-dark: $pressed-button-color-dark !default;

/// The background color of pressed buttons.
/// @group buttons
$button-pressed-bg-dark: $pressed-button-bg-dark !default;

/// The border color of pressed buttons.
/// @group buttons
$button-pressed-border-dark: $pressed-button-border-dark !default;

/// The background gradient of pressed buttons.
/// @group buttons
$button-pressed-gradient-dark: none !default;
$button-pressed-shadow-dark: $focused-shadow-outset !default;

/// The shadow of focused buttons.
/// @group buttons
$button-focused-shadow-dark:  $focused-shadow-outset !default;

// Primary button
$primary-button-bg: $primary !default;
$primary-button-text: #ffffff !default;
$primary-button-border: transparent !default;
$primary-button-gradient: none !default;

$primary-button-hovered-text: $primary-button-text !default;
$primary-button-hovered-bg: #0582C3 !default;
$primary-button-hovered-border: $primary-button-border !default;
$primary-button-hovered-gradient: none !default;

$primary-button-pressed-text: $primary-button-text !default;
$primary-button-pressed-bg: #10689D !default;
$primary-button-pressed-border: $primary-button-border !default;
$primary-button-pressed-gradient: none !default;
$primary-button-pressed-shadow: $kendo-button-focus-shadow !default;

$primary-button-focused-shadow: $kendo-button-focus-shadow !default;

// Primary button - dark
$primary-button-text-dark: $base-button-color-dark !default;
$primary-button-bg-dark: #0D75B0 !default;
$primary-button-border-dark: transparent !default;
$primary-button-gradient-dark: none !default;

$primary-button-hovered-text-dark: $primary-button-text-dark !default;
$primary-button-hovered-bg-dark: #0582C3 !default;
$primary-button-hovered-border-dark: transparent !default;
$primary-button-hovered-gradient-dark: none !default;

$primary-button-pressed-text-dark: $primary-button-text-dark !default;
$primary-button-pressed-bg-dark: #10689D !default;
$primary-button-pressed-border-dark: transparent !default;
$primary-button-pressed-gradient-dark: none !default;
$primary-button-pressed-shadow-dark: $kendo-button-focus-shadow !default;

$primary-button-focused-shadow-dark: $kendo-button-focus-shadow !default;

