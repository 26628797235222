// Toolbar

/// The horizontal padding of the container.
/// @group toolbar
$toolbar-padding-x: map-get( $spacing, 2 ) !default;
/// The vertical padding of the container.
/// @group toolbar
$toolbar-padding-y: map-get( $spacing, 2 ) !default;
$toolbar-border-width: 0px !default;
$toolbar-border-radius: null !default;
$toolbar-spacing: $toolbar-padding-x !default;

$toolbar-inner-calc-size: calc( #{$kendo-button-calc-size} + #{$toolbar-padding-y * 2} ) !default;

$toolbar-font-family: $font-family !default;
$toolbar-font-size: $font-size !default;
$toolbar-line-height: $line-height !default;

$toolbar-bg: $base-bg !default;
$toolbar-text: $base-text !default;
$toolbar-border: $base-border !default;
$toolbar-gradient: null !default;
$toolbar-shadow: null !default;

$toolbar-separator-border: inherit !default;

$toolbar-input-width: 10em !default;
