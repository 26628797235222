@include exports( "combobox/layout" ) {

    // Combobox
    .k-combobox {
        .k-clear-value {
            right: 36px
        }
    }

}
