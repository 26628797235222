@include exports( "utils/order" ) {

    /// @name k-order-first
    /// @description This is equivalent to `order: -9999;`.
    /// @group order

    /// @name k-order-last
    /// @description This is equivalent to `order: 9999;`.
    /// @group order

    /// @name k-order-none
    /// @description This is equivalent to `order: 0;`.
    /// @group order

    /// @name from k-order-1 to k-order-12
    /// @description This is equivalent to `order: 1;`, `order: 2;`, `order: 12;`, etc.
    /// @group order

    // Order utility classes
    $utils-order: (
        first: -9999,
        last: 9999,
        none: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
        7: 7,
        8: 8,
        9: 9,
        10: 10,
        11: 11,
        12: 12
    ) !default;

    // sass-lint:disable no-important
    @if $utils-order {
        @each $order, $val in $utils-order {
            .k-order-#{$order} { order: $val !important; }
        }
    }
    // sass-lint:enable no-important

}
