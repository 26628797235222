@include exports("colorgradient/layout") {

    // ColorGradient
    .k-colorgradient {
        @include border-radius( $colorgradient-border-radius );
        width: $colorgradient-width;
        padding: $colorgradient-padding-y $colorgradient-padding-x;
        border-style: solid;
        border-width: $colorgradient-border-width;
        box-sizing: border-box;
        outline: 0;
        font-size: $colorgradient-font-size;
        font-family: $colorgradient-font-family;
        line-height: $colorgradient-line-height;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: $colorgradient-gap;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        *,
        *::before,
        *::after {
            box-sizing: border-box;
        }
    }

    // Canvas
    .k-colorgradient-canvas {
        gap: $colorgradient-canvas-gap;

        .k-hsv-rectangle {
            @include border-radius( $colorgradient-canvas-border-radius );
            position: relative;
            flex: 1 1 auto;
            user-select: none;
            -ms-touch-action: pinch-zoom double-tap-zoom;
        }

        .k-hsv-gradient {
            @include border-radius( $colorgradient-canvas-border-radius );
            height: $colorgradient-canvas-rectangle-height;
        }

        .k-hsv-draghandle {
            margin-top: $colorgradient-canvas-draghandle-margin-y;
            margin-left: $colorgradient-canvas-draghandle-margin-x;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 10;
            cursor: pointer;
        }

        .k-hsv-controls {
            position: relative;
            flex-shrink: 0;
            gap: $colorgradient-canvas-gap;
        }
    }

    // DragHandle
    .k-colorgradient .k-draghandle {
        @include border-radius( $colorgradient-draghandle-border-radius );
        width: $colorgradient-draghandle-width;
        height: $colorgradient-draghandle-height;
        border-width: $colorgradient-draghandle-border-width;
        border-style: solid;
        box-sizing: border-box;
    }

    // Sliders
    .k-colorgradient-slider {

        &.k-slider-vertical {
            width: $colorgradient-slider-track-size;
            height: $colorgradient-slider-vertical-size;
            flex: 0 0 $colorgradient-slider-track-size;
        }
        &.k-slider-vertical .k-slider-track {
            width: $colorgradient-slider-track-size;
            border-radius: $colorgradient-slider-border-radius;
            margin-left: -($colorgradient-slider-track-size * 0.5);
        }

        &.k-slider-horizontal {
            width: $colorgradient-slider-horizontal-size;
            height: $colorgradient-slider-track-size;
            flex: 0 0 $colorgradient-slider-track-size;
        }
        &.k-slider-horizontal .k-slider-track {
            height: $colorgradient-slider-track-size;
            border-radius: $colorgradient-slider-border-radius;
            margin-top: -($colorgradient-slider-track-size * 0.5);
        }

        &.k-alpha-slider .k-slider-track::before {
            content: "";
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 10px;
            position: relative;
            z-index: -1;
        }

        .k-slider-wrap,
        .k-slider-track-wrap {
            z-index: 1;
        }

        .k-slider-selection {
            display: none;
        }

        .k-draghandle {
            border-width: $colorgradient-slider-draghandle-border-width;
        }
    }

    // Inputs
    .k-colorgradient-inputs {
        gap: $colorgradient-input-gap;

        .k-colorgradient-input-label {
            text-transform: uppercase;
        }

        > .k-vstack {
            gap: $colorgradient-input-label-gap;
        }

        .k-numerictextbox {
            width: $colorgradient-input-width;
            .k-input-inner {
                padding: 4px 8px;
                /* Chrome, Safari, Edge, Opera */
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
                }

                /* Firefox */
                &[type=number] {
                -moz-appearance: textfield;
                } 
            }
        }
        
        .k-input.k-textbox.k-hex-value {
            padding: 4px 8px;
            border-radius: 4px;
            .k-input {
                padding: 0px
            }
        }

        .k-input-inner {
            text-overflow: clip;
        }
    }

    // Contrast
    .k-colorgradient-color-contrast {

        > div {
            display: flex;
            flex-flow: row nowrap;
            gap: $colorgradient-contrast-spacer;
        }

        .k-contrast-ratio {
            margin-bottom: $colorgradient-contrast-spacer;
        }

        .k-contrast-ratio-text {
            font-weight: $colorgradient-contrast-ratio-font-weight;
        }

        .k-contrast-validation {
            display: inline-flex;
            align-items: center;
            gap: ( $colorgradient-contrast-spacer * 0.5 );

            .k-icon {
                vertical-align: middle;
            }

            // Needed for the double check icons
            .k-icon + .k-icon {
                margin-left: -13px;
            }
        }
    }
    .k-color-contrast-svg {
        position: absolute;
        top: 0;
        left: 0;
        overflow: visible;
        pointer-events: none;
    }

}

@include exports( "colorgradient/layout/rtl" ) {

    .k-rtl,
    [dir="rtl"] {

        .k-colorgradient-color-contrast .k-i-check + .k-i-check {
            margin-left: 0;
            margin-right: -13px;
        }

    }

}
