@include exports("wizard/theme") {
    .k-wizard {
        border-color: transparent;
    }

    .k-wizard-step {
        &:focus,
        &.k-focus {
            outline-color: $wizard-step-border-focused;
        }

        .k-ie &:focus,
        .k-ie &.k-focus {
            @include box-shadow($wizard-focused-shadow);
            outline: none;
        }
    }

    .k-wizard-buttons {
        background-color: #C6CED5
    }

}
