// Checkbox

/// Border width of checkbox.
/// @group checkbox
$kendo-checkbox-border-width: 1px !default;

// Checkbox sizes
$kendo-checkbox-sizes: (
    sm: (
        size: map-get( $spacing, 4 ),
        glyph-size: ( map-get( $spacing, 4 ) - map-get( $spacing, thin ) ),
        background-size: map-get( $spacing, 2 ),
        ripple-size: map-get( $spacing, 4 ) * 3
    ),
    md: (
        size: map-get( $spacing, 6 ),
        glyph-size: ( map-get( $spacing, 6 ) - map-get( $spacing, thin ) ),
        background-size: map-get( $spacing, 4 ) - 2,
        ripple-size: map-get( $spacing, 6 ) * 3
    ),
    lg: (
        size: map-get( $spacing, 8 ),
        glyph-size: ( map-get( $spacing, 8 ) - map-get( $spacing, thin ) ),
        background-size: map-get( $spacing, 6 ) - 2,
        ripple-size: map-get( $spacing, 8 ) * 3
    )
) !default;

/// Background color of checkbox.
/// @group checkbox
$kendo-checkbox-bg: $kendo-input-bg !default;
/// Color of checkbox.
/// @group checkbox
$kendo-checkbox-text: $kendo-input-text !default;
/// Border color of checkbox.
/// @group checkbox
$kendo-checkbox-border: $kendo-input-border !default;

/// Background color of hovered checkbox.
/// @group checkbox
$kendo-checkbox-hover-bg: null !default;
/// Color of hovered checkbox.
/// @group checkbox
$kendo-checkbox-hover-text: null !default;
/// Border color of hovered checkbox.
/// @group checkbox
$kendo-checkbox-hover-border: $kendo-input-hover-border !default;

/// Background color of checked checkbox.
/// @group checkbox
$kendo-checkbox-checked-bg: null !default;
/// Color of checked checkbox.
/// @group checkbox
$kendo-checkbox-checked-text: $kendo-checkbox-text !default;
/// Border color of checked checkbox.
/// @group checkbox
$kendo-checkbox-checked-border: null !default;

/// Background color of indeterminate checkbox.
/// @group checkbox
$kendo-checkbox-indeterminate-bg: null !default;
/// Color of indeterminate checkbox.
/// @group checkbox
$kendo-checkbox-indeterminate-text: $kendo-checkbox-text !default;
/// Border color of indeterminate checkbox.
/// @group checkbox
$kendo-checkbox-indeterminate-border: null !default;

/// Border color of focused checkbox.
/// @group checkbox
$kendo-checkbox-focus-border: null !default;
/// Box shadow of focused checkbox.
/// @group checkbox
$kendo-checkbox-focus-shadow: $focused-shadow-outset !default;
/// Border color of focused and checked checkbox.
/// @group checkbox
$kendo-checkbox-focus-checked-border: null !default;
/// Box shadow of focused and checked checkbox.
/// @group checkbox
$kendo-checkbox-focus-checked-shadow: $kendo-checkbox-focus-shadow !default;

/// Background color of disabled checkbox.
/// @group checkbox
$kendo-checkbox-disabled-bg: $kendo-input-disabled-bg !default;
/// Color of disabled checkbox.
/// @group checkbox
$kendo-checkbox-disabled-text: $kendo-input-disabled-text !default;
/// Border color of disabled checkbox.
/// @group checkbox
$kendo-checkbox-disabled-border: null !default;

/// Background color of disabled and checked checkbox.
/// @group checkbox
$kendo-checkbox-disabled-checked-bg: null !default;
/// Color of disabled and checked checkbox.
/// @group checkbox
$kendo-checkbox-disabled-checked-text: null !default;
/// Border color of disabled and checked checkbox.
/// @group checkbox
$kendo-checkbox-disabled-checked-border: null !default;

/// Background color of invalid checkbox.
/// @group checkbox
$kendo-checkbox-invalid-bg: null !default;
/// Color of invalid checkbox.
/// @group checkbox
$kendo-checkbox-invalid-text: null !default;
/// Border color of invalid checkbox.
/// @group checkbox
$kendo-checkbox-invalid-border: $invalid-border !default;


// Checkbox indicator

/// Type of checkbox indicator.
/// @group checkbox
$kendo-checkbox-indicator-type: image !default;

/// Glyph font family of checkbox indicator.
/// @group checkbox
$kendo-checkbox-glyph-font-family: "WebComponentsIcons", monospace !default;
/// Glyph of checkbox indicator.
/// @group checkbox
$kendo-checkbox-checked-glyph: "\e118" !default;
/// Glyph of indeterminate checkbox indicator.
/// @group checkbox
$kendo-checkbox-indeterminate-glyph: "\e121" !default;

/// Image of checked checkbox indicator.
/// @group checkbox
$kendo-checkbox-checked-image: escape-svg( url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'><path fill='none' stroke='#{$kendo-checkbox-checked-text}' stroke-width='3' d='M1 7.66667L5.78571 11.8L12.3 1'/></svg>") ) !default;
/// Image image of checked checkbox indicator.
/// @group checkbox
$kendo-checkbox-indeterminate-image: escape-svg( url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$kendo-checkbox-indeterminate-text}' stroke-linecap='square' stroke-linejoin='square' stroke-width='3' d='M4,8 h8'/></svg>") ) !default;


// Checkbox label

/// The horizontal margin of the checkbox inside a label.
/// @group checkbox
$kendo-checkbox-label-margin-x: map-get( $spacing, 2 ) !default;


// Checkbox list

/// Spacing between items of horizontal checkbox list.
/// @group checkbox
$kendo-checkbox-list-spacing: map-get( $spacing, 4 ) !default;
/// Horizontal padding of checkbox list items.
/// @group checkbox
$kendo-checkbox-list-item-padding-x: 0px !default;
/// Vertical padding of checkbox list items.
/// @group checkbox
$kendo-checkbox-list-item-padding-y: $kendo-list-item-padding-y-md !default;


// Checkbox ripple

/// Background color of checkbox ripple.
/// @group checkbox
$kendo-checkbox-ripple-bg: $kendo-checkbox-checked-bg !default;
/// Opacity of checkbox ripple.
/// @group checkbox
$kendo-checkbox-ripple-opacity: .25 !default;
