// List

/// Font size of the list component, if no size is set.
/// @group list
$kendo-list-font-size: null !default;
$kendo-list-font-size-sm: $font-size-md !default;
$kendo-list-font-size-md: $font-size-md !default;
$kendo-list-font-size-lg: $font-size-md !default;

/// Line height of the list component, if no size is set.
/// @group list
$kendo-list-line-height: null !default;
$kendo-list-line-height-sm: $line-height-md !default;
$kendo-list-line-height-md: $line-height-md !default;
$kendo-list-line-height-lg: $line-height-lg !default;

/// Horizontal padding of list header, if no size is set.
/// @group list
$kendo-list-header-padding-x: null !default;
$kendo-list-header-padding-x-base: map-get( $spacing, 2 ) !default;
$kendo-list-header-padding-x-sm: map-get( $spacing, 2 ) !default;
$kendo-list-header-padding-x-md: map-get( $spacing, 2 ) !default;
$kendo-list-header-padding-x-lg: map-get( $spacing, 2 ) !default;

/// Vertical padding of list header, if no size is set.
/// @group list
$kendo-list-header-padding-y: null !default;
$kendo-list-header-padding-y-base: map-get( $spacing, 1 ) !default;
$kendo-list-header-padding-y-sm: map-get( $spacing, 1 ) - map-get( $spacing, thin ) !default;
$kendo-list-header-padding-y-md: map-get( $spacing, 1 ) !default;
$kendo-list-header-padding-y-lg: map-get( $spacing, 1 ) + map-get( $spacing, thin ) !default;

/// Border width of list header.
/// @group list
$kendo-list-header-border-width: 0 0 1px !default;

/// Font size of list header, if no size is set.
/// @group list
$kendo-list-header-font-size: null !default;
$kendo-list-header-font-size-sm: null !default;
$kendo-list-header-font-size-md: null !default;
$kendo-list-header-font-size-lg: null !default;

/// Line height of list header, if no size is set.
/// @group list
$kendo-list-header-line-height: null !default;
$kendo-list-header-line-height-sm: null !default;
$kendo-list-header-line-height-md: null !default;
$kendo-list-header-line-height-lg: null !default;

/// Font weight of list header.
/// @group list
$kendo-list-header-font-weight: null !default;

/// Horizontal padding of list items, when no size is set.
/// @group list
$kendo-list-item-padding-x: null !default;
$kendo-list-item-padding-x-base: map-get( $spacing, 2 ) !default;
$kendo-list-item-padding-x-sm: map-get( $spacing, 2 ) !default;
$kendo-list-item-padding-x-md: map-get( $spacing, 2 ) !default;
$kendo-list-item-padding-x-lg: map-get( $spacing, 2 ) !default;

/// Vertical padding of list items, when no size is set.
/// @group list
$kendo-list-item-padding-y: null !default;
$kendo-list-item-padding-y-base: map-get( $spacing, 1 ) !default;
$kendo-list-item-padding-y-sm: map-get( $spacing, 1 ) - map-get( $spacing, thin ) !default;
$kendo-list-item-padding-y-md: map-get( $spacing, 1 ) !default;
$kendo-list-item-padding-y-lg: map-get( $spacing, 1 ) + map-get( $spacing, thin ) !default;

/// Font size of list items, if no size is set.
/// @group list
$kendo-list-item-font-size: null !default;
$kendo-list-item-font-size-sm: null !default;
$kendo-list-item-font-size-md: null !default;
$kendo-list-item-font-size-lg: null !default;

/// Line height of list items, if no size is set.
/// @group list
$kendo-list-item-line-height: null !default;
$kendo-list-item-line-height-sm: null !default;
$kendo-list-item-line-height-md: null !default;
$kendo-list-item-line-height-lg: null !default;

/// Horizontal padding of list group items, when no size is set.
/// @group list
$kendo-list-group-item-padding-x: null !default;
$kendo-list-group-item-padding-x-base: map-get( $spacing, 2 ) !default;
$kendo-list-group-item-padding-x-sm: map-get( $spacing, 2 ) !default;
$kendo-list-group-item-padding-x-md: map-get( $spacing, 2 ) !default;
$kendo-list-group-item-padding-x-lg: map-get( $spacing, 2 ) !default;

/// Vertical padding of list group items, when no size is set.
/// @group list
$kendo-list-group-item-padding-y: null !default;
$kendo-list-group-item-padding-y-base: map-get( $spacing, 1 ) !default;
$kendo-list-group-item-padding-y-sm: map-get( $spacing, 1 ) - map-get( $spacing, thin ) !default;
$kendo-list-group-item-padding-y-md: map-get( $spacing, 1 ) !default;
$kendo-list-group-item-padding-y-lg: map-get( $spacing, 1 ) + map-get( $spacing, thin ) !default;

/// Border width of list group items.
/// @group list
$kendo-list-group-item-border-width: 0 0 1px !default;

/// Font size of list group items, if no size is set.
/// @group list
$kendo-list-group-item-font-size: null !default;
$kendo-list-group-item-font-size-sm: null !default;
$kendo-list-group-item-font-size-md: null !default;
$kendo-list-group-item-font-size-lg: null !default;

/// Line height of list group items, if no size is set.
/// @group list
$kendo-list-group-item-line-height: null !default;
$kendo-list-group-item-line-height-sm: null !default;
$kendo-list-group-item-line-height-md: null !default;
$kendo-list-group-item-line-height-lg: null !default;

/// Font weight of list group item.
/// @group list
$kendo-list-group-item-font-weight: null !default;


// Kendo list sizes
$kendo-list-sizes: (
    sm: (
        font-size: $kendo-list-font-size-sm,
        line-height: $kendo-list-line-height-sm,
        header-padding-x: $kendo-list-header-padding-x-sm,
        header-padding-y: $kendo-list-header-padding-y-sm,
        header-font-size: $kendo-list-font-size-sm + 2px,
        header-line-height: null,
        item-padding-x: $kendo-list-item-padding-x-sm,
        item-padding-y: $kendo-list-item-padding-y-sm,
        item-font-size: null,
        item-line-height: null,
        group-item-padding-x: $kendo-list-group-item-padding-x-sm,
        group-item-padding-y: $kendo-list-group-item-padding-y-sm,
        group-item-font-size: $kendo-list-font-size-sm + 2px,
        group-item-line-height: null
    ),
    md: (
        font-size: $kendo-list-font-size-md,
        line-height: $kendo-list-line-height-md,
        header-padding-x: $kendo-list-header-padding-x-md,
        header-padding-y: $kendo-list-header-padding-y-md,
        header-font-size: $kendo-list-font-size-md + 2px,
        header-line-height: null,
        item-padding-x: $kendo-list-item-padding-x-md,
        item-padding-y: $kendo-list-item-padding-y-md,
        item-font-size: null,
        item-line-height: null,
        group-item-padding-x: $kendo-list-group-item-padding-x-md,
        group-item-padding-y: $kendo-list-group-item-padding-y-md,
        group-item-font-size: $kendo-list-font-size-md + 2px,
        group-item-line-height: null
    ),
    lg: (
        font-size: $kendo-list-font-size-lg,
        line-height: $kendo-list-line-height-lg,
        header-padding-x: $kendo-list-header-padding-x-lg,
        header-padding-y: $kendo-list-header-padding-y-lg,
        header-font-size: $kendo-list-font-size-lg + 2px,
        header-line-height: null,
        item-padding-x: $kendo-list-item-padding-x-lg,
        item-padding-y: $kendo-list-item-padding-y-lg,
        item-font-size: null,
        item-line-height: null,
        group-item-padding-x: $kendo-list-group-item-padding-x-lg,
        group-item-padding-y: $kendo-list-group-item-padding-y-lg,
        group-item-font-size: $kendo-list-font-size-lg + 2px,
        group-item-line-height: null
    )
) !default;


/// Background color of the list component.
/// @group list
$kendo-list-bg: $component-bg !default;
/// Text color of the list component.
/// @group list
$kendo-list-text: $component-text !default;
/// Border color of the list component.
/// @group list
$kendo-list-border: $component-border !default;


/// Background color of list header.
/// @group list
$kendo-list-header-bg: null !default;
/// Text color of list header.
/// @group list
$kendo-list-header-text: $heading-text !default;
/// Border color of list header.
/// @group list
$kendo-list-header-border: $border-color !default;
/// Box shadow of list header.
/// @group list
$kendo-list-header-shadow: null !default;


/// Background color of list items.
/// @group list
$kendo-list-item-bg: null !default;
/// Text color of list items.
/// @group list
$kendo-list-item-text: null !default;

/// Background color of hovered list items.
/// @group list
$kendo-list-item-hover-bg: $hovered-bg !default;
/// Text color of hovered list items.
/// @group list
$kendo-list-item-hover-text: $hovered-text !default;

/// Background color of focused list items.
/// @group list
$kendo-list-item-focus-bg: null !default;
/// Text color of focused list items.
/// @group list
$kendo-list-item-focus-text: null !default;
/// Box shadow of focused list items.
/// @group list
$kendo-list-item-focus-shadow: $focused-shadow-inset !default;

/// Background color of selected list items.
/// @group list
$kendo-list-item-selected-bg: $selected-bg !default;
/// Text color of selected list items.
/// @group list
$kendo-list-item-selected-text: $selected-text !default;

/// Background color of list group items.
/// @group list
$kendo-list-group-item-bg: null !default;
/// Text color of list group items.
/// @group list
$kendo-list-group-item-text: $heading-text !default;
/// The border color of list group items.
/// @group list
$kendo-list-group-item-border: $border-color !default;
/// The base shadow of list group items.
/// @group list
$kendo-list-group-item-shadow: null !default;

/// Text color of the 'No Data' text.
/// @group list
$kendo-list-no-data-text: $subtle-text !default;
