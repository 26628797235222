// Stepper
$stepper-margin-x: null !default;
$stepper-margin-y: null !default;
$stepper-padding-x: null !default;
$stepper-padding-y: null !default;
$stepper-label-margin-x: 8px !default;
$stepper-label-padding-x: 8px !default;
$stepper-label-padding-y: 4px !default;
$stepper-border-width: 0px !default;

$stepper-inline-content-padding-x: 20px !default;
$stepper-inline-content-padding-y: 10px !default;

$stepper-font-size: $font-size !default;
$stepper-line-height: $line-height !default;
$stepper-font-family: $font-family !default;

$stepper-bg: null !default;
$stepper-text: $component-text !default;
$stepper-border: null !default;

$stepper-indicator-width: 28px !default;
$stepper-indicator-height: $stepper-indicator-width !default;
$stepper-indicator-border-width: 1px !default;
$stepper-indicator-border-radius: 50% !default;

$stepper-indicator-focus-border-width: 1px !default;
$stepper-indicator-focus-size: 2px !default;
$stepper-indicator-focus-offset: 3px !default;
$stepper-indicator-focus-calc-offset: calc( #{$stepper-indicator-focus-border-width} + #{$stepper-indicator-focus-offset} ) !default;

$stepper-indicator-bg: $bg-color !default;
$stepper-indicator-text: $component-text !default;
$stepper-indicator-border: #cccccc !default;

$stepper-indicator-hover-bg: try-shade( $stepper-indicator-bg ) !default;
$stepper-indicator-hover-text: null !default;
$stepper-indicator-hover-border: null !default;

$stepper-indicator-disabled-bg: null !default;
$stepper-indicator-disabled-text: $disabled-text !default;
$stepper-indicator-disabled-border: null !default;

$stepper-indicator-done-bg: $primary !default;
$stepper-indicator-done-text: contrast-wcag( $stepper-indicator-done-bg ) !default;
$stepper-indicator-done-border: $stepper-indicator-done-bg !default;

$stepper-indicator-done-hover-bg: try-shade( $stepper-indicator-done-bg ) !default;
$stepper-indicator-done-hover-text: null !default;
$stepper-indicator-done-hover-border: null !default;

$stepper-indicator-done-disabled-bg: mix( $stepper-indicator-done-bg, $component-bg, 60%) !default;
$stepper-indicator-done-disabled-text: contrast-wcag( $stepper-indicator-done-bg ) !default;
$stepper-indicator-done-disabled-border: $stepper-indicator-done-disabled-bg !default;

$stepper-indicator-current-bg: $stepper-indicator-done-bg !default;
$stepper-indicator-current-text: $stepper-indicator-done-text !default;
$stepper-indicator-current-border: $stepper-indicator-done-border !default;

$stepper-indicator-current-hover-bg: $stepper-indicator-done-hover-bg !default;
$stepper-indicator-current-hover-text: $stepper-indicator-done-hover-text !default;
$stepper-indicator-current-hover-border: $stepper-indicator-done-hover-border !default;

$stepper-indicator-current-disabled-bg: $stepper-indicator-done-disabled-bg !default;
$stepper-indicator-current-disabled-text: $stepper-indicator-done-disabled-text !default;
$stepper-indicator-current-disabled-border: $stepper-indicator-done-disabled-border !default;

$stepper-label-text: null !default;
$stepper-label-success-text: $success !default;
$stepper-label-error-text: $error !default;
$stepper-label-hover-text: try-shade( $stepper-text, 2 ) !default;
$stepper-label-disabled-text: $disabled-text !default;

$stepper-optional-label-text: $subtle-text !default;
$stepper-optional-label-opacity: null !default;
$stepper-optional-label-font-size: null !default;
$stepper-optional-label-font-style: italic !default;

$stepper-progressbar-size: 2px !default;
$stepper-progressbar-bg: null !default;
$stepper-progressbar-text: null !default;
$stepper-progressbar-fill-bg: null !default;
$stepper-progressbar-fill-text: null !default;

$stepper-content-transition-property: height !default;
$stepper-content-transition-duration: 300ms !default;
$stepper-content-transition-timing-function: cubic-bezier(.4, 0, .2, 1) 0ms !default;

$stepper-tabstrip-bg: #C6CED5 !default;
$stepper-tabstrip-text: $text-color !default;
$stepper-tabstrip-border: null !default;

$stepper-tabstrip-item-bg: $stepper-tabstrip-bg !default;
$stepper-tabstrip-item-text: $stepper-tabstrip-text !default;
$stepper-tabstrip-item-border: null !default;

$stepper-tabstrip-item-hovered-bg: rgba(255,255,255,.5) !default;
$stepper-tabstrip-item-hovered-text: $stepper-tabstrip-text !default;
$stepper-tabstrip-item-hovered-border: null !default;


$stepper-tabstrip-item-selected-bg: $bg-color !default;
$stepper-tabstrip-item-selected-text: $stepper-tabstrip-text !default;
$stepper-tabstrip-item-selected-border: $panel-border !default;
