@include exports("progressbar/theme") {

    @keyframes progressbar-indeterminate-animation {
        from { background-position: 0 0; }
        to { background-position: $progressbar-height 0; }
    }

    .k-progressbar {
        @include fill( $progressbar-text, $progressbar-bg, $progressbar-border, $progressbar-gradient );

        .k-selected {
            @include fill( $progressbar-fill-text, $progressbar-fill-bg, $progressbar-fill-border, $progressbar-fill-gradient );
        }

        .k-item {
            border-color: $progressbar-chunk-border;
        }
    }

    .k-progressbar-indeterminate {
        @include fill( $progressbar-indeterminate-text, $progressbar-indeterminate-bg, $progressbar-indeterminate-border );
        @include striped-gradient( shade($progressbar-indeterminate-bg) );
        background-size: $progressbar-height $progressbar-height;
        animation: progressbar-indeterminate-animation $progressbar-animation-timing;
    }

}

@include exports("circular-progressbar/theme") {

    .k-circular-progressbar-scale {
        stroke: $kendo-circular-progressbar-scale-stroke;
    }

    .k-circular-progressbar-arc {
        stroke: $kendo-circular-progressbar-arc-stroke;
        transition: stroke .5s ease;
    }

}
