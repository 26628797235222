@include exports("messagebox/layout") {

    // Base
    .k-messagebox {
        @include border-radius( $kendo-border-radius );
        margin: $message-box-margin;
        padding: $message-box-padding-y $message-box-padding-x;
        border-width: $message-box-border-width;
        border-style: solid;
        box-sizing: border-box;
        font-size: $message-box-font-size;
        line-height: $message-box-line-height;
        box-shadow: $message-box-shadow;

        a {
            color: inherit;
            font-style: $message-box-link-font-style;
            text-decoration: $message-box-link-text-decoration;
        }
    }

}
