@include exports("chip/layout") {

    // Chip
    .k-chip {
        padding: $kendo-chip-padding-y $kendo-chip-padding-x;
        border-width: $kendo-chip-border-width;
        border-style: solid;
        outline: 0;
        font-family: $font-family;
        font-size: $kendo-chip-font-size;
        line-height: $kendo-chip-line-height;
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        gap: $kendo-chip-spacing;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        user-select: none;

        *,
        *::before,
        *::after {
            box-sizing: border-box;
        }

        .k-selected-icon-wrapper {
            display: none !important; // sass-lint:disable-line no-important
        }

        .k-icon.k-i-x-circle::before { content: "\e11b"; }
    }


    // Chip content
    .k-chip-content {
        min-width: 0;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        overflow: hidden;
        flex: 1 1 auto;
    }


    // Chip text
    .k-chip-text,
    .k-chip-label {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex: 1 1 auto;
    }


    // Chip avatar
    .k-chip-avatar {
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        flex: none;
    }


    // Chip icon
    .k-chip-icon {
        font-size: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
    }
    .k-ie .k-chip-icon {
        margin-right: $kendo-chip-spacing;
    }


    // Actions
    .k-chip-actions {
        margin: -5em 0;
        flex: none;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        align-self: center;
    }
    .k-chip-action {
        flex: none;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        align-self: center;
        border-radius: $kendo-button-border-radius;
    }


    // Legacy chip icons
    .k-remove-icon {
        font-size: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
    }
    .k-ie .k-remove-icon {
        margin-right: 0;
        margin-left: $kendo-chip-spacing;
    }


    // Chip list
    .k-chip-list {
        min-width: 0px;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
    }


    // RTL
    .k-ie .k-chip.k-rtl,
    .k-ie .k-rtl .k-chip,
    .k-ie .k-chip[dir="rtl"] {
        .k-chip-icon {
            margin-right: 0;
            margin-left: $kendo-chip-spacing;
        }

        .k-remove-icon {
            margin-left: 0;
            margin-right: $kendo-chip-spacing;
        }
    }



    // Sizes
    @each $size, $size-props in $kendo-chip-sizes {
        $_padding-x: map-get( $size-props, padding-x );
        $_padding-y: map-get( $size-props, padding-y );
        $_font-size: map-get( $size-props, font-size );
        $_line-height: map-get( $size-props, line-height );
        $_avatar-size: 1em;

        .k-chip-#{$size} {
            padding: $_padding-y $_padding-x;
            font-size: $_font-size;
            line-height: $_line-height;

            .k-chip-action {
                font-size: $_font-size;
            }
        }

        .k-chip-avatar {
            width: $_avatar-size;
            height: $_avatar-size;
            flex-basis: $_avatar-size;
        }

    }

    // Sizes
    @each $name, $size in $kendo-chip-list-sizes {
        .k-chip-list-#{$name} {
            gap: $size;
        }
    }

}
