@include exports("textarea/theme") {

    // Textarea
    .k-textarea {

        &:not([readonly]):not(.k-readonly):not([disabled]) {
            &.k-required, &.mri-k-show-validation {
                .k-input-inner {
                    background-size: $kendo-input-font-size $kendo-input-font-size;
                    background-repeat: no-repeat;
                    background-position: calc(100% - #{$kendo-input-padding-x}) center;
                }
            }

            &.k-required {
                .k-input-inner {
                    padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
                    background-image: $required-icon;
                }
            }

            &.mri-k-show-validation {
                // warning
                &.k-warning {
                    .k-input-inner {
                        padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
                        background-image: $warning-icon;
                    }
                }
                
                // invalid
                &.k-invalid,
                &.ng-invalid.ng-touched,
                &.ng-invalid.ng-dirty {
                    .k-input-inner {
                        padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
                        background-image: $invalid-icon;
                    }
                }
    
                // Valid
                &.k-success, &.k-valid:not(.ng-pristine),
                &.ng-valid.ng-touched:not(.ng-pristine),
                &.ng-valid.ng-dirty:not(.ng-pristine) {
                    .k-input-inner {
                        padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
                        background-image: $success-icon;
                    }
                }
            }
    
            &.k-is-loading {
                position: relative;
    
                .k-input-inner {
                    background-image: none;
                    padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
                }
    
                &::before {
                    display: inline-block;
                    content: "";
                    box-sizing: inherit;
                    border-radius: 50%;
                    border-width: 2px;
                    border-style: solid;
                    border-color: $loading-foreground-color;
                    border-left-color: $loading-background-color;
                    border-right-color: $loading-background-color;
                    border-top-color: $loading-foreground-color;
                    border-bottom-color: $loading-background-color;
                    background-color: transparent;
                    margin-top: -.5em;
                    margin-left: -.5em;
                    width: 1em;
                    height: 1em;
                    animation: k-loading-animation .5s linear infinite;
                }
    
                &.\!k-flex-row {
                    &::before {
                        position: absolute;
                        top: 16px;
                        right: 36px;
                    }
                }
    
                &.\!k-flex-col {
                    &::before {
                        position: absolute;
                        top: 16px;
                        right: 8px;
                    }
                }
            }
        }
    }

    .k-input-wrap.k-is-loading {
        position: relative;
        font-size: $font-size-xs;
        display: inline-block;
        line-height: $kendo-input-line-height;
        width: 100%;
        &::after {
            position: absolute;
            top: 17px;
            right: 9px;
            display: inline-block;
            content: "";
            box-sizing: inherit;
            border-radius: 50%;
            border-width: 2px;
            border-style: solid;
            border-color: $loading-foreground-color;
            border-left-color: $loading-background-color;
            border-right-color: $loading-background-color;
            border-top-color: $loading-foreground-color;
            border-bottom-color: $loading-background-color;
            background-color: transparent;
            margin-top: -.5em;
            margin-left: -.5em;
            width: 1em;
            height: 1em;
            animation: k-loading-animation .5s linear infinite;
        }

        .k-textarea {
            background-image: none;
            padding-right: 30px;
            position: relative;
        }
    }

}
