@include exports( "forms/theme" ) {

    .k-form,
    .k-form-inline {
        @include fill( $color: $body-text );

        fieldset {
            legend {
                @include fill( $color: $fieldset-legend-text );
            }
        }

        .k-form-legend {
            @include fill( $border: $form-legend-border-color );
        }

        .k-field-info {
            @include fill( $color: $subtle-text );
        }

        .k-alert-error {
            @include fill( $color: $error );
        }

        /*
        //commented out pending further development
        &.ng-touched.ng-submitted.ng-dirty.ng-validzzz {
            .k-button.mri-k-show-validation {
                .k-button-text {
                    color: transparent;
                }
    
                &.k-icon-button {
                    .k-button-icon {
                        color: transparent;
                    }
                }
    
                &::before {
                    background-image: $success-icon;
                    background-size: 14px 14px !important;
                    background-position: center !important;
                    background-repeat: no-repeat !important;
                    background-color: transparent;
                    opacity: initial;
                    display: inline;
                }
    
                &.k-button-solid-primary, &.mri-k-button--dark {
                    &::before {
                        background-image: $success-icon--dark;
                    }
                }
            }
        }

        &.ng-touched.ng-submitted.ng-dirty.ng-invalidzzz {
            .k-button.mri-k-show-validation {
                .k-button-text {
                    color: transparent;
                }
    
                &.k-icon-button {
                    .k-button-icon {
                        color: transparent;
                    }
                }
    
                &::before {
                    background-size: 14px 14px !important;
                    background-position: center !important;
                    background-repeat: no-repeat !important;
                    background-color: transparent;
                    opacity: initial;
                    display: inline;
                }
    
                &::before {
                    background-image: $invalid-icon;
                }

                &.k-button-solid-primary, &.mri-k-button--dark {
                    &::before {
                        background-image: $invalid-icon--dark;
                    }
                }
            }
        }

        &.ng-touched.ng-submitted.ng-dirtyzzz:not(.ng-invalid):not(.ng-valid) {
            .k-button.mri-k-show-validation {
                .k-button-text {
                    color: transparent;
                }
    
                &.k-icon-button {
                    .k-button-icon {
                        color: transparent;
                    }
                }
    
                &::before {
                    background-size: 14px 14px !important;
                    background-position: center !important;
                    background-repeat: no-repeat !important;
                    background-color: transparent;
                    opacity: initial;
                    display: inline;
                }
    
                pointer-events: none;
    
                &::before {
                    @extend .k-i-loading;
                }
    
                &.k-button-solid-primary, &.mri-k-button--dark {
                    &::before {
                        @extend .k-i-loading--dark;
    
                    }
                }
            }
        }
        */
    }

    .k-tooltip-validation.k-invalid-msg, .k-form-error {
        border-color: transparent;
        color: $error-txt;
        background-color: $error-bg;
    }

    .k-form-error {
        box-shadow: $popup-shadow;
    }

    .k-form-separator {
        @include fill( $border: $form-separator-border-color );
    }

}
