// Dialog
$dialog-titlebar-bg: #DFE7E8 !default;
$dialog-titlebar-text: $heading-text  !default;
$dialog-titlebar-border: transparent !default;

$dialog-buttongroup-padding-x: 16px !default;
$dialog-buttongroup-padding-y: 16px !default;
$dialog-buttongroup-border-width: 0px !default;

$dialog-button-spacing: $actions-button-spacing !default;
$dialog-margin: 16px !default;
