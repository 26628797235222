@include exports( "button/theme" ) {

    // Solid button
    .k-button-solid-base, .k-button {
        @include fill(
            $kendo-button-text,
            $kendo-button-bg,
            $kendo-button-border,
            $kendo-button-gradient
        );
        @include box-shadow( $kendo-button-shadow );

        .k-button-text::after, &:empty::after, &.k-split-button-arrow .k-button-icon::before {
            background-color: $kendo-button-text;
        }

        // Hover state
        &:hover,
        &.k-hover {
            @include fill(
                $kendo-button-hover-text,
                $kendo-button-hover-bg,
                $kendo-button-hover-border,
                $kendo-button-hover-gradient
            );
            @include box-shadow( $kendo-button-hover-shadow );
        }

        // Focus state
        &:focus,
        &.k-focus {
            @include box-shadow( $kendo-button-focus-shadow );
        }

        // Active state
        &:active,
        &.k-active {
            @include fill(
                $kendo-button-active-text,
                $kendo-button-active-bg,
                $kendo-button-active-border,
                $kendo-button-active-gradient
            );
            @include box-shadow( $kendo-button-active-shadow );
        }

        // Selected state
        &.k-selected {
            @include fill(
                $kendo-button-selected-text,
                $kendo-button-selected-bg,
                $kendo-button-selected-border,
                $kendo-button-selected-gradient
            );
            @include box-shadow( $kendo-button-selected-shadow );
        }

        // Disabled state
        &:disabled,
        &.k-disabled {
            @include fill(
                $kendo-button-disabled-text,
                $kendo-button-disabled-bg,
                $kendo-button-disabled-border,
                $kendo-button-disabled-gradient
            );
            @include box-shadow( $kendo-button-disabled-shadow );
        }

        &.mri-k-button--dark:not(.k-button-solid-primary) {
            @include fill(
                $button-text-dark,
                $button-bg-dark,
                $button-border-dark,
                $button-gradient-dark
            );
            @include box-shadow( $kendo-button-shadow );
    
            .k-button-text::after, &:empty::after, &.k-split-button-arrow .k-button-icon::before {
                background-color: $button-text-dark;
            }

            // Hover state
            &:hover,
            &.k-hover {
                @include fill(
                    $button-hovered-text-dark,
                    $button-hovered-bg-dark,
                    $button-hovered-border-dark,
                    $button-hovered-gradient-dark
                );
                @include box-shadow( $kendo-button-hover-shadow );
            }

            // Active state
            &:active,
            &.k-active {
                @include fill(
                    $button-pressed-text-dark,
                    $button-pressed-bg-dark,
                    $button-pressed-border-dark,
                    $button-pressed-gradient-dark
                );
                @include box-shadow( $kendo-button-active-shadow );
            }

            // Selected state
            &.k-selected {
                @include fill(
                    $button-pressed-text-dark,
                    $button-pressed-bg-dark,
                    $button-pressed-border-dark,
                    $button-pressed-gradient-dark
                );
                @include box-shadow( $kendo-button-selected-shadow );
            }
        }

        //&.mri-k-valid:not(:hover), &.mri-k-invalid:not(:hover), &.mri-k-is-loading {
        &.mri-k-show-validation.mri-k-valid, &.mri-k-show-validation.mri-k-warning, &.mri-k-show-validation.mri-k-invalid, &.mri-k-show-validation.mri-k-is-loading {
            pointer-events: none;

            .k-button-text {
                color: transparent;
            }

            &.k-icon-button {
                .k-button-icon {
                    color: transparent;
                }
            }

            &::before {
                background-size: 1em 1em !important;
                background-position: center !important;
                background-repeat: no-repeat !important;
                background-color: transparent;
                opacity: initial;
                display: inline;
            }
        }

        &.mri-k-show-validation {
            //&.mri-k-valid:not(:hover) {
            &.mri-k-valid {
                &::before {
                    background-image: $success-icon;
                }
    
                &.k-button-solid-primary, &.mri-k-button--dark {
                    &::before {
                        background-image: $success-icon--dark;
                    }
                }
            }
    
            &.mri-k-warning {
                &::before {
                    background-image: $warning-icon;
                }
    
                &.k-button-solid-primary, &.mri-k-button--dark {
                    &::before {
                        background-image: $warning-icon--dark;
                    }
                }
            }

            &.mri-k-invalid {
                &::before {
                    background-image: $invalid-icon;
                }
    
                &.k-button-solid-primary, &.mri-k-button--dark {
                    &::before {
                        background-image: $invalid-icon--dark;
                    }
                }
            }
    
            &.mri-k-is-loading {
                &::before {
                    @extend .k-i-loading;
                }
    
                &.k-button-solid-primary, &.mri-k-button--dark {
                    &::before {
                        @extend .k-i-loading--dark;
    
                    }
                }
            }
        }
    }

    $_shade-fn: get-function( $kendo-solid-button-shade-function );

    @each $name, $color in $kendo-button-theme-colors {
        @if ($name != "base") {
            $_button-text: contrast-wcag( $color );
            $_button-bg: if( $kendo-solid-button-shade-bg-amount, call( $_shade-fn, $color, $kendo-solid-button-shade-bg-amount ), null );
            $_button-border: if( $kendo-solid-button-shade-border-amount, call( $_shade-fn, $color, $kendo-solid-button-shade-border-amount ), null );

            $_button-hover-text: null;
            $_button-hover-bg: if( $kendo-solid-button-hover-shade-bg-amount, call( $_shade-fn, $color, $kendo-solid-button-hover-shade-bg-amount ), null );
            $_button-hover-border: if( $kendo-solid-button-hover-shade-border-amount, call( $_shade-fn, $color, $kendo-solid-button-hover-shade-border-amount ), null );

            $_button-active-text: null;
            $_button-active-bg: if( $kendo-solid-button-active-shade-bg-amount, call( $_shade-fn, $color, $kendo-solid-button-active-shade-bg-amount ), null );
            $_button-active-border: if( $kendo-solid-button-active-shade-border-amount, call( $_shade-fn, $color, $kendo-solid-button-active-shade-border-amount ), null );

            .k-button-solid-#{$name} {
                @include fill(
                    $_button-text,
                    $_button-bg,
                    $_button-border,
                    $kendo-solid-button-gradient
                );

                .k-button-text::after, &:empty::after, &.k-split-button-arrow .k-button-icon::before {
                    background-color: $_button-text;
                }

                // Hover state
                &:hover,
                &.k-hover {
                    @include fill(
                        $_button-hover-text,
                        $_button-hover-bg,
                        $_button-hover-border
                    );
                }

                // Focus state
                &:focus,
                &.k-focus {
                    @if ( $kendo-solid-button-shadow ) {
                        box-shadow: $kendo-button-focus-shadow;
                    }
                }

                // Active state
                &:active,
                &.k-active {
                    @include fill(
                        $_button-active-text,
                        $_button-active-bg,
                        $_button-active-border
                    );
                }

                // Selected
                &.k-selected {
                    @include fill(
                        $_button-active-text,
                        $_button-active-bg,
                        $_button-active-border
                    );
                }

                // Disabled state
                &:disabled,
                &.k-disabled {
                    @include fill(
                        $kendo-button-disabled-text,
                        $kendo-button-disabled-bg,
                        $kendo-button-disabled-border,
                        $kendo-button-disabled-gradient
                    );
                    @include box-shadow( $kendo-button-disabled-shadow );
                }
            }
        }
    }


    // Outline button
    @each $name, $color in map-merge( $kendo-button-theme-colors, ( "base": $kendo-button-border ) ) {
        .k-button-outline-#{$name} {
            @include box-shadow( none );
            border-color: $color;
            color: $kendo-button-text;
            background-color: $kendo-button-bg;

            .k-button-text::after, &:empty::after, &.k-split-button-arrow .k-button-icon::before {
                background-color: $kendo-button-text;
            }
    
            // Hover state
            &:hover,
            &.k-hover {
                background-color: $kendo-button-hover-bg;
            }

            // Focus state
            &:focus,
            &.k-focus {
                @if $kendo-outline-button-shadow {
                    box-shadow: $kendo-button-focus-shadow;
                }
            }

            // Active state
            &:active,
            &.k-active {
                background-color: $kendo-button-active-bg;
            }

            // Selected
            &.k-selected {
                background-color: $kendo-button-selected-bg;
            }

            // Disabled state
            &:disabled,
            &.k-disabled {
                color: $kendo-button-disabled-text;
            }

            &.mri-k-button--dark {
                @include fill(
                    $button-text-dark,
                    $button-bg-dark,
                    $button-border-dark,
                    $button-gradient-dark
                );
                @include box-shadow( $kendo-button-shadow );
        
                .k-button-text::after, &:empty::after, &.k-split-button-arrow .k-button-icon::before {
                    background-color: $button-text-dark;
                }
    
                // Hover state
                &:hover,
                &.k-hover {
                    @include fill(
                        $button-hovered-text-dark,
                        $button-hovered-bg-dark,
                        $button-hovered-border-dark,
                        $button-hovered-gradient-dark
                    );
                    @include box-shadow( $kendo-button-hover-shadow );
                }
    
                // Active state
                &:active,
                &.k-active {
                    @include fill(
                        $button-pressed-text-dark,
                        $button-pressed-bg-dark,
                        $button-pressed-border-dark,
                        $button-pressed-gradient-dark
                    );
                    @include box-shadow( $kendo-button-active-shadow );
                }
    
                // Selected state
                &.k-selected {
                        @include fill(
                        $button-pressed-text-dark,
                        $button-pressed-bg-dark,
                        $button-pressed-border-dark,
                        $button-pressed-gradient-dark
                    );
                    @include box-shadow( $kendo-button-selected-shadow );
                }
            }
        }
    }


    // Flat button
    @each $name, $color in map-merge( $kendo-button-theme-colors, ( "base": $interactive ) ) {
        .k-button-flat-#{$name} {
            @include box-shadow( none );
            border-color: transparent;
            color: $color;
            background-color: $kendo-button-bg;

            &.k-icon-button {
                color: $kendo-button-text;
            }

            .k-button-text::after, &:empty::after, &.k-split-button-arrow .k-button-icon::before {
                background-color: $color;
            }
    
           // Hover state
           &:hover,
           &.k-hover {
               background-color: $kendo-button-hover-bg;
           }

           // Focus state
           &:focus,
           &.k-focus {
               @if $kendo-flat-button-shadow {
                   box-shadow: $kendo-button-focus-shadow;
               }
           }

           // Active state
           &:active,
           &.k-active {
               background-color: $kendo-button-active-bg;
           }

           // Selected
           &.k-selected {
              background-color: $kendo-button-selected-bg;
           }

           // Disabled state
            &:disabled,
            &.k-disabled {
                color: $kendo-button-disabled-text;
            }

            &.mri-k-button--dark {
                border-color: transparent !important;
                color: $button-text-dark;
        
                .k-button-text::after, &:empty::after, &.k-split-button-arrow .k-button-icon::before {
                    background-color: $button-text-dark;
                }
    
                // Hover state
                &:hover,
                &.k-hover {
                    background-color: $button-hovered-bg-dark;
                }
    
                // Active state
                &:active,
                &.k-active {
                    background-color: $button-pressed-bg-dark;
                }
    
                // Selected state
                &.k-selected {
                        background-color: $button-pressed-bg-dark;
                }
            }
        }
    }


    // Link button
    @each $name, $color in map-merge( $kendo-button-theme-colors, ( "base": $interactive ) ) {
        .k-button-link-#{$name} {
            @include box-shadow( none );
            border-color: transparent;
            color: $color;
            background-color: $kendo-button-bg;

            .k-button-text::after, &:empty::after, &.k-split-button-arrow .k-button-icon::before {
                background-color: $kendo-button-text;
            }
    
            // Hover
            &:hover,
            &.k-hover {
                background-color: $kendo-button-hover-bg;
            }

            // Focus
            &:focus,
            &.k-focus {
                @if ( $kendo-link-button-shadow ) {
                    box-shadow: $kendo-button-focus-shadow;
                }
            }

            // Active
            &:active,
            &.k-active {
                background-color: $kendo-button-active-bg;
            }

            // Selected
            &.k-selected {
                background-color: $kendo-button-selected-bg;
            }

            // Disabled state
            &:disabled,
            &.k-disabled {
                color: $kendo-button-disabled-text;
            }
        }
    }


    // Clear button
    @each $name, $color in map-merge( $kendo-button-theme-colors, ( "base": $kendo-button-text ) ) {
        .k-button-clear-#{$name} {
            @include box-shadow( none );
            border-color: transparent;
            color: $color;
            background-color: $kendo-button-bg;

            .k-button-text::after, &:empty::after, &.k-split-button-arrow .k-button-icon::before {
                background-color: $kendo-button-text;
            }
    
            // Hover
            &:hover,
            &.k-hover {
                background-color: $kendo-button-hover-bg;
            }

            // Focus
            &:focus,
            &.k-focus {
                @if ( $kendo-link-button-shadow ) {
                    box-shadow: $kendo-button-focus-shadow;
                }
            }

            // Active
            &:active,
            &.k-active {
                background-color: $kendo-button-active-bg;
            }

            // Selected
            &.k-selected {
                background-color: $kendo-button-selected-bg;
            }

            // Disabled state
            &:disabled,
            &.k-disabled {
                color: $kendo-button-disabled-text;
            }
        }
    }


    // Button group
    .k-button-group .k-button {
        &:focus,
        &.k-focus {
            @include box-shadow( $kendo-button-group-focus-shadow );
        }
    }

    .k-button-group.mri-k-button-group-selector {
        background-color: #D8E2E8;
        > .k-button {
            border-color: transparent;

            // Hover
            &:hover,
            &.k-hover {
                background-color: #F1F4F6;
            }

            // Focus
            &:focus,
            &.k-focus {
                @if ( $kendo-link-button-shadow ) {
                    box-shadow: $kendo-button-focus-shadow;
                }
            }

            // Active
            &:active,
            &.k-active {
                background-color: #ffffff;
                box-shadow: 0px 2px 2px rgba(0,0,0,0.05), 0px 2px 4px rgba(0,0,0,0.05), 0px 2px 6px rgba(0,0,0,0.05), 0px 2px 8px rgba(0,0,0,0.05);
            }

            // Selected
            &.k-selected {
                background-color: #ffffff;
                box-shadow: 0px 2px 2px rgba(0,0,0,0.05), 0px 2px 4px rgba(0,0,0,0.05), 0px 2px 6px rgba(0,0,0,0.05), 0px 2px 8px rgba(0,0,0,0.05);
            }
        }
    }

}
