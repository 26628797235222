// Time selector
$time-selector-border-width: 1px !default;
$time-selector-font-family: $font-family !default;
$time-selector-font-size: $font-size !default;
$time-selector-line-height: $line-height !default;

$time-selector-bg: $component-bg !default;
$time-selector-text: $component-text !default;
$time-selector-border: $component-border !default;

$time-selector-header-padding-x: map-get( $spacing, 1 ) !default;
$time-selector-header-padding-y: map-get( $spacing, 1 ) !default;
$time-selector-header-border-width: 0px !default;

$time-list-width: 4em !default;
$time-list-height: 240px !default;

$time-list-title-font-size: $font-size-sm !default;
$time-list-title-line-height: 1.5 !default;
$time-list-title-height: ( $time-list-title-font-size * $time-list-title-line-height) !default;
$time-list-title-text: $subtle-text !default;
$time-list-title-focus-text: $component-text !default;

$time-list-item-padding-x: $kendo-list-item-padding-x-md !default;
$time-list-item-padding-y: $kendo-list-item-padding-y-md !default;

$time-list-highlight-border-width-x: 0px !default;
$time-list-highlight-border-width-y: 1px !default;
$time-list-highlight-border-width: $time-list-highlight-border-width-y $time-list-highlight-border-width-x !default;
$time-list-highlight-height: calc( #{$time-selector-font-size * $time-selector-line-height} + #{ $time-list-item-padding-y * 2} ) !default;
$time-list-highlight-bg: $component-bg !default;
$time-list-highlight-border: $component-border !default;

$time-list-focused-bg: rgba(0, 0, 0, .04) !default;


// Time selector sizes
$kendo-time-selector-sm-font-size: $kendo-list-font-size-sm !default;
$kendo-time-selector-sm-line-height: $kendo-list-line-height-sm !default;
$kendo-time-selector-sm-list-item-padding-x: $kendo-list-item-padding-x-sm !default;
$kendo-time-selector-sm-list-item-padding-y: $kendo-list-item-padding-y-sm !default;

$kendo-time-selector-md-font-size: $kendo-list-font-size-md !default;
$kendo-time-selector-md-line-height: $kendo-list-line-height-md !default;
$kendo-time-selector-md-list-item-padding-x: $kendo-list-item-padding-x-md !default;
$kendo-time-selector-md-list-item-padding-y: $kendo-list-item-padding-y-md !default;

$kendo-time-selector-lg-font-size: $kendo-list-font-size-lg !default;
$kendo-time-selector-lg-line-height: $kendo-list-line-height-lg !default;
$kendo-time-selector-lg-list-item-padding-x: $kendo-list-item-padding-x-lg !default;
$kendo-time-selector-lg-list-item-padding-y: $kendo-list-item-padding-y-lg !default;

$kendo-time-selector-sizes: (
    sm: (
        font-size: $kendo-time-selector-sm-font-size,
        line-height: $kendo-time-selector-sm-line-height,
        list-item-padding-x: $kendo-time-selector-sm-list-item-padding-x,
        list-item-padding-y: $kendo-time-selector-sm-list-item-padding-y
    ),
    md: (
        font-size: $kendo-time-selector-md-font-size,
        line-height: $kendo-time-selector-md-line-height,
        list-item-padding-x: $kendo-time-selector-md-list-item-padding-x,
        list-item-padding-y: $kendo-time-selector-md-list-item-padding-y
    ),
    lg: (
        font-size: $kendo-time-selector-lg-font-size,
        line-height: $kendo-time-selector-lg-line-height,
        list-item-padding-x: $kendo-time-selector-lg-list-item-padding-x,
        list-item-padding-y: $kendo-time-selector-lg-list-item-padding-y
    )
) !default;
