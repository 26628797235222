@include exports("appbar/theme") {

    .k-appbar {
        @include box-shadow( $appbar-box-shadow );
    }
    .k-appbar-bottom {
        @include box-shadow( $appbar-bottom-box-shadow );
    }


    // AppBar theme colors
    @each $name, $color in $kendo-theme-colors {
        .k-appbar-#{$name} {
            @if $name == "light" {
                color: $appbar-light-text;
                background-color: $appbar-light-bg;
            } @else if $name == "dark" {
                color: $appbar-dark-text;
                background-color: $appbar-dark-bg;
            } @else {
                color: contrast-wcag( $color );
                background-color: $color;
            }
        }
    }

}
