// Loader
$loader-segment-border-radius: 50% !default;
$loader-segment-size-sm: map-get( $spacing, 1 ) !default;
$loader-segment-size-md: map-get( $spacing, 2 ) !default;
$loader-segment-size-lg: map-get( $spacing, 4 ) !default;

$loader-padding-sm: ( $loader-segment-size-sm * 0.5 ) !default;
$loader-padding-md: ( $loader-segment-size-md * 0.5 ) !default;
$loader-padding-lg: ( $loader-segment-size-lg * 0.5 ) !default;

$loader-spinner-3-width-sm: ( $loader-segment-size-sm * 4 ) !default;
$loader-spinner-3-height-sm: ( $loader-spinner-3-width-sm * $equilateral-height ) !default;
$loader-spinner-3-width-md: ( $loader-segment-size-md * 4 ) !default;
$loader-spinner-3-height-md: ( $loader-spinner-3-width-md * $equilateral-height ) !default;
$loader-spinner-3-width-lg: ( $loader-segment-size-lg * 4 ) !default;
$loader-spinner-3-height-lg: ( $loader-spinner-3-width-lg * $equilateral-height ) !default;

$loader-spinner-4-width-sm: $loader-segment-size-sm * 4 !default;
$loader-spinner-4-height-sm: $loader-spinner-4-width-sm !default;
$loader-spinner-4-width-md: $loader-segment-size-md * 4 !default;
$loader-spinner-4-height-md: $loader-spinner-4-width-md !default;
$loader-spinner-4-width-lg: $loader-segment-size-lg * 4 !default;
$loader-spinner-4-height-lg: $loader-spinner-4-width-lg !default;

$loader-secondary-bg: #656565 !default;

$loader-container-panel-border-width: 1px !default;
$loader-container-panel-border-style: solid !default;
$loader-container-panel-border-color: $component-border !default;
$loader-container-panel-border-radius: $kendo-border-radius-md !default;
$loader-container-panel-bg: $white !default;

$loader-container-padding-sm: map-get( $spacing, 4 ) !default;
$loader-container-gap-sm: map-get( $spacing, 1 ) !default;
$loader-container-font-size-sm: $font-size-sm !default;

$loader-container-padding-md: map-get( $spacing, 5 ) !default;
$loader-container-gap-md: map-get( $spacing, 2 ) !default;
$loader-container-font-size-md: $font-size-md !default;

$loader-container-padding-lg: map-get( $spacing, 6 ) !default;
$loader-container-gap-lg: map-get( $spacing, 3 ) !default;
$loader-container-font-size-lg: $font-size-lg !default;
