@include exports( "floating-label/theme" ) {

    // Floating label
    .k-floating-label-container {

        > .k-label {
            @include fill(
                $color: $floating-label-text,
                $bg: $floating-label-bg
            );
        }

        &.k-focus > .k-label {
            @include fill(
                $color: $floating-label-focus-text,
                $bg: $floating-label-focus-bg
            );
        }

    }

}
