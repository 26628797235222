@include exports( "breadcrumb/layout" ) {

    // Breadcrumb
    .k-breadcrumb {
        margin: $breadcrumb-margin-y $breadcrumb-margin-x;
        padding: $breadcrumb-padding-y $breadcrumb-padding-x;
        border-width: $breadcrumb-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-family: $breadcrumb-font-family;
        font-size: $breadcrumb-font-size;
        line-height: $breadcrumb-line-height;
        display: flex;
        flex-direction: row;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        *,
        *::before,
        *::after {
            box-sizing: border-box;
        }
    }


    // Breadcrumb container
    .k-breadcrumb-container,
    .k-breadcrumb-root-item-container {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-flow: row nowrap;
        overflow: hidden;
    }

    .k-breadcrumb-container {

        &.k-flex-wrap {
            flex-wrap: wrap;
        }
    }
    .k-breadcrumb-root-item-container {
        align-items: flex-start;
        flex-shrink: 0;
    }


    // Breadcrumb item
    .k-breadcrumb-item {
        vertical-align: middle;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
    }


    // Breadcrumb root item
    .k-breadcrumb-root-item {
        flex-shrink: 0;
    }


    // Breadcrumb links
    .k-breadcrumb-link,
    .k-breadcrumb-root-link {
        @include border-radius( $breadcrumb-link-border-radius );
        padding: $breadcrumb-link-padding-y $breadcrumb-link-padding-x;
        color: $breadcrumb-link-initial-text;
        text-decoration: none;
        white-space: nowrap;
        outline: none;
        cursor: pointer;
        position: relative;
        display: inline-flex;
        align-items: center;
        align-self: stretch;
        overflow: hidden;
        transition: $transition;
    }

    .k-breadcrumb-root-link {
        margin-right: $breadcrumb-root-link-spacing;
    }

    .k-breadcrumb-link > .k-image,
    .k-breadcrumb-icontext-link .k-icon {
        margin-right: $breadcrumb-link-icon-spacing;
    }

    .k-breadcrumb-icon-link {
        padding: $breadcrumb-icon-link-padding-y $breadcrumb-icon-link-padding-x;
    }


    // Breadcrumb delimiter
    .k-breadcrumb-delimiter,
    .k-breadcrumb-delimiter-icon {
        color: inherit;
        font-size: $breadcrumb-delimiter-icon-font-size;
        opacity: $disabled-opacity;
    }


    // Editing
    .k-breadcrumb-input-container {
        width: 100%;

        .k-breadcrumb-input-container {
            border-color: transparent;
        }
    }

    @include exports("breadcrumb/layout/rtl") {

        .k-breadcrumb {
            &[dir="rtl"],
            .k-rtl & {
                .k-breadcrumb-root-link {
                    margin-right: 0;
                    margin-left: $breadcrumb-root-link-spacing;
                }

                .k-breadcrumb-link > .k-image,
                .k-breadcrumb-icontext-link .k-icon {
                    margin-right: 0;
                    margin-left: $breadcrumb-link-icon-spacing;
                }
            }
        }

    }

}
