@include exports("grid/theme") {

    $tooltip-bg: $primary !default;
    $tooltip-text: contrast-wcag($primary) !default;

    .k-grid td {
        @include fill( $border: $grid-border );
    }
    
    .k-grid-header,
    .k-header,
    th.k-header,
    .k-grid-header-wrap,
    .k-grouping-header,
    .k-grouping-header .k-group-indicator,
    .k-grid-footer,
    .k-grid-footer-wrap,
    .k-grid-content-locked,
    .k-grid-footer-locked,
    .k-grid-header-locked,
    .k-filter-row > td,
    .k-filter-row > th {
        @include fill( $border: $grid-header-border );
    }

    .k-filter-row {
        background-color: #C6CED5;
    }

    .k-grid {
        .k-checkbox, .k-input, .k-picker {
            background-color: $bg-color;
        }
    } 

    .k-grid-header,
    .k-grouping-header,
    .k-grid-add-row,
    .k-grid-footer {
        @include fill(
            $color: $grid-header-text,
            $bg: $grid-header-bg
        );
    }

    .k-grouping-header {
        color: $grid-header-text;
        background-color: #E6F4F5;

        .k-group-indicator {
            border-color: rgba(0,0,0,0.08) !important;
        }
    }

    .k-grid .k-header.k-required {
        // padding-right: 30px;
        background-image: $required-icon;
        background-size: $grid-font-size $grid-font-size;
        background-repeat: no-repeat;
        background-position: calc(100% - 11px) center;

        &.k-filterable {
            background-position: calc(100% - 40px) center;
        }
        
        &.align-right {
            // padding-right: 8px;
            // padding-left: 30px;
            background-position: 11px center;

            &.k-filterable {
                background-position: 40px center;
            }
        }
    }

    .k-header .k-i-more-vertical {
        &::before { 
            content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.75 8.75C2.7165 8.75 3.5 7.9665 3.5 7C3.5 6.0335 2.7165 5.25 1.75 5.25C0.783502 5.25 0 6.0335 0 7C0 7.9665 0.783502 8.75 1.75 8.75ZM7 8.75C7.9665 8.75 8.75 7.9665 8.75 7C8.75 6.0335 7.9665 5.25 7 5.25C6.0335 5.25 5.25 6.0335 5.25 7C5.25 7.9665 6.0335 8.75 7 8.75ZM14 7C14 7.9665 13.2165 8.75 12.25 8.75C11.2835 8.75 10.5 7.9665 10.5 7C10.5 6.0335 11.2835 5.25 12.25 5.25C13.2165 5.25 14 6.0335 14 7Z' fill='%23162029'/%3E%3C/svg%3E%0A");
        }
    }
    .k-grid-toolbar,
    .k-grid-add-row {
        @include fill(
            $color: $grid-header-text,
            $bg: $bg-color
        );
    }

    .k-grid-content {
        // setting this background color resolves glitches in iOS
        @include fill($bg: $grid-bg );
    }

    .k-group-footer td,
    .k-grouping-row td,
    tbody .k-group-cell {
        @include fill(
            $color: $grid-grouping-row-text,
            $bg: $grid-grouping-row-bg
        );
    }

    .k-grouping-dropclue {
        &::before {
            border-color: $grid-header-text transparent transparent;
        }

        &::after {
            background-color: $grid-header-text;
        }
    }

    .k-grid {
        @include fill( $grid-text, $grid-bg, $grid-border );

        // Alt row
        &.mri-k-show-alt-color {
            tr.k-alt {
                background-color: $grid-alt-bg;
            }
        }

        // Hover state
        &.mri-k-show-hover-color {
            tbody tr:not(.k-detail-row):hover,
            tbody tr:not(.k-detail-row).k-hover {
                color: $grid-hovered-text;
                background-color: $grid-hovered-bg;
            }
        }

        // Hover state, drilldown, iteractive
        .k-gridrow-drilldown, .k-gridrow-interactive, .k-gridrow-editable, .k-selectable tr {
            &:hover {
                color: $grid-hovered-text;
                background-color: $hovered-bg !important;
                cursor: pointer !important;
            }
            &:active {
                color: $grid-selected-text !important;
                background-color: $grid-selected-bg !important;
            }
        }
    
        // Selected state
        td.k-selected,
        tr.k-selected {
            color: $grid-selected-text;
            background-color: $grid-selected-bg;
        }

        // Focused state
        td:focus,
        td.k-focus,
        th:focus,
        th.k-focus,
        .k-master-row > td:focus,
        .k-grouping-row > td:focus,
        .k-detail-row > td:focus,
        .k-group-footer > td:focus,
        .k-grid-pager.k-focus {
            box-shadow: $grid-focused-shadow;
        }

        .k-grid-filter,
        .k-header-column-menu,
        .k-grid-header-menu,
        .k-hierarchy-cell .k-icon {
            color: $grid-header-text;
        }

        .k-grouping-row {
            background-color: $grid-grouping-row-bg;

            .k-icon {
                color: $grid-header-text;
                text-decoration: none;

                &:hover {
                    background-color: $hovered-bg;
                }
        
                // Focused state
                &:focus {
                    box-shadow: $kendo-button-focus-shadow !important;
                }
        
                // Pressed state
                &:active {
                    background-color: $pressed-bg;
                }
            }

            .k-grid-content-sticky {
                border-color: $grid-sticky-border;
                border-top-color: $grid-header-border;
            }
        }

        .k-tooltip.k-tooltip-validation, .k-tooltip.k-tooltip-error {
            border-color: transparent;
            color: $error-txt;
            background-color: $error-bg;
            box-shadow: $popup-shadow;

            .k-tooltip-icon {
                display: none;
            }

            .k-callout-n {
                border-bottom-color: $tooltip-bg;
            }

            .k-callout-e {
                border-left-color: $tooltip-bg;
            }

            .k-callout-s {
                border-top-color: $tooltip-bg;
            }

            .k-callout-w {
                border-right-color: $tooltip-bg;
            }
        }

        // Locked columns
        .k-grid-header-locked,
        .k-grid-content-locked,
        .k-grid-header-locked .k-header,
        .k-grid-content-locked td {
            border-color: $grid-sticky-header-border;
        }

        .k-grid-content-locked {
            .k-group-footer td,
            .k-group-cell {
                @include fill( $border: $grid-header-border );
            }

            .k-grouping-row + tr td {
                border-top-color: $grid-header-border;
            }

            // Selected state
            .k-selected td {
                @include fill( $bg: $grid-sticky-selected-bg );
            }

            .k-selected.k-alt td {
                @include fill( $bg: $grid-sticky-selected-alt-bg );
            }

            // Selected hover
            .k-selected:hover td,
            .k-selected.k-hover td {
                @include fill( $bg: $grid-sticky-selected-hovered-bg );
            }
        }

        .k-grid-header-locked .k-header {
            border-bottom-color: $grid-header-border;
        }

        &[dir="rtl"],
        &.k-rtl {
            th.k-hierarchy-cell,
            td.k-hierarchy-cell {
                border-left-color: transparent;
            }
        }
    }

    col.k-sorted,
    th.k-sorted {
        background-color: $grid-sorted-bg;
    }

    // Grid header
    .k-grid-header {

        .k-i-sort-asc-small,
        .k-i-sort-desc-small,
        .k-i-sort-asc-smallall,
        .k-i-sort-desc-smallall,
        .k-sort-order {
            color: $grid-sorting-indicator-text;
        }

        .k-grid-filter,
        .k-header-column-menu,
        .k-grid-header-menu,
        .k-hierarchy-cell .k-icon {
            &:hover {
                color: $kendo-button-hover-text;
                background-color: $kendo-button-hover-bg;
            }
            &:focus,
            &.k-focus {
                box-shadow: inset 0 0 0 2px rgba( 0, 0, 0, .1 );
            }
            &.k-active {
                color: $selected-text;
                background-color: $selected-bg;
            }
        }

        th.k-grid-header-sticky,
        td.k-grid-header-sticky,
        .k-grid-header-sticky.k-sorted {
            @include fill(
                $color: $grid-sticky-header-text,
                $bg: $grid-sticky-header-bg
            );

            border-right-color: $grid-sticky-header-border;
            border-left-color: $grid-sticky-header-border;
        }
    }

    // Grid footer
    .k-grid-footer {
        @include fill(
            $grid-footer-text,
            $grid-footer-bg,
            $grid-footer-border
        );
        .k-grid-footer-sticky {
            border-left-color: $grid-sticky-border;
            border-right-color: $grid-sticky-border;
            background-color: $grid-sticky-footer-bg;
        }
    }

    // Content
    .k-master-row {
        .k-grid-content-sticky {
            border-color: $grid-sticky-border;
            border-top-color: $grid-header-border;
            background-color: $grid-sticky-bg;
        }

        .k-grid-row-sticky {
            border-top-color: $grid-sticky-border;
            border-bottom-color: $grid-sticky-border;
            background-color: $grid-sticky-bg;
        }

        &.k-alt {
            .k-grid-content-sticky,
            .k-grid-row-sticky {
                @include fill( $bg: $grid-sticky-alt-bg );
            }
        }

        // Selected state
        &.k-selected .k-grid-content-sticky,
        &.k-selected .k-grid-row-sticky,
        td.k-grid-content-sticky.k-selected {
            @include fill( $bg: $grid-sticky-selected-bg );
        }

        &.k-selected.k-alt .k-grid-content-sticky,
        &.k-selected.k-alt .k-grid-row-sticky,
        &.k-alt td.k-grid-content-sticky.k-selected {
            @include fill( $bg: $grid-sticky-selected-alt-bg );
        }


        // Hovered state
        &:hover .k-grid-content-sticky,
        &:hover .k-grid-row-sticky,
        &.k-hover .k-grid-content-sticky,
        &.k-hover .k-grid-row-sticky {
            background-color: $grid-sticky-hovered-bg;
        }


        // Selected hover
        &.k-selected:hover .k-grid-content-sticky,
        &.k-selected:hover .k-grid-row-sticky,
        &.k-selected.k-hover .k-grid-content-sticky,
        &.k-selected.k-hover .k-grid-row-sticky,
        &:hover td.k-grid-content-sticky.k-selected,
        &.k-hover td.k-grid-content-sticky.k-selected {
            background-color: $grid-sticky-selected-hovered-bg;
        }
    }

    .k-filtercell,  .k-header.k-filterable {
        .k-dropdownlist.k-dropdown-operator {
            background-color: transparent;
        }
        
        .k-grid-filter-menu,
        .k-button.k-icon-button,
        .k-button.k-button-icon {
            &:hover,
            &.k-hover {
                background-color: $kendo-button-hover-bg;
            }
    
            // Focus state
            &:focus,
            &.k-focus {
                box-shadow: $kendo-button-focus-shadow;
            }
    
            // Active state
            &:active,
            &.k-active {
                background-color: $kendo-button-active-bg;
            }
        }
    }

    .k-grid .k-edit-cell,
    .k-grid .k-grid-edit-row td {
        .k-input,
        .k-picker {
            border-color: transparent;

            &:hover,
            &.k-hover {
                border-color: $kendo-input-hover-border;
            }
    
            // Focus
            &:focus,
            &:focus-within,
            &.k-focus {
                border-color: $focused-shadow-color !important;
                box-shadow: $grid-focused-shadow-inset !important;
                > .k-input {
                    border-color: transparent !important;
                    box-shadow: none !important;
                }
            }
        }

        &.mri-k-show-validation .k-input, 
        &.mri-k-show-validation .k-picker, 
        .k-input.mri-k-show-validation, 
        .k-picker.mri-k-show-validation {
            &.k-warning {
                background-color: $warning-bg;
                border-color: $warning-bg !important;
                .k-clear-value {
                    background-color: $warning-bg;
                }

                &:hover {
                    border-color: $warning-border !important;
                }

                &:focus,
                &.k-focus {
                    border-color: $focused-warning-shadow-color !important;
                    box-shadow: $grid-focused-warning-shadow-inset !important;
                    > .k-input {
                        border-color: transparent !important;
                        box-shadow: none !important;
                    }
                }
            }

            // Invalid
            &.k-invalid,
            &.ng-invalid.ng-touched,
            &.ng-invalid.ng-dirty {
                background-color: $error-bg;
                border-color: $error-bg !important;
                .k-clear-value {
                    background-color: $error-bg;
                }
    
                &:hover {
                    border-color: $invalid-border !important;
                }

                &:focus,
                &.k-focus {
                    border-color: $focused-invalid-shadow-color !important;
                    box-shadow: $grid-focused-invalid-shadow-inset !important;
                    > .k-input {
                        border-color: transparent !important;
                        box-shadow: none !important;
                    }
                }
            }

            // Success
            &.k-success, &.k-valid:not(.ng-pristine),
            &.ng-valid.ng-touched:not(.ng-pristine),
            &.ng-valid.ng-dirty:not(.ng-pristine) {
                &:focus,
                &.k-focus {
                    border-color: $focused-success-shadow-color !important;
                    box-shadow: $grid-focused-success-shadow-inset !important;
                    > .k-input {
                        border-color: transparent !important;
                        box-shadow: none !important;
                    }
                }
            }
        }
    }

    tr:not(.k-gridrow-editable) {
        td.mri-k-editable {
            cursor: pointer;
            &:hover {
                background-color: $hovered-bg;
                //box-shadow: inset 0 0 0 1px $kendo-input-hover-border;
            }

            &.k-edit-cell {
                cursor: default;
                &:hover {
                    background-color: $component-bg;
                    //box-shadow: inset 0 0 0 1px $kendo-input-hover-border;
                }
            }
        }
    }

    tr:not(.k-gridrow-editable).k-grid-edit-row td.mri-k-editable {
        cursor: default;
        &:hover {
            background-color: $component-bg;
        }
    }

    kendo-grid {
        .k-grid-content-sticky {
            border-top-color: $grid-header-border;
            border-left-color: $grid-sticky-border;
            border-right-color: $grid-sticky-border;
            background-color: $grid-sticky-bg;

            &:hover,
            &.k-hover {
                background-color: $grid-sticky-hovered-bg;
            }
        }

        .k-grid-row-sticky {
            td {
                border-top-color: $grid-sticky-border;
                border-bottom-color: $grid-sticky-border;
                background-color: $grid-sticky-bg;
            }

            &:hover td,
            &.k-hover td {
                background-color: $grid-sticky-hovered-bg;
            }
        }

        .k-alt .k-grid-content-sticky,
        .k-grid-row-sticky.k-alt td {
            @include fill( $bg: $grid-sticky-alt-bg );
        }

        // Selected state
        tr.k-selected .k-grid-content-sticky,
        .k-selected.k-grid-row-sticky td,
        .k-grid-row-sticky td.k-selected,
        .k-selected.k-grid-content-sticky {
            @include fill( $bg: $grid-sticky-selected-bg );
        }

        tr.k-selected.k-alt .k-grid-content-sticky,
        .k-selected.k-alt.k-grid-row-sticky td,
        .k-alt .k-selected.k-grid-content-sticky {
            @include fill( $bg: $grid-sticky-selected-alt-bg );
        }

        // Hover state
        tr:hover .k-grid-content-sticky,
        tr.k-hover .k-grid-content-sticky,
        .k-grid-row-sticky:hover td,
        .k-grid-row-sticky.k-hover td,
        .k-grid-row-sticky.k-alt:hover td,
        .k-grid-row-sticky.k-alt.k-hover td,
        .k-alt:hover .k-grid-content-sticky,
        .k-alt.k-hover .k-grid-content-sticky {
            background-color: $grid-sticky-hovered-bg;
        }

        // Selected + Hover
        tr.k-selected:hover .k-grid-content-sticky,
        tr.k-selected.k-hover .k-grid-content-sticky,
        .k-selected.k-grid-row-sticky:hover td,
        .k-selected.k-grid-row-sticky.k-hover td,
        .k-selected.k-alt.k-grid-row-sticky:hover td,
        .k-selected.k-alt.k-grid-row-sticky.k-hover td,
        tr.k-selected.k-alt:hover .k-grid-content-sticky,
        tr.k-selected.k-alt.k-hover .k-grid-content-sticky,
        .k-grid-row-sticky:hover td.k-selected,
        .k-grid-row-sticky.k-hover td.k-selected,
        tr:hover .k-grid-content-sticky.k-selected,
        tr.k-hover .k-grid-content-sticky.k-selected {
            background-color: $grid-sticky-selected-hovered-bg;
        }
    }

    .k-grouping-row {
        .k-grid-content-sticky {
            @include fill( $bg: $grid-sticky-header-bg );
        }

        &:hover .k-grid-content-sticky,
        &.k-hover .k-grid-content-sticky {
            @include fill( $bg: $grid-sticky-hovered-bg );
        }
    }

    .k-column-list-item:hover,
    .k-columnmenu-item:hover {
        @include fill(
            $kendo-list-item-hover-text,
            $kendo-list-item-hover-bg
        );
    }
    .k-columnmenu-item:focus,
    .k-columnmenu-item.k-focus {
        @include box-shadow( $kendo-list-item-focus-shadow );
    }

    .k-columnmenu-item {
        &.k-selected {
            @include fill(
                $kendo-list-item-selected-text,
                $kendo-list-item-selected-bg
            );
        }
    }

    .k-column-menu {

        .k-menu:not(.k-context-menu) {

            @include fill(
                $popup-text,
                $popup-bg,
                $popup-border
            );

            .k-item {
                @include fill(
                    $kendo-list-item-text,
                    $kendo-list-item-bg
                );

                &:hover,
                &.k-hover {
                    @include fill(
                        $kendo-list-item-hover-text,
                        $kendo-list-item-hover-bg
                    );
                }

                &.k-selected {
                    @include fill(
                        $kendo-list-item-selected-text,
                        $kendo-list-item-selected-bg
                    );
                }

                &:focus,
                &.k-focus {
                    @include box-shadow( $kendo-list-item-focus-shadow );
                }
            }
        }
    }

    .k-column-menu-group-header-text {
        @include fill(
            $grid-column-menu-group-header-text,
            $grid-column-menu-group-header-bg,
            $grid-column-menu-group-header-border
        );
    }

    .k-check-all-wrap {
        @include fill( $border: $popup-border );
    }

    .k-grid-norecords-template {
        background-color: $grid-bg;
        border-color: $grid-border;
    }

    .mri-k-grid-summary {
        .k-grid-header,
        .k-grouping-header,
        .k-grid-add-row,
        .k-grid-footer {
            @include fill(
                $color: $grid-header-text,
                $bg: transparent
            );
        }
        .k-grid-header th {
            border-color: $text-color-muted;
        }
        .k-grid-toolbar {
            padding: 0px;
            
            .k-button-solid {
                @extend .k-button-flat;
            }
            .k-button-solid-base {
                @extend .k-button-flat-base;
            }
            .k-button-md {
                @extend .k-button-sm;
            }
            .k-input-md {
                @extend .k-input-sm;
            }
        }
    }

}
