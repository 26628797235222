// Radio button

/// Border radius of radio button.
/// @group radio
$kendo-radio-radius: 50% !default;
/// Border width of radio button.
/// @group radio
$kendo-radio-border-width: 1px !default;

// Radio button sizes
$kendo-radio-sizes: (
    sm: (
        size: map-get( $spacing, 4 ),
        glyph-size: ( map-get( $spacing, 4 ) - map-get( $spacing, thin ) ),
        background-size: map-get( $spacing, 3 ),
        ripple-size: map-get( $spacing, 4 ) * 3
    ),
    md: (
        size: map-get( $spacing, 6 ),
        glyph-size: ( map-get( $spacing, 6 ) - map-get( $spacing, thin ) ),
        background-size: map-get( $spacing, 4 ) - 2,
        ripple-size: map-get( $spacing, 6 ) * 3
    ),
    lg: (
        size: map-get( $spacing, 8 ),
        glyph-size: ( map-get( $spacing, 8 ) - map-get( $spacing, thin ) ),
        background-size: map-get( $spacing, 6 ) - 2,
        ripple-size: map-get( $spacing, 8 ) * 3
    )
) !default;

/// Background color of radio button.
/// @group radio
$kendo-radio-bg: $kendo-checkbox-bg !default;
/// Color of radio button.
/// @group radio
$kendo-radio-text: $kendo-checkbox-text !default;
/// Border color of radio button.
/// @group radio
$kendo-radio-border: $kendo-checkbox-border !default;

/// Background color of hovered radio button.
/// @group radio
$kendo-radio-hover-bg: $kendo-checkbox-hover-bg !default;
/// Color of hovered radio button.
/// @group radio
$kendo-radio-hover-text: $kendo-checkbox-hover-text !default;
/// Border color of hovered radio button.
/// @group radio
$kendo-radio-hover-border: $kendo-checkbox-hover-border !default;

/// Background color of checked radio button.
/// @group radio
$kendo-radio-checked-bg: $kendo-checkbox-checked-bg !default;
/// Color of checked radio button.
/// @group radio
$kendo-radio-checked-text: $kendo-checkbox-checked-text !default;
/// Border color of checked radio button.
/// @group radio
$kendo-radio-checked-border: $kendo-checkbox-checked-border !default;

/// Border color of focused radio button.
/// @group radio
$kendo-radio-focus-border: $kendo-checkbox-focus-border !default;
/// Box shadow of focused radio button.
/// @group radio
$kendo-radio-focus-shadow: $kendo-checkbox-focus-shadow !default;
/// Border color of focused and checked radio button.
/// @group radio
$kendo-radio-focus-checked-border: $kendo-checkbox-focus-checked-border !default;
/// Box shadow of focused and checked radio button.
/// @group radio
$kendo-radio-focus-checked-shadow: $kendo-checkbox-focus-checked-shadow !default;

/// Background color of disabled radio button.
/// @group radio
$kendo-radio-disabled-bg: $kendo-checkbox-disabled-bg !default;
/// Color of disabled radio button.
/// @group radio
$kendo-radio-disabled-text: $kendo-checkbox-disabled-text !default;
/// Border color of disabled radio button.
/// @group radio
$kendo-radio-disabled-border: $kendo-checkbox-disabled-border !default;

/// Background color of disabled and checked radio button.
/// @group radio
$kendo-radio-disabled-checked-bg: $kendo-checkbox-disabled-checked-bg !default;
/// Color of disabled and checked radio button.
/// @group radio
$kendo-radio-disabled-checked-text: $kendo-checkbox-disabled-checked-text !default;
/// Border color of disabled and checked radio button.
/// @group radio
$kendo-radio-disabled-checked-border: $kendo-checkbox-disabled-checked-border !default;

/// Background color of invalid radio button.
/// @group radio
$kendo-radio-invalid-bg: $kendo-checkbox-invalid-bg !default;
/// Color of invalid radio button.
/// @group radio
$kendo-radio-invalid-text: $kendo-checkbox-invalid-text !default;
/// Border color of invalid radio button.
/// @group radio
$kendo-radio-invalid-border: $kendo-checkbox-invalid-border !default;


// Radio indicator

/// Type of radio button indicator.
/// @group radio
$kendo-radio-indicator-type: image !default;

/// Glyph font family of radio button indicator.
/// @group radio
$kendo-radio-glyph-font-family: "WebComponentsIcons", monospace !default;
/// Glyph of radio button indicator.
/// @group radio
$kendo-radio-checked-glyph: "\e308" !default;

/// Image of checked radio button indicator.
/// @group radio
$kendo-radio-checked-image: escape-svg( url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'><circle cx='50%' cy='50%' r='4' fill='#{$kendo-radio-checked-text}'/></svg>") ) !default;
/// Image of disabled and checked radio button indicator.
/// @group radio
$kendo-radio-disabled-checked-image: null !default;


// Radio label

/// The horizontal margin of the radio button inside of a label.
/// @group radio
$kendo-radio-label-margin-x: map-get( $spacing, 2 ) !default;


// Radio list

/// The horizontal list item margin of radio button.
/// @group radio
$kendo-radio-list-spacing: map-get( $spacing, 4 ) !default;
/// The horizontal list item padding of radio button.
/// @group radio
$kendo-radio-list-item-padding-x: 0px !default;
/// The vertical list item padding of radio button.
/// @group radio
$kendo-radio-list-item-padding-y: $kendo-list-item-padding-y-md !default;


// Radio ripple

/// Background color of radio button ripple.
/// @group radio
$kendo-radio-ripple-bg: $kendo-radio-checked-bg !default;
/// Opacity of radio button ripple.
/// @group radio
$kendo-radio-ripple-opacity: .25 !default;
