@include exports( "utils/border" ) {

    // Border utility classes
    // sass-lint:disable-block no-important

    $utils-border-sides: (
        default: border-width,
        top: border-top-width,
        right: border-right-width,
        bottom: border-bottom-width,
        left: border-left-width,
        x: ( border-left-width, border-right-width ),
        y: ( border-top-width, border-bottom-width )
    ) !default;

    $utils-border-style: (
        solid,
        dashed,
        dotted,
        double,
        none
    ) !default;

    $utils-border-width: (
        default: 1px,
        0: 0
    ) !default;

    $utils-border-color: $kendo-theme-colors !default;

    $utils-border-radius-sides: (
        default: border-radius,
        top: ( border-top-left-radius, border-top-right-radius ),
        right: ( border-top-right-radius, border-bottom-right-radius ),
        bottom: ( border-bottom-left-radius, border-bottom-right-radius ),
        left: ( border-bottom-left-radius, border-top-left-radius ),
        top-left: border-top-left-radius,
        top-right: border-top-right-radius,
        bottom-left: border-bottom-left-radius,
        bottom-right: border-bottom-right-radius
    ) !default;

    $utils-border-radius: (
        default: $kendo-border-radius-md,
        0: 0,
        sm: $kendo-border-radius-sm,
        md: $kendo-border-radius-md,
        lg: $kendo-border-radius-lg,
        full: 9999px
    ) !default;


    @if $utils-border-sides and $utils-border-width {
        @each $side, $props in $utils-border-sides {

            $_selector-base: if( $side == default, "border", "border-#{$side}" );

            @each $width, $value in $utils-border-width {
                $_selector-suffix: if( $width == default, "", "-#{$width}" );

                .k-#{$_selector-base}#{$_selector-suffix} {
                    @each $prop in $props {
                        #{$prop}: $value !important;
                    }
                }
            }
        }
    }

    @if $utils-border-style {
        @each $style in $utils-border-style {
            .k-border-#{$style} {
                border-style: $style !important;
            }
        }
    }

    @if $utils-border-color {
        @each $name, $color in $utils-border-color {
            .k-border-#{$name} {
                border-color: $color !important;
            }
        }
    }

    @if $utils-border-radius-sides and $utils-border-radius {
        @each $side, $props in $utils-border-radius-sides {

            $_selector-base: if( $side == default, "rounded", "rounded-#{$side}" );

            @each $radius, $value in $utils-border-radius {
                $_selector-suffix: if( $radius == default, "", "-#{$radius}" );

                .k-#{$_selector-base}#{$_selector-suffix} {
                    @each $prop in $props {
                        #{$prop}: $value;
                    }
                }
                .\!k-#{$_selector-base}#{$_selector-suffix} {
                    @each $prop in $props {
                        #{$prop}: $value !important;
                    }
                }
            }

        }
    }

}
