@include exports("tabstrip/theme") {

    // Wrapper
    .k-tabstrip-wrapper {
        @include fill(
            $tabstrip-wrapper-text,
            $tabstrip-wrapper-bg,
            $tabstrip-wrapper-border
        );
    }


    // Items
    .k-tabstrip-items-wrapper {
        @include fill(
            $tabstrip-text,
            $tabstrip-bg,
            $tabstrip-border
        );

        .k-item {
            @include fill(
                $tabstrip-item-text,
                $tabstrip-item-bg,
                $tabstrip-item-border,
                $tabstrip-item-gradient
            );

            &:hover,
            &.k-hover {
                @include fill(
                    $tabstrip-item-hovered-text,
                    $tabstrip-item-hovered-bg,
                    $tabstrip-item-hovered-border,
                    $tabstrip-item-hovered-gradient
                );
            }

            &:active,
            &.k-active,
            &.k-selected {
                @include fill(
                    $tabstrip-item-selected-text,
                    $tabstrip-item-selected-bg,
                    $tabstrip-item-selected-border,
                    $tabstrip-item-selected-gradient
                );
            }

            &:focus,
            &.k-focus {
                @include box-shadow( $tabstrip-item-focused-shadow );
            }

            &.k-tabstrip-dragging {
                @include box-shadow( $tabstrip-item-dragging-shadow );
            }
        }

    }


    // Content
    .k-tabstrip-content,
    .k-tabstrip > .k-content {
        @include fill(
            $tabstrip-content-text,
            $tabstrip-content-bg,
            $tabstrip-content-border
        );

        &:focus,
        &.k-focus {
            outline-color: $tabstrip-content-border-focused;
        }
    }


    // Selected indicator
    @if ($tabstrip-indicator-size) {

        .k-tabstrip-items-wrapper {
            .k-item.k-active::after {
                border-color: $tabstrip-indicator-color;
            }
        }
    }

    .mri-k-tabstrip-secondary {
        .k-tabstrip-items-wrapper {
            background-color: transparent;
            border-bottom-color: #C6CED5 !important;

            .k-tabstrip-items {
                .k-item {
                    background-color: transparent;
                    border-bottom-color: transparent;

                    &:hover,
                    &.k-hover {
                        border-bottom-color: #C2CFD3;
                    }
        
                    &.k-active {
                        border-bottom-color: $focused-shadow-color;

                        &:hover,
                        &.k-hover {
                            border-bottom-color: $focused-shadow-color;
                        }
                    }

                    &:focus,
                    &.k-focus {
                        @include box-shadow( $tabstrip-item-focused-shadow );
                    }
                }
            }
        }
    }


    .k-tabstrip.mri-k-process-tabs {
        .k-tabstrip-items-wrapper {
            .k-active ~ .k-item:not(.k-tab-done) {
                .k-tabstrip-tab-number {
                    background-color: transparent !important;
                    background-image: none !important;
                }
            }

            .k-item {
                &.k-disabled,
                &:disabled {
                    .k-link {
                        color: $tabstrip-text;
                    }
                }

                &:not(.k-active) {
                    background-color: $tabstrip-bg;
                    &:hover,
                    &.k-hover {
                        background-color: #E3E7EA;
                    }
                    &:focus,
                    &.k-focused {
                        box-shadow: none;
                    }
                    .k-tabstrip-tab-number {
                        background-color: #ffffff;
                        background-image: $success-icon;
                    }
                }

                &.k-active {
                    background-color: #ffffff;
                    .k-tabstrip-tab-number {
                        background-color: transparent;
                        background-image: none;
                    }
                }
            }
        }
    }

}
