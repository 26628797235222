@include exports("dialog/layout") {

    .k-dialog-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        outline: 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10001;

        .k-dialog {
            position: relative;
            max-height: calc(100% - (#{ $dialog-margin } * 2));
            max-width: calc(100% - (#{ $dialog-margin } * 2));
            //animation: fade-in-down cubic-bezier(.25, .8, .25, 1);
            @media (max-width: 640px) {
                max-height: calc(100% - #{ $dialog-margin });
                max-width: calc(100% - #{ $dialog-margin });
            }
        }

        &.k-dialog-left {
            align-items: flex-start;
            justify-content: flex-start;
            .k-dialog {
                height: 100%;
                max-height: 100%;
                max-width: calc(100% - #{ $dialog-margin }) !important;
                border-radius: 0;
                //animation: fade-in-right cubic-bezier(.25, .8, .25, 1);
                .k-dialog-titlebar, .k-window-titlebar {
                    border-radius: 0;
                }
                @media (max-width: 640px) {
                    height: 100%;
                    width: calc(100% - #{ $dialog-margin }) !important;
                }
            }
        }

        &.k-dialog-right {
            align-items: flex-end;
            justify-content: flex-start;
            .k-dialog {
                height: 100%;
                max-height: 100%;
                max-width: calc(100% - #{ $dialog-margin }) !important;
                border-radius: 0;
                //animation: fade-in-right cubic-bezier(.25, .8, .25, 1);
                .k-dialog-titlebar, .k-window-titlebar {
                    border-radius: 0;
                }
                @media (max-width: 640px) {
                    height: 100%;
                    width: calc(100% - #{ $dialog-margin }) !important;
                }
            }
        }

        &.k-dialog-full-screen {
            .k-dialog {
                height: calc(100% - (#{ $dialog-margin } * 2));
                width: calc(100% - (#{ $dialog-margin } * 2));
                    @media (max-width: 640px) {
                        height: calc(100% - #{ $dialog-margin });
                        width: calc(100% - #{ $dialog-margin });
                    }
            }
        }
    }

    .k-dialog {
        padding: 0;
        position: fixed;
        box-sizing: border-box;
        border-radius: $kendo-border-radius-lg;

        // Centered
        &.k-dialog-centered {
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
        }

        &.k-dialog-left {
            height: 100%;
            max-height: 100%;
            max-width: calc(100% - #{ $dialog-margin }) !important;
            border-radius: 0;
            top: 0 !important;
            right: unset !important;
            left: 0 !important;
            .k-dialog-titlebar, .k-window-titlebar {
                border-radius: 0;
            }
        }

        &.k-dialog-right {
            height: 100%;
            max-height: 100%;
            max-width: calc(100% - #{ $dialog-margin }) !important;
            border-radius: 0;
            top: 0 !important;
            left: unset !important;
            right: 0 !important;
            .k-dialog-titlebar, .k-window-titlebar {
                border-radius: 0;
            }
        }

        &.k-dialog-full-screen {
            height: calc(100% - (#{ $dialog-margin } * 2)) !important;
            width: calc(100% - (#{ $dialog-margin } * 2)) !important;
            transform: translate(-50%, -50%);
            top: 50% !important;
            left: 50% !important;
            @media (max-width: 758px) {
                height: 100% !important;
                width: 100% !important;
                border-radius: 0;
                .k-dialog-titlebar, .k-window-titlebar {
                    border-radius: 0;
                }
            }
        }

        .k-multiselect {
            width: 100%;
        }
    }


    // Titlebar
    .k-dialog-titlebar {
        border-top-left-radius: $kendo-border-radius-lg;
        border-top-right-radius: $kendo-border-radius-lg;
    }

    .k-dialog-title {}

    .k-dialog-close {
        align-self: flex-end;
    }


    // Actions
    .k-dialog-actions {}
    .k-dialog-action {}


    // Content
    .k-dialog-content {}


    // Buttons
    .k-dialog-buttongroup {
        @include border-bottom-radius( $window-border-radius );
        padding: $dialog-buttongroup-padding-y $dialog-buttongroup-padding-x;
        border-width: $dialog-buttongroup-border-width 0 0;
        border-style: solid;
        border-color: inherit;
        flex: 0 0 auto;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-end;
        gap: $dialog-button-spacing;
        overflow: hidden;
    }


    // Legacy spacing
    .k-ie .k-dialog-buttongroup {

        .k-button ~ .k-button {
            margin-left: $dialog-button-spacing;

            .k-rtl &,
            [dir="rtl"] & {
                margin-left: 0;
                margin-right: $dialog-button-spacing;
            }
        }
    }

}
