@include exports("grid/layout") {

    // TODO: see why we need this variable
    // $filter-rows-span-size: calc( #{$line-height-em} + #{$kendo-input-padding-y-md * 2} + #{$kendo-input-border-width * 2}) !default;
    $filter-rows-span-size: null !default;

    $grid-group-dropclue-size: 6px;
    $grid-group-dropclue-line-size: calc($grid-group-dropclue-size / 3);
    $grid-group-dropclue-height: $kendo-button-calc-size !default;
    $grid-group-dropclue-top: 3px !default;

    $grid-row-inner-height: calc( #{$line-height-em} + #{$table-cell-padding-y * 2} );
    // $grid-row-half-inner-height: calc( #{$line-height-em * 0.5} + #{$table-cell-padding-y} );

    // be cautious when changing the next line; see https://github.com/MoOx/reduce-css-calc/issues/38
    $grid-command-cell-padding-y: calc( #{$table-cell-padding-y} - (#{$kendo-button-calc-size} - #{$line-height-em}) * 0.5 ) !default;

    $grid-hierarchy-col-width: ($icon-size * 2) !default;

    $grid-group-indicator-border-radius: $kendo-border-radius-md !default;
    $grid-group-indicator-gap: $table-cell-padding-y !default;
    $grid-group-indicator-button-opacity: $kendo-input-clear-value-opacity !default;
    $grid-group-indicator-button-hover-opacity: $kendo-input-clear-value-hover-opacity !default;

    $grid-grouping-row-border-top: 1px !default;
    $grid-group-footer-border-y: 1px !default;
    $grid-group-footer-second-cell-border: 1px !default;
    $grid-header-first-border: 1px !default;
    $grid-header-menu-icon-spacing: $icon-spacing !default;
    $grid-sorted-icon-spacing: $icon-spacing !default;

    // helper variables
    //$grid-filterable-icon-spacing: calc( #{$kendo-button-calc-size} + #{$grid-header-menu-icon-spacing} );
    $grid-filterable-icon-spacing: 40px;
    $grid-required-icon-spacing: 18px;
    $default-scrollbar-width: 17px;

    .k-grid {
        border-width: $grid-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-family: $grid-font-family;
        font-size: $grid-font-size;
        line-height: $grid-line-height;
        display: flex;
        flex-direction: column;
        position: relative;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        &.k-grid-display-block {
            display: block;
        }

        .k-grid-container {
            display: flex;
            flex: 1 1 auto;
            overflow: hidden;
            position: relative;
        }

        .k-grid-aria-root {
            border-color: inherit;
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            overflow: hidden;
        }

        table {
            margin: 0;
            width: 100%;
            max-width: none;
            border-width: 0;
            border-color: inherit;
            border-collapse: separate;
            border-spacing: 0;
            empty-cells: show;
            outline: 0;
        }
        thead,
        tbody,
        tfoot {
            text-align: left;
            border-color: inherit;
        }
        tr {
            border-color: inherit;
        }

        .k-gridrow-drilldown {
            td:last-child {
                padding-right: $grid-outer-column-padding-x + $font-size + $grid-cell-padding-x;
                position: relative;
                &::after {
                    content: '';
                    background: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5791 6.44583L4.05415 0.145833C3.93748 0.0583333 3.79165 0 3.61665 0C3.47081 0 3.32498 0.0875 3.20831 0.204167L2.47915 1.1375C2.27498 1.37083 2.30415 1.75 2.53748 1.95417L8.48748 6.88333L2.53748 11.8125C2.42081 11.9 2.33331 12.0458 2.33331 12.2208C2.33331 12.3667 2.36248 12.5417 2.47915 12.6583L3.23748 13.5625C3.35415 13.7083 3.52915 13.7667 3.67498 13.7667C3.82081 13.7667 3.93748 13.7083 4.05415 13.6208L11.5791 7.35C11.725 7.23333 11.7833 7.0875 11.7833 6.9125C11.7833 6.7375 11.725 6.53333 11.5791 6.44583Z' fill='%2310689D'/%3E%3C/svg%3E%0A");
                    width: $font-size;
                    height: $font-size;
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    right: $grid-outer-column-padding-x;
                }
            }
        }

        th,
        td {
            padding: $grid-cell-padding-y $grid-cell-padding-x;
            border-style: solid;
            border-color: inherit;
            outline: 0;
            font-weight: inherit;
            text-align: inherit;
            overflow: hidden;
            text-overflow: ellipsis;

            &:first-child {
                padding-left: $grid-outer-column-padding-x;
            }

            &:last-child {
                padding-right: $grid-outer-column-padding-x;
            }

            &:focus {
                outline: none;
            }

            .k-placeholder-line {
                width: $grid-cell-placeholder-line-width;
            }
        }

        th {
            border-width: 0 0 0 $grid-cell-vertical-border-width;
            white-space: nowrap;
            font-weight: bold;
        }

        th, td {
             .k-checkbox + .k-checkbox-label, input[type="checkbox"] + .k-checkbox-label {
                margin-left: $icon-spacing;
                display: inline-block;
                line-height: inherit;
                vertical-align: baseline;

                &:empty {
                    display: none;
                }
            }
            &.mri-k-checkbox-column {
                overflow: visible;
            }
        }
    
        td {
            border-width: 0 0 $grid-cell-horizontal-border-width $grid-cell-vertical-border-width;
            vertical-align: top;
            font-weight: inherit;
        }

        .align-right {
            text-align: right;
        }

        .align-center {
            text-align: center;
        }

        a {
            color: inherit;
            text-decoration: none;
        }
        a:hover {
            text-decoration: none;
        }

        table,
        .k-grid-header-wrap {
            box-sizing: content-box;
        }

        // Grouping, hierarcy
        .k-group-col,
        .k-hierarchy-col {
            padding: 0;
            width: $grid-hierarchy-col-width;
        }

        .k-grouping-row p {
            margin: 0;
            display: flex;
            align-items: center;
            align-content: center;
        }
        .k-grouping-row td:only-child {
            overflow: visible;
            padding-left: $grid-outer-column-padding-x;
            padding-right: $grid-outer-column-padding-x;
        }

        .k-grouping-row .k-group-cell,
        .k-grouping-row + tr .k-group-cell {
            border-top-width: 0;
            text-overflow: clip;
        }

        .k-group-cell + td:not(.k-group-cell), .k-group-cell + th:not(.k-group-cell) {
            padding-left: $grid-outer-column-padding-x;
        }

        .k-grouping-row .k-icon {
            margin-left: calc( #{$icon-size * 0.5} - #{$cell-padding-x} );
            margin-right: ($icon-size * 0.5);
            border: 1px solid transparent;
            border-radius: 999px;
            outline: none;

            &.k-i-collapse::before,
            &.k-i-caret-alt-down::before {
                content: "\e013";
            }

            &.k-i-expand::before,
            &.k-i-caret-alt-right::before {
                content: "\e015";
            }
        }

        .k-footer-template td, .k-footer-template th {
            border-width: 0;
        }

        .k-group-footer td {
            border-style: solid;
            border-width: $grid-group-footer-border-y 0;
        }
        .k-group-footer .k-group-cell + td {
            border-left-width: $grid-group-footer-second-cell-border;
        }

        .k-hierarchy-cell,
        .k-drag-cell {
            text-align: center;
            padding: 0;
            overflow: visible;

            > .k-icon {
                padding: $table-cell-padding-y 0;
                width: 100%;
                height: 100%;
                // That causes an issue with grid material. Commenting it for now
                // -- joneff
                // line-height: $line-height;
                display: inline-block;
                outline: 0;
            }
        }

        .k-hierarchy-cell + .k-grid-content-sticky {
            border-left-width: $grid-cell-vertical-border-width;
        }
        .k-detail-cell {}
        .k-master-row {}

        .k-detail-row {
            .k-detail-cell {
                border-left-width: 0;
            }
        }

        &[dir = "rtl"],
        .k-rtl & {
            thead,
            tbody,
            tfoot {
                text-align: right;
            }

            th {
                white-space: nowrap;
            }

            .k-grid-header-wrap,
            .k-grid-footer-wrap {
                border-width: 0 0 0 $grid-cell-vertical-border-width;
            }

            .k-group-indicator {
                .k-button {
                    margin-left: -( $icon-spacing * 0.5 );
                    margin-right: ( 2 * $icon-spacing );
                }

                .k-link .k-icon {
                    margin-left: $icon-spacing;
                    margin-right: -( $icon-spacing * 0.5);
                }
            }
            .k-group-indicator {
                margin-right: 0;
                margin-left: ( $grid-group-indicator-gap * 0.5 );
            }

            .k-group-indicator + .k-group-indicator {
                margin-right: ( $grid-group-indicator-gap * 0.5 );
            }

            .k-grid-content-locked,
            .k-grid-footer-locked,
            .k-grid-header-locked {
                border-left-width: $grid-cell-vertical-border-width;
                border-right-width: 0;
            }

            .k-grid-header-sticky,
            .k-grid-content-sticky,
            .k-grid-footer-sticky {
                border-left-width: $grid-cell-vertical-border-width;
            }

            .k-grid-header-sticky:not([style*="display: none"]) + td,
            .k-grid-header-sticky:not([style*="display: none"]) + th,
            .k-grid-content-sticky:not([style*="display: none"]) + td,
            .k-grid-content-sticky:not([style*="display: none"]) + th {
                border-left-width: $grid-cell-vertical-border-width;
                border-right-width: 0;
            }

            .k-grid-header-sticky.k-header:first-child {
                border-left-width: $grid-cell-vertical-border-width;
            }

            .k-grid-row-sticky {
                border-bottom-width: $grid-border-width;
                border-top-width: $grid-border-width;
            }

            th {
                border-width: 0 $grid-cell-vertical-border-width 1px 0;

                &:first-child {
                    border-right-width: 0;
                }
            }

            td {
                border-width: 0 $grid-cell-vertical-border-width 0 0;

                &:first-child {
                    border-right-width: 0;
                }
            }

            td.k-hierarchy-cell {
                border-right-width: 0;
            }

            .k-hierarchy-cell + td {
                border-right-width: 0;
            }

            .k-grid-header {
                .k-header {
                    z-index: 1;

                    &.k-first {
                        border-left-width: 0;
                        border-right-width: $grid-cell-vertical-border-width;
                    }
                }

                .k-with-icon,
                .k-filterable {
                    padding-left: $grid-filterable-icon-spacing;
                    padding-right: $grid-cell-padding-x;
                }

                .k-grid-filter,
                .k-header-column-menu,
                .k-grid-header-menu {
                    right: auto;
                    left: $grid-header-menu-icon-spacing;
                }
            }

            .k-grid-header-sticky.k-header.k-first {
                border-left-width: $grid-cell-vertical-border-width;
            }

            .k-grid-header-sticky.k-header.k-grid-no-left-border.k-first {
                border-left-width: $grid-cell-vertical-border-width;
                border-right-width: 0;
            }

            .k-dirty {
                border-color: currentColor currentColor transparent transparent;
                left: auto;
                right: 0;
            }

            .k-grid-header-wrap,
            .k-grid-footer-wrap {
                margin-left: -1px;
                margin-right: 0;
            }

            .k-grid-header-locked + .k-grid-header-wrap.k-auto-scrollable {
                margin-left: 0;
            }

            .k-group-footer td {
                border-width: $grid-group-footer-border-y 0;
            }
            .k-group-footer .k-group-cell + td {
                border-right-width: $grid-group-footer-second-cell-border;
            }

            .k-grouping-row .k-icon {
                margin-left: #{$icon-size * 0.5};
                margin-right: calc( #{$icon-size * 0.5} - #{$table-cell-padding-x} );
            }

            .k-column-resizer {
                left: 0;
                right: auto;
            }
        }

        .k-widget.k-tooltip.k-tooltip-validation {
            display: flex;
            position: absolute;
            width: auto;
            padding: $padding-y $padding-x;

            .k-callout {
                display: block;
            }
        }

        .k-tooltip.k-tooltip-error {
            position: absolute;
            margin-top: calc( #{$grid-cell-padding-y} + #{$grid-cell-horizontal-border-width} );
            margin-left: calc( (#{$grid-cell-padding-x} - #{$grid-cell-horizontal-border-width}) * -1 );
        }


        .k-animation-container-fixed .k-tooltip.k-tooltip-validation {
            position: static;
        }

        .k-dirty-cell {
            position: relative;

            &.k-edit-cell {
                position: static;
            }

            &.k-grid-content-sticky {
                position: sticky;
            }
        }

        .k-dirty {
            border-width: 5px;
            border-color: currentColor transparent transparent currentColor;
            left: 0;
            right: auto;
        }

        .k-grid-content-locked + .k-grid-content {
            box-sizing: content-box;
        }

        .k-grid-content-expander {
            position: absolute;
            visibility: hidden;
            height: 1px;
            bottom: 1px; // fix for vertical scrollbar appearing when no records template is displayed
        }

        .k-grid-filter,
        .k-header-column-menu,
        .k-grid-header-menu,
        .k-hierarchy-cell .k-icon {
            border-width: 0;
        }

        // Drag column
        .k-drag-col {
            padding: 0;
            width: $grid-drag-cell-width;
        }

        .k-drag-cell {
            cursor: move;
        }
    
        &.mri-k-grid-responsive--list {
            @media only screen and (max-width: 640px) {

                table, thead, tbody, th, td, tr { 
                    display: block; 
                }

                .k-grid-header {
                    height: 40px;
                    padding: 0px !important;

                    th {
                        padding: 8px 16px 8px 16px;
                    }

                    th:not(.mri-k-checkbox-header), .k-column-resizer {
                        display: none !important;
                    }
                }

                .k-grid-content {
                    border-style: solid;
                    border-width: 1px 0px 0px 0px;
                    border-color: $grid-border;

                    .mri-k-gridrow-responsive {
                        padding: 8px 16px;
                        border-style: solid;
                        border-width: 0px 0px 1px 0px;
                        border-color: $grid-border;
                        position: relative;
                        display: flex;
                        flex-direction: column;
            
                        td {
                            border-width: 0px;
                            padding: 0px;
                            text-align: left;
                        }

                        .k-command-cell .k-button {
                            margin: 0px;
                        }
                    }
                }
                .k-grid-footer .k-footer-template {
                    display: flex;
                    padding: 0px 8px;
                    td {
                        display: flex;
                        border-width: 0px;
                        padding: 8px;
                        text-align: left;
                        width: max-content;
                        &:empty {
                            display: none;
                        }
                    }
                }
            }
        }

        &.mri-k-grid-responsive--cards {
            @media only screen and (max-width: 640px) {
                table, thead, tbody, th, td, tr { 
                    display: block; 
                }

                .k-grid-header {
                    height: 40px;
                    padding: 0px !important;

                    th {
                        padding: 8px 16px 8px 16px;
                    }

                    th:not(.mri-k-checkbox-header), .k-column-resizer {
                        display: none !important;
                    }
                }

                .k-grid-content {
                    padding: 8px 16px;

                    .mri-k-gridrow-responsive {
                        @include border-radius( $card-border-radius );
                        padding: $card-body-padding-y $card-body-padding-x;
                        border-width: $card-border-width;
                        border-color: $card-border;
                        border-style: solid;
                        box-sizing: border-box;
                        position: relative;
                        @include box-shadow( $card-shadow );
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 16px;
                        &:last-child {
                            margin-bottom: 0px;
                        }                
                            
                        td {
                            border-width: 0px;
                            padding: 0px;
                            text-align: left;
                        }

                        .k-command-cell .k-button {
                            margin: 0px;
                        }
                    }
                }

                .k-grid-footer .k-footer-template {
                    display: flex;
                    padding: 0px 8px;
                    td {
                        display: flex;
                        border-width: 0px;
                        padding: 8px;
                        text-align: left;
                        width: max-content;
                        &:empty {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    // Toolbar
    .k-grid-toolbar {
        padding: $grid-toolbar-padding-y $grid-toolbar-padding-x;
        border-width: 0 0 $grid-toolbar-border-width;
        flex-shrink: 0;
    }
    .k-grid-toolbar-bottom {
        border-width: $grid-toolbar-border-width 0 0;
    }


    // Grouping header
    .k-grouping-header {
        padding: $grid-grouping-header-padding-y $grid-grouping-header-padding-x;
        border-width: 0 0 1px;
        border-style: solid;
        border-color: inherit;
        white-space: normal;
        flex-shrink: 0;
        display: block;
        position: relative;

        &::before {
            content: "";
            height: $kendo-button-calc-size;
            display: inline-block;
            vertical-align: middle;
        }
    }

    .k-grouping-header-flex {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: $grid-grouping-header-padding-y;

        &::before {
            margin-left: -$grid-grouping-header-padding-y;
        }

        .k-group-indicator {
            margin: 0;
        }
    }

    // override for angular
    .k-grid .k-indicator-container {
        margin: -$grid-grouping-header-padding-y 0;
        padding: $grid-grouping-header-padding-y 0;
    }
    .k-grid .k-indicator-container:last-child {
        flex-grow: 1;
    }

    .k-group-indicator,
    .k-drag-clue {
        @include border-radius( $grid-group-indicator-border-radius );
        margin: 0;
        padding: $kendo-button-padding-y $kendo-button-padding-x * 0.5;
        border-width: 1px;
        border-style: solid;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        vertical-align: top;
        box-sizing: border-box;
        border-color: rgba(0,0,0,0.08) !important;

        .k-link,
        .k-button {
            padding: 0;
            border-width: 0;
            display: inline-flex;
            align-items: center;
        }

        .k-link {
            position: relative;
            padding: 0 24px 0 0;
            @include border-radius( $grid-group-indicator-border-radius );

            &:hover {
                background-color: $hovered-bg !important;
            }
    
            // Focused state
            &:focus {
                box-shadow: $kendo-button-focus-shadow !important;
            }

            .k-icon {
                position: absolute;
                right: 0;
            }

            .k-i-sort-desc-small::before {
                content: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.5 0H9.5C9.775 0 10 0.225 10 0.5V1.5C10 1.775 9.775 2 9.5 2H0.5C0.225 2 0 1.775 0 1.5V0.5C0 0.225 0.225 0 0.5 0ZM6.5 4.00002H0.5C0.225 4.00002 0 4.22502 0 4.50002V5.50002C0 5.77502 0.225 6.00002 0.5 6.00002H6.5C6.775 6.00002 7 5.77502 7 5.50002V4.50002C7 4.22502 6.775 4.00002 6.5 4.00002ZM4.5 7.99998H0.5C0.225 7.99998 0 8.22498 0 8.49998V9.49998C0 9.77498 0.225 9.99998 0.5 9.99998H4.5C4.775 9.99998 5 9.77498 5 9.49998V8.49998C5 8.22498 4.775 7.99998 4.5 7.99998Z' fill='%23162029'/%3E%3C/svg%3E%0A");
            }
            .k-i-sort-asc-small::before {
                content: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.5 2H4.5C4.775 2 5 1.775 5 1.5V0.5C5 0.225 4.775 0 4.5 0H0.5C0.225 0 0 0.225 0 0.5V1.5C0 1.775 0.225 2 0.5 2ZM0.5 6.00002H6.5C6.775 6.00002 7 5.77502 7 5.50002V4.50002C7 4.22502 6.775 4.00002 6.5 4.00002H0.5C0.225 4.00002 0 4.22502 0 4.50002V5.50002C0 5.77502 0.225 6.00002 0.5 6.00002ZM0.5 7.99998H9.5C9.775 7.99998 10 8.22498 10 8.49998V9.49998C10 9.77498 9.775 9.99998 9.5 9.99998H0.5C0.225 9.99998 0 9.77498 0 9.49998V8.49998C0 8.22498 0.225 7.99998 0.5 7.99998Z' fill='%23162029'/%3E%3C/svg%3E%0A");
            }

            .k-i-sort-desc-small,
            .k-i-sort-asc-small {
                width: $font-size-sm;
                height: 20px;
                &::before{
                    align-self: baseline;
                }
            }
        }

        .k-button {
            margin-left: $icon-spacing;
            margin-right: 0;
            width: auto;
            height: auto;
            opacity: $grid-group-indicator-button-opacity;

            &::before,
            &::after {
                display: none;
            }

            &:hover {
                background-color: $hovered-bg !important;
            }
    
            // Focused state
            &:focus {
                box-shadow: $kendo-button-focus-shadow !important;
            }
    
            // Pressed state
            &:active {
                background-color: $pressed-bg;
            }
        }
    }

    .k-group-indicator + .k-group-indicator {
        margin-left: ( $grid-group-indicator-gap * 0.5 );
    }

    .k-grouping-dropclue {
        width: ($grid-group-dropclue-size * 2);
        height: $grid-group-dropclue-height;
        position: absolute;
        top: $grid-group-dropclue-top;
        box-sizing: content-box;

        &::before,
        &::after {
            display: inline-block;
            content: "";
            position: absolute;
        }

        &::before {
            border-width: $grid-group-dropclue-size;
            border-style: solid;
            border-left-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
            top: 0;
        }

        &::after {
            width: $grid-group-dropclue-line-size;
            height: calc(100% - #{$grid-group-dropclue-size});
            top: $grid-group-dropclue-size;
            left: calc( #{$grid-group-dropclue-size} - #{$grid-group-dropclue-line-size * 0.5} );
        }
    }


    // Grid header / footer
    .k-grid-header-wrap,
    .k-grid-footer-wrap {
        margin-right: -1px;
        width: 100%;
        border-width: 0 $grid-cell-vertical-border-width 0 0;
        border-style: solid;
        border-color: inherit;
        position: relative;
        overflow: hidden;
    }

    .k-grid-header-locked + .k-grid-header-wrap.k-auto-scrollable {
        margin-right: 0;

        .k-ie & {
            display: inline-block;
        }
    }

    .k-grid-header,
    .k-grid-footer {
        padding-inline-start: 0;
        padding-inline-end: var(--kendo-scrollbar-width, #{$default-scrollbar-width});
        border-width: 0;
        border-style: solid;
        border-color: inherit;
        flex: 0 0 auto;

        table {
            table-layout: fixed;
        }
    }

    div.k-grid-header,
    div.k-grid-footer {
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }

    .k-grid-header {
       // border-bottom-width: 1px;
        font-size: $grid-header-font-size;

        //table {
            //margin-bottom: -1px;
        //}

        &.k-grid-draggable-header {
            user-select: none;
            touch-action: none;
        }

        .k-header {
            position: relative;
            vertical-align: bottom;
            cursor: default;

            &:first-child {
                border-left-width: 0;
            }

            &.k-first {
                border-left-width: $grid-header-first-border;
            }
        }

        .k-header .k-link {
            position: relative;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            outline: 0;

            .k-ie &,
            .k-edge & {
                margin: 0;
                padding: 0;
            }

        }

        .k-with-icon,
        .k-filterable {
            padding-right: $grid-filterable-icon-spacing;

            &:last-child {
                padding-right: calc( #{ $grid-filterable-icon-spacing} + #{ $grid-outer-column-padding-x } );
            }

            .k-ie &,
            .k-edge & {
                padding-right: calc( #{ button-size() } + #{ $grid-header-menu-icon-spacing });
            }

            &.align-right {
                padding-right: $grid-header-padding-x;
                padding-left: $grid-filterable-icon-spacing;

                &:first-child {
                    padding-left: calc( #{ $grid-filterable-icon-spacing} + #{ $grid-outer-column-padding-x } );
                }
            }
        }

        .k-header.k-required  {
            padding-right: $grid-required-icon-spacing + 8px;

            &.align-right {
                padding-right: $grid-header-padding-x;
                padding-left: $grid-required-icon-spacing + 8px;
            }

            .k-ie &,
            .k-edge & {
                padding-right: calc( #{ button-size() } + #{$grid-required-icon-spacing} );
            }
        }

        .k-header.k-required.k-filterable, 
        .k-header.k-required.k-with-icon  {
            padding-right: calc( #{ $grid-filterable-icon-spacing } + #{ $grid-required-icon-spacing } );

            &.align-right {
                padding-right: $grid-header-padding-x;
                padding-left: calc( #{ $grid-filterable-icon-spacing } + #{ $grid-required-icon-spacing } );
            }

            .k-ie &,
            .k-edge & {
                padding-right: calc( #{ button-size() } + #{ $grid-header-menu-icon-spacing} + #{$grid-required-icon-spacing} );
            }
        }


        .k-header > .k-link:focus {
            text-decoration: none;
        }

        .k-grid-filter,
        .k-header-column-menu,
        .k-grid-header-menu {
            padding: $kendo-button-padding-y;
            width: 24px;
            height: 24px;
            box-sizing: border-box;
            border-radius: 9999px;
            outline: 0;
            line-height: $form-line-height;
            display: flex !important;
            align-items: center;
            align-content: center;
            justify-content: center;
            position: absolute;
            right: $grid-header-menu-icon-spacing;
            bottom: $grid-header-padding-y;
            z-index: 1;

            &:hover {
                cursor: pointer;
            }
        }

        .k-header.align-right {
            .k-grid-filter,
            .k-header-column-menu,
            .k-grid-header-menu, .k-grid-column-menu {
                right: auto;
                left: $grid-header-menu-icon-spacing;
            }
        }

        .k-with-icon, .k-filterable {
            &:last-child {
                .k-grid-filter,
                .k-header-column-menu,
                .k-grid-header-menu, .k-grid-column-menu {
                    right: $grid-outer-column-padding-x;
                }
            }

            &.align-right {
                &:first-child {
                    .k-grid-filter,
                    .k-header-column-menu,
                    .k-grid-header-menu, .k-grid-column-menu {
                        left: $grid-outer-column-padding-x;
                    }
                }
            }
        }


        .k-header.k-sorted {
            .k-link {
                display: grid;
                grid-auto-columns: auto 14px max-content;

                .k-column-title {
                    grid-row: 1;
                    grid-column: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-right: $icon-spacing;
                }

                .k-icon.k-i-sort-desc-small,
                .k-icon.k-i-sort-asc-small {
                    width: $font-size;
                    height: $font-size;
                    display: flex;
                    line-height: inherit;
                    grid-row: 1;
                    grid-column: 2;
                    &::before {
                        align-self: center !important;
                    }
                }

                .k-sort-order {
                    display: inline-block;
                    vertical-align: baseline;
                    height: 24px;
                    font-size: $grid-sorting-index-font-size;
                    grid-row: 1;
                    grid-column: 3;
                    justify-self: start;
                    padding-left: 2px;
                }
            }

            &.align-right {
                .k-link {
                    grid-auto-columns: 14px max-content auto;

                    .k-column-title {
                        grid-column: 3;
                        padding-left: $icon-spacing;
                        padding-right: 0px;
                    }
    
                    .k-icon.k-i-sort-desc-small,
                    .k-icon.k-i-sort-asc-small {
                        grid-column: 1;
                    }
                    .k-sort-order {
                        grid-column: 2;
                    }
                }
            }
        }

        .k-header .k-link > span:empty {
            display: none;
        }
    }

    .k-column-resizer {
        width: 1px;
        border-right: 1px solid #c2cfd3 ;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        cursor: col-resize;
    }

    .k-grid-header-wrap[data-role=resizable] {
        th:not(.k-group-cell) {
            border-right: 1px solid #c2cfd3 ;
        }
    }

    .k-grid-footer {
        border-width: 1px 0 0;

        td {
            height: $line-height-em;
        }
    }

    .k-grid-footer td,
    .k-group-footer td,
    .k-grouping-row td {
        font-weight: bold;
    }

    .k-grid-filter-popup {
        min-width: 200px;
    }

    // Standalone column menu
    .k-grid-column-menu-standalone a.k-grid-filter {
        position: initial;
        display: inline-block;
        margin: -4px 0;
        padding: 4px 8px;
    }

    // Filter row
    .k-filter-row {
        line-height: $grid-line-height;

        td,
        th {
            border-width: 0 0 0 $grid-cell-vertical-border-width;
            font-weight: normal;
            white-space: nowrap;
            padding: $grid-filter-cell-padding-y $grid-filter-cell-padding-x;
            vertical-align: top;

            &:first-child {
                padding-left: $grid-outer-column-padding-x;
            }

            &:last-child {
                padding-right: $grid-outer-column-padding-x;
            }
        }

        td:first-child {
            border-left-width: 0;
        }
    }

    .k-header.k-filterable {
        .k-grid-filter-menu {
            height: $grid-line-height;
            width: $grid-line-height;

            .k-icon {
                height: $grid-line-height;
                width: $grid-line-height;
                line-height: $grid-line-height;
                font-size: 12px;
            }
        }
    }

    .k-filtercell {
        width: auto;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        > span,
        .k-filtercell-wrapper {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: ( $grid-cell-padding-y * 0.5 );
            flex: 1 1 auto;

            > .k-button {
                flex: none;
            }
        }

        .k-filtercell-operator {
            display: flex;
            gap: $grid-padding-x;
        }

        // dropdown button for filter operators
        .k-dropdownlist.k-dropdown-operator {
            height: $grid-line-height !important;
            width: $grid-line-height;
            border-width: 0;
            border-radius: 999px;

            .k-button.k-icon-button,
            .k-button.k-button-icon {
                height: $grid-line-height;
                width: $grid-line-height;

                .k-icon {
                    height: $grid-line-height;
                    width: $grid-line-height;
                    line-height: $grid-line-height;
                    font-size: 12px;
                }
            }
        }

        // clear filter button
        .k-button.k-icon-button,
        .k-button.k-button-icon {
            height: $grid-line-height;
            width: $grid-line-height;
            border-width: 0;
            border-radius: 999px;
            padding: 0;

            .k-icon {
                height: $grid-line-height;
                width: $grid-line-height;
                line-height: $grid-line-height;
                font-size: 12px;
            }
        }

        .k-textbox {
            padding: 2px 4px !important;
            line-height: 16px !important;
            font-size: 12px !important;
        }

        .k-input, .k-picker:not(.k-dropdown-operator) {
            width: auto;
            height: 22px!important;
            flex: 1 1 auto;

            .k-input-inner {
                padding: 2px 4px !important;
                line-height: 16px !important;
                font-size: 12px !important;
            }

            .k-input-spinner {
                width: 22px!important;

                .k-spinner-increase,
                .k-spinner-decrease {
                    padding: 0px;
                }
                .k-spinner-increase .k-icon {
                    bottom: if( $use-input-spinner-icon-offset, calc( -1 * 1px), auto );
                }
                .k-spinner-decrease .k-icon {
                    top: if( $use-input-spinner-icon-offset, calc( -1 * 1px), auto );
                }
            }


            .k-input-button {
                width: 20px;
                height: 20px!important;
                .k-icon {
                    font-size: 12px;
                    &::before {
                        width: 12px!important;
                        height: 12px!important;                        
                    }
                }
            }

            &.k-picker .k-input-button,
            &.k-combobox .k-input-button {
                .k-icon {
                    font-size: 20px;
                    &::before {
                        width: 20px!important;
                        height: 20px!important;                        
                    }
                }
            }

            .k-clear-value {
                height: 16px;
                line-height: 16px;
                width: 16px;
                top: 4px;

                .k-icon {
                    font-size: 14px;
                }
            }
        }

        .k-color-picker,
        .k-dropdown-operator {
            width: min-content;
            flex: none;
        }

        .k-combobox  {
            .k-input-inner {
                padding-right: 24px;
            }
            .k-clear-value {
                right: 26px;
            }
        }
    
        .k-autocomplete  {
            .k-input-inner {
                padding-right: 24px;
            }
        }

        .k-multiselect {
            height: auto !important;

            .k-input-values {
                padding: 2px 4px !important;
                line-height: 16px !important;
                font-size: 12px !important;
                min-height: 22px;
            }

            .k-chip {
                .k-chip-action .k-icon {
                    width: 14px;
                    height: 14px;
                    font-size: 12px;
                }
            }

            .k-clear-value {
                right: 24px;
            }

            &::after {
                content: '';
                mask: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.75008 1.1665H2.91675C3.23758 1.1665 3.50008 1.429 3.50008 1.74984V2.9165C3.50008 3.23734 3.23758 3.49984 2.91675 3.49984H1.75008C1.42925 3.49984 1.16675 3.23734 1.16675 2.9165V1.74984C1.16675 1.429 1.42925 1.1665 1.75008 1.1665ZM11.0834 3.49984H5.25008C4.92925 3.49984 4.66675 3.23734 4.66675 2.9165V1.74984C4.66675 1.429 4.92925 1.1665 5.25008 1.1665H11.0834C11.4042 1.1665 11.6667 1.429 11.6667 1.74984V2.9165C11.6667 3.23734 11.4042 3.49984 11.0834 3.49984ZM12.2501 5.83317H5.25008C4.92925 5.83317 4.66675 6.09567 4.66675 6.4165V7.58317C4.66675 7.904 4.92925 8.1665 5.25008 8.1665H12.2501C12.5709 8.1665 12.8334 7.904 12.8334 7.58317V6.4165C12.8334 6.09567 12.5709 5.83317 12.2501 5.83317ZM1.75008 5.83317H2.91675C3.23758 5.83317 3.50008 6.09567 3.50008 6.4165V7.58317C3.50008 7.904 3.23758 8.1665 2.91675 8.1665H1.75008C1.42925 8.1665 1.16675 7.904 1.16675 7.58317V6.4165C1.16675 6.09567 1.42925 5.83317 1.75008 5.83317ZM9.91675 10.4998H5.25008C4.92925 10.4998 4.66675 10.7623 4.66675 11.0832V12.2498C4.66675 12.5707 4.92925 12.8332 5.25008 12.8332H9.91675C10.2376 12.8332 10.5001 12.5707 10.5001 12.2498V11.0832C10.5001 10.7623 10.2376 10.4998 9.91675 10.4998ZM1.75008 10.4998H2.91675C3.23758 10.4998 3.50008 10.7623 3.50008 11.0832V12.2498C3.50008 12.5707 3.23758 12.8332 2.91675 12.8332H1.75008C1.42925 12.8332 1.16675 12.5707 1.16675 12.2498V11.0832C1.16675 10.7623 1.42925 10.4998 1.75008 10.4998Z' fill='black'/%3E%3C/svg%3E%0A");
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                -webkit-mask-position-x: 20px;
                -webkit-mask-position-y: 5px;
                height: 22px;
                width: 36px;
                line-height: 18px;
                top: 0;
                right: 0;

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    /* IE10+ CSS styles go here */
                    mask: none;
                    background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.75008 1.1665H2.91675C3.23758 1.1665 3.50008 1.429 3.50008 1.74984V2.9165C3.50008 3.23734 3.23758 3.49984 2.91675 3.49984H1.75008C1.42925 3.49984 1.16675 3.23734 1.16675 2.9165V1.74984C1.16675 1.429 1.42925 1.1665 1.75008 1.1665ZM11.0834 3.49984H5.25008C4.92925 3.49984 4.66675 3.23734 4.66675 2.9165V1.74984C4.66675 1.429 4.92925 1.1665 5.25008 1.1665H11.0834C11.4042 1.1665 11.6667 1.429 11.6667 1.74984V2.9165C11.6667 3.23734 11.4042 3.49984 11.0834 3.49984ZM12.2501 5.83317H5.25008C4.92925 5.83317 4.66675 6.09567 4.66675 6.4165V7.58317C4.66675 7.904 4.92925 8.1665 5.25008 8.1665H12.2501C12.5709 8.1665 12.8334 7.904 12.8334 7.58317V6.4165C12.8334 6.09567 12.5709 5.83317 12.2501 5.83317ZM1.75008 5.83317H2.91675C3.23758 5.83317 3.50008 6.09567 3.50008 6.4165V7.58317C3.50008 7.904 3.23758 8.1665 2.91675 8.1665H1.75008C1.42925 8.1665 1.16675 7.904 1.16675 7.58317V6.4165C1.16675 6.09567 1.42925 5.83317 1.75008 5.83317ZM9.91675 10.4998H5.25008C4.92925 10.4998 4.66675 10.7623 4.66675 11.0832V12.2498C4.66675 12.5707 4.92925 12.8332 5.25008 12.8332H9.91675C10.2376 12.8332 10.5001 12.5707 10.5001 12.2498V11.0832C10.5001 10.7623 10.2376 10.4998 9.91675 10.4998ZM1.75008 10.4998H2.91675C3.23758 10.4998 3.50008 10.7623 3.50008 11.0832V12.2498C3.50008 12.5707 3.23758 12.8332 2.91675 12.8332H1.75008C1.42925 12.8332 1.16675 12.5707 1.16675 12.2498V11.0832C1.16675 10.7623 1.42925 10.4998 1.75008 10.4998Z' fill='black'/%3E%3C/svg%3E%0A");
                }
            }
        }

        .k-datepicker {
            .k-input-button .k-icon { 
                &::before {
                    mask: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 1H1.5C1.225 1 1 1.225 1 1.5V10.5C1 10.775 1.225 11 1.5 11H10.5C10.775 11 11 10.775 11 10.5V1.5C11 1.225 10.775 1 10.5 1ZM5.5 5V6H4.25V5H5.5ZM6.5 5H7.75V6H6.5V5ZM7.75 7V8H6.5V7H7.75ZM8.75 7H10V8H8.75V7ZM8.75 6V5H10V6H8.75ZM3.25 5V6H2V5H3.25ZM2 7H3.25V8H2V7ZM4.25 7H5.5V8H4.25V7ZM5.5 9V10H4.25V9H5.5ZM6.5 9H7.75V10H6.5V9ZM2 9H3.25V10H2V9Z' fill='black'/%3E%3C/svg%3E%0A");

                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        /* IE10+ CSS styles go here */
                        mask: none;
                        background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 1H1.5C1.225 1 1 1.225 1 1.5V10.5C1 10.775 1.225 11 1.5 11H10.5C10.775 11 11 10.775 11 10.5V1.5C11 1.225 10.775 1 10.5 1ZM5.5 5V6H4.25V5H5.5ZM6.5 5H7.75V6H6.5V5ZM7.75 7V8H6.5V7H7.75ZM8.75 7H10V8H8.75V7ZM8.75 6V5H10V6H8.75ZM3.25 5V6H2V5H3.25ZM2 7H3.25V8H2V7ZM4.25 7H5.5V8H4.25V7ZM5.5 9V10H4.25V9H5.5ZM6.5 9H7.75V10H6.5V9ZM2 9H3.25V10H2V9Z' fill='black'/%3E%3C/svg%3E%0A");
                    }
                }
            }
        }
        .k-datetimepicker {
            .k-input-button .k-icon { 
                &::before {
                    mask: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6667 1.45817C11.6667 1.29709 11.5362 1.1665 11.3751 1.1665H1.45841C1.29733 1.1665 1.16675 1.29709 1.16675 1.45817V11.3748C1.16675 11.5359 1.29733 11.6665 1.45841 11.6665H5.54143C5.27735 11.3149 5.065 10.9223 4.91569 10.4998H4.66675V9.33317H4.67632C4.66997 9.23677 4.66675 9.13951 4.66675 9.0415C4.66675 8.74185 4.69687 8.44923 4.75426 8.1665H4.66675V6.99984H5.17136C5.58271 6.22165 6.22189 5.58247 7.00008 5.17111V4.6665H8.16675V4.75402C8.44947 4.69663 8.74209 4.6665 9.04175 4.6665C9.13976 4.6665 9.23701 4.66973 9.33342 4.67607V4.6665H10.5001V4.91545C10.9225 5.06476 11.3152 5.2771 11.6667 5.54118V1.45817ZM3.50008 8.1665H2.33341V6.99984H3.50008V8.1665ZM2.33341 9.33317H3.50008V10.4998H2.33341V9.33317ZM2.33341 5.83317V4.6665H3.50008V5.83317H2.33341ZM4.66675 4.6665H5.83342V5.83317H4.66675V4.6665Z' fill='black'/%3E%3Cpath d='M9.33342 6.99984C9.4945 6.99984 9.62508 7.13042 9.62508 7.2915V8.45817H10.2084C10.3695 8.45817 10.5001 8.58875 10.5001 8.74984V9.33317C10.5001 9.49425 10.3695 9.62484 10.2084 9.62484H8.75008C8.589 9.62484 8.45842 9.49425 8.45842 9.33317V7.2915C8.45842 7.13042 8.589 6.99984 8.75008 6.99984H9.33342Z' fill='black'/%3E%3Cpath d='M5.25008 9.0415C5.25008 6.94742 6.94767 5.24984 9.04175 5.24984C11.1358 5.24984 12.8334 6.94742 12.8334 9.0415C12.8334 11.1356 11.1358 12.8332 9.04175 12.8332C6.94767 12.8332 5.25008 11.1356 5.25008 9.0415ZM9.04175 6.4165C7.592 6.4165 6.41675 7.59176 6.41675 9.0415C6.41675 10.4913 7.592 11.6665 9.04175 11.6665C10.4915 11.6665 11.6667 10.4913 11.6667 9.0415C11.6667 7.59176 10.4915 6.4165 9.04175 6.4165Z' fill='black'/%3E%3C/svg%3E%0A");

                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        /* IE10+ CSS styles go here */
                        mask: none;
                        background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6667 1.45817C11.6667 1.29709 11.5362 1.1665 11.3751 1.1665H1.45841C1.29733 1.1665 1.16675 1.29709 1.16675 1.45817V11.3748C1.16675 11.5359 1.29733 11.6665 1.45841 11.6665H5.54143C5.27735 11.3149 5.065 10.9223 4.91569 10.4998H4.66675V9.33317H4.67632C4.66997 9.23677 4.66675 9.13951 4.66675 9.0415C4.66675 8.74185 4.69687 8.44923 4.75426 8.1665H4.66675V6.99984H5.17136C5.58271 6.22165 6.22189 5.58247 7.00008 5.17111V4.6665H8.16675V4.75402C8.44947 4.69663 8.74209 4.6665 9.04175 4.6665C9.13976 4.6665 9.23701 4.66973 9.33342 4.67607V4.6665H10.5001V4.91545C10.9225 5.06476 11.3152 5.2771 11.6667 5.54118V1.45817ZM3.50008 8.1665H2.33341V6.99984H3.50008V8.1665ZM2.33341 9.33317H3.50008V10.4998H2.33341V9.33317ZM2.33341 5.83317V4.6665H3.50008V5.83317H2.33341ZM4.66675 4.6665H5.83342V5.83317H4.66675V4.6665Z' fill='black'/%3E%3Cpath d='M9.33342 6.99984C9.4945 6.99984 9.62508 7.13042 9.62508 7.2915V8.45817H10.2084C10.3695 8.45817 10.5001 8.58875 10.5001 8.74984V9.33317C10.5001 9.49425 10.3695 9.62484 10.2084 9.62484H8.75008C8.589 9.62484 8.45842 9.49425 8.45842 9.33317V7.2915C8.45842 7.13042 8.589 6.99984 8.75008 6.99984H9.33342Z' fill='black'/%3E%3Cpath d='M5.25008 9.0415C5.25008 6.94742 6.94767 5.24984 9.04175 5.24984C11.1358 5.24984 12.8334 6.94742 12.8334 9.0415C12.8334 11.1356 11.1358 12.8332 9.04175 12.8332C6.94767 12.8332 5.25008 11.1356 5.25008 9.0415ZM9.04175 6.4165C7.592 6.4165 6.41675 7.59176 6.41675 9.0415C6.41675 10.4913 7.592 11.6665 9.04175 11.6665C10.4915 11.6665 11.6667 10.4913 11.6667 9.0415C11.6667 7.59176 10.4915 6.4165 9.04175 6.4165Z' fill='black'/%3E%3C/svg%3E%0A");
                    }
                }
            }
        }
        .k-timepicker {
            .k-input-button .k-icon { 
                &::before {
                    mask: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.8334 6.99984C12.8334 10.2215 10.2217 12.8332 7.00008 12.8332C3.77842 12.8332 1.16675 10.2215 1.16675 6.99984C1.16675 3.77818 3.77842 1.1665 7.00008 1.1665C10.2217 1.1665 12.8334 3.77818 12.8334 6.99984ZM2.33341 6.99984C2.33341 9.57717 4.42275 11.6665 7.00008 11.6665C9.57741 11.6665 11.6667 9.57717 11.6667 6.99984C11.6667 4.42251 9.57741 2.33317 7.00008 2.33317C4.42275 2.33317 2.33341 4.42251 2.33341 6.99984ZM9.33342 7.2915C9.33342 7.45192 9.20217 7.58317 9.04175 7.58317H7.29175H6.70842C6.548 7.58317 6.41675 7.45192 6.41675 7.2915V6.70817V3.7915C6.41675 3.63109 6.548 3.49984 6.70842 3.49984H7.29175C7.45217 3.49984 7.58342 3.63109 7.58342 3.7915V6.4165H9.04175C9.20217 6.4165 9.33342 6.54775 9.33342 6.70817V7.2915Z' fill='black'/%3E%3C/svg%3E%0A");

                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        /* IE10+ CSS styles go here */
                        mask: none;
                        background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.8334 6.99984C12.8334 10.2215 10.2217 12.8332 7.00008 12.8332C3.77842 12.8332 1.16675 10.2215 1.16675 6.99984C1.16675 3.77818 3.77842 1.1665 7.00008 1.1665C10.2217 1.1665 12.8334 3.77818 12.8334 6.99984ZM2.33341 6.99984C2.33341 9.57717 4.42275 11.6665 7.00008 11.6665C9.57741 11.6665 11.6667 9.57717 11.6667 6.99984C11.6667 4.42251 9.57741 2.33317 7.00008 2.33317C4.42275 2.33317 2.33341 4.42251 2.33341 6.99984ZM9.33342 7.2915C9.33342 7.45192 9.20217 7.58317 9.04175 7.58317H7.29175H6.70842C6.548 7.58317 6.41675 7.45192 6.41675 7.2915V6.70817V3.7915C6.41675 3.63109 6.548 3.49984 6.70842 3.49984H7.29175C7.45217 3.49984 7.58342 3.63109 7.58342 3.7915V6.4165H9.04175C9.20217 6.4165 9.33342 6.54775 9.33342 6.70817V7.2915Z' fill='black'/%3E%3C/svg%3E%0A");
                    }
                }
            }
        }
    }

    // Grid content
    .k-grid-content,
    .k-grid-content-locked {
        border-color: inherit;
        outline: 0;

        table {
            table-layout: fixed;
        }
        //tr:last-child > td {
        //    border-bottom-width: 0;
        //}
    }
    .k-grid-content {
        width: 100%;
        min-height: 0;
        overflow: auto;
        overflow-x: auto;
        overflow-y: scroll;
        position: relative;
        flex: 1;
    }

    // Virtualization
    .k-virtual-scrollable-wrap {
        height: 100%;
        overflow-y: hidden;
        position: relative;
    }


    // Checkboxes
    .k-grid th,
    .k-grid td {
        > .k-radio,
        > .k-radio-wrap,
        > .k-checkbox,
        > .k-checkbox-wrap {
            vertical-align: top;
        }
    }


    // Edit row
    .k-grid .k-edit-cell,
    .k-grid .k-command-cell,
    .k-grid .k-grid-edit-row td {
        text-overflow: clip;
        //padding: 0px;

        &:first-child {
            padding-left: $grid-outer-column-padding-x;
        }

        &:last-child {
            padding-right: $grid-outer-column-padding-x;
        }
    }

    .k-grid .k-edit-cell,
    .k-grid .k-grid-edit-row td {
        > .k-input,
        > .k-picker {
            display: flex;
            border-radius: 0px;
            height: 40px;
            width: -webkit-fill-available !important;
            margin: $grid-cell-padding-y * -1 $grid-cell-padding-x * -1 $grid-cell-padding-y * -1 $grid-cell-padding-x * -1;
        }

        .k-input-button,
        .k-input-prefix .k-button, 
        .k-input-suffix .k-button {
            height: 40px;
            .k-icon {
                font-size: 26px;
                height: 40px;
            }
        }


        > .k-input > .k-input.k-dateinput {
            margin: 0px;
        }

        > .k-switch {
            margin: -1px 0;
        }
    }

    .k-grid .k-edit-cell,
    .k-grid .k-command-cell,
    .k-grid .k-grid-edit-row td {
        > .k-button {
            margin-top: $grid-cell-padding-y * -1;
            margin-bottom: $grid-cell-padding-y * -1;
            padding: $kendo-button-padding-y-sm $kendo-button-padding-x-sm;
            font-size: $kendo-button-font-size-sm;
            line-height: $kendo-button-line-height-sm;
            .k-icon {
                font-size: $kendo-button-font-size-sm;
            }
        }
    }

    .k-grid .k-command-cell > .k-button {
        margin-right: $grid-command-cell-button-spacing;
        &:last-child {
            margin-right: 0px;
        }
    }


    // Resize handle
    .k-grid > .k-resize-handle,
    .k-grid-header .k-resize-handle {
        height: 25px;
        cursor: col-resize;
        position: absolute;
        z-index: 2;
    }


    // Pager
    .k-grid-pager {
        padding: $grid-cell-padding-y $grid-outer-column-padding-x;
        border-width: 0;
        //border-color: $grid-border;
        font-size: inherit;
    }
    .k-grid-pager-top {
        border-width: 0 0 1px 0;
    }

    .k-grid-toolbar + .k-grid-pager-top {
        border-width: 1px 0 0;
    }

    .k-pager-input {
        .k-numerictextbox {
            margin: 0 $font-size * 0.5;
            width: 3em;
        }
    }

    .k-grid-virtual .k-grid-content {
        .k-grid-table-wrap {
            float: left;
            width: 100%;
        }

        .k-grid-table {
            position: relative;
            float: left;
            z-index: 1;
        }

        > .k-height-container {
            position: relative;
            float: left;
        }

        &::after {
            content: "";
            display: block;
            clear: both;
        }
    }

    .k-width-container {
        position: absolute;
        visibility: hidden;
    }

    .k-width-container  div {
        height: 1px;
    }

    .k-grid-virtual[dir="rtl"],
    .k-grid-virtual.k-rtl {
        .k-grid-content .k-grid-table {
            float: right;
        }
    }

    .k-grid-add-row td {
        border-bottom-style: solid;
        border-bottom-width: 1px;
    }


    // Locked columns
    .k-grid-lockedcolumns {
        white-space: nowrap;
    }

    .k-grid-content,
    .k-grid-content-locked {
        white-space: normal;
    }

    .k-grid-content-locked,
    .k-grid-footer-locked,
    .k-grid-header-locked {
        flex: 0 0 auto;
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        position: relative;
        border-style: solid;
        border-width: 0 1px 0 0;
        box-sizing: content-box;

        & + .k-grid-content.k-auto-scrollable {
            display: inline-block;
        }
    }

    .k-grid-content,
    .k-grid-footer-wrap,
    .k-grid-header-wrap {
        flex: 1 1 auto;
        display: inline-block;
        vertical-align: top;

        &.k-auto-scrollable {
            display: block;
        }
    }

    //.k-grid-header-locked > table,
    //.k-grid-header-wrap > table {
    //    margin-bottom: -1px;
    //}

    .k-grid-header .k-header.k-grid-header-sticky,
    .k-grid-header .k-filter-row .k-grid-header-sticky,
    .k-grid-content-sticky,
    .k-grid-row-sticky,
    .k-grid-footer-sticky {
        position: sticky;
        z-index: 2;

        &.k-edit-cell {
            overflow: visible;
            z-index: 3;
        }
    }

    kendo-grid {
        tr.k-grid-row-sticky {
            border: 0;
            position: static;
            z-index: auto;
        }

        .k-grid-row-sticky td {
            border-bottom-width: $grid-border-width;
            border-top-width: $grid-border-width;

            position: sticky;
            top: inherit;
            bottom: inherit;
        }

        .k-grid-row-sticky td {
            z-index: 2;
        }

        .k-grid-row-sticky td.k-grid-content-sticky,
        .k-grid-row-sticky.k-alt td.k-grid-content-sticky {
            z-index: 3;
        }

        // Locked and sticky
        .k-grid-header-locked th.k-grid-header-sticky:last-child,
        .k-grid-content-locked tr .k-grid-content-sticky:last-child {
            border-right: 0;
        }

        .k-grid-header .k-grid-column-menu,
        .k-grid-header .k-grid-header-menu {
            z-index: 1;
        }
    }

    .k-grid-content-sticky.k-grid-row-sticky {
        z-index: 3;
    }

    .k-grid .k-grid-header-sticky,
    .k-grid .k-grid-content-sticky,
    .k-grid .k-grid-footer-sticky {
        border-right-width: $grid-cell-vertical-border-width;

        &:not([style*="display: none"]) + td,
        &:not([style*="display: none"]) + th {
            border-left-width: 0;
        }
    }

    .k-grid .k-grid-row-sticky {
        border-bottom-width: $grid-border-width;
        border-top-width: $grid-border-width;
    }

    .k-grid-header-sticky.k-header.k-grid-no-left-border.k-first {
        border-left-width: 0;
    }

    .k-grid.k-grid-no-scrollbar {

        .k-grid-header-wrap,
        .k-grid-footer-wrap {
            margin: 0;
            border-width: 0;
        }

        .k-grid-header,
        .k-grid-footer {
            padding: 0;
        }

        .k-grid-content {
            overflow-y: auto;
        }
    }

    .k-grid-norecords {
        text-align: center;
    }

    div.k-grid-norecords {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .k-grid-norecords-template {
        box-sizing: border-box;
        margin: 0 auto;
        width: 20em;
        height: 4em;
        border: 1px solid;
        line-height: 4em;
    }

    /*.k-header > .k-cell-inner {
        margin: (-1 * $grid-header-padding-y) calc(-1 * calc( #{$kendo-button-calc-size} - #{$grid-cell-padding-x})) (-1 * $grid-header-padding-y) (-1 * $grid-cell-padding-x);
    }

    .k-filterable {
        > .k-cell-inner {
            margin: (-1 * $grid-header-padding-y) calc(-1 * #{$kendo-button-calc-size}) (-1 * $grid-header-padding-y) (-1 * $grid-cell-padding-x);

            .k-link {
                padding-right: 0;
            }
        }
    }*/

    .k-cell-inner {
        //display: inline-flex;
        //flex-flow: row nowrap;
        //align-items: center;
        //justify-content: inherit;
        overflow: hidden;
        width: 100%;
        //gap: $icon-spacing;

        > .k-link {
            width: auto;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: inherit;
            flex: 1;
            overflow: hidden;

            > .k-icon.k-i-sort-desc-small,
            > .k-icon.k-i-sort-asc-small {
                margin-left: 0;
                display: inline-block;
                flex-shrink: 0;
            }

        }

        .k-sort-order {
            flex-shrink: 0;
            line-height: normal;
        }

        .k-grid-filter,
        .k-header-column-menu,
        .k-hierarchy-cell .k-icon {
            text-align: center;
            display: inline-block;
            flex-shrink: 0;
        }
    }

    .k-column-title {
        min-width: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex-shrink: 1;
        overflow: hidden;
    }

    // Sizing
    @each $size, $size-props in $mri-kendo-grid-sizes {
        $_cell-padding-x: map-get( $size-props, cell-padding-x );
        $_cell-padding-y: map-get( $size-props, cell-padding-y );
        $_header-padding-x: map-get( $size-props, header-padding-x );
        $_header-padding-y: map-get( $size-props, header-padding-y );
        $_footer-padding-x: map-get( $size-props, footer-padding-x );
        $_footer-padding-y: map-get( $size-props, footer-padding-y );
        $_font-size: map-get( $size-props, font-size );
        $_filter-font-size: map-get( $size-props, filter-font-size );
        $_line-height: map-get( $size-props, line-height );
        $_filter-line-height: map-get( $size-props, filter-line-height );
        $_input-padding-x: map-get( $size-props, input-padding-x );
        $_input-padding-y: map-get( $size-props, input-padding-y );
        $_input-line-height: map-get( $size-props, input-line-height );
        $_input-icon-offset-y: map-get( $size-props, input-icon-offset-y );
        $_input-button-offset-x: map-get( $size-props, input-button-offset-x );
        $_sort-inidicator-offset-y: map-get( $size-props, sort-inidicator-offset-y );
        $_menu-icon-size: map-get( $size-props, menu-icon-size );
        $_filter-icon-size: map-get( $size-props, filter-icon-size );
        $_checkbox-size: map-get( $size-props, checkbox-size );
        $_checkbox-glyph-size: map-get( $size-props, checkbox-glyph-size );
        $_checkbox-background-size: map-get( $size-props, checkbox-background-size );
        $_checkbox-offset-y: map-get( $size-props, checkbox-offset-y );
        $_checkbox-margin-y: map-get( $size-props, checkbox-margin-y );
        $_cell-height: map-get( $size-props, cell-height );
        $_button-padding-x: map-get( $size-props, button-padding-x );
        $_button-padding-y: map-get( $size-props, button-padding-y );
        $_switch-font-size: map-get( $size-props, switch-font-size );
        $_switch-track-width: map-get( $size-props, switch-track-width );
        $_switch-track-height: map-get( $size-props, switch-track-height );
        $_switch-thumb-size: map-get( $size-props, switch-thumb-size );
        $_switch-thumb-offset: map-get( $size-props, switch-thumb-offset );
        $_switch-label-offset: map-get( $size-props, switch-label-offset );
        $_multiselect-button-offset-y: map-get( $size-props, multiselect-button-offset-y );
        $_multiselect-icon-offset-y: map-get( $size-props, multiselect-icon-offset-y );
        $_toolbar-padding-x: map-get( $size-props, toolbar-padding-x );
        $_toolbar-padding-y: map-get( $size-props, toolbar-padding-y );
        $_toolbar-button-padding-x: map-get( $size-props, toolbar-button-padding-x );
        $_toolbar-button-padding-y: map-get( $size-props, toolbar-button-padding-y );
        $_toolbar-button-font-size: map-get( $size-props, toolbar-button-font-size );
        $_toolbar-button-line-height: map-get( $size-props, toolbar-button-line-height );
        $_toolbar-button-icon-font-size: map-get( $size-props, toolbar-button-icon-font-size );
        $_filterable-icon-spacing: map-get( $size-props, filterable-icon-spacing );
        $_required-icon-spacing: map-get( $size-props, required-icon-spacing );


        .mri-k-grid-#{$size} {
            font-size: $_font-size;
            line-height: $_line-height;

            .k-grid-toolbar {
                padding: $_toolbar-padding-y $_toolbar-padding-x;

                .k-button {
                    padding: $_toolbar-button-padding-y $_toolbar-button-padding-x;
                    font-size: $_toolbar-button-font-size;
                    line-height: $_toolbar-button-line-height;

                    > .k-button-icon {
                        font-size: $_toolbar-button-icon-font-size;
                    }
                }
                .k-input,
                .k-picker {
                    font-size: $_font-size;

                    .k-input-inner {
                        padding: $_input-padding-y $_input-padding-x;
                    }
                    .k-input-icon,
                    .k-input-validation-icon,
                    .k-input-loading-icon {
                        font-size: $_font-size;
                        width: calc( #{$_font-size} * 2 );
                        height: calc( #{$_font-size} * 2 );
                    }
                }
            }
    
            th,
            td {
                padding: $_cell-padding-y $_cell-padding-x;

                .k-checkbox {
                    width: $_checkbox-size;
                    height: $_checkbox-size;
                    background-size: $_checkbox-background-size;
                    margin-top: $_checkbox-offset-y;
        
                    &::before {
                        font-size: $_checkbox-glyph-size;
                    }
                }
            }

            .k-edit-cell,
            .k-grid-edit-row td {
                .k-input,
                .k-picker {
                    display: flex;
                    font-size: $_font-size;
                    line-height: $_line-height;
                    height: $_cell-height !important;
                    margin: $_cell-padding-y * -1 $_cell-padding-x * -1 $_cell-padding-y * -1 $_cell-padding-x * -1;

                    .k-input-inner {
                        padding: $_input-padding-y $_input-padding-x;
                    }
                }

                &.mri-k-show-validation {
                    &.k-required, 
                    &.k-warning,
                    &.k-invalid,
                    &.ng-invalid.ng-touched,
                    &.ng-invalid.ng-dirty,
                    &.k-success, &.k-valid:not(.ng-pristine),
                    &.ng-valid.ng-touched:not(.ng-pristine),
                    &.ng-valid.ng-dirty:not(.ng-pristine) {
                        .k-input,
                        .k-picker {
                                &.k-textbox:not(span) {
                                    padding-right: calc( (#{$_input-padding-x} * 2)  + $_font-size );
                                    background-size: $_font-size $_font-size;
                                    background-position: calc(100% - #{$_input-padding-x}) center;
                                }

                                &span.k-textbox .k-input-inner {
                                    padding-right: calc( (#{$_input-padding-x} * 2)  + $_font-size );
                                    background-size: $_font-size $_font-size;
                                    background-position: calc(100% - #{$_input-padding-x}) center;
                                }

                                &:not(.k-dropdowntree.k-input):not(.k-multiselect):not(.k-textarea) {
                                    .k-input-inner {
                                        padding-right: calc( (#{$_input-padding-x} * 2)  + $_font-size );
                                        background-size: $_font-size $_font-size;
                                        background-position: calc(100% - #{$_input-padding-x}) center;
                                    }
                                }
                        }

                        &.align-right .k-input.k-numerictextbox .k-input-inner {
                            padding-left: calc( (#{$_input-padding-x} * 2)  + $_font-size ) !important;
                            padding-right: $_input-padding-x !important;
                            background-position: $_input-padding-x center !important;
                        }
                    }
                }

                .k-input,
                .k-picker {
                    &.k-required, 
                    &.k-warning,
                    &.k-invalid,
                    &.ng-invalid.ng-touched,
                    &.ng-invalid.ng-dirty,
                    &.k-success, &.k-valid:not(.ng-pristine),
                    &.ng-valid.ng-touched:not(.ng-pristine),
                    &.ng-valid.ng-dirty:not(.ng-pristine) {
                        &.mri-k-show-validation {
                            &.k-textbox:not(span) {
                                padding-right: calc( (#{$_input-padding-x} * 2)  + $_font-size );
                                background-size: $_font-size $_font-size;
                                background-position: calc(100% - #{$_input-padding-x}) center;
                            }

                            &span.k-textbox .k-input-inner {
                                padding-right: calc( (#{$_input-padding-x} * 2)  + $_font-size );
                                background-size: $_font-size $_font-size;
                                background-position: calc(100% - #{$_input-padding-x}) center;
                            }

                            &:not(.k-dropdowntree.k-input):not(.k-multiselect):not(.k-textarea) {
                                .k-input-inner {
                                    padding-right: calc( (#{$_input-padding-x} * 2)  + $_font-size );
                                    background-size: $_font-size $_font-size ;
                                    background-position: calc(100% - #{$_input-padding-x}) center;
                                }
                            }
                        }
                    }
                }

                &.align-right .k-input.k-numerictextbox.mri-k-show-validation {
                    &.k-required, 
                    &.k-warning,
                    &.k-invalid,
                    &.ng-invalid.ng-touched,
                    &.ng-invalid.ng-dirty,
                    &.k-success, &.k-valid:not(.ng-pristine),
                    &.ng-valid.ng-touched:not(.ng-pristine),
                    &.ng-valid.ng-dirty:not(.ng-pristine) {
                        .k-input-inner {
                            padding-left: calc( (#{$_input-padding-x} * 2)  + $_font-size ) !important;
                            padding-right: $_input-padding-x !important;
                            background-position: $_input-padding-x center !important;
                        }
                    }
                }

                input.k-input,
                textarea.k-textarea {
                    padding: $_input-padding-y $_input-padding-x;
                }
   
                .k-datepicker {
                    .k-input-button .k-icon::before {
                        width: $_font-size;
                        height: $_font-size;
                        mask: url("data:image/svg+xml,%3Csvg width='#{$_font-size}' height='#{$_font-size}' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.2501 1.1665H1.75008C1.42925 1.1665 1.16675 1.429 1.16675 1.74984V12.2498C1.16675 12.5707 1.42925 12.8332 1.75008 12.8332H12.2501C12.5709 12.8332 12.8334 12.5707 12.8334 12.2498V1.74984C12.8334 1.429 12.5709 1.1665 12.2501 1.1665ZM6.41675 5.83317V6.99984H4.95841V5.83317H6.41675ZM7.58342 5.83317H9.04175V6.99984H7.58342V5.83317ZM9.04175 8.1665V9.33317H7.58342V8.1665H9.04175ZM10.2084 8.1665H11.6667V9.33317H10.2084V8.1665ZM10.2084 6.99984V5.83317H11.6667V6.99984H10.2084ZM3.79175 5.83317V6.99984H2.33341V5.83317H3.79175ZM2.33341 8.1665H3.79175V9.33317H2.33341V8.1665ZM4.95841 8.1665H6.41675V9.33317H4.95841V8.1665ZM6.41675 10.4998V11.6665H4.95841V10.4998H6.41675ZM7.58342 10.4998H9.04175V11.6665H7.58342V10.4998ZM2.33341 10.4998H3.79175V11.6665H2.33341V10.4998Z' fill='black'/%3E%3C/svg%3E%0A");
                    }
                }
    
                .k-datetimepicker {
                    .k-input-button .k-icon::before {
                        width: $_font-size;
                        height: $_font-size;
                        mask: url("data:image/svg+xml,%3Csvg width='#{$_font-size}' height='#{$_font-size}' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6667 1.45817C11.6667 1.29709 11.5362 1.1665 11.3751 1.1665H1.45841C1.29733 1.1665 1.16675 1.29709 1.16675 1.45817V11.3748C1.16675 11.5359 1.29733 11.6665 1.45841 11.6665H5.54143C5.27735 11.3149 5.065 10.9223 4.91569 10.4998H4.66675V9.33317H4.67632C4.66997 9.23677 4.66675 9.13951 4.66675 9.0415C4.66675 8.74185 4.69687 8.44923 4.75426 8.1665H4.66675V6.99984H5.17136C5.58271 6.22165 6.22189 5.58247 7.00008 5.17111V4.6665H8.16675V4.75402C8.44947 4.69663 8.74209 4.6665 9.04175 4.6665C9.13976 4.6665 9.23701 4.66973 9.33342 4.67607V4.6665H10.5001V4.91545C10.9225 5.06476 11.3152 5.2771 11.6667 5.54118V1.45817ZM3.50008 8.1665H2.33341V6.99984H3.50008V8.1665ZM2.33341 9.33317H3.50008V10.4998H2.33341V9.33317ZM2.33341 5.83317V4.6665H3.50008V5.83317H2.33341ZM4.66675 4.6665H5.83342V5.83317H4.66675V4.6665Z' fill='black'/%3E%3Cpath d='M9.33342 6.99984C9.4945 6.99984 9.62508 7.13042 9.62508 7.2915V8.45817H10.2084C10.3695 8.45817 10.5001 8.58875 10.5001 8.74984V9.33317C10.5001 9.49425 10.3695 9.62484 10.2084 9.62484H8.75008C8.589 9.62484 8.45842 9.49425 8.45842 9.33317V7.2915C8.45842 7.13042 8.589 6.99984 8.75008 6.99984H9.33342Z' fill='black'/%3E%3Cpath d='M5.25008 9.0415C5.25008 6.94742 6.94767 5.24984 9.04175 5.24984C11.1358 5.24984 12.8334 6.94742 12.8334 9.0415C12.8334 11.1356 11.1358 12.8332 9.04175 12.8332C6.94767 12.8332 5.25008 11.1356 5.25008 9.0415ZM9.04175 6.4165C7.592 6.4165 6.41675 7.59176 6.41675 9.0415C6.41675 10.4913 7.592 11.6665 9.04175 11.6665C10.4915 11.6665 11.6667 10.4913 11.6667 9.0415C11.6667 7.59176 10.4915 6.4165 9.04175 6.4165Z' fill='black'/%3E%3C/svg%3E%0A");
                    }
                }
    
                .k-timepicker {
                    .k-input-button .k-icon::before {
                        width: $_font-size;
                        height: $_font-size;
                        mask: url("data:image/svg+xml,%3Csvg width='#{$_font-size}' height='#{$_font-size}' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.8334 6.99984C12.8334 10.2215 10.2217 12.8332 7.00008 12.8332C3.77842 12.8332 1.16675 10.2215 1.16675 6.99984C1.16675 3.77818 3.77842 1.1665 7.00008 1.1665C10.2217 1.1665 12.8334 3.77818 12.8334 6.99984ZM2.33341 6.99984C2.33341 9.57717 4.42275 11.6665 7.00008 11.6665C9.57741 11.6665 11.6667 9.57717 11.6667 6.99984C11.6667 4.42251 9.57741 2.33317 7.00008 2.33317C4.42275 2.33317 2.33341 4.42251 2.33341 6.99984ZM9.33342 7.2915C9.33342 7.45192 9.20217 7.58317 9.04175 7.58317H7.29175H6.70842C6.548 7.58317 6.41675 7.45192 6.41675 7.2915V6.70817V3.7915C6.41675 3.63109 6.548 3.49984 6.70842 3.49984H7.29175C7.45217 3.49984 7.58342 3.63109 7.58342 3.7915V6.4165H9.04175C9.20217 6.4165 9.33342 6.54775 9.33342 6.70817V7.2915Z' fill='black'/%3E%3C/svg%3E%0A");
                    }
                }

                .k-input-values {
                    //min-height: $_input-values-size;
                    padding: $_input-padding-y 0px $_input-padding-y $_input-padding-x;
                    gap: ($_input-padding-y * 0.5);
                    .k-input-inner {
                        padding:  0px;
                        width: 1em;
                        height: ($_font-size + 2px);
                    }
                }

                .k-spinner-increase,
                .k-spinner-decrease {
                    padding: $_input-padding-y $_input-padding-x;
                    .k-icon {
                        font-size: $_font-size;
                    }
                }
    
                .k-input-button,
                .k-input-prefix .k-button, 
                .k-input-suffix .k-button {
                    height: $_cell-height;
                    .k-icon {
                        font-size: $_cell-height * .65;
                        height: $_cell-height;
                    }
                }

                &.k-picker, 
                &.k-combobox {
                    .k-icon.k-i-arrow-s {
                        font-size: $_cell-height * .65;
                    }
                }
    
                &.k-numerictextbox.align-right .k-input-inner {
                    padding-left: calc( (#{$_input-padding-x} * 2)  + 8px ) !important;
                    padding-right: $_input-padding-x !important;
                    background-position: $_input-padding-x center !important;
                }

                .k-clear-value {
                    height: $_font-size + 4px;
                    line-height: $_font-size + 4px;
                    width: $_font-size + 4px;
                    margin: -2px;

                    .k-icon {
                        font-size: $_font-size + 2px;
                    }
                }

                &k-dropdowntree.k-input, .k-multiselect {
                    height: auto !important;
                    
                    .k-input-values {
                        padding: $_input-padding-y - 1px 0px $_input-padding-y - 1px $_input-padding-x !important;
                        line-height: $_input-line-height !important;
                        font-size: $_font-size !important;
                        min-height: $_cell-height - 2px;
                        overflow-y: auto;

                        .k-chip {
                            font-size: $_font-size;
                            line-height: $_input-line-height;
                            .k-chip-action .k-icon {
                                width: $_font-size;
                                height: $_font-size;
                                font-size: $_font-size;
                            }
                        }

                        .k-input-inner {
                            padding: 0px !important;
                            height: $_input-line-height  * $_font-size !important;
                        }
                    }

                    .k-clear-value {
                        right: calc( #{$_input-padding-x} + #{$kendo-input-button-size} - #{$_input-button-offset-x});
                        top: $_input-icon-offset-y;
                    }
    
                    &.mri-k-show-validation {
                        background-size: $_font-size $_font-size !important;
                        background-position-x: calc(100% - (#{$_input-padding-x} + #{$kendo-input-button-size} - #{$_input-button-offset-x})) !important;
                        background-position-y: $_input-icon-offset-y !important;
                    }
                                
                    &::after {
                        content: '';
                        mask: url("data:image/svg+xml,%3Csvg width='#{$_font-size}' height='#{$_font-size}' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.75008 1.1665H2.91675C3.23758 1.1665 3.50008 1.429 3.50008 1.74984V2.9165C3.50008 3.23734 3.23758 3.49984 2.91675 3.49984H1.75008C1.42925 3.49984 1.16675 3.23734 1.16675 2.9165V1.74984C1.16675 1.429 1.42925 1.1665 1.75008 1.1665ZM11.0834 3.49984H5.25008C4.92925 3.49984 4.66675 3.23734 4.66675 2.9165V1.74984C4.66675 1.429 4.92925 1.1665 5.25008 1.1665H11.0834C11.4042 1.1665 11.6667 1.429 11.6667 1.74984V2.9165C11.6667 3.23734 11.4042 3.49984 11.0834 3.49984ZM12.2501 5.83317H5.25008C4.92925 5.83317 4.66675 6.09567 4.66675 6.4165V7.58317C4.66675 7.904 4.92925 8.1665 5.25008 8.1665H12.2501C12.5709 8.1665 12.8334 7.904 12.8334 7.58317V6.4165C12.8334 6.09567 12.5709 5.83317 12.2501 5.83317ZM1.75008 5.83317H2.91675C3.23758 5.83317 3.50008 6.09567 3.50008 6.4165V7.58317C3.50008 7.904 3.23758 8.1665 2.91675 8.1665H1.75008C1.42925 8.1665 1.16675 7.904 1.16675 7.58317V6.4165C1.16675 6.09567 1.42925 5.83317 1.75008 5.83317ZM9.91675 10.4998H5.25008C4.92925 10.4998 4.66675 10.7623 4.66675 11.0832V12.2498C4.66675 12.5707 4.92925 12.8332 5.25008 12.8332H9.91675C10.2376 12.8332 10.5001 12.5707 10.5001 12.2498V11.0832C10.5001 10.7623 10.2376 10.4998 9.91675 10.4998ZM1.75008 10.4998H2.91675C3.23758 10.4998 3.50008 10.7623 3.50008 11.0832V12.2498C3.50008 12.5707 3.23758 12.8332 2.91675 12.8332H1.75008C1.42925 12.8332 1.16675 12.5707 1.16675 12.2498V11.0832C1.16675 10.7623 1.42925 10.4998 1.75008 10.4998Z' fill='black'/%3E%3C/svg%3E%0A");
                        -webkit-mask-repeat: no-repeat;
                        mask-repeat: no-repeat;
                        -webkit-mask-position-x: 28px;
                        -webkit-mask-position-y: $_multiselect-icon-offset-y;
                        height: $_cell-height - 2px;
                        width: $_font-size + 32px;
        
                        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                            /* IE10+ CSS styles go here */
                            mask: none;
                            background: url("data:image/svg+xml,%3Csvg width='#{$_font-size}' height='#{$_font-size}' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.75008 1.1665H2.91675C3.23758 1.1665 3.50008 1.429 3.50008 1.74984V2.9165C3.50008 3.23734 3.23758 3.49984 2.91675 3.49984H1.75008C1.42925 3.49984 1.16675 3.23734 1.16675 2.9165V1.74984C1.16675 1.429 1.42925 1.1665 1.75008 1.1665ZM11.0834 3.49984H5.25008C4.92925 3.49984 4.66675 3.23734 4.66675 2.9165V1.74984C4.66675 1.429 4.92925 1.1665 5.25008 1.1665H11.0834C11.4042 1.1665 11.6667 1.429 11.6667 1.74984V2.9165C11.6667 3.23734 11.4042 3.49984 11.0834 3.49984ZM12.2501 5.83317H5.25008C4.92925 5.83317 4.66675 6.09567 4.66675 6.4165V7.58317C4.66675 7.904 4.92925 8.1665 5.25008 8.1665H12.2501C12.5709 8.1665 12.8334 7.904 12.8334 7.58317V6.4165C12.8334 6.09567 12.5709 5.83317 12.2501 5.83317ZM1.75008 5.83317H2.91675C3.23758 5.83317 3.50008 6.09567 3.50008 6.4165V7.58317C3.50008 7.904 3.23758 8.1665 2.91675 8.1665H1.75008C1.42925 8.1665 1.16675 7.904 1.16675 7.58317V6.4165C1.16675 6.09567 1.42925 5.83317 1.75008 5.83317ZM9.91675 10.4998H5.25008C4.92925 10.4998 4.66675 10.7623 4.66675 11.0832V12.2498C4.66675 12.5707 4.92925 12.8332 5.25008 12.8332H9.91675C10.2376 12.8332 10.5001 12.5707 10.5001 12.2498V11.0832C10.5001 10.7623 10.2376 10.4998 9.91675 10.4998ZM1.75008 10.4998H2.91675C3.23758 10.4998 3.50008 10.7623 3.50008 11.0832V12.2498C3.50008 12.5707 3.23758 12.8332 2.91675 12.8332H1.75008C1.42925 12.8332 1.16675 12.5707 1.16675 12.2498V11.0832C1.16675 10.7623 1.42925 10.4998 1.75008 10.4998Z' fill='black'/%3E%3C/svg%3E%0A");
                        }
                    }
                }

                .k-autocomplete {
                    .k-clear-value {
                        right: $_input-padding-x;
                        top: $_input-icon-offset-y;
                    }
                }

                .k-combobox .k-clear-value {
                    right: calc(#{$_input-padding-x} + 28px);
                    top: $_input-icon-offset-y;
                }

                .k-switch {
                    width: $_switch-track-width;
                    height: $_switch-track-height;
                    font-size: $_switch-font-size;
        
                    .k-switch-track {
                        width: $_switch-track-width;
                        height: $_switch-track-height;
                    }
        
                    .k-switch-label-on { left: $_switch-label-offset; }
                    .k-switch-label-off { right: $_switch-label-offset; }
        
                    .k-switch-thumb {
                        width: $_switch-thumb-size;
                        height: $_switch-thumb-size;
                    }
        
                    &.k-switch-on .k-switch-thumb-wrap {
                        left: calc( 100% - #{$_switch-thumb-size * 0.5} - #{$_switch-thumb-offset} );
                    }
                    &.k-switch-off .k-switch-thumb-wrap {
                        left: calc( #{$_switch-thumb-size * 0.5} + #{$_switch-thumb-offset} + 1px );
                    }
        
                    &:hover {
                        &.k-switch-on .k-switch-thumb-wrap {
                            left: calc( 100% - #{$_switch-thumb-size * 0.5} - #{$_switch-thumb-offset} - #{$_switch-thumb-offset} );
                        }
                        &.k-switch-off .k-switch-thumb-wrap {
                            left: calc( #{$_switch-thumb-size * 0.5} + #{$_switch-thumb-offset} + 1px + #{$_switch-thumb-offset} );
                        }
                    }
                }
            }

            .k-edit-cell,
            .k-command-cell,
            .k-grid-edit-row td {
                > .k-button {
                    margin-top: $_cell-padding-y * -1;
                    margin-bottom: $_cell-padding-y * -1;
                    padding: $_button-padding-y - 1px $_button-padding-x;
                    font-size: $_font-size;
                    line-height: 1;
                    .k-icon {
                        font-size: $_font-size;
                    }
                }
            }

            .k-widget.k-tooltip.k-tooltip-validation {
                padding: $padding-y $padding-x;
            }
    
            .k-tooltip.k-tooltip-error {
                margin-top: calc( #{$_cell-padding-y} + #{$grid-cell-horizontal-border-width} );
                margin-left: calc( (#{$_cell-padding-x} - #{$grid-cell-horizontal-border-width}) * -1 );
            }
    

            .k-header.k-sorted {
                .k-link {
                    grid-auto-columns: auto $_font-size max-content;
    
                    .k-icon.k-i-sort-desc-small,
                    .k-icon.k-i-sort-asc-small {
                        width: $_font-size;
                        height: $_font-size;
                        &::before {
                            margin-top: $_sort-inidicator-offset-y;
                        }
                    }
                    .k-i-sort-asc-small::before { content: url("data:image/svg+xml,%3Csvg width='#{$_font-size}' height='16' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.75008 3.49984H6.41675C6.73758 3.49984 7.00008 3.23734 7.00008 2.9165V1.74984C7.00008 1.429 6.73758 1.1665 6.41675 1.1665H1.75008C1.42925 1.1665 1.16675 1.429 1.16675 1.74984V2.9165C1.16675 3.23734 1.42925 3.49984 1.75008 3.49984ZM1.75008 8.1665H8.75008C9.07092 8.1665 9.33342 7.904 9.33342 7.58317V6.4165C9.33342 6.09567 9.07092 5.83317 8.75008 5.83317H1.75008C1.42925 5.83317 1.16675 6.09567 1.16675 6.4165V7.58317C1.16675 7.904 1.42925 8.1665 1.75008 8.1665ZM1.75008 10.4998H12.2501C12.5709 10.4998 12.8334 10.7623 12.8334 11.0832V12.2498C12.8334 12.5707 12.5709 12.8332 12.2501 12.8332H1.75008C1.42925 12.8332 1.16675 12.5707 1.16675 12.2498V11.0832C1.16675 10.7623 1.42925 10.4998 1.75008 10.4998Z' fill='%23162029'/%3E%3C/svg%3E%0A"); }
                    .k-i-sort-desc-small::before { content: url("data:image/svg+xml,%3Csvg width='#{$_font-size}' height='16' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.75008 1.1665H12.2501C12.5709 1.1665 12.8334 1.429 12.8334 1.74984V2.9165C12.8334 3.23734 12.5709 3.49984 12.2501 3.49984H1.75008C1.42925 3.49984 1.16675 3.23734 1.16675 2.9165V1.74984C1.16675 1.429 1.42925 1.1665 1.75008 1.1665ZM8.75008 5.83317H1.75008C1.42925 5.83317 1.16675 6.09567 1.16675 6.4165V7.58317C1.16675 7.904 1.42925 8.1665 1.75008 8.1665H8.75008C9.07092 8.1665 9.33342 7.904 9.33342 7.58317V6.4165C9.33342 6.09567 9.07092 5.83317 8.75008 5.83317ZM6.41675 10.4998H1.75008C1.42925 10.4998 1.16675 10.7623 1.16675 11.0832V12.2498C1.16675 12.5707 1.42925 12.8332 1.75008 12.8332H6.41675C6.73758 12.8332 7.00008 12.5707 7.00008 12.2498V11.0832C7.00008 10.7623 6.73758 10.4998 6.41675 10.4998Z' fill='%23162029'/%3E%3C/svg%3E%0A"); }

                    .k-sort-order {
                        height: $_line-height;
                    }
                }
            }

            .k-grid-header {
                .k-with-icon, .k-filterable {
                    padding-right: $_filterable-icon-spacing;
        
                    &:last-child {
                        padding-right: calc( #{ $_filterable-icon-spacing} + #{ $grid-outer-column-padding-x } );
                    }
        
                    .k-ie &,
                    .k-edge & {
                        padding-right: calc( #{ button-size() } + #{ $grid-header-menu-icon-spacing });
                    }
        
                    &.align-right {
                        padding-right: $header-padding-x;
                        padding-left: $_filterable-icon-spacing;
        
                        &:first-child {
                            padding-left: calc( #{ $_filterable-icon-spacing} + #{ $grid-outer-column-padding-x } );
                        }
                    }
                }

                .k-header.k-required.k-filterable, 
                .k-header.k-required.k-with-icon  {
                    padding-right: calc( #{ $_filterable-icon-spacing } + #{ $grid-required-icon-spacing } );
                    background-position: calc(100% - #{ $_filterable-icon-spacing }) center;

                    &.align-right {
                        padding-right: $grid-header-padding-x;
                        padding-left: calc( #{ $_filterable-icon-spacing } + #{ $grid-required-icon-spacing } );
                        background-position: $_filterable-icon-spacing center;
                    }
        
                    .k-ie &,
                    .k-edge & {
                        padding-right: calc( #{ button-size() } + #{ $grid-header-menu-icon-spacing} + #{$grid-required-icon-spacing} );
                    }
                }
            }

            .k-grid-filter,
            .k-header-column-menu,
            .k-grid-header-menu {
                padding: $_button-padding-y;
                width: $_line-height;
                height: $_line-height;
                line-height: $_line-height;
                right: $_cell-padding-x;
                bottom: $_cell-padding-y;

                .k-icon {
                    font-size: $_filter-icon-size;
                }
            }

            .k-header .k-i-more-vertical {
                &::before { 
                    content: url("data:image/svg+xml,%3Csvg width='#{$_menu-icon-size}' height='#{$_menu-icon-size}' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.75 8.75C2.7165 8.75 3.5 7.9665 3.5 7C3.5 6.0335 2.7165 5.25 1.75 5.25C0.783502 5.25 0 6.0335 0 7C0 7.9665 0.783502 8.75 1.75 8.75ZM7 8.75C7.9665 8.75 8.75 7.9665 8.75 7C8.75 6.0335 7.9665 5.25 7 5.25C6.0335 5.25 5.25 6.0335 5.25 7C5.25 7.9665 6.0335 8.75 7 8.75ZM14 7C14 7.9665 13.2165 8.75 12.25 8.75C11.2835 8.75 10.5 7.9665 10.5 7C10.5 6.0335 11.2835 5.25 12.25 5.25C13.2165 5.25 14 6.0335 14 7Z' fill='%23162029'/%3E%3C/svg%3E%0A");
                }
            }

            .k-filter-row {
                line-height: $_line-height;
        
                td,
                th {
                    padding: $_cell-padding-y * 0.5 $grid-filter-cell-padding-x;
                    &:first-child {
                        padding-left: $grid-outer-column-padding-x;
                    }
        
                    &:last-child {
                        padding-right: $grid-outer-column-padding-x;
                    }
                }
            }

            .k-header.k-filterable {
                .k-grid-filter-menu {
                    height: $_line-height;
                    width: $_line-height;
                    right: $_cell-padding-x;
                    bottom: $_cell-padding-y;

                    .k-icon {
                        height: $_line-height;
                        width: $_line-height;
                        line-height: $_line-height;
                        font-size: $_filter-icon-size;
                    }
                }
                &:last-child {
                    .k-grid-filter-menu {
                        right: $grid-outer-column-padding-x;
                    }
                }
            }
        
            .k-filtercell {
                // clear filter button
                .k-button.k-icon-button,
                .k-button.k-button-icon {
                    height: $_line-height;
                    width: $_line-height;
    
                    .k-icon {
                        height: $_line-height;
                        width: $_line-height;
                        line-height: $_line-height;
                        font-size: $_filter-icon-size;
                    }
                }
        
                .k-dropdownlist.k-dropdown-operator {
                    height: $_line-height !important;
                    width: $_line-height;
    
                    .k-button.k-icon-button,
                    .k-button.k-button-icon {
                        height: $_line-height;
                        width: $_line-height;

                        .k-icon {
                            height: $_line-height;
                            width: $_line-height;
                            line-height: $_line-height;
                            font-size: $_filter-icon-size;
                        }
                    }
                }

                .k-textbox {
                    padding: 2px 4px !important;
                    line-height: $_filter-line-height !important;
                    font-size: $_filter-font-size !important;
                }
        
                .k-input, .k-picker:not(.k-dropdown-operator) {
                    height: $_filter-line-height + 6px !important;
        
                    .k-input-inner {
                        padding: 2px 4px !important;
                        line-height: $_filter-line-height !important;
                        font-size: $_filter-font-size !important;
                        height: $_filter-line-height + 4px !important;
                    }
        
                    .k-input-spinner {
                        width: 22px!important;
        
                        .k-spinner-increase,
                        .k-spinner-decrease {
                            padding: 0px;
                        }
                        .k-spinner-increase .k-icon {
                            bottom: if( $use-input-spinner-icon-offset, calc( -1 * 1px), auto );
                        }
                        .k-spinner-decrease .k-icon {
                            top: if( $use-input-spinner-icon-offset, calc( -1 * 1px), auto );
                        }
                    }
        
        
                    .k-input-button,
                    .k-input-prefix .k-button, 
                    .k-input-suffix .k-button {
                        height: $_filter-line-height + 4px !important;
                        .k-icon {
                            font-size: $_filter-font-size;
                            width: $_filter-icon-size;
                            height: $_filter-icon-size;
                            &::before {
                                width: $_filter-icon-size !important;
                                height: $_filter-icon-size !important;                        
                            }
                        }
                    }

                    &.k-picker .k-input-button,
                    &.k-combobox .k-input-button {
                        .k-icon {
                            font-size: $_filter-line-height + 4px;
                            &::before {
                                width: $_filter-line-height + 4px !important;
                                height: $_filter-line-height + 4px !important;                        
                            }
                        }
                    }
        
                    .k-clear-value {
                        height: 16px;
                        line-height: 16px;
                        width: 16px;
        
                        .k-icon {
                            font-size: 14px;
                        }
                    }
                }
        
        
                .k-combobox .k-clear-value {
                    right: 24px;
                }
            
                .k-multiselect {
                    height: auto !important;
                    
                    .k-input-values {
                        padding: 2px $_filter-font-size + 32px 2px 4px !important;
                        line-height: $_filter-line-height !important;
                        font-size: $_filter-font-size !important;
                        min-height: $_filter-line-height + 4px;

                        .k-chip {
                            font-size: $_filter-font-size;
                            line-height: $_filter-font-size;
                            .k-chip-action .k-icon {
                                width: $_filter-font-size;
                                height: $_filter-font-size;
                                font-size: $_filter-font-size;
                            }
                        }

                        .k-input-inner {
                            padding: 0px !important;
                            height: $_filter-line-height !important;
                        }
                    }

                    .k-clear-value {
                        right: $_filter-font-size + 12px;
                        top: 2px;
                    }
        
                    &::after {
                        content: '';
                        mask: url("data:image/svg+xml,%3Csvg width='#{$_filter-font-size}' height='#{$_filter-font-size}' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.75008 1.1665H2.91675C3.23758 1.1665 3.50008 1.429 3.50008 1.74984V2.9165C3.50008 3.23734 3.23758 3.49984 2.91675 3.49984H1.75008C1.42925 3.49984 1.16675 3.23734 1.16675 2.9165V1.74984C1.16675 1.429 1.42925 1.1665 1.75008 1.1665ZM11.0834 3.49984H5.25008C4.92925 3.49984 4.66675 3.23734 4.66675 2.9165V1.74984C4.66675 1.429 4.92925 1.1665 5.25008 1.1665H11.0834C11.4042 1.1665 11.6667 1.429 11.6667 1.74984V2.9165C11.6667 3.23734 11.4042 3.49984 11.0834 3.49984ZM12.2501 5.83317H5.25008C4.92925 5.83317 4.66675 6.09567 4.66675 6.4165V7.58317C4.66675 7.904 4.92925 8.1665 5.25008 8.1665H12.2501C12.5709 8.1665 12.8334 7.904 12.8334 7.58317V6.4165C12.8334 6.09567 12.5709 5.83317 12.2501 5.83317ZM1.75008 5.83317H2.91675C3.23758 5.83317 3.50008 6.09567 3.50008 6.4165V7.58317C3.50008 7.904 3.23758 8.1665 2.91675 8.1665H1.75008C1.42925 8.1665 1.16675 7.904 1.16675 7.58317V6.4165C1.16675 6.09567 1.42925 5.83317 1.75008 5.83317ZM9.91675 10.4998H5.25008C4.92925 10.4998 4.66675 10.7623 4.66675 11.0832V12.2498C4.66675 12.5707 4.92925 12.8332 5.25008 12.8332H9.91675C10.2376 12.8332 10.5001 12.5707 10.5001 12.2498V11.0832C10.5001 10.7623 10.2376 10.4998 9.91675 10.4998ZM1.75008 10.4998H2.91675C3.23758 10.4998 3.50008 10.7623 3.50008 11.0832V12.2498C3.50008 12.5707 3.23758 12.8332 2.91675 12.8332H1.75008C1.42925 12.8332 1.16675 12.5707 1.16675 12.2498V11.0832C1.16675 10.7623 1.42925 10.4998 1.75008 10.4998Z' fill='black'/%3E%3C/svg%3E%0A");
                        -webkit-mask-repeat: no-repeat;
                        mask-repeat: no-repeat;
                        -webkit-mask-position-x: 28px;
                        -webkit-mask-position-y: 4px;
                        height: $_filter-line-height + 4px;
                        width: $_filter-font-size + 32px;
        
                        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                            /* IE10+ CSS styles go here */
                            mask: none;
                            background: url("data:image/svg+xml,%3Csvg width='#{$_filter-font-size}' height='#{$_filter-font-size}' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.75008 1.1665H2.91675C3.23758 1.1665 3.50008 1.429 3.50008 1.74984V2.9165C3.50008 3.23734 3.23758 3.49984 2.91675 3.49984H1.75008C1.42925 3.49984 1.16675 3.23734 1.16675 2.9165V1.74984C1.16675 1.429 1.42925 1.1665 1.75008 1.1665ZM11.0834 3.49984H5.25008C4.92925 3.49984 4.66675 3.23734 4.66675 2.9165V1.74984C4.66675 1.429 4.92925 1.1665 5.25008 1.1665H11.0834C11.4042 1.1665 11.6667 1.429 11.6667 1.74984V2.9165C11.6667 3.23734 11.4042 3.49984 11.0834 3.49984ZM12.2501 5.83317H5.25008C4.92925 5.83317 4.66675 6.09567 4.66675 6.4165V7.58317C4.66675 7.904 4.92925 8.1665 5.25008 8.1665H12.2501C12.5709 8.1665 12.8334 7.904 12.8334 7.58317V6.4165C12.8334 6.09567 12.5709 5.83317 12.2501 5.83317ZM1.75008 5.83317H2.91675C3.23758 5.83317 3.50008 6.09567 3.50008 6.4165V7.58317C3.50008 7.904 3.23758 8.1665 2.91675 8.1665H1.75008C1.42925 8.1665 1.16675 7.904 1.16675 7.58317V6.4165C1.16675 6.09567 1.42925 5.83317 1.75008 5.83317ZM9.91675 10.4998H5.25008C4.92925 10.4998 4.66675 10.7623 4.66675 11.0832V12.2498C4.66675 12.5707 4.92925 12.8332 5.25008 12.8332H9.91675C10.2376 12.8332 10.5001 12.5707 10.5001 12.2498V11.0832C10.5001 10.7623 10.2376 10.4998 9.91675 10.4998ZM1.75008 10.4998H2.91675C3.23758 10.4998 3.50008 10.7623 3.50008 11.0832V12.2498C3.50008 12.5707 3.23758 12.8332 2.91675 12.8332H1.75008C1.42925 12.8332 1.16675 12.5707 1.16675 12.2498V11.0832C1.16675 10.7623 1.42925 10.4998 1.75008 10.4998Z' fill='black'/%3E%3C/svg%3E%0A");
                        }
                    }
                }
        
                .k-datepicker {
                    .k-input-button .k-icon { 
                        &::before {
                            mask: url("data:image/svg+xml,%3Csvg width='#{$_filter-font-size}' height='#{$_filter-font-size}' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 1H1.5C1.225 1 1 1.225 1 1.5V10.5C1 10.775 1.225 11 1.5 11H10.5C10.775 11 11 10.775 11 10.5V1.5C11 1.225 10.775 1 10.5 1ZM5.5 5V6H4.25V5H5.5ZM6.5 5H7.75V6H6.5V5ZM7.75 7V8H6.5V7H7.75ZM8.75 7H10V8H8.75V7ZM8.75 6V5H10V6H8.75ZM3.25 5V6H2V5H3.25ZM2 7H3.25V8H2V7ZM4.25 7H5.5V8H4.25V7ZM5.5 9V10H4.25V9H5.5ZM6.5 9H7.75V10H6.5V9ZM2 9H3.25V10H2V9Z' fill='black'/%3E%3C/svg%3E%0A");
        
                            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                                /* IE10+ CSS styles go here */
                                mask: none;
                                background: url("data:image/svg+xml,%3Csvg width='#{$_filter-font-size}' height='#{$_filter-font-size}' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 1H1.5C1.225 1 1 1.225 1 1.5V10.5C1 10.775 1.225 11 1.5 11H10.5C10.775 11 11 10.775 11 10.5V1.5C11 1.225 10.775 1 10.5 1ZM5.5 5V6H4.25V5H5.5ZM6.5 5H7.75V6H6.5V5ZM7.75 7V8H6.5V7H7.75ZM8.75 7H10V8H8.75V7ZM8.75 6V5H10V6H8.75ZM3.25 5V6H2V5H3.25ZM2 7H3.25V8H2V7ZM4.25 7H5.5V8H4.25V7ZM5.5 9V10H4.25V9H5.5ZM6.5 9H7.75V10H6.5V9ZM2 9H3.25V10H2V9Z' fill='black'/%3E%3C/svg%3E%0A");
                            }
                        }
                    }
                }
                .k-datetimepicker {
                    .k-input-button .k-icon { 
                        &::before {
                            mask: url("data:image/svg+xml,%3Csvg width='#{$_filter-font-size}' height='#{$_filter-font-size}' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6667 1.45817C11.6667 1.29709 11.5362 1.1665 11.3751 1.1665H1.45841C1.29733 1.1665 1.16675 1.29709 1.16675 1.45817V11.3748C1.16675 11.5359 1.29733 11.6665 1.45841 11.6665H5.54143C5.27735 11.3149 5.065 10.9223 4.91569 10.4998H4.66675V9.33317H4.67632C4.66997 9.23677 4.66675 9.13951 4.66675 9.0415C4.66675 8.74185 4.69687 8.44923 4.75426 8.1665H4.66675V6.99984H5.17136C5.58271 6.22165 6.22189 5.58247 7.00008 5.17111V4.6665H8.16675V4.75402C8.44947 4.69663 8.74209 4.6665 9.04175 4.6665C9.13976 4.6665 9.23701 4.66973 9.33342 4.67607V4.6665H10.5001V4.91545C10.9225 5.06476 11.3152 5.2771 11.6667 5.54118V1.45817ZM3.50008 8.1665H2.33341V6.99984H3.50008V8.1665ZM2.33341 9.33317H3.50008V10.4998H2.33341V9.33317ZM2.33341 5.83317V4.6665H3.50008V5.83317H2.33341ZM4.66675 4.6665H5.83342V5.83317H4.66675V4.6665Z' fill='black'/%3E%3Cpath d='M9.33342 6.99984C9.4945 6.99984 9.62508 7.13042 9.62508 7.2915V8.45817H10.2084C10.3695 8.45817 10.5001 8.58875 10.5001 8.74984V9.33317C10.5001 9.49425 10.3695 9.62484 10.2084 9.62484H8.75008C8.589 9.62484 8.45842 9.49425 8.45842 9.33317V7.2915C8.45842 7.13042 8.589 6.99984 8.75008 6.99984H9.33342Z' fill='black'/%3E%3Cpath d='M5.25008 9.0415C5.25008 6.94742 6.94767 5.24984 9.04175 5.24984C11.1358 5.24984 12.8334 6.94742 12.8334 9.0415C12.8334 11.1356 11.1358 12.8332 9.04175 12.8332C6.94767 12.8332 5.25008 11.1356 5.25008 9.0415ZM9.04175 6.4165C7.592 6.4165 6.41675 7.59176 6.41675 9.0415C6.41675 10.4913 7.592 11.6665 9.04175 11.6665C10.4915 11.6665 11.6667 10.4913 11.6667 9.0415C11.6667 7.59176 10.4915 6.4165 9.04175 6.4165Z' fill='black'/%3E%3C/svg%3E%0A");
        
                            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                                /* IE10+ CSS styles go here */
                                mask: none;
                                background: url("data:image/svg+xml,%3Csvg width='#{$_filter-font-size}' height='#{$_filter-font-size}' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6667 1.45817C11.6667 1.29709 11.5362 1.1665 11.3751 1.1665H1.45841C1.29733 1.1665 1.16675 1.29709 1.16675 1.45817V11.3748C1.16675 11.5359 1.29733 11.6665 1.45841 11.6665H5.54143C5.27735 11.3149 5.065 10.9223 4.91569 10.4998H4.66675V9.33317H4.67632C4.66997 9.23677 4.66675 9.13951 4.66675 9.0415C4.66675 8.74185 4.69687 8.44923 4.75426 8.1665H4.66675V6.99984H5.17136C5.58271 6.22165 6.22189 5.58247 7.00008 5.17111V4.6665H8.16675V4.75402C8.44947 4.69663 8.74209 4.6665 9.04175 4.6665C9.13976 4.6665 9.23701 4.66973 9.33342 4.67607V4.6665H10.5001V4.91545C10.9225 5.06476 11.3152 5.2771 11.6667 5.54118V1.45817ZM3.50008 8.1665H2.33341V6.99984H3.50008V8.1665ZM2.33341 9.33317H3.50008V10.4998H2.33341V9.33317ZM2.33341 5.83317V4.6665H3.50008V5.83317H2.33341ZM4.66675 4.6665H5.83342V5.83317H4.66675V4.6665Z' fill='black'/%3E%3Cpath d='M9.33342 6.99984C9.4945 6.99984 9.62508 7.13042 9.62508 7.2915V8.45817H10.2084C10.3695 8.45817 10.5001 8.58875 10.5001 8.74984V9.33317C10.5001 9.49425 10.3695 9.62484 10.2084 9.62484H8.75008C8.589 9.62484 8.45842 9.49425 8.45842 9.33317V7.2915C8.45842 7.13042 8.589 6.99984 8.75008 6.99984H9.33342Z' fill='black'/%3E%3Cpath d='M5.25008 9.0415C5.25008 6.94742 6.94767 5.24984 9.04175 5.24984C11.1358 5.24984 12.8334 6.94742 12.8334 9.0415C12.8334 11.1356 11.1358 12.8332 9.04175 12.8332C6.94767 12.8332 5.25008 11.1356 5.25008 9.0415ZM9.04175 6.4165C7.592 6.4165 6.41675 7.59176 6.41675 9.0415C6.41675 10.4913 7.592 11.6665 9.04175 11.6665C10.4915 11.6665 11.6667 10.4913 11.6667 9.0415C11.6667 7.59176 10.4915 6.4165 9.04175 6.4165Z' fill='black'/%3E%3C/svg%3E%0A");
                            }
                        }
                    }
                }
                .k-timepicker {
                    .k-input-button .k-icon { 
                        &::before {
                            mask: url("data:image/svg+xml,%3Csvg width='#{$_filter-font-size}' height='#{$_filter-font-size}' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.8334 6.99984C12.8334 10.2215 10.2217 12.8332 7.00008 12.8332C3.77842 12.8332 1.16675 10.2215 1.16675 6.99984C1.16675 3.77818 3.77842 1.1665 7.00008 1.1665C10.2217 1.1665 12.8334 3.77818 12.8334 6.99984ZM2.33341 6.99984C2.33341 9.57717 4.42275 11.6665 7.00008 11.6665C9.57741 11.6665 11.6667 9.57717 11.6667 6.99984C11.6667 4.42251 9.57741 2.33317 7.00008 2.33317C4.42275 2.33317 2.33341 4.42251 2.33341 6.99984ZM9.33342 7.2915C9.33342 7.45192 9.20217 7.58317 9.04175 7.58317H7.29175H6.70842C6.548 7.58317 6.41675 7.45192 6.41675 7.2915V6.70817V3.7915C6.41675 3.63109 6.548 3.49984 6.70842 3.49984H7.29175C7.45217 3.49984 7.58342 3.63109 7.58342 3.7915V6.4165H9.04175C9.20217 6.4165 9.33342 6.54775 9.33342 6.70817V7.2915Z' fill='black'/%3E%3C/svg%3E%0A");
        
                            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                                /* IE10+ CSS styles go here */
                                mask: none;
                                background: url("data:image/svg+xml,%3Csvg width='#{$_filter-font-size}' height='#{$_filter-font-size}' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.8334 6.99984C12.8334 10.2215 10.2217 12.8332 7.00008 12.8332C3.77842 12.8332 1.16675 10.2215 1.16675 6.99984C1.16675 3.77818 3.77842 1.1665 7.00008 1.1665C10.2217 1.1665 12.8334 3.77818 12.8334 6.99984ZM2.33341 6.99984C2.33341 9.57717 4.42275 11.6665 7.00008 11.6665C9.57741 11.6665 11.6667 9.57717 11.6667 6.99984C11.6667 4.42251 9.57741 2.33317 7.00008 2.33317C4.42275 2.33317 2.33341 4.42251 2.33341 6.99984ZM9.33342 7.2915C9.33342 7.45192 9.20217 7.58317 9.04175 7.58317H7.29175H6.70842C6.548 7.58317 6.41675 7.45192 6.41675 7.2915V6.70817V3.7915C6.41675 3.63109 6.548 3.49984 6.70842 3.49984H7.29175C7.45217 3.49984 7.58342 3.63109 7.58342 3.7915V6.4165H9.04175C9.20217 6.4165 9.33342 6.54775 9.33342 6.70817V7.2915Z' fill='black'/%3E%3C/svg%3E%0A");
                            }
                        }
                    }
                }
            }
        
            .k-gridrow-drilldown {
                td:last-child {
                    padding-right: $grid-outer-column-padding-x + $_menu-icon-size + $_cell-padding-x;
                    &::after {
                        background: url("data:image/svg+xml,%3Csvg width='#{$_menu-icon-size}' height='#{$_menu-icon-size}' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5791 6.44583L4.05415 0.145833C3.93748 0.0583333 3.79165 0 3.61665 0C3.47081 0 3.32498 0.0875 3.20831 0.204167L2.47915 1.1375C2.27498 1.37083 2.30415 1.75 2.53748 1.95417L8.48748 6.88333L2.53748 11.8125C2.42081 11.9 2.33331 12.0458 2.33331 12.2208C2.33331 12.3667 2.36248 12.5417 2.47915 12.6583L3.23748 13.5625C3.35415 13.7083 3.52915 13.7667 3.67498 13.7667C3.82081 13.7667 3.93748 13.7083 4.05415 13.6208L11.5791 7.35C11.725 7.23333 11.7833 7.0875 11.7833 6.9125C11.7833 6.7375 11.725 6.53333 11.5791 6.44583Z' fill='%2310689D'/%3E%3C/svg%3E%0A");
                        width: $_menu-icon-size;
                        height: $_menu-icon-size;
                        right: $grid-outer-column-padding-x;
                    }
                }
            }
        }    
    }
    .mri-k-grid-summary {
        .k-grid-header th {
            border-width: 0 0 $grid-cell-horizontal-border-width $grid-cell-vertical-border-width;
        }
    }
}

@include exports( "grid/pdf-export" ) {
    // PDF export
    .k-loading-pdf-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 100;

        > .k-i-loading {
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 64px;
        }

        .k-loading-pdf-progress {
            margin: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

    }

    .k-pdf-export .k-loading-pdf-mask {
        display: none;
    }

    .k-grid-pdf-export-element {
        position: absolute;
        left: -10000px;
        top: -10000px;

        .k-filter-row {
            display: none;
        }
    }

    // Remove Grid scrollbar during built-in export
    .k-pdf-export-shadow {
        .k-grid {
            float: left;
            width: auto !important; // sass-lint:disable-line no-important
        }

        // Remove all sizes and scrolling
        .k-grid,
        .k-grid-content,
        .k-grid-content-locked {
            height: auto !important; // sass-lint:disable-line no-important
            overflow: visible;
        }

        .k-grid-header-locked {
            & + .k-grid-header-wrap,
            & + .k-grid-content,
            & + .k-grid-footer-wrap {
                width: auto !important; // sass-lint:disable-line no-important
            }
        }

        // Remove empty space reserved above the scrollbar
        .k-grid-header,
        .k-grid[data-role="grid"] .k-grid-footer {
            padding: 0 !important; // sass-lint:disable-line no-important
        }
    }
}

@include exports( "filtermenu/layout" ) {

    // Filter menu
    .k-filter-menu-popup {
        width: $grid-column-menu-width;
    }
    .k-filter-menu {
        box-sizing: border-box;
    }
    .k-filter-menu-container {
        padding: map-get( $spacing, 2 );
        box-sizing: border-box;
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        gap: map-get( $spacing, 2 );

        .k-filter-and {
            width: min-content;
            align-self: start;
        }

        .k-actions {
            margin: 0;
            padding: 0;
        }

        // Angular specific
        kendo-numeric-filter-menu,
        kendo-grid-string-filter-menu,
        kendo-grid-date-filter-menu,
        kendo-grid-numeric-filter-menu,
        kendo-grid-filter-menu-input-wrapper {
            display: flex;
            flex-flow: column nowrap;
            align-items: stretch;
            gap: map-get( $spacing, 2 );
        }
    }
    .k-ie .k-filter-menu-container {
        > * { margin-top: map-get( $spacing, 2 ); }
        > :first-child { margin-top: 0; }

        // Angular specific
        .k-filter-and,
        kendo-grid-filter-menu-input-wrapper > * {
            margin-top: map-get( $spacing, 2 );
        }
        kendo-grid-filter-menu-input-wrapper > :first-child {
            margin-top: 0;
        }
    }

    .k-filter-menu.k-popup,
    .k-grid-filter-popup.k-popup {

        .k-filter-menu-container {
            width: $grid-column-menu-width;
            .k-action-buttons.k-button-group {
                justify-content: end;
                .k-button {
                    border-radius: $kendo-button-border-radius;
                }
            }
        }
    }

    .k-popup .k-multicheck-wrap {
        margin: 0;
        padding: 0;
        max-height: 300px;
        font-size: $kendo-list-font-size;
        line-height: $kendo-list-line-height;
        white-space: nowrap;
        overflow: auto;
        list-style: none;

        .k-item,
        .k-check-all-wrap {
            padding: $grid-column-menu-list-item-padding-y $grid-column-menu-list-item-padding-x;
            display: flex;
            flex-flow: row nowrap;
        }
    }

    .k-filter-selected-items {
        font-weight: bold;
        line-height: normal;
        white-space: nowrap;
    }

    // autofitting requires automatic table layout to measure sizes
    .k-autofitting {
        // sass-lint:disable-block no-important

        width: auto !important;
        table-layout: auto !important;

        th.k-header,
        td {
            white-space: nowrap !important;
        }

        .k-detail-row {
            display: none !important;
        }
    }

}

@include exports("columnmenu/layout") {

    // Column menu
    .k-column-menu-popup,
    .k-grid-columnmenu-popup {
        width: $grid-column-menu-width;
        box-sizing: border-box;

        &.k-popup {
            padding: $grid-column-menu-popup-padding-y $grid-column-menu-popup-padding-x;
        }
        .k-popup > & {
            max-width: 100%;
        }

        .k-actions {
            margin: 0;
        }

    }
    .k-column-menu {
        box-sizing: border-box;

        .k-menu:not(.k-context-menu) {
            font-weight: 400;
        }

        .k-expander {
            border: 0;
            background: inherit;

            .k-columnmenu-item {
                display: flex;
                align-items: center;
            }
        }
    }

    .k-column-list {
        padding: 0;
        margin: 0;
        list-style: none;
        max-height: 200px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .k-column-chooser-title,
    .k-column-list-item {
        padding: $grid-column-menu-list-item-padding-y $grid-column-menu-list-item-padding-x;
    }

    .k-column-list-item {
        margin: 0;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        position: relative;

        .k-checkbox-label {
            margin: 0;
        }
    }

    .k-columns-items-wrap {
        padding: $grid-column-menu-items-wrap-padding-y $grid-column-menu-items-wrap-padding-x;
    }

    .k-columnmenu-item {
        padding: $grid-column-menu-item-padding-y $grid-column-menu-item-padding-x;
        outline: 0;
        cursor: pointer;

        > .k-icon {
            margin-right: $icon-spacing;
        }
    }

    .k-columnmenu-item-wrapper + .k-columnmenu-item-wrapper {
        border-top: 1px solid;
        border-top-color: $popup-border;
    }

    .k-columnmenu-item-content {
        overflow: hidden;
    }

    .k-column-menu-group-header {
        padding: $grid-column-menu-group-header-padding-y $grid-column-menu-group-header-padding-x;
        display: flex;
        flex: 0 0 auto;
        position: relative;
        user-select: none;
    }

    .k-column-menu-group-header-text {
        border-bottom-width: $grid-column-menu-group-header-border-bottom-width;
        border-bottom-style: solid;
        font-size: $grid-column-menu-group-header-font-size;
        line-height: $grid-column-menu-group-header-line-height;
        font-weight: bold;
        text-transform: $grid-column-menu-group-header-text-transform;
        flex: 1 1 auto;
    }

    [dir="rtl"],
    .k-rtl {
        .k-columnmenu-item > .k-icon {
            margin-left: $icon-spacing;
            margin-right: 0;
        }

        .k-header > .k-cell-inner {
            margin-right: (-1 * $grid-cell-padding-x);
            margin-left: calc(-1 * calc( #{$kendo-button-calc-size} - #{$grid-cell-padding-x}));

            .k-sort-order {
                margin-right: $grid-sorting-index-spacing-x;
                margin-left: 0;
            }
        }

        .k-filterable {
            > .k-cell-inner {
                margin-right: (-1 * $grid-cell-padding-x);
                margin-left: calc(-1 * #{$kendo-button-calc-size});

                .k-link {
                    padding-right: $grid-header-padding-x;
                    padding-left: 0;
                }
            }
        }
    }
}

@include exports("grid/layout/IE") {
    // TODO: Remove once we drop IE support

    .k-ie {
        .k-grid-header,
        .k-grid-footer {
            padding-right: $default-scrollbar-width;
            padding-left: 0;
            display: block;
        }

        [dir = "rtl"],
        .k-rtl {
            .k-grid-header,
            .k-grid-footer {
                padding-right: 0;
                padding-left: $default-scrollbar-width;
            }
        }
    }
}