@include exports("splitter/theme") {

    .k-splitter {
        @include fill(
            $splitter-text,
            $splitter-bg,
            $splitter-border
        );
    }

    // Splitbar
    .k-splitbar {
        color: $splitbar-text;
        background-color: $splitbar-bg;
    }
    .k-splitbar:hover,
    .k-splitbar.k-hover,
    .k-splitbar-horizontal-hover,
    .k-splitbar-vertical-hover {
        color: $splitbar-hover-text;
        background-color: $splitbar-hover-bg;
    }
    .k-splitbar:focus,
    .k-splitbar.k-focus {
        color: $splitbar-selected-text;
        background: $splitbar-selected-bg;
    }

    // Ghost splitbar
    .k-ghost-splitbar {
        background-color: $splitbar-hover-bg;
    }

}
