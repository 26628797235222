@include exports( "timeselector/theme" ) {

    // Time selector
    .k-timeselector {
        @include fill(
            $time-selector-text,
            $time-selector-bg,
            $time-selector-border
        );
    }


    // Time selector header
    .k-time-header,
    .k-time-selector-header {

        .k-time-now {
            color: $link-text;
        }
        .k-time-now:hover {
            color: $link-hover-text;
        }
    }


    // Time list wrapper
    .k-time-list-wrapper {

        .k-title {
            color: $time-list-title-text;
        }

        &.k-focus {
            .k-title {
                color: $time-list-title-focus-text;
            }

            &::before,
            &::after {
                background-color: $time-list-focused-bg;
            }
        }
    }


    // Time list
    .k-time-list {
        &::before,
        &::after {
            $shadow-size: 3em;
            box-shadow: 0 0 $shadow-size ($shadow-size * 0.5) $time-selector-bg;
        }

        .k-item:hover {
            color: $primary;
        }
    }

    .k-time-container {
        background: transparent;
    }

    .k-time-highlight {
        @include fill(
            $bg: $time-list-highlight-bg,
            $border: $time-list-highlight-border
        );
    }

}
