@include exports("tabstrip/layout") {

    .k-tabstrip-wrapper {
        padding: $tabstrip-wrapper-padding-y $tabstrip-wrapper-padding-x;
        box-sizing: border-box;
        border-width: $tabstrip-wrapper-border-width;
        border-style: solid;
        display: flex;
        flex-direction: column;
    }

    .k-tabstrip {
        border-width: 0;
        border-color: transparent;
        box-sizing: border-box;
        outline: 0;
        font-family: $tabstrip-font-family;
        font-size: $tabstrip-font-size;
        line-height: $tabstrip-line-height;
        color: inherit;
        background-color: transparent;
        display: flex;
        flex-flow: column nowrap;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        .k-tabstrip-wrapper > & {
            flex: 1 1 auto;
        }
    }

    .k-tabstrip-items-wrapper {
        box-sizing: border-box;
        border-width: 0;
        border-style: solid;
        border-color: inherit;
        position: relative;
        //z-index: 2;
    }

    .k-tabstrip-items {
        padding: $padding-y-sm $padding-x-lg 0 $padding-x-lg;
        box-sizing: border-box;
        outline: 0;
        display: flex;
        flex-flow: row wrap;
        flex: 0 0 auto;

        .k-item {
            margin: 0;
            padding: $tabstrip-item-padding-y $tabstrip-item-padding-x;
            border: $tabstrip-item-border-width solid transparent;
            position: relative;
            flex-shrink: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            outline: 0;
            gap: 8px;

            .k-badge {
                padding: 2px 8px;
                position: relative;
                transform: none;
                margin-left: 8px;
                border-width: 0px;
            }

            .k-remove-tab {
                padding: 4px;
                .k-icon {
                    margin: 0px;
                }
            }
        }

        .k-tab-on-top {
            z-index: 1;
        }

        .k-link {
            color: inherit;
            cursor: pointer;
            display: inline-flex;
            vertical-align: middle;
            flex: 1 1 auto;
            flex-direction: row;
            align-content: center;
            align-items: center;
        }

        .k-remove-tab-link,
        .k-link[data-type="remove"] {
            padding: $tabstrip-item-padding-y;
            flex: none;
        }
        
        .k-icon {
            margin-right: $icon-spacing;
        }
    }

    .k-ie11 .k-tabstrip-items {
        white-space: nowrap;
    }

    .k-tabstrip-content,
    .k-tabstrip > .k-content {
        padding: $tabstrip-content-padding-y $tabstrip-content-padding-x;
        box-sizing: border-box;
        border-width: $tabstrip-content-border-width;
        border-style: solid;
        border-color: inherit;
        display: none;
        overflow: auto;
        flex: 1 1 auto;
        position: relative;
        z-index: 1;

        &.k-active ,
        &.k-active {
            display: block;
        }

        &:focus,
        &.k-focused ,
        &.k-focus {
            outline-width: 1px;
            outline-style: dotted;
            outline-offset: -1px;
        }
    }

    // Loading
    .k-tabstrip-items .k-loading {
        width: 20%;
        height: 0;
        border: 0;
        border-top: 1px solid transparent;
        border-color: inherit;
        background: none;
        position: absolute;
        top: 0;
        left: 0;
        transition: width .2s linear;

        // TODO: a better name
        display: none;

        &.k-complete {
            width: 100%;
            border-top-width: 0;
        }
    }


    // Scrolling
    .k-tabstrip-scrollable {
        > .k-tabstrip-items-wrapper {

            > .k-tabstrip-items {
                flex: 1 1 auto;
                flex-wrap: nowrap;
                white-space: nowrap;
                overflow: hidden;
            }

            &.k-hstack {
                position: relative;
                > .k-button {
                    position: absolute;
                    top: 5px;
                    z-index: 2;
                    background-color: $tabstrip-bg-txp;
                    &.k-tabstrip-prev {
                        left: 0px;
                    }
                    &.k-tabstrip-next {
                        right: 0px;
                    }
                }
            }

            &.k-vstack {
                > .k-button {
                    position: absolute;
                    left: 5px;
                    z-index: 2;
                    &.k-tabstrip-prev {
                        top: 0px;
                    }
                    &.k-tabstrip-next {
                        bottom: 0px;
                    }
                }
            }
        }
    }


    // Tabstrip variants
    .k-tabstrip-top {
        > .k-tabstrip-items-wrapper {
            border-bottom-width: $tabstrip-border-width;
            margin-bottom: -$tabstrip-content-border-width;

            > .k-tabstrip-items {
                width: 100%;
            }

            .k-item {
                @include border-top-radius( $tabstrip-item-border-radius );
                margin-bottom: -$tabstrip-border-width;
            }

            .k-item + .k-item {
                margin-left: $tabstrip-item-gap;
            }

            .k-item.k-active ,
            .k-item.k-active {
                border-bottom-color: transparent;
            }
        }

        > .k-content,
        > .k-tabstrip-content {
            //@include border-bottom-radius( $tabstrip-item-border-radius );
            border-top-color: transparent !important; // sass-lint:disable-line no-important
        }
    }
    .k-tabstrip-bottom {
        > .k-tabstrip-items-wrapper {
            border-top-width: $tabstrip-border-width;
            margin-top: -$tabstrip-content-border-width;

            > .k-tabstrip-items {
                width: 100%;
                padding:  0 $padding-x-lg $padding-y-sm $padding-x-lg;
            }

            .k-item {
                @include border-bottom-radius( $tabstrip-item-border-radius );
                margin-top: -$tabstrip-border-width;
            }

            .k-item + .k-item {
                margin-left: $tabstrip-item-gap;
            }

            .k-item.k-active ,
            .k-item.k-active {
                border-top-color: transparent;
            }
        }

        > .k-content,
        > .k-tabstrip-content {
            //@include border-top-radius( $tabstrip-item-border-radius );
            border-bottom-color: transparent !important; // sass-lint:disable-line no-important
        }
    }
    .k-tabstrip-left {
        flex-direction: row;

        > .k-tabstrip-items-wrapper {
            border-right-width: $tabstrip-border-width;
            margin-right: -$tabstrip-content-border-width;

            > .k-tabstrip-items {
                height: 100%;
                display: inline-flex;
                flex-direction: column;
                padding:  $padding-x-lg 0 $padding-x-lg $padding-y-sm;
            }

            .k-item {
                @include border-left-radius( $tabstrip-item-border-radius );
                margin-right: -$tabstrip-border-width;
            }

            .k-item + .k-item {
                margin-top: $tabstrip-item-gap;
            }

            .k-item.k-active ,
            .k-item.k-active {
                border-right-color: transparent;
            }
        }

        > .k-content,
        > .k-tabstrip-content {
            //@include border-right-radius( $tabstrip-item-border-radius );
            margin: 0 !important; // sass-lint:disable-line no-important
            border-left-color: transparent !important; // sass-lint:disable-line no-important
            padding-left: 16px;
        }

    }
    .k-tabstrip-right {
        flex-direction: row-reverse;

        > .k-tabstrip-items-wrapper {
            border-left-width: $tabstrip-border-width;
            margin-left: -$tabstrip-content-border-width;

            > .k-tabstrip-items {
                height: 100%;
                display: inline-flex;
                flex-direction: column;
                padding:  $padding-x-lg $padding-y-sm $padding-x-lg 0;
            }

            .k-item {
                @include border-right-radius( $tabstrip-item-border-radius );
                margin-left: -$tabstrip-border-width;
            }

            .k-item + .k-item {
                margin-top: $tabstrip-item-gap;
            }

            .k-item.k-active ,
            .k-item.k-active {
                border-left-color: transparent;
            }
        }

        > .k-content,
        > .k-tabstrip-content {
            //@include border-left-radius( $tabstrip-item-border-radius );
            margin: 0 !important; // sass-lint:disable-line no-important
            border-right-color: transparent !important; // sass-lint:disable-line no-important
            padding-right: 16px;
        }
    }

    .mri-k-tabstrip-secondary {
        .k-tabstrip-items-wrapper {
            border-bottom: 1px solid;

            .k-tabstrip-items {
                padding: 0px 16px;

                .k-item {
                    border-radius: 0px;
                    border-bottom: 2px solid;
                    margin: 0px;
                    padding: 8px;
                }
            }
        }
    }

    .k-tabstrip.mri-k-process-tabs {
        .k-tabstrip-items {
            padding: 0;
            display: flex;
            flex-flow: row nowrap;
            flex-grow: 0;
            flex-shrink: 0;
            border: none;
            margin: 0;
    
            .k-item {
                border-radius: 0;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                flex-shrink: 1;
                flex-basis: auto;
                min-width: -webkit-min-content;
                min-width: -moz-min-content;
                min-width: min-content;
                white-space: nowrap;
                border-style: solid;
                margin-right: 0;
                transition: background-color 0.25s 0s cubic-bezier(0, 1, 1, 1);
                pointer-events: none;
                cursor: pointer;
                position: relative;
                border-width: 0;
                border-radius: 0;
                padding: 8px 16px;
                            
                .k-link {
                    z-index: 2;
                    padding: 0;
                }

                .k-tabstrip-tab-number {
                    text-align: center;
                    font-family: "Noto Sans", "Work Sans", "Futura", sans-serif;
                    border-style: solid;
                    font-size: 1em;
                    border-width: 1px;
                    width: 1.5em;
                    height: 1.5em;
                    line-height: inherit;
                    border-radius: 9999px;
                }

                .k-active {
                    .k-tabstrip-tab-number {
                        span {
                            display: initial;
                        }
                    }
                }

                &:not(.k-active) {
                    pointer-events: auto;

                    .k-tabstrip-tab-number {
                        border-width: 0;
                        width: calc( 1.5em + 2px);
                        height: calc( 1.5em + 2px);
    
                        span {
                            display: none;
                        }
                    }
                }

                &:not(:first-child) {
                    padding-left: calc(16px + 1em);
                }
                  
                &:first-child {
                    padding-left: 32px;
                }
                  
                &::before, &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 50%;
                    left: 6px;
                    background-color: inherit;
                    z-index: 1;
                }
                
                &::before {
                transform: skew(30deg);
                top: 0;
                }
            
                &::after {
                transform: skew(-30deg);
                bottom: 1px;
                }
            }

            .k-item + .k-item {
                margin-left: 0px;
            }

            .k-active ~ .k-item:not(.k-tab-done) {
                pointer-events: none;

                .k-tabstrip-tab-number {
                    border-width: 1px;
                    width: 1.5em;
                    height: 1.5em;

                    span {
                        display: initial;
                    }
                }
            }
        }
        &.k-tabstrip-left {
            flex-direction: row;
    
            > .k-tabstrip-items-wrapper {
                border-right-width: $tabstrip-border-width;
                margin-right: -$tabstrip-content-border-width;
    
                > .k-tabstrip-items {
                    height: 100%;
                    display: inline-flex;
                    flex-direction: column;
                    padding:  $padding-x-lg 0 $padding-x-lg $padding-y-sm;
                }
    
                .k-item {
                    @include border-left-radius( $tabstrip-item-border-radius );
                    margin-right: -$tabstrip-border-width;
                    padding: 4px 8px;
                    &::before, &::after {
                        display: none;
                    }
                }
    
                .k-item + .k-item {
                    margin-top: $tabstrip-item-gap;
                }
    
                .k-item.k-active ,
                .k-item.k-active {
                    border-right-color: transparent;
                }
            }
    
            > .k-content,
            > .k-tabstrip-content {
                //@include border-right-radius( $tabstrip-item-border-radius );
                margin: 0 !important; // sass-lint:disable-line no-important
                border-left-color: transparent !important; // sass-lint:disable-line no-important
                padding-left: 0px;
            }
    
        }

        &.k-tabstrip-right {
            flex-direction: row-reverse;
    
            > .k-tabstrip-items-wrapper {
                border-left-width: $tabstrip-border-width;
                margin-left: -$tabstrip-content-border-width;
    
                > .k-tabstrip-items {
                    height: 100%;
                    display: inline-flex;
                    flex-direction: column;
                    padding:  $padding-x-lg  $padding-y-sm $padding-x-lg 0;
                }
    
                .k-item {
                    @include border-right-radius( $tabstrip-item-border-radius );
                    margin-left: -$tabstrip-border-width;
                    padding: 4px 8px;
                    &::before, &::after {
                        display: none;
                    }
                }
    
                .k-item + .k-item {
                    margin-top: $tabstrip-item-gap;
                }
    
                .k-item.k-active ,
                .k-item.k-active {
                    border-left-color: transparent;
                }
            }
    
            > .k-content,
            > .k-tabstrip-content {
                //@include border-left-radius( $tabstrip-item-border-radius );
                margin: 0 !important; // sass-lint:disable-line no-important
                border-right-color: transparent !important; // sass-lint:disable-line no-important
                padding-right: 0px;
            }
    
        }
    }


    // Selected indicator
    @if ($tabstrip-indicator-size) {

        .k-tabstrip-items-wrapper {

            .k-item::after {
                content: "";
                border-width: 0;
                border-style: solid;
                display: none;
                position: absolute;
                z-index: 0;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                // Variants
                .k-tabstrip-top > & {
                    border-bottom-width: $tabstrip-indicator-size;
                }
                .k-tabstrip-bottom > & {
                    border-top-width: $tabstrip-indicator-size;
                }
                .k-tabstrip-left > & {
                    border-right-width: $tabstrip-indicator-size;
                }
                .k-tabstrip-right > & {
                    border-left-width: $tabstrip-indicator-size;
                }
            }
            .k-item.k-active::after ,
            .k-item.k-active::after {
                display: block;
            }

            .k-item > .k-link {
                z-index: 1;
            }
        }

    }

}




@include exports("tabstrip/layout/rtl") {
    .k-rtl .k-tabstrip,
    .k-tabstrip[dir="rtl"] {

        &.k-tabstrip-left,
        &.k-tabstrip-right {
            > .k-tabstrip-items-wrapper {
                order: 1;
            }

            > .k-content { order: 0; }
            > .k-tabstrip-content { order: 0; }
        }

        .k-tabstrip-prev,
        .k-tabstrip-next {
            transform: scaleX(-1);
        }

    }

}
