@include exports("dropdownlist/layout") {

    // Dropdown list
    .k-dropdown-list {}


    // Native select
    select.k-dropdown-list {}


    // Dropdown operator
    .k-dropdown-operator {
        width: min-content;
        flex: none;
        aspect-ratio: 1;

        .k-input-button {
            border-width: 0;
        }
        .k-input-button .k-button-icon {
            width: auto;
            aspect-ratio: 1;
        }

        .k-input-inner {
            display: none;
        }
    }

    // Alias
    .k-dropdown,
    .k-dropdownlist {
        @extend .k-dropdown-list !optional;
    }

}
