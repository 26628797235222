// Listgroup
$listgroup-border-width: 1px !default;
$listgroup-border-radius: $kendo-border-radius-md !default;

$listgroup-font-size: $font-size !default;
$listgroup-line-height: $line-height !default;

$listgroup-bg: $component-bg !default;
$listgroup-text: $component-text !default;
$listgroup-border: $component-border !default;

$listgroup-item-padding-x: map-get( $spacing, 2 ) !default;
$listgroup-item-padding-y: map-get( $spacing, 2 ) !default;
$listgroup-item-border-width: 1px !default;
