@include exports( "avatar/theme" ) {

    // Solid Avatars
    @each $name, $color in $kendo-avatar-theme-colors {
        .k-avatar-solid-#{$name} {
            border-color: $color;
            color: contrast-wcag( $color );
            background-color: $color;
        }
    }

    // Outline avatars
    .k-avatar-outline {
        border-color: currentColor;
    }

    @each $name, $color in $kendo-avatar-theme-colors {
        .k-avatar-outline-#{$name} {
            color: $color;
        }
    }

}
