// Wizard
$wizard-padding-x: 0px !default;
$wizard-padding-y: 0px !default;
$wizard-content-padding-x: 16px !default;
$wizard-content-padding-y: 16px !default;
$wizard-buttons-margin-x: 8px !default;

$wizard-border-width: 1px !default;
$wizard-font-size: $font-size !default;
$wizard-line-height: $line-height !default;
$wizard-font-family: $font-family !default;

$wizard-step-border-focused: #656565 !default;
$wizard-focused-shadow: inset 0 0 0 2px rgba(0, 0, 0, .13) !default;
