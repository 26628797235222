@include exports( "autocomplete/layout" ) {

    // Autocomplete
    .k-autocomplete {
        .k-input-inner {
            padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
        }
        
        .k-clear-value {
            right: $kendo-input-padding-x;
        }
    }

}
