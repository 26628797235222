@include exports( "multiselect/theme" ) {

    // Multiselect
    .k-multiselect::after {
        background-color: $kendo-input-text;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            background-color: transparent;
        }
    }

    .k-multiselect .k-chip {
        &:hover,
        &.k-hover {
            background-color: transparent;
        }
    }

    .k-multiselect.k-required, .k-multiselect.mri-k-show-validation, td.mri-k-show-validation > .k-multiselect {
        background-size: $kendo-input-font-size $kendo-input-font-size;
        background-repeat: no-repeat;
        background-position: calc(100% - 36px) 11px;
    }

    .k-multiselect.k-required {
        padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size} + #{$kendo-input-button-size});
        background-image: $required-icon;
    }

    .k-multiselect.mri-k-show-validation, td.mri-k-show-validation > .k-multiselect {
        // warning state
        &.k-warning {
            padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size} + #{$kendo-input-button-size});
            background-image: $warning-icon;
        }

        // Invalid state
        &.k-invalid,
        &.ng-invalid.ng-touched,
        &.ng-invalid.ng-dirty {
            padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size} + #{$kendo-input-button-size});
            background-image: $invalid-icon;
        }

        // valid state
        &.k-success, 
        &.k-valid:not(.ng-pristine),
        &.ng-valid.ng-touched:not(.ng-pristine),
        &.ng-valid.ng-dirty:not(.ng-pristine) {
            padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size} + #{$kendo-input-button-size});
            background-image: $success-icon;
        }
    }

    .k-multiselect[disabled] {
        &::after {
            width: 20px;
        }

        .k-chip {
            &:first-of-type {
                max-width: calc(100% - 28px);
            }
        }
    }

    .k-multiselect.k-readonly, .k-multiselect[disabled], .k-multiselect.k-disabled {
        background-image: none;
        padding-right: 0px !important;
        .k-searchbar {
            display: none;
        }
    }

    .k-multiselect.k-readonly {
        .k-input-values {
            padding-right: $kendo-input-padding-x;
        }
        &::after {
            content: '';
            mask: none;
            background: none;
            height: unset;
            width: unset;
        }
    }

    .k-multiselect.k-is-loading {
        position: relative;
        background-image: none;

        &::before {
            position: absolute;
            top: 50%;
            right: 36px;
            display: inline-block;
            content: "";
            box-sizing: inherit;
            border-radius: 50%;
            border-width: 2px;
            border-style: solid;
            border-color: $loading-foreground-color;
            border-left-color: $loading-background-color;
            border-right-color: $loading-background-color;
            border-top-color: $loading-foreground-color;
            border-bottom-color: $loading-background-color;
            background-color: transparent;
            margin-top: -.5em;
            margin-left: -.5em;
            width: 1em;
            height: 1em;
            animation: k-loading-animation .5s linear infinite;
        }
    }
}
