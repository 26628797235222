// Switch

/// Font family of the switch.
/// @group switch
$kendo-switch-font-family: null !default;

/// Border width of the switch track.
/// @group switch
$kendo-switch-track-border-width: 1px !default;

/// Border width of the switch thumb.
/// @group switch
$kendo-switch-thumb-border-width: 0px !default;

/// Text transform of the switch label.
/// @group switch
$kendo-switch-label-text-transform: uppercase !default;
/// Display of the switch label.
/// @group switch
$kendo-switch-label-display: inline !default;

/// Map with the different switch sizes.
/// @group switch
$kendo-switch-sizes: (
    sm: ( font-size: 10px, track-width: 52px, track-height: 20px, thumb-width: 16px, thumb-height: 16px, thumb-offset: 2px, label-offset: 4px ),
    md: ( font-size: 10px, track-width: 60px, track-height: 24px, thumb-width: 18px, thumb-height: 18px, thumb-offset: 2px, label-offset: 6px ),
    lg: ( font-size: 10px, track-width: 60px, track-height: 24px, thumb-width: 18px, thumb-height: 18px, thumb-offset: 2px, label-offset: 6px )
) !default;

$kendo-switch-thumb-hover-offset: 2px !default;

/// The background of the track when the switch is not checked.
/// @group switch
$kendo-switch-off-track-bg: $component-bg !default;
/// The text color of the track when the switch is not checked.
/// @group switch
$kendo-switch-off-track-text: $component-text !default;
/// The border color of the track when the switch is not checked.
/// @group switch
$kendo-switch-off-track-border: $component-border !default;
/// The background gradient of the track when the switch is not checked.
/// @group switch
$kendo-switch-off-track-gradient: null !default;

/// The background of the track when the hovered switch is not checked.
/// @group switch
$kendo-switch-off-track-hover-bg: $hovered-bg !default;
/// The text color of the track when the hovered switch is not checked.
/// @group switch
$kendo-switch-off-track-hover-text: null !default;
/// The border color of the track when the hovered switch is not checked.
/// @group switch
$kendo-switch-off-track-hover-border: null !default;
/// The background gradient of the track when the hovered switch is not checked.
/// @group switch
$kendo-switch-off-track-hover-gradient: null !default;

/// The background of the track when the focused switch is not checked.
/// @group switch
$kendo-switch-off-track-focus-bg: null !default;
/// The text color of the track when the focused switch is not checked.
/// @group switch
$kendo-switch-off-track-focus-text: null !default;
/// The border color of the track when the focused switch is not checked.
/// @group switch
$kendo-switch-off-track-focus-border: null !default;
/// The background gradient of the track when the focused switch is not checked.
/// @group switch
$kendo-switch-off-track-focus-gradient: null !default;
/// The ring around the track when the focused switch is not checked.
/// @group switch
$kendo-switch-off-track-focus-ring: $focused-shadow-outset !default;

/// The background of the track when the disabled switch is not checked.
/// @group switch
$kendo-switch-off-track-disabled-bg: null !default;
/// The text color of the track when the disabled switch is not checked.
/// @group switch
$kendo-switch-off-track-disabled-text: null !default;
/// The border color of the track when the disabled switch is not checked.
/// @group switch
$kendo-switch-off-track-disabled-border: null !default;
/// The background gradient of the track when the disabled switch is not checked.
/// @group switch
$kendo-switch-off-track-disabled-gradient: null !default;

/// The background of the thumb when the switch is not checked.
/// @group switch
$kendo-switch-off-thumb-bg: $bg-color !default;
/// The text color of the thumb when the switch is not checked.
/// @group switch
$kendo-switch-off-thumb-text: $base-text !default;
/// The border color of the thumb when the switch is not checked.
/// @group switch
$kendo-switch-off-thumb-border: transparent !default;
/// The background gradient of the thumb when the switch is not checked.
/// @group switch
$kendo-switch-off-thumb-gradient: null !default;
/// The shadow of the thumb when the switch is not checked.
/// @group switch
$kendo-switch-off-thumb-shadow: 0 0 1px rgba(0,0,0,.4), 0 2px 4px rgba(0,0,0,.4) !default;

/// The background of the thumb when the hovered switch is not checked.
/// @group switch
$kendo-switch-off-thumb-hover-bg: null !default;
/// The text color of the thumb when the hovered switch is not checked.
/// @group switch
$kendo-switch-off-thumb-hover-text: null !default;
/// The border color of the thumb when the hovered switch is not checked.
/// @group switch
$kendo-switch-off-thumb-hover-border: null !default;
/// The background gradient of the thumb when the hovered switch is not checked.
/// @group switch
$kendo-switch-off-thumb-hover-gradient: null !default;


/// The background of the track when the switch is checked.
/// @group switch
$kendo-switch-on-track-bg: $primary !default;
/// The text color of the track when the switch is checked.
/// @group switch
$kendo-switch-on-track-text: contrast-wcag( $kendo-switch-on-track-bg ) !default;
/// The border color of the track when the switch is checked.
/// @group switch
$kendo-switch-on-track-border: $kendo-switch-on-track-bg !default;
/// The background gradient of the track when the switch is checked.
/// @group switch
$kendo-switch-on-track-gradient: null !default;

/// The background of the track when the hovered switch is checked.
/// @group switch
$kendo-switch-on-track-hover-bg: null !default;
/// The text color of the track when the hovered switch is checked.
/// @group switch
$kendo-switch-on-track-hover-text: null !default;
/// The border color of the track when the hovered switch is checked.
/// @group switch
$kendo-switch-on-track-hover-border: null !default;
/// The background gradient of the track when the hovered switch is checked.
/// @group switch
$kendo-switch-on-track-hover-gradient: null !default;

/// The background of the track when the focused switch is checked.
/// @group switch
$kendo-switch-on-track-focus-bg: null !default;
/// The text color of the track when the focused switch is checked.
/// @group switch
$kendo-switch-on-track-focus-text: null !default;
/// The border color of the track when the focused switch is checked.
/// @group switch
$kendo-switch-on-track-focus-border: null !default;
/// The background gradient of the track when the focused switch is checked.
/// @group switch
$kendo-switch-on-track-focus-gradient: null !default;
/// The ring around the track when the focused switch is checked.
/// @group switch
$kendo-switch-on-track-focus-ring: $focused-shadow-outset !default;

/// The background of the track when the disabled switch is checked.
/// @group switch
$kendo-switch-on-track-disabled-bg: null !default;
/// The text color of the track when the disabled switch is checked.
/// @group switch
$kendo-switch-on-track-disabled-text: null !default;
/// The border color of the track when the disabled switch is checked.
/// @group switch
$kendo-switch-on-track-disabled-border: null !default;
/// The background gradient of the track when the disabled switch is checked.
/// @group switch
$kendo-switch-on-track-disabled-gradient: null !default;

/// The background of the thumb when the switch is checked.
/// @group switch
$kendo-switch-on-thumb-bg: $bg-color !default;
/// The text color of the thumb when the switch is checked.
/// @group switch
$kendo-switch-on-thumb-text: $base-text !default;
/// The border color of the thumb when the switch is checked.
/// @group switch
$kendo-switch-on-thumb-border: transparent !default;
/// The background gradient of the thumb when the switch is checked.
/// @group switch
$kendo-switch-on-thumb-gradient: null !default;
/// The shadow of the thumb when the switch is checked.
/// @group switch
$kendo-switch-on-thumb-shadow: 0 0 1px rgba(0,0,0,.4), 0 2px 4px rgba(0,0,0,.4) !default;

/// The background of the thumb when the hovered switch is checked.
/// @group switch
$kendo-switch-on-thumb-hover-bg: null !default;
/// The text color of the thumb when the hovered switch is checked.
/// @group switch
$kendo-switch-on-thumb-hover-text: null !default;
/// The border color of the thumb when the hovered switch is checked.
/// @group switch
$kendo-switch-on-thumb-hover-border: null !default;
/// The background gradient of the thumb when the hovered switch is checked.
/// @group switch
$kendo-switch-on-thumb-hover-gradient: null !default;
