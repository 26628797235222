@include exports("messagebox/theme") {

    .k-messagebox-info {
        color: $message-box-info-text;
        background-color: $message-box-info-bg;
        border-bottom-color: $message-box-info-border;

        .k-i-info {
            color: $message-box-info-border;
        }
    }
    .k-messagebox-success {
        color: $message-box-success-text;
        background-color: $message-box-success-bg;
        border-bottom-color: $message-box-success-border;

        .k-i-success {
            color: $message-box-success-border;
        }
    }
    .k-messagebox-warning {
        color: $message-box-warning-text;
        background-color: $message-box-warning-bg;
        border-bottom-color: $message-box-warning-border;

        .k-i-warning {
            color: $message-box-warning-border;
        }
    }
    .k-messagebox-error {
        color: $message-box-error-text;
        background-color: $message-box-error-bg;
        border-bottom-color: $message-box-error-border;

        .k-i-error {
            color: $message-box-error-border;
        }
    }


}
