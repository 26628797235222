// Treeview

/// Font family of the treeview component.
/// @group treeview
$kendo-treeview-font-family: $font-family !default;
/// Font size of the treeview component.
/// @group treeview
$kendo-treeview-font-size: $font-size-md !default;
$kendo-treeview-font-size-sm: $font-size-md !default;
$kendo-treeview-font-size-md: $font-size-md !default;
$kendo-treeview-font-size-lg: $font-size-md !default;
/// Line height of the treeview component.
/// @group treeview
$kendo-treeview-line-height: $line-height-md !default;
$kendo-treeview-line-height-sm: $line-height-md !default;
$kendo-treeview-line-height-md: $line-height-md !default;
$kendo-treeview-line-height-lg: $line-height-md !default;
/// Indentation of child groups in treeview component.
/// @group treeview
$kendo-treeview-indent: 16px !default;

/// Horizontal padding of treeview items.
/// @group treeview
$kendo-treeview-item-padding-x: map-get( $spacing, 2 ) !default;
$kendo-treeview-item-padding-x-sm: map-get( $spacing, 2 ) !default;
$kendo-treeview-item-padding-x-md: map-get( $spacing, 2 ) !default;
$kendo-treeview-item-padding-x-lg: map-get( $spacing, 2 ) !default;
/// Vertical padding of treeview items.
/// @group treeview
$kendo-treeview-item-padding-y: map-get( $spacing, 1 ) !default;
$kendo-treeview-item-padding-y-sm: map-get( $spacing, 1 ) - map-get( $spacing, thin ) !default;
$kendo-treeview-item-padding-y-md: map-get( $spacing, 1 ) !default;
$kendo-treeview-item-padding-y-lg: map-get( $spacing, 1 ) + map-get( $spacing, thin ) !default;
/// Border width of treeview items.
/// @group treeview
$kendo-treeview-item-border-width: 0px !default;
/// Border radius of treeview items.
/// @group treeview
$kendo-treeview-item-border-radius: $kendo-border-radius-md !default;


/// The sizes of the treeview.
/// @group treeview
$kendo-treeview-sizes: (
    sm: (
        font-size: $kendo-treeview-font-size-sm,
        line-height: $kendo-treeview-line-height-sm,
        item-padding-x: $kendo-treeview-item-padding-x-sm,
        item-padding-y: $kendo-treeview-item-padding-y-sm,
        checkbox-size: map-get( $spacing, 4 ),
        checkbox-glyph-size: ( map-get( $spacing, 4 ) - map-get( $spacing, thin ) ),
        checkbox-background-size: map-get( $spacing, 2 ),
    ),
    md: (
        font-size: $kendo-treeview-font-size-md,
        line-height: $kendo-treeview-line-height-md,
        item-padding-x: $kendo-treeview-item-padding-x-md,
        item-padding-y: $kendo-treeview-item-padding-y-md,
        checkbox-size: map-get( $spacing, 6 ),
        checkbox-glyph-size: ( map-get( $spacing, 6 ) - map-get( $spacing, thin ) ),
        checkbox-background-size: map-get( $spacing, 4 ) - 2,
    ),
    lg: (
        font-size: $kendo-treeview-font-size-lg,
        line-height: $kendo-treeview-line-height-lg,
        item-padding-x: $kendo-treeview-item-padding-x-lg,
        item-padding-y: $kendo-treeview-item-padding-y-lg,
        checkbox-size: map-get( $spacing, 6 ),
        checkbox-glyph-size: ( map-get( $spacing, 6 ) - map-get( $spacing, thin ) ),
        checkbox-background-size: map-get( $spacing, 4 ) - 2,
    )
) !default;


/// Background color of the treeview component.
/// @group treeview
$kendo-treeview-bg: null !default;
/// Text color of the treeview component.
/// @group treeview
$kendo-treeview-text: $component-text !default;
/// Border color of the treeview component.
/// @group treeview
$kendo-treeview-border: null !default;

/// Background color of hovered treeview items.
/// @group treeview
$kendo-treeview-item-hover-bg: $hovered-bg !default;
/// Text color of hovered treeview items.
/// @group treeview
$kendo-treeview-item-hover-text: $hovered-text !default;
/// Border color of hovered treeview items.
/// @group treeview
$kendo-treeview-item-hover-border: null !default;
/// Background gradient of hovered treeview items.
/// @group treeview
$kendo-treeview-item-hover-gradient: null !default;

/// Background color of selected treeview items.
/// @group treeview
$kendo-treeview-item-selected-bg: $selected-bg !default;
/// Text color of selected treeview items.
/// @group treeview
$kendo-treeview-item-selected-text: $selected-text !default;
/// Border color of selected treeview items.
/// @group treeview
$kendo-treeview-item-selected-border: null !default;
/// Background gradient of selected treeview items.
/// @group treeview
$kendo-treeview-item-selected-gradient: null !default;

/// Box shadow of focused treeview items.
/// @group treeview
$kendo-treeview-item-focus-shadow: $focused-shadow !default;

/// Background color of load more.
/// @group treeview
$kendo-treeview-loadmore-bg: transparent !default;
/// Text color of load more.
/// @group treeview
$kendo-treeview-loadmore-text: $link-text !default;
/// Border color of load more.
/// @group treeview
$kendo-treeview-loadmore-border: null !default;

/// Background color of a hovered load more.
/// @group treeview
$kendo-treeview-loadmore-hover-bg: transparent !default;
/// Text color of a hovered load more.
/// @group treeview
$kendo-treeview-loadmore-hover-text: $link-hover-text !default;
/// Border color of a hovered load more.
/// @group treeview
$kendo-treeview-loadmore-hover-border: null !default;

/// Background color of a focused load more.
/// @group treeview
$kendo-treeview-loadmore-focus-bg: transparent !default;
/// Text color of a focused load more.
/// @group treeview
$kendo-treeview-loadmore-focus-text: $link-hover-text !default;
/// Border color of a focused load more.
/// @group treeview
$kendo-treeview-loadmore-focus-border: null !default;
/// Box shadow of a focused load more.
/// @group treeview
$kendo-treeview-loadmore-focus-shadow: $kendo-treeview-item-focus-shadow !default;
