@include exports("overlay/layout") {

    // Overlay
    .k-overlay {
        width: 100%;
        height: 100%;
        opacity: $overlay-opacity;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10001;
    }

    .k-dialog .k-overlay {
        opacity: 1;
    }
}
