// Scrollview
$scrollview-border-width: 1px !default;
$scrollview-font-family: $font-family !default;
$scrollview-font-size: $font-size !default;
$scrollview-line-height: $line-height !default;

$scrollview-bg: $component-bg !default;
$scrollview-text: $component-text !default;
$scrollview-border: $component-border !default;

$scrollview-pagebutton-size: 8px !default;
$scrollview-pagebutton-bg: #e9e9e9 !default; //$button-bg !default;
$scrollview-pagebutton-border: #bbbbbb !default; //$button-border !default;
$scrollview-pagebutton-primary-bg: $primary !default;
$scrollview-pagebutton-primary-border: #e9e9e9 !default;
$scrollview-pagebutton-shadow: 0 0 2px rgba(0, 0, 0, .08) !default;

$scrollview-pager-offset: 20px !default;
$scrollview-pager-item-spacing: 20px !default;
$scrollview-pager-item-border-width: 1px !default;
$scrollview-pager-height: calc( #{$scrollview-pagebutton-size} + #{$scrollview-pager-item-border-width * 2} + #{$scrollview-pager-item-spacing * 0.5} ) !default;

$scrollview-pager-multidot-threshold: 10 !default;
$scrollview-pager-multidot-intermediate: 3 !default;
$scrollview-pager-multidot-step: 1px !default;

$scrollview-arrow-icon-size: 4.5em !default;
$scrollview-arrow-tap-highlight-color: $rgba-transparent !default;
$scrollview-navigation-color: white !default;
$scrollview-navigation-icon-shadow: rgba(0, 0, 0, .3) 0 0 15px !default;
$scrollview-navigation-bg: rgba(0, 0, 0, 0) !default;
$scrollview-navigation-default-opacity: .7 !default;
$scrollview-navigation-hover-opacity: 1 !default;
$scrollview-navigation-hover-span-bg: null !default;

$scrollview-light-bg: rgba(255, 255, 255, .4) !default;
$scrollview-dark-bg: rgba(0, 0, 0, .4) !default;

$scrollview-transition-duration: .3s !default;
$scrollview-transition-timing-function: ease-in-out !default;
