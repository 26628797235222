@include exports("tilelayout/theme") {

    .k-tilelayout {
        @include fill(
            $bg: $tilelayout-bg
        );
    }

    .k-tilelayout-item {
        border-color: $panel-border;
        .k-card-title {
            color: #00B288;
        }
    }

    .k-tilelayout-item:focus,
    .k-tilelayout-item.k-focus {
        @include box-shadow($tilelayout-card-focus-shadow);
    }

    .k-layout-item-hint {
        @include fill(
            $bg: $tilelayout-hint-bg,
            $border: $tilelayout-hint-border
        );
    }

}
