@include exports( "colorpicker/layout" ) {

    // Color picker
    .k-color-picker {
        width: min-content;
        .k-input-inner {
            padding: 2px;
            width: calc( #{$kendo-input-border-width * 4} + #{$kendo-input-padding-y} + #{$kendo-input-line-height * 1em} );
            height: calc( #{$kendo-input-border-width * 4} + #{$kendo-input-padding-y} + #{$kendo-input-line-height * 1em} );
            box-sizing: content-box;
    
            .k-color-preview {
                box-sizing: border-box;
                line-height: 0;
                position: relative;
                border-width: 0px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
            }
        }
    }

    // Alias
    .k-colorpicker {
        @extend .k-color-picker;
    }

}
