// Listbox
$listbox-margin: $padding-x  * 2 !default;
$listbox-button-margin: $padding-x !default;
$listbox-width: auto !default;
$listbox-default-height: 200px !default;

$listbox-border-width: 1px !default;
$listbox-font-family: $font-family !default;
$listbox-font-size: $font-size !default;
$listbox-line-height: $line-height !default;

$listbox-bg: $component-bg !default;
$listbox-text: $component-text !default;
$listbox-border: $component-border !default;

$listbox-item-padding-x: null !default;
$listbox-item-padding-y: null !default;

$listbox-drop-hint-border-width: null !default;
$listbox-drop-hint-width: 1px !default;
