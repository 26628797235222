@include exports("slider/theme") {
    $slider-tick-horizontal-image: map-get($data-uris, "slider-h.gif") !default;
    $slider-tick-vertical-image: map-get($data-uris, "slider-v.gif") !default;

    .k-slider {
        color: $component-text;

        .k-slider-track,
        .k-slider-selection {
            @include border-radius( $kendo-border-radius );
        }

        .k-slider-track {
            @include fill( $bg: $slider-track-bg );
        }

        .k-slider-selection {
            @include fill( $bg: $slider-selection-bg );
        }

        .k-draghandle {
            @include fill(
                $slider-draghandle-text,
                $slider-draghandle-bg,
                $slider-draghandle-border,
                $slider-draghandle-gradient
            );
            @include border-radius( 50% );

            &:hover {
                @include fill(
                    $slider-draghandle-hovered-text,
                    $slider-draghandle-hovered-bg,
                    $slider-draghandle-hovered-border,
                    $slider-draghandle-hovered-gradient
                );
            }

            &:active,
            &.k-pressed {
                @include fill(
                    $slider-draghandle-pressed-text,
                    $slider-draghandle-pressed-bg,
                    $slider-draghandle-pressed-border,
                    $slider-draghandle-pressed-gradient
                );
            }

            &:focus,
            &.k-focus {
                @include box-shadow( $slider-draghandle-focused-shadow );
            }
        }

        &.k-focus {
            .k-draghandle {
                @include box-shadow( $slider-draghandle-focused-shadow );
            }
        }

        &.k-disabled {
            opacity: $slider-disabled-opacity;
        }
    }

    .k-slider-horizontal .k-tick {
        @include background-image( $slider-tick-horizontal-image );
    }

    .k-slider-vertical .k-tick {
        @include background-image( $slider-tick-vertical-image );
    }
}
