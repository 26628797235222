@include exports("switch/theme") {

    // Switch
    .k-switch {}

    .k-switch[aria-readonly="true"] {
        pointer-events: none;
    }

    // Switch OFF
    .k-switch-off {

        // Normal state
        .k-switch-track {
            @include fill(
                $kendo-switch-off-track-text,
                $kendo-switch-off-track-bg,
                $kendo-switch-off-track-border,
                $kendo-switch-off-track-gradient
            );
        }
        .k-switch-thumb {
            @include fill(
                $kendo-switch-off-thumb-text,
                $kendo-switch-off-thumb-bg,
                $kendo-switch-off-thumb-border,
                $kendo-switch-off-thumb-gradient
            );
            box-shadow: $kendo-switch-off-thumb-shadow;
        }

        // Hover
        &:hover,
        &.k-hover {
            .k-switch-track {
                @include fill(
                    $kendo-switch-off-track-hover-text,
                    $kendo-switch-off-track-hover-bg,
                    $kendo-switch-off-track-hover-border,
                    $kendo-switch-off-track-hover-gradient
                );
            }

            .k-switch-thumb {
                @include fill(
                    $kendo-switch-off-thumb-hover-text,
                    $kendo-switch-off-thumb-hover-bg,
                    $kendo-switch-off-thumb-hover-border,
                    $kendo-switch-off-thumb-hover-gradient
                );
                box-shadow: $kendo-switch-off-thumb-shadow;
            }
        }

        // Focus
        &:focus,
        &.k-focus {
            .k-switch-track {
                @include fill(
                    $kendo-switch-off-track-focus-text,
                    $kendo-switch-off-track-focus-bg,
                    $kendo-switch-off-track-focus-border,
                    $kendo-switch-off-track-focus-gradient
                );
                box-shadow: $kendo-switch-off-track-focus-ring;
            }
        }

        &.k-readonly {
            .k-switch-track {
                background-color: #EFF3F3;
            }
        }

        // Disabled
        &:disabled,
        &.k-disabled {
            .k-switch-track {
                @include fill(
                    $kendo-switch-off-track-disabled-text,
                    $kendo-switch-off-track-disabled-bg,
                    $kendo-switch-off-track-disabled-border,
                    $kendo-switch-off-track-disabled-gradient
                );
            }
        }

        .k-switch-label-on {
            color: transparent;
        }
    }


    // Switch ON
    .k-switch-on {

        // Normal state
        .k-switch-track {
            @include fill(
                $kendo-switch-on-track-text,
                $kendo-switch-on-track-bg,
                $kendo-switch-on-track-border,
                $kendo-switch-on-track-gradient
            );
        }
        .k-switch-thumb {
            @include fill(
                $kendo-switch-on-thumb-text,
                $kendo-switch-on-thumb-bg,
                $kendo-switch-on-thumb-border,
                $kendo-switch-on-thumb-gradient
            );
            box-shadow: $kendo-switch-off-thumb-shadow;
        }

        // Hover
        &:hover,
        &.k-hover {
            .k-switch-track {
                @include fill(
                    $kendo-switch-on-track-hover-text,
                    $kendo-switch-on-track-hover-bg,
                    $kendo-switch-on-track-hover-border,
                    $kendo-switch-on-track-hover-gradient
                );
            }

            .k-switch-thumb {
                @include fill(
                    $kendo-switch-on-thumb-hover-text,
                    $kendo-switch-on-thumb-hover-bg,
                    $kendo-switch-on-thumb-hover-border,
                    $kendo-switch-on-thumb-hover-gradient
                );
                box-shadow: $kendo-switch-off-thumb-shadow;
            }
        }

        // Focus
        &:focus,
        &.k-focus {
            .k-switch-track {
                @include fill(
                    $kendo-switch-on-track-focus-text,
                    $kendo-switch-on-track-focus-bg,
                    $kendo-switch-on-track-focus-border,
                    $kendo-switch-on-track-focus-gradient
                );
                box-shadow: $kendo-switch-on-track-focus-ring;
            }
        }


        &.k-readonly {
            .k-switch-track {
                background-color: #9CD0E0;
                color: $base-text;
            }
        }
        
        // Disabled
        &:disabled,
        &.k-disabled {
            .k-switch-track {
                @include fill(
                    $kendo-switch-on-track-disabled-text,
                    $kendo-switch-on-track-disabled-bg,
                    $kendo-switch-on-track-disabled-border,
                    $kendo-switch-on-track-disabled-gradient
                );
            }
        }

        .k-switch-label-off {
            color: transparent;
        }
    }

    .k-switch.mri-k-show-validation {
        // warning
        &.k-warning {
            .k-switch-track {
                @include fill( $border: $warning-border );

                &:focus,
                &.k-focus {
                    @include box-shadow($focused-warning-shadow-outset);
                }
            }
            &.k-switch-on .k-switch-track {
                background-color: $warning;
            }
        }

        // Invalid
        &.k-invalid,
        &.ng-invalid.ng-touched,
        &.ng-invalid.ng-dirty {
            .k-switch-track {
                @include fill( $border: $invalid-border );

                &:focus,
                &.k-focus {
                    @include box-shadow($focused-invalid-shadow-outset);
                }
            }
            &.k-switch-on .k-switch-track {
                background-color: $error;
            }
        }

        &.k-success, &.k-valid:not(.ng-pristine),
        &.ng-valid.ng-touched:not(.ng-pristine),
        &.ng-valid.ng-dirty:not(.ng-pristine) {
            .k-switch-track{
                &:focus,
                &.k-focus {
                    @include box-shadow($focused-success-shadow-outset);
                }
            }
            &.k-switch-on .k-switch-track {
                background-color: $success;
            }
        }
    }
}
