@include exports("timepicker/layout") {

    // Time picker
    .k-timepicker {
        .k-input-button .k-icon { 
            &::before {
                width: $kendo-input-font-size-md;
                height: $kendo-input-font-size-md;
                mask: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.8334 6.99984C12.8334 10.2215 10.2217 12.8332 7.00008 12.8332C3.77842 12.8332 1.16675 10.2215 1.16675 6.99984C1.16675 3.77818 3.77842 1.1665 7.00008 1.1665C10.2217 1.1665 12.8334 3.77818 12.8334 6.99984ZM2.33341 6.99984C2.33341 9.57717 4.42275 11.6665 7.00008 11.6665C9.57741 11.6665 11.6667 9.57717 11.6667 6.99984C11.6667 4.42251 9.57741 2.33317 7.00008 2.33317C4.42275 2.33317 2.33341 4.42251 2.33341 6.99984ZM9.33342 7.2915C9.33342 7.45192 9.20217 7.58317 9.04175 7.58317H7.29175H6.70842C6.548 7.58317 6.41675 7.45192 6.41675 7.2915V6.70817V3.7915C6.41675 3.63109 6.548 3.49984 6.70842 3.49984H7.29175C7.45217 3.49984 7.58342 3.63109 7.58342 3.7915V6.4165H9.04175C9.20217 6.4165 9.33342 6.54775 9.33342 6.70817V7.2915Z' fill='black'/%3E%3C/svg%3E%0A");

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    /* IE10+ CSS styles go here */
                    mask: none;
                    background: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.8334 6.99984C12.8334 10.2215 10.2217 12.8332 7.00008 12.8332C3.77842 12.8332 1.16675 10.2215 1.16675 6.99984C1.16675 3.77818 3.77842 1.1665 7.00008 1.1665C10.2217 1.1665 12.8334 3.77818 12.8334 6.99984ZM2.33341 6.99984C2.33341 9.57717 4.42275 11.6665 7.00008 11.6665C9.57741 11.6665 11.6667 9.57717 11.6667 6.99984C11.6667 4.42251 9.57741 2.33317 7.00008 2.33317C4.42275 2.33317 2.33341 4.42251 2.33341 6.99984ZM9.33342 7.2915C9.33342 7.45192 9.20217 7.58317 9.04175 7.58317H7.29175H6.70842C6.548 7.58317 6.41675 7.45192 6.41675 7.2915V6.70817V3.7915C6.41675 3.63109 6.548 3.49984 6.70842 3.49984H7.29175C7.45217 3.49984 7.58342 3.63109 7.58342 3.7915V6.4165H9.04175C9.20217 6.4165 9.33342 6.54775 9.33342 6.70817V7.2915Z' fill='black'/%3E%3C/svg%3E%0A");
                }
            }
        }
    }

}
