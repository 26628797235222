// Tabstrip
$tabstrip-wrapper-padding-x: 0px !default;
$tabstrip-wrapper-padding-y: 0px !default;
$tabstrip-wrapper-border-width: 0px !default;

$tabstrip-font-family: $font-family !default;
$tabstrip-font-size: $font-size !default;
$tabstrip-line-height: $line-height !default;
$tabstrip-border-width: 1px !default;

$tabstrip-wrapper-bg: null !default;
$tabstrip-wrapper-text: null !default;
$tabstrip-wrapper-border: null !default;

/// Background color of tabstrip component
/// @group tabstrip
$tabstrip-bg: #C6CED5 !default;
$tabstrip-bg-txp: #C6CED5CC !default;
/// Text color of tabstrip component
/// @group tabstrip
$tabstrip-text: $component-text !default;
/// Border color of tabstrip component
/// @group tabstrip
$tabstrip-border: null !default;

/// Horizontal padding of tabs
/// @group tabstrip
$tabstrip-item-padding-x: $nav-item-padding-x * 2 !default;
/// Vertical padding of tabs
/// @group tabstrip
$tabstrip-item-padding-y: $nav-item-padding-y !default;
/// Width of border around tabs
/// @group tabstrip
$tabstrip-item-border-width: 0 !default;
/// Border radius of tabs
/// @group tabstrip
$tabstrip-item-border-radius: $border-radius !default;
/// Spacing between tabs
/// @group tabstrip
$tabstrip-item-gap: 2px !default;

/// Background color of tabs
/// @group tabstrip
$tabstrip-item-bg: transparent !default;
/// Text color of tabs
/// @group tabstrip
$tabstrip-item-text: $tabstrip-text !default;
/// Border color of tabs
/// @group tabstrip
$tabstrip-item-border: null !default;
/// Background gradient of tabs
/// @group tabstrip
$tabstrip-item-gradient: null !default;

/// Background color of hovered tabs
/// @group tabstrip
$tabstrip-item-hovered-bg: rgba(255,255,255,.5) !default;
/// Text color of hovered tabs
/// @group tabstrip
$tabstrip-item-hovered-text: $tabstrip-text !default;
/// Border color of hovered tabs
/// @group tabstrip
$tabstrip-item-hovered-border: null !default;
/// Background gradient of hovered tabs
/// @group tabstrip
$tabstrip-item-hovered-gradient: null !default;

/// Background color of selected tabs
/// @group tabstrip
$tabstrip-item-selected-bg: $body-bg !default;
/// Text color of selected tabs
/// @group tabstrip
$tabstrip-item-selected-text: $component-text !default;
/// Border color of selected tabs
/// @group tabstrip
$tabstrip-item-selected-border: $component-border !default;
/// Background gradient of selected tabs
/// @group tabstrip
$tabstrip-item-selected-gradient: null !default;

$tabstrip-item-focused-shadow: null !default; //$focused-shadow-inset !default;

$tabstrip-item-dragging-shadow: 0px 3px 4px rgba(0, 0, 0, .15) !default;

$tabstrip-indicator-size: null !default;
$tabstrip-indicator-color: null !default;

/// Horizontal padding of tabstrip content
/// @group tabstrip
$tabstrip-content-padding-x: 0 !default;
/// Vertical padding of tabstrip content
/// @group tabstrip
$tabstrip-content-padding-y: 0 !default;
/// Width of border around tabstrip content
/// @group tabstrip
$tabstrip-content-border-width: 0 !default;

/// Background color of tabstrip content
/// @group tabstrip
$tabstrip-content-bg: $component-bg !default;
/// Text color of tabstrip content
/// @group tabstrip
$tabstrip-content-text: $component-text !default;
/// Border color of tabstrip content
/// @group tabstrip
$tabstrip-content-border: $component-border !default;
/// Border color of tabstrip focused content
/// @group tabstrip
$tabstrip-content-border-focused: $component-text !default;
$tabstrip-item-disabled-text: mix($tabstrip-item-text, #ffffff, 50%) !default;