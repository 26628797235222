// Disabled
@mixin disabled( $disabled-styling ) {
    outline: none;
    cursor: default;
    opacity: map-get($disabled-styling, opacity);
    filter: map-get($disabled-styling, filter);
    pointer-events: none;
    box-shadow: none;
}

@mixin disabled-legacy-ie( $disabled-styling ) {
    outline: none;
    cursor: default;
    opacity: map-get($disabled-styling, opacity);
    box-shadow: none;
}
