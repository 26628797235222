// Grid
$grid-border-width: 0px !default;
$grid-font-family: $font-family !default;

$grid-outer-column-padding-x: 16px !default;

$grid-font-size: $font-size !default;
$grid-line-height: 24px !default;

$grid-input-line-height: $line-height-md !default;

$grid-padding-x: $table-cell-padding-x !default;
$grid-padding-y: $table-cell-padding-y !default;

$grid-cell-padding-x: $grid-padding-x !default;
$grid-cell-padding-y: $grid-padding-y !default;

$grid-header-padding-x: $grid-padding-x !default;
$grid-header-padding-y: $grid-padding-y !default;
$grid-header-font-size: null !default;

$grid-footer-padding-x: $grid-header-padding-x !default;
$grid-footer-padding-y: $grid-header-padding-y !default;

$grid-grouping-header-padding-x: $grid-outer-column-padding-x !default;
$grid-grouping-header-padding-y: $grid-padding-y !default;

$grid-filter-cell-padding-x: $grid-padding-x !default;
$grid-filter-cell-padding-y: ($grid-padding-y * 0.5) !default;

$grid-toolbar-padding-x: $grid-outer-column-padding-x !default;
$grid-toolbar-padding-y: $grid-padding-y !default;
$grid-toolbar-spacing: ($grid-toolbar-padding-y * 0.5) !default;
$grid-toolbar-button-spacing: ($grid-toolbar-padding-y * 0.5) !default;
$grid-toolbar-border-width: $grid-border-width !default;

$grid-cell-horizontal-border-width: 1px !default;
$grid-cell-vertical-border-width: 0 !default;

$grid-cell-placeholder-line-width: 80% !default;

$mri-kendo-grid-sizes: (
    x-small: (
        cell-padding-x: $grid-cell-padding-x,
        cell-padding-y: $grid-cell-padding-y * 0.5,
        header-padding-x: $grid-header-padding-x,
        header-padding-y: $grid-header-padding-y * 0.5,
        footer-padding-x: $grid-footer-padding-x,
        footer-padding-y: $grid-footer-padding-y * 0.5,
        font-size: 10px,
        filter-font-size: 10px,
        line-height: 16px,
        filter-line-height: 14px,
        input-padding-x: $grid-cell-padding-x,
        input-padding-y: $grid-cell-padding-y * 0.5,
        input-line-height: 1,
        input-icon-offset-y: 6px,
        input-button-offset-x: 1px,
        sort-inidicator-offset-y: 2px,
        menu-icon-size: 12px,
        filter-icon-size: 10px,
        cell-height: 24px,
        checkbox-size: map-get( $spacing, 4 ),
        checkbox-glyph-size: ( map-get( $spacing, 4 ) - map-get( $spacing, thin ) ),
        checkbox-background-size: map-get( $spacing, 2 ),
        checkbox-offset-y: 0px,
        checkbox-margin-y: 4px,
        button-padding-x: 8px,
        button-padding-y: 2px,
        switch-font-size: 10px, 
        switch-track-width: 52px, 
        switch-track-height: 16px, 
        switch-thumb-size: 12px, 
        switch-thumb-offset: 2px, 
        switch-label-offset: 4px,
        multiselect-button-offset-y: 1px,
        multiselect-icon-offset-y: 6px,
        toolbar-padding-x: 16px,
        toolbar-padding-y: 4px,
        toolbar-button-font-size: 10px,
        toolbar-button-padding-x: 8px,
        toolbar-button-padding-y: 2px,
        toolbar-button-line-height: $line-height-sm,
        toolbar-button-icon-font-size: 10px,
        filterable-icon-spacing: 32px,
    ),
    small: (
        cell-padding-x: $grid-cell-padding-x,
        cell-padding-y: $grid-cell-padding-y * 0.5,
        header-padding-x: $grid-header-padding-x,
        header-padding-y: $grid-header-padding-y * 0.5,
        footer-padding-x: $grid-footer-padding-x,
        footer-padding-y: $grid-footer-padding-y * 0.5,
        font-size: 12px,
        filter-font-size: 12px,
        line-height: $grid-line-height,
        filter-line-height: 16px,
        input-padding-x: $grid-cell-padding-x,
        input-padding-y: $grid-cell-padding-y * 0.5,
        input-line-height: $grid-input-line-height,
        input-icon-offset-y: 9px,
        input-button-offset-x: 0px,
        sort-inidicator-offset-y: 1px,
        menu-icon-size: 14px,
        filter-icon-size: 12px,
        cell-height: 32px,
        checkbox-size: map-get( $spacing, 4 ),
        checkbox-glyph-size: ( map-get( $spacing, 4 ) - map-get( $spacing, thin ) ),
        checkbox-background-size: map-get( $spacing, 2 ),
        checkbox-offset-y: 4px,
        checkbox-margin-y: 8px,
        button-padding-x: 8px,
        button-padding-y: 5px,
        switch-font-size: 10px, 
        switch-track-width: 60px, 
        switch-track-height: 22px, 
        switch-thumb-size: 16px, 
        switch-thumb-offset: 2px, 
        switch-label-offset: 6px,
        multiselect-button-offset-y: 5px,
        multiselect-icon-offset-y: 9px,
        toolbar-padding-x: 16px,
        toolbar-padding-y: 4px,
        toolbar-button-font-size: 12px,
        toolbar-button-padding-x: 8px,
        toolbar-button-padding-y: 2px,
        toolbar-button-line-height: $line-height-sm,
        toolbar-button-icon-font-size: $font-size-md,
        filterable-icon-spacing: 38px,
    ),
    normal: (
        cell-padding-x: $grid-cell-padding-x,
        cell-padding-y: $grid-cell-padding-y,
        header-padding-x: $grid-header-padding-x,
        header-padding-y: $grid-header-padding-y,
        footer-padding-x: $grid-footer-padding-x,
        footer-padding-y: $grid-footer-padding-y,
        font-size: $grid-font-size,
        filter-font-size: 12px,
        line-height: $grid-line-height,
        filter-line-height: 16px,
        input-padding-x: $grid-cell-padding-x,
        input-padding-y: $grid-cell-padding-y,
        input-line-height: $grid-input-line-height,
        input-icon-offset-y: 11px,
        input-button-offset-x: 0px,
        sort-inidicator-offset-y: 1px,
        menu-icon-size: 14px,
        filter-icon-size: 12px,
        cell-height: 40px,
        checkbox-size: map-get( $spacing, 6 ),
        checkbox-glyph-size: ( map-get( $spacing, 6 ) - map-get( $spacing, thin ) ),
        checkbox-background-size: map-get( $spacing, 4 ) - 2,
        checkbox-offset-y: 0px,
        checkbox-margin-y: 8px,
        button-padding-x: 8px,
        button-padding-y: 4px,
        switch-font-size: 10px, 
        switch-track-width: 60px, 
        switch-track-height: 24px, 
        switch-thumb-size: 18px, 
        switch-thumb-offset: 2px, 
        switch-label-offset: 6px,
        multiselect-button-offset-y: 9px,
        multiselect-icon-offset-y: 12px,
        toolbar-padding-x: 16px,
        toolbar-padding-y: 8px,
        toolbar-button-font-size: 14px,
        toolbar-button-padding-x: 16px,
        toolbar-button-padding-y: 4px,
        toolbar-button-line-height: $line-height-md,
        toolbar-button-icon-font-size: $font-size-mdx,
        filterable-icon-spacing: 40px,
    )
) !default;


/// Background color of the grid component
/// @group grid
$grid-bg: $component-bg !default;
/// Text color of the grid component
/// @group grid
$grid-text: $component-text !default;
/// Border color of the grid component
/// @group grid
$grid-border: #c2cfd3 !default; //$component-border !default;

/// Background color of grid header
/// @group grid
$grid-header-bg: #dfe7e8 !default; //$header-bg !default;
/// Text color of grid header
/// @group grid
$grid-header-text: $header-text !default;
/// Border color of grid header
/// @group grid
$grid-header-border: transparent !default;
/// Background gradient of grid header
/// @group grid
$grid-header-gradient: $header-gradient !default;

/// Background color of grid footer
/// @group grid
$grid-footer-bg: $grid-header-bg !default;
/// Text color of grid footer
/// @group grid
$grid-footer-text: $grid-header-text !default;
/// Border color of grid footer
/// @group grid
$grid-footer-border: $grid-header-border !default;

/// Background color of alternating rows in grid
/// @group grid
$grid-alt-bg: rgba(140, 160, 172, 0.15) !default;
/// Text color of alternating rows in grid
/// @group grid
$grid-alt-text: null !default;
/// Border color of alternating rows in grid
/// @group grid
$grid-alt-border: null !default;

/// Background color of hovered rows in grid
/// @group grid
$grid-hovered-bg: $hovered-static-bg !default;
/// Text color of hovered rows in grid
/// @group grid
$grid-hovered-text: null !default;
/// Border color of hovered rows in grid
/// @group grid
$grid-hovered-border: null !default;

/// Background color of selected rows in grid
/// @group grid
$grid-selected-bg: rgba($selected-bg, .25) !default;
/// Text color of selected rows in grid
/// @group grid
$grid-selected-text: null !default;
/// Border color of selected rows in grid
/// @group grid
$grid-selected-border: null !default;

$grid-selected-alt-bg: shade($grid-selected-bg, .4) !default;
$grid-selected-hovered-bg: shade($grid-selected-bg, .7) !default;

$grid-grouping-row-bg: $grid-bg !default;
$grid-grouping-row-text: $grid-text !default;

$grid-sorted-bg: null !default;
$grid-sorting-indicator-text: $grid-text !default;
$grid-sorting-index-font-size: $font-size-xs !default;
$grid-sorting-index-height: $icon-size !default;
$grid-sorting-index-spacing-y: 0 !default;
$grid-sorting-index-spacing-x: 4px !default;


$grid-focused-shadow: $kendo-list-item-focus-shadow !default;

$grid-edit-cell-padding-x: 0px !default;
$grid-edit-cell-padding-y: 0px !default;

$grid-command-cell-button-spacing: map-get( $spacing, 2 ) !default;

$grid-sticky-bg: $component-bg !default;
$grid-sticky-text: $grid-text !default;
$grid-sticky-border: rgba( black, .3 ) !default;

$grid-sticky-alt-bg: shade($grid-bg, 3.5%) !default;

$grid-sticky-header-bg: $grid-header-bg !default;
$grid-sticky-header-text: $grid-header-text !default;
$grid-sticky-header-border: $grid-sticky-border !default;

$grid-sticky-footer-bg: $grid-header-bg !default;
$grid-sticky-footer-hovered-bg: $grid-hovered-bg !default;

$grid-sticky-selected-bg: mix($selected-bg, #ffffff, 25%) !default;
$grid-sticky-selected-alt-bg: shade($grid-sticky-selected-bg, .4) !default;

$grid-sticky-hovered-bg: darken($grid-bg, 8%) !default;
$grid-sticky-selected-hovered-bg: shade($grid-sticky-selected-bg, .7) !default;

$grid-column-menu-width: 230px !default;

$grid-filter-menu-check-all-border-bottom-width: 1px !default;

$grid-filter-menu-item-spacing-x: map-get( $spacing, 2 ) !default;
$grid-filter-menu-item-spacing-y: $grid-filter-menu-item-spacing-x !default;

$grid-column-menu-popup-padding-x: null !default;
$grid-column-menu-popup-padding-y: null !default;

$grid-column-menu-item-padding-x: $kendo-menu-popup-item-padding-x !default;
$grid-column-menu-item-padding-y: $kendo-menu-popup-item-padding-y !default;

$grid-column-menu-list-item-padding-x: $kendo-list-item-padding-x-md !default;
$grid-column-menu-list-item-padding-y: $kendo-list-item-padding-y-md !default;

$grid-column-menu-items-wrap-padding-x: 0 !default;
$grid-column-menu-items-wrap-padding-y: $padding-y-lg !default;

$grid-column-menu-filter-container-padding-x: $padding-x !default;
$grid-column-menu-filter-container-padding-y: $padding-y !default;

$grid-column-menu-group-header-padding-x: $kendo-menu-popup-item-padding-x-md !default;
$grid-column-menu-group-header-padding-y: $kendo-menu-popup-item-padding-y-md !default;

$grid-column-menu-group-header-font-size: $font-size-sm !default;
$grid-column-menu-group-header-line-height: $line-height !default;
$grid-column-menu-group-header-text-transform: uppercase !default;
$grid-column-menu-group-header-border-bottom-width: 1px !default;

$grid-column-menu-group-header-bg: null !default;
$grid-column-menu-group-header-text: $popup-text !default;
$grid-column-menu-group-header-border: $popup-border !default;

$grid-drag-cell-width: ($icon-size * 2.25) !default;
