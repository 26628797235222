@include exports( "checkbox/theme" ) {

    // Checkbox
    .k-checkbox {
        @include fill(
            $kendo-checkbox-text,
            $kendo-checkbox-bg,
            $kendo-checkbox-border
        );
    }


    // Hover state
    .k-checkbox:hover,
    .k-checkbox.k-hover {
        @include fill(
            $kendo-checkbox-hover-text,
            $kendo-checkbox-hover-bg,
            $kendo-checkbox-hover-border
        );
    }


    // Focus state
    .k-checkbox:focus,
    .k-checkbox.k-focus {
        @include fill( $border: $kendo-checkbox-focus-border );
        @include box-shadow( $kendo-checkbox-focus-shadow );
    }


    // Indeterminate
    .k-checkbox:indeterminate,
    .k-checkbox.k-indeterminate {
        @include fill(
            $kendo-checkbox-indeterminate-text,
            $kendo-checkbox-indeterminate-bg,
            $kendo-checkbox-indeterminate-border
        );
    }


    // Checked
    .k-checkbox:checked,
    .k-checkbox.k-checked {
        @include fill(
            $kendo-checkbox-checked-text,
            $kendo-checkbox-checked-bg,
            $kendo-checkbox-checked-border
        );
    }
    .k-checkbox:checked:focus,
    .k-checkbox.k-checked.k-focus {
        @include fill( $border: $kendo-checkbox-focus-checked-border );
        @include box-shadow( $kendo-checkbox-focus-checked-shadow );
    }

    // Disabled
    .k-checkbox:disabled,
    .k-checkbox.k-disabled {
        @include fill(
            $kendo-checkbox-disabled-text,
            $kendo-checkbox-disabled-bg,
            $kendo-checkbox-disabled-border
        );
    }
    .k-checkbox:checked:disabled,
    .k-checkbox:indeterminate:disabled,
    .k-checkbox.k-checked.k-disabled,
    .k-checkbox.k-indeterminate.k-disabled {
        @include fill(
            $kendo-checkbox-disabled-checked-text,
            $kendo-checkbox-disabled-checked-bg,
            $kendo-checkbox-disabled-checked-border
        );
    }

    //read-only
    .k-checkbox.k-readonly {
        @include fill(
            $kendo-input-readonly-text,
            $kendo-input-readonly-bg,
            $kendo-checkbox-border
        );
        opacity: 1;
        pointer-events: none;
    }

    // warning
    .k-checkbox.k-warning.mri-k-show-validation, .k-warning.mri-k-show-validation .k-checkbox {
        @include fill( $border: $warning-border );

        &:focus,
        &.k-focus {
            @include box-shadow($focused-warning-shadow-outset);
        }
    }


    // Invalid
    .k-checkbox.k-invalid.mri-k-show-validation, .k-invalid.mri-k-show-validation .k-checkbox {
        @include fill( $border: $kendo-checkbox-invalid-border );

        &:focus,
        &.k-focus {
            @include box-shadow($focused-invalid-shadow-outset);
        }
    }

    .k-checkbox.k-success.mri-k-show-validation, .k-success.mri-k-show-validation .k-checkbox {
        &:focus,
        &.k-focus {
            @include box-shadow($focused-success-shadow-outset);
        }
    }


    // Ripple
    .k-checkbox-wrap .k-ripple-blob {
        color: $kendo-checkbox-ripple-bg;
        opacity: $kendo-checkbox-ripple-opacity;
    }
    .k-ripple-container {
        .k-checkbox::after {
            background: $kendo-checkbox-ripple-bg;
            opacity: $kendo-checkbox-ripple-opacity;
        }
    }

}
