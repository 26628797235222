@include exports("stepper/layout") {

    // Base
    .k-stepper {
        margin: $stepper-margin-y $stepper-margin-x;
        padding: $stepper-padding-y $stepper-padding-x;
        border-width: $stepper-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-size: $stepper-font-size;
        line-height: $stepper-line-height;
        font-family: $stepper-font-family;
        display: block;
        position: relative;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;
        // TODO: remove those once k-widget styles are removed. Link: https://github.com/telerik/kendo-themes/issues/1359
        color: inherit;
        background: none;


        // Step list
        .k-step-list {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            z-index: 1;
        }


        // Step
        .k-step { }


        // Step link
        .k-step-link {
            outline: none;
            color: inherit;
            text-decoration: none;
            white-space: nowrap;
            display: flex;
            align-items: center;
            overflow: hidden;
        }

        // Step indicator
        .k-step-indicator {
            @include border-radius( $stepper-indicator-border-radius );
            margin: if($stepper-indicator-focus-offset > 0, $stepper-indicator-focus-offset, null);
            width: $stepper-indicator-width;
            height: $stepper-indicator-height;
            border-width: $stepper-indicator-border-width;
            border-style: solid;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: none;
            position: relative;
            z-index: 1;
            overflow: visible;
            transition-property: color, background-color, border-color;
            transition-duration: .4s;
            transition-timing-function: ease-in-out;

            &::after {
                @include border-radius( 100% );
                content: "";
                border-width: $stepper-indicator-focus-border-width;
                border-style: solid;
                border-color: inherit;
                pointer-events: none;
                display: none;
                position: absolute;
                top: calc( -1 * #{$stepper-indicator-focus-calc-offset} );
                right: calc( -1 * #{$stepper-indicator-focus-calc-offset} );
                bottom: calc( -1 * #{$stepper-indicator-focus-calc-offset} );
                left: calc( -1 * #{$stepper-indicator-focus-calc-offset} );
                z-index: 2;
            }
        }

        .k-step-focus,
        .k-step.k-focus,
        .k-step-link:focus {
            .k-step-indicator::after {
                display: block;
            }
        }


        // Step label
        .k-step-label {
            max-width: clamp(100%, 10em, 100%);
            display: inline-flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            z-index: 1;
        }
        .k-step-label:only-child {
            @include border-radius( ($stepper-indicator-height + 2 * $stepper-indicator-border-width) * 0.5 );
            padding: $stepper-label-padding-y $stepper-label-padding-x;
            border-width: 0;
        }
        .k-step-label .k-step-text {
            max-width: calc(10em - (#{$icon-size} + #{$icon-spacing}));
            flex-grow: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .k-step-label .k-icon { margin-left: $icon-spacing; }

        .k-step-current .k-step-label {
            font-weight: bold;
        }


        // Optional label
        .k-step-label-optional {
            flex-basis: 100%;
            font-size: $stepper-optional-label-font-size;
            font-style: $stepper-optional-label-font-style;
            opacity: $stepper-optional-label-opacity;
        }


        .k-step-disabled,
        .k-step.k-disabled {
            opacity: 1;
            pointer-events: none;

            .k-step-link { cursor: default; }

            .k-step-label-optional {
                color: inherit;
            }
        }


        // Progressbar
        .k-progressbar {
            pointer-events: none;
            z-index: 0;
            overflow: visible;
        }

        .k-progressbar-horizontal {
            grid-row: 1 / -1;
        }

        .k-progressbar-vertical {
            position: absolute;
        }
    }


    // Horizontal
    .k-step-list-horizontal {
        flex-direction: row;
        grid-row: 1;

        .k-step {
            flex: 1 0 auto;
            text-align: center;
        }

        .k-step-link {
            margin: auto;
            max-width: 10em;
            flex-direction: column;
        }

        // Label
        .k-step-label {
            text-align: center;
        }
        .k-step-indicator + .k-step-label {
            margin-top: if($stepper-indicator-focus-offset > 0, calc(8px - #{$stepper-indicator-focus-offset}), 8px);
        }

        // Progressbar
        & ~ .k-progressbar {
            width: 100%;
            height: $stepper-progressbar-size;
            top: calc(((#{$stepper-indicator-height} + 2 * #{$stepper-indicator-focus-size}) * 0.5) + #{$stepper-indicator-focus-size} * 0.5);
        }
    }

    // Vertical
    .k-step-list-vertical {
        flex-direction: column;

        .k-step {
            min-height: calc((#{$stepper-indicator-width} + 2 * #{$stepper-indicator-border-width} + 2 * #{$stepper-indicator-focus-size}) + 20px);
        }

        // Label
        .k-step-label {
            justify-content: flex-start;
        }
        .k-step-indicator + .k-step-label {
            margin-left: $stepper-label-margin-x;
        }


        // Progressbar
        & ~ .k-progressbar {
            width: $stepper-progressbar-size;
            height: 100%;
            min-height: 20px;
            left: calc((#{$stepper-indicator-width} + 2 * #{$stepper-indicator-border-width} + 2 * #{$stepper-indicator-focus-size}) * 0.5);
        }

        // Inline content
        .k-step-content {
            height: 0;
            overflow: hidden;
            transition: $stepper-content-transition-property $stepper-content-transition-duration $stepper-content-transition-timing-function;
        }

        .k-step-current .k-step-content {
            height: auto;
            overflow: visible;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: $stepper-indicator-width + 2 * $stepper-indicator-border-width;
            padding: $stepper-inline-content-padding-x $stepper-inline-content-padding-y;
        }
    }

    .mri-k-process-tabs-vertical {
        display: flex;
        flex-direction: row;
        .process-tab-content {
            width: 100%;
        }
    }

    .k-stepper.mri-k-process-tabs, .k-wizard .k-stepper {
        box-sizing: content-box;

        .k-progressbar {
            display: none;
        }
        .k-step-list {
            padding: 0;
            display: flex;
            flex-flow: row nowrap;
            flex-grow: 0;
            flex-shrink: 0;
            align-items: flex-start;
            border: none;
            margin: 0;
    
            .k-step {
                display: flex;
                max-width: none !important;
                transition: background-color 0.25s 0s cubic-bezier(0, 1, 1, 1);
                pointer-events: none;
                cursor: pointer;
                position: relative;
                padding: 8px 16px;
                margin: 0;
                            
                .k-step-link {
                    z-index: 2;
                    max-width: none;
                    padding: 0;
                    flex-flow: row nowrap;
                    align-items: center;
                    flex-shrink: 1;
                    flex-basis: auto;
                    min-width: -webkit-min-content;
                    min-width: -moz-min-content;
                    min-width: min-content;
                    white-space: nowrap;
                    }
        
                .k-step-indicator {
                    margin: 0;
                    width: 1.5em;
                    height: 1.5em;

                    .k-icon {
                        font-size: 1em;
                    }
                }

                .k-step-label-optional {
                    display: none;
                }
    
                // Label
                .k-step-label {
                    justify-content: flex-start;
                    margin: 0;
                    max-width: none;
                }

                .k-step-indicator + .k-step-label {
                    margin-left: $stepper-label-margin-x;
                }
                
                &.k-step-current .k-step-label {
                    font-weight: normal;
                }

                .k-step-label .k-step-text {
                    max-width: none;
                }

                &.k-step-done {
                    pointer-events: auto;

                    .k-step-indicator {
                        border-width: 0;
                        width: calc( 1.5em + (#{$stepper-indicator-border-width} * 2));
                        height: calc( 1.5em + (#{$stepper-indicator-border-width} * 2));

                        span {
                            display: none;
                        }
                    }
                }

                &:not(:first-child) {
                    padding-left: calc(16px + 1em);
                }
                  
                &:first-child {
                    padding-left: 16px;
                }
                  
                &::before, &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 50%;
                    left: 6px;
                    background-color: inherit;
                    z-index: 1;
                }
                
                &::before {
                transform: skew(30deg);
                top: 0;
                }
            
                &::after {
                transform: skew(-30deg);
                bottom: 1px;
                }
            }
        }

        &.mri-k-process-tabs-vertical {
            flex-direction: column;
        }

        .k-step-list.k-step-list-vertical {
            flex-flow: column;
            padding: 8px 0px 8px 8px;

            .k-step {
                padding: 4px 8px;
                flex: 0 1 auto;
                min-height: auto;
                width: stretch;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;

                &::before, &::after {
                    display: none;
                }
                &:first-child {
                    padding-left: 8px;
                }
            }
        }


        .k-step-focus,
        .k-step-link:focus {
            .k-step-indicator::after {
                display: none;
            }
        }

        &.k-validation {
            .k-step-label {
                padding-right: 24px;
            }

            .k-step-done .k-step-label {
                padding-right: 0;
            }
        }

        &:not(.k-validation) {
            .k-step-label .k-icon {
                display: none;
            }
        }

        &.k-required {
            .k-step {
                padding-right: 16px;
                //padding-right: 30px;
                //background-image: $required-icon;
                //background-size: 8px 8px;
                //background-repeat: no-repeat;
                //background-position: calc(100% - 11px) center;
            }

            .k-step.k-step-optional {
                padding-right: 16px;
                background-image: none;
            }
        }
    }
}


@include exports("stepper/layout/rtl") {

    .k-rtl .k-stepper,
    .k-stepper[dir="rtl"] {
        direction: rtl;

        .k-step-label .k-icon {
            margin-left: 0;
            margin-right: $icon-spacing;
        }

        // Horizontal
        .k-step-list-horizontal { }


        // Vertical
        .k-step-list-vertical {
            .k-step-indicator + .k-step-label {
                margin-left: 0;
                margin-right: $stepper-label-margin-x;
            }

            & ~ .k-progressbar {
                left: auto;
                right: calc((#{$stepper-indicator-width} + 2 * #{$stepper-indicator-border-width} + 2 * #{$stepper-indicator-focus-size}) * 0.5);
            }
        }
    }
}
