@mixin typography($font-size: null, $font-family: null, $line-height: null, $font-weight: null, $letter-spacing: null) {
    @if $font-size {
        font-size: $font-size;
    }
    @if $font-family {
        font-family: $font-family;
    }
    @if $line-height {
        line-height: $line-height;
    }
    @if $font-weight {
        font-weight: $font-weight;
    }
    @if $letter-spacing {
        letter-spacing: $letter-spacing;
    }
}
