@include exports("menu/layout") {

    // Menu bar
    .k-menu-bar {
        border-width: $menu-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-family: $menu-font-family;
        font-size: $menu-font-size;
        line-height: $menu-line-height;
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        position: relative;
        cursor: default;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        *,
        *::before,
        *::after {
            box-sizing: border-box;
        }
    }
    .k-menu { @extend .k-menu-bar !optional; }


    // Menu item
    .k-menu-item {
        border-width: 0;
        outline: 0;
        display: flex;
        flex-flow: column nowrap;
        flex: none;
        position: relative;
        user-select: none;
    }
    .k-menu-item-content {
        display: block;
        height: auto;
        overflow: visible;

        .k-actions {
            margin-top: 0;
        }
    }


    // Menu link
    .k-menu-link {
        padding: $menu-item-padding-y $menu-item-padding-x;
        outline: 0;
        color: inherit;
        display: flex;
        flex-flow: row nowrap;
        flex: 1 1 auto;
        gap: $menu-item-icon-spacing;
        align-items: center;
        position: relative;
        white-space: nowrap;
        cursor: pointer;
    }


    // Menu item text
    .k-menu-link-text {
        display: flex;
        flex-flow: row nowrap;
        flex: 1 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
    }


    // Expand arrow
    .k-menu-expand-arrow {
        margin-inline-end: -$icon-spacing;
        display: inline-flex;
        flex-flow: row wrap;
        align-items: center;
        flex: none;
        position: relative;
        .k-i-arrow-60-right::before { content: "\e005"; }
        .k-i-arrow-e::before { content: "\e005"; }
        &.k-i-caret-alt-right::before { content: "\e005"; }
    }


    // Orientation -- horizontal
    .k-menu-horizontal {
        flex-direction: row;

        > .k-menu-item + .k-menu-item {
            margin-left: $menu-item-spacing;
        }

        > .k-separator {
            margin: 0 $menu-separator-spacing;
            width: 0;
            height: auto;
            border-width: 0 0 0 1px;
            border-style: solid;
        }
    }


    // Orientation -- vertical
    .k-menu-vertical {
        flex-direction: column;

        > .k-menu-item + .k-menu-item {
            margin-top: $menu-item-spacing;
        }

        > .k-menu-item > .k-menu-link {
            padding: $kendo-menu-popup-item-padding-y $kendo-menu-popup-item-padding-x;
            padding-right: $kendo-menu-popup-item-padding-end;
        }

        > .k-menu-item > .k-menu-link > .k-menu-expand-arrow {
            margin-inline-start: $kendo-menu-popup-item-icon-margin-start;
            margin-inline-end: $kendo-menu-popup-item-icon-margin-end;
        }

        > .k-separator {
            margin: $menu-separator-spacing 0;
            height: 0;
            border-width: 1px 0 0;
            border-style: solid;
            border-color: $panel-border;
            display: block;
        }
    }


    // Menu popup
    .k-menu-popup {
        padding: $kendo-menu-popup-padding-y $kendo-menu-popup-padding-x;
        box-sizing: border-box;
        border-width: $kendo-menu-popup-border-width;
        border-style: solid;
        overflow: auto;
        max-height: 80vh;

        *,
        *::before,
        *::after {
            box-sizing: border-box;
        }
    }


    // Sub menu
    .k-menu-group {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-flow: column nowrap;
        position: absolute;

        .k-menu-item + .k-menu-item {
            margin-top: $kendo-menu-popup-item-spacing;
        }

        .k-separator {
            margin: $menu-separator-spacing 0;
            height: 0;
            border-width: 1px 0 0;
            border-style: solid;
            border-color: $component-border;
            display: block;
        }
    }
    .k-menu-popup .k-menu-group {
        position: relative;
    }


    // Sizes
    .k-menu-group-sm {
        font-size: $kendo-menu-popup-font-size-sm;
        line-height: $kendo-menu-popup-line-height-sm;

        // jquery popup overrides those
        .k-menu-item {
            font-size: $kendo-menu-popup-font-size-sm;
            line-height: $kendo-menu-popup-line-height-sm;
        }

        .k-menu-link {
            padding: $kendo-menu-popup-item-padding-y-sm $kendo-menu-popup-item-padding-x-sm;
            padding-inline-end: $kendo-menu-popup-item-padding-end-sm;
        }

        .k-menu-expand-arrow {
            margin-inline-start: $kendo-menu-popup-item-icon-margin-start-sm;
            margin-inline-end: $kendo-menu-popup-item-icon-margin-end-sm;
        }
    }
    .k-menu-group-md {
        font-size: $kendo-menu-popup-font-size-md;
        line-height: $kendo-menu-popup-line-height-md;

        // jquery popup overrides those
        .k-menu-item {
            font-size: $kendo-menu-popup-font-size-md;
            line-height: $kendo-menu-popup-line-height-md;
        }

        .k-menu-link {
            padding: $kendo-menu-popup-item-padding-y-md $kendo-menu-popup-item-padding-x-md;
            padding-inline-end: $kendo-menu-popup-item-padding-end-md;
        }

        .k-menu-expand-arrow {
            margin-inline-start: $kendo-menu-popup-item-icon-margin-start;
            margin-inline-end: $kendo-menu-popup-item-icon-margin-end;
        }
    }
    .k-menu-group-lg {
        font-size: $kendo-menu-popup-font-size-lg;
        line-height: $kendo-menu-popup-line-height-lg;

        // jquery popup overrides those
        .k-menu-item {
            font-size: $kendo-menu-popup-font-size-lg;
            line-height: $kendo-menu-popup-line-height-lg;
        }

        .k-menu-link {
            padding: $kendo-menu-popup-item-padding-y-lg $kendo-menu-popup-item-padding-x-lg;
            padding-inline-end: $kendo-menu-popup-item-padding-end-lg;
        }

        .k-menu-expand-arrow {
            margin-inline-start: $kendo-menu-popup-item-icon-margin-start-lg;
            margin-inline-end: $kendo-menu-popup-item-icon-margin-end-lg;
        }
    }


    // Context menu
    .k-popups-wrapper {
        position: relative;
        border: 0;
        margin: 0;
        padding: 0;
    }
    .k-context-menu {
        margin: 0;
        padding: $kendo-menu-popup-padding-y $kendo-menu-popup-padding-x;
        border-width: $kendo-menu-popup-border-width;
        border-style: solid;

        &.k-menu-horizontal {
            padding: $kendo-menu-popup-padding-x $kendo-menu-popup-padding-y;
        }
    }
    .k-animation-container .k-context-menu.k-menu-horizontal {
        // kendo-jquery adds `display: block` via js and we need to override it.
        display: flex !important; // sass-lint:disable-line no-important
        flex-wrap: nowrap;
    }
    .k-context-menu-popup {
        z-index: 12000;

        .k-context-menu {
            border-width: 0;
        }
    }
    .k-popup .k-context-menu,
    .k-context-menu-popup .k-context-menu {
        border-width: 0;
    }


    // Scrolling
    .k-menu-scroll-wrapper {
        margin: 0;
        padding: 0;
        border: 0;
        position: relative;

        .k-menu {
            overflow: hidden;
            flex-wrap: nowrap;
        }

        .k-menu-scroll-button {
            @include border-radius( 0 );
            padding: 0;
            border-width: 0;
            border-color: inherit;
            color: inherit;
            background: inherit;
            position: absolute;

            &.k-scroll-left {
                top: 0;
                left: 0;
                height: 100%;
                width: 16px;
                border-right-width: 1px;
            }
            &.k-scroll-right {
                top: 0;
                right: 0;
                height: 100%;
                width: 16px;
                border-left-width: 1px;
            }
            &.k-scroll-up {
                top: 0;
                left: 0;
                width: 100%;
                height: 16px;
                border-bottom-width: 1px;
            }
            &.k-scroll-down {
                bottom: 0;
                left: 0;
                width: 100%;
                height: 16px;
                border-top-width: 1px;
            }
        }
    }


    // RTL
    .k-rtl,
    [dir="rtl"] {

        // Orientation -- horizontal
        .k-menu-horizontal {

            > .k-menu-item + .k-menu-item {
                margin-right: $menu-item-spacing;
                margin-left: 0;
            }
        }


        // Orientation -- vertical
        .k-menu-vertical {

            > .k-menu-item > .k-menu-link {
                padding-right: $kendo-menu-popup-item-padding-x;
                padding-left: $kendo-menu-popup-item-padding-end;
            }
        }


        // Sub menu
        .k-menu-group {

            .k-menu-link {
                padding-right: $kendo-menu-popup-item-padding-x;
                padding-left: $kendo-menu-popup-item-padding-end;
            }

        }

    }

}
