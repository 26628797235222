// Message box
$message-box-margin: 0 0 $spacer 0 !default;
$message-box-padding-x: $padding-x !default;
$message-box-padding-y: $padding-y !default;
$message-box-border-width: 0px !default;
$message-box-shadow: 0 0 1px rgba(0, 0, 0, 0.5), 0 1px 2px rgba(0, 0, 0, 0.5) !default;
$message-box-font-size: $font-size !default;
$message-box-line-height: $line-height !default;

$message-box-bg-level: -11 !default;
$message-box-text-level: 1 !default;
$message-box-border-level: 1 !default;

$message-box-link-font-style: none !default;
$message-box-link-text-decoration: underline !default;

$message-box-bg: $component-bg !default;
$message-box-text: $component-text !default;
$message-box-border: $component-border !default;


$message-box-info-bg: $info-bg !default;
$message-box-info-text: $info-txt !default;
$message-box-info-border: $info !default;
$message-box-success-bg: $success-bg !default;
$message-box-success-text: $success-txt !default;
$message-box-success-border: $success !default;
$message-box-warning-bg: $warning-bg !default;
$message-box-warning-text: $warning-txt !default;
$message-box-warning-border: $warning !default;
$message-box-error-bg: $error-bg !default;
$message-box-error-text: $error-txt !default;
$message-box-error-border: $error !default;

