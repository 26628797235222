@include exports( "chip/theme" ) {

    // Solid
    @each $name, $color in $kendo-chip-theme-colors {
        .k-chip-solid-#{$name} {

            @if ($name == "base") {
                @include fill(
                    $kendo-chip-solid-text,
                    $kendo-chip-solid-bg,
                    $kendo-chip-solid-border
                );

                .k-chip-action {
                    color: $text-color-muted;
                }

                &:focus,
                &.k-focus {
                    box-shadow: $focused-shadow-outset;
                }

                &:hover,
                &.k-hover {
                    @include fill( $bg: $kendo-chip-solid-hover-bg );
                }

                &.k-selected {
                    @include fill( $bg: $kendo-chip-solid-selected-bg );
                }
            } @else {
                @include fill(
                    $color,
                    transparentize( $color, 0.95),
                    transparentize( $color, 0.05)
                );

                &:focus,
                &.k-focus {
                    box-shadow: $focused-shadow-outset;
                }

                &:hover,
                &.k-hover {
                    background-color: transparentize( $color, 0.9);
                }

                &.k-selected {
                    background-color: transparentize( $color, 0.85);
                }
            }
        }
    }


    // Outline
    @each $name, $color in $kendo-chip-theme-colors {
        .k-chip-outline-#{$name} {
            @if ($name == "base") {
                color: $kendo-chip-outline-text;
                background-color: $kendo-chip-outline-bg;
                border-color: $kendo-chip-outline-border;
                box-shadow: $kendo-chip-outline-shadow;

                .k-chip-action {
                    color: $text-color-muted;
                }
        
                &:focus,
                &.k-focus {
                    box-shadow: $focused-shadow-outset;
                }

                &:hover,
                &.k-hover {
                    background-color: $kendo-chip-outline-hover-bg;
                }

                &.k-selected {
                    background-color: $kendo-chip-outline-selected-bg;
                }
            } @else {
                color: $color;
                background-color: $white;
                border-color: $kendo-chip-outline-border;
                box-shadow: $kendo-chip-outline-shadow;

                &:focus,
                &.k-focus {
                    box-shadow: $focused-shadow-outset;
                }

                &:hover,
                &.k-hover {
                    background-color: transparentize( $color, 0.95);
                }

                &.k-selected {
                    background-color: transparentize( $color, 0.9);
                }
            }
        }
    }

    .k-chip {
        .k-i-close-circle::before { content: "\e11b"; }
        .k-chip-action {
            border-color: transparent !important; // sass-lint:disable-line no-important
            background: none;
    
            // Hover state
            &:hover {
               background-color: $hovered-bg;
            }
    
            // Focused state
            &:focus {
                box-shadow: $focused-shadow-outset;
            }
    
            // Active state
            &:active {
                background-color: $pressed-bg;
                box-shadow: $focused-shadow-outset;
            }
        }
    }
}
