@include exports("pager/layout") {

    .k-pager-wrap,
    .k-pager {
        padding: $pager-padding-y $pager-padding-x;
        border-width: $pager-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-family: $pager-font-family;
        font-size: $pager-font-size;
        line-height: $pager-line-height;
        white-space: nowrap;
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
        cursor: default;
        flex: 0 0 auto;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        *,
        *::before,
        *::after {
            box-sizing: border-box;
        }

        .k-link {
            text-decoration: none;
            outline: 0;
            font: inherit;
            background-color: inherit;

            // Focused state
            &:focus,
            &.k-focus {
                z-index: 3;
            }
        }

        .k-rtl &,
        &[dir="rtl"],
        [dir="rtl"] & {
            .k-i-caret-alt-to-left,
            .k-i-caret-alt-to-right,
            .k-i-caret-alt-left,
            .k-i-caret-alt-right,
            .k-i-arrow-end-left,
            .k-i-arrow-60-left,
            .k-i-arrow-60-right,
            .k-i-arrow-end-right,
            .k-i-seek-w,
            .k-i-arrow-w,
            .k-i-arrow-e,
            .k-i-seek-e {
                transform: scaleX(-1);
            }
        }

    }


    // Base pager section
    %base-pager-section {
        @if ($pager-section-spacing) {
            margin-left: $pager-section-spacing;
            margin-right: $pager-section-spacing;
        }
        display: flex;
        flex-direction: row;
        align-items: center;
    
        &.k-label {
            .k-dropdownlist.k-picker {
                .k-input-inner, .k-input-button {
                    padding: $kendo-input-padding-y-sm $kendo-input-padding-x !important;
                    height: auto !important;
                    .k-button-icon {
                        height: 20px !important
                    }
                }
            }
        }
    }


    // Base pager item
    %base-pager-item {
        min-width: $kendo-button-calc-size;
        height: $kendo-button-calc-size;
        color: inherit;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &:hover {
            z-index: 2;
        }
        &.k-disabled {
            color: inherit;
        }
    }


    // Pager items
    .k-pager-nav {
        @extend %base-pager-item;
        @include border-radius( $pager-item-border-radius );
        padding: $pager-item-padding-y $pager-item-padding-x;
        border-width: $pager-item-border-width;
        border-style: solid;
    }


    // Pager numbers
    .k-pager-numbers-wrap {
        display: flex;
        flex-direction: row;
        position: relative;

        select.k-dropdown-list {
            cursor: pointer;
            display: none;
        }
    }
    .k-pager-numbers {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: row;

        li {
            display: inline-flex;
        }


        // Links
        .k-link {
            @extend %base-pager-item;
            @include border-radius( $pager-number-border-radius );
            padding: $pager-number-padding-y $pager-number-padding-x;
            border-width: $pager-number-border-width;
            border-style: solid;
        }

        // Selected state
        .k-selected {
            cursor: inherit;
            z-index: 2;
        }
    }


    // Spacing between items
    .k-pager-nav + .k-pager-nav,
    .k-pager-nav + .k-pager-numbers-wrap,
    .k-pager-numbers-wrap + .k-pager-nav,
    .k-pager-numbers > li + li {
        @if ($pager-item-spacing) {
            margin-left: $pager-item-spacing;
        }

        .k-rtl &,
        [dir="rtl"] & {
            @if ($pager-item-spacing) {
                margin-left: 0;
                margin-right: $pager-item-spacing;
            }
        }
    }


    // Pager input
    .k-pager-input {
        @extend %base-pager-section;

        .k-textbox {
            margin: 0 1ex;
            width: $pager-input-width;
        }
    }


    // Pager sizes
    .k-pager-sizes {
        @extend %base-pager-section;

        .k-dropdown-list,
        > select {
            margin-right: 1ex;
            width: $pager-dropdown-width;
        }

        .k-input-inner,
        .k-input-value-text {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .k-rtl &,
        [dir="rtl"] & {
            .k-dropdown-list,
            > select {
                margin-left: 1ex;
                margin-right: 0;
            }
        }
    }


    // Pager info
    .k-pager-info {
        @extend %base-pager-section;
        text-align: right;
        justify-content: flex-end;
        flex: 1 1 0%;
        order: 9;
    }


    // Pager refresh
    .k-pager-refresh {
        @extend %base-pager-item;
        margin-left: auto;
        order: 10;

        .k-rtl &,
        [dir="rtl"] & {
            margin-left: 0;
            margin-right: auto;
        }
    }

    // Responsive
    .k-pager-sm {
        overflow: visible;

        // Show native select
        .k-pager-numbers-wrap {
            select.k-dropdown-list {
                @extend .k-picker;
                width: $pager-dropdown-width;
                padding: 0 $kendo-input-padding-x;
                display: flex;
                align-items: center;
                background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.791 2.62516C12.6744 2.45016 12.4994 2.3335 12.266 2.3335H1.76602C1.56185 2.3335 1.35768 2.45016 1.24102 2.62516C1.12435 2.80016 1.15352 3.0335 1.27018 3.2085L6.52018 11.3752C6.63685 11.5502 6.81185 11.6377 7.01602 11.6377C7.22018 11.6377 7.39518 11.5502 7.51185 11.3752L12.7619 3.2085C12.8785 3.06266 12.8785 2.82933 12.791 2.62516Z' fill='black'/%3E%3C/svg%3E%0A");
                background-repeat:no-repeat;
                background-position: right 8px center;
            }
        }

        .k-pager-numbers-wrap {
            @if ($pager-section-spacing) {
                margin-left: $pager-section-spacing;
                margin-right: $pager-section-spacing;
            }

            height: $kendo-button-calc-size;
            border-color: inherit;
        }

        .k-rtl &,
        &[dir="rtl"],
        [dir="rtl"] & {
            .k-pager-numbers-wrap {
                @if ($pager-section-spacing) {
                    margin-left: $pager-section-spacing;
                    margin-right: $pager-section-spacing;
                }
            }
        }

    }

    // Hide components
    .k-pager-md .k-pager-info,
    .k-pager-sm .k-pager-info,
    .k-pager-sm .k-pager-sizes,
    .k-pager-sm .k-pager-numbers {
        display: none;
    }

}
