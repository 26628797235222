// Window
$window-border-width: 0px !default;
$window-border-radius: $kendo-border-radius-lg !default;
$window-font-family: $font-family !default;
$window-font-size: $font-size !default;
$window-line-height: $line-height !default;

$window-titlebar-padding-x: 16px !default;
$window-titlebar-padding-y: 16px !default;
$window-titlebar-border-width: 0 !default;
$window-titlebar-border-style: solid !default;

$window-title-font-size: $font-size-xl !default;
$window-title-line-height: $line-height !default;

$window-actions-gap: null !default;

$window-action-opacity: null !default;
$window-action-hover-opacity: null !default;

$window-inner-padding-x: 16px !default;
$window-inner-padding-y: 16px !default;

$window-buttongroup-padding-x: $actions-padding-x !default;
$window-buttongroup-padding-y: $actions-padding-y !default;
$window-buttongroup-border-width: 1px !default;

$window-bg: $bg-color !default;
$window-text: $component-text !default;
$window-border: rgba(0, 0, 0, .08) !default;

$window-titlebar-bg: $header-bg !default;
$window-titlebar-text: $header-text !default;
$window-titlebar-border: transparent !default;
$window-titlebar-gradient: null !default;

$window-shadow: 0 2px 8px rgba(0,0,0,.25) !default;
$window-focused-shadow: 0 2px 8px rgba(0,0,0,.5) !default;

$window-sizes: (
    sm: 300px,
    md: 800px,
    lg: 1200px
) !default;

$window-margin: 8px !default;
