@include exports("filter/layout") {

    .k-filter {
        box-sizing: border-box;
        border-width: 0;
        display: inline-block;
        background-color: transparent;
        padding: 0 16px;

        *,
        *::before,
        *::after {
            box-sizing: border-box;
        }

        ul {
            padding: 0;

            li {
                list-style-type: none;
            }
        }

        .k-filter-container,
        .k-filter-preview {
            margin-bottom: $filter-bottom-margin;
        }

        .k-filter-lines,
        .k-filter-item {
            padding-left: $filter-padding-x;
            margin-left: $filter-padding-x * 0.5;
        }

        .k-filter-toolbar {
            display: inline-flex;
            position: relative;
            padding: $filter-padding-y 0;

            &::before {
                content: "";
                position: absolute;
                width: $filter-padding-x;
                height: $filter-line-size;
                top: 50%;
                left: -$filter-padding-x;
            }

            .k-toolbar {
                border-style: solid;
                border-width: $filter-line-size;
                border-radius: 4px;
                padding: 0px 8px;
            }

            .k-picker-md {@extend .k-picker-sm;}
            .k-input-md {@extend .k-input-sm;}
            .k-button-md {@extend .k-button-sm;}
        }
        .k-filter-operator .k-dropdown-list {
            width: $filter-operator-dropdown-width;
        }

        .k-filter-item {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                width: $filter-line-size;
                height: 100%;
                top: -$filter-padding-y;
                left: 0;
            }
        }

        // The second selector targets the Angular rendering
        .k-filter-lines .k-filter-item:last-child > .k-filter-toolbar::after,
        .k-filter-lines .k-filter-item:last-child > * > .k-filter-toolbar::after {
            content: "";
            position: absolute;
            width: $filter-line-size;
            top: calc(-#{$filter-padding-y} - #{$filter-line-size});
            bottom: 50%;
            left: -$filter-padding-x;
        }

        // The forth and fifth selectors targets the Angular rendering
        .k-filter-group-main::before,
        .k-filter-group-main > .k-filter-toolbar::before,
        .k-filter-group-main > .k-filter-toolbar::after,
        .k-filter-group-main > * > .k-filter-toolbar::before,
        .k-filter-group-main > * > .k-filter-toolbar::after,
        .k-filter-lines .k-filter-item:last-child::before {
            display: none;
        }

        &+ * {
            margin-top: 16px;
        }

        .k-rtl &,
        &[dir="rtl"],
        [dir="rtl"] & {
            .k-filter-lines,
            .k-filter-item {
                padding-left: 0;
                padding-right: $filter-padding-x;
            }

            .k-filter-toolbar {

                &::before {
                    left: auto;
                    right: -$filter-padding-x;
                }
            }

            .k-filter-item {

                &::before {
                    left: auto;
                    right: 0;
                }
            }

            // The second selector targets the Angular rendering
            .k-filter-lines .k-filter-item:last-child > .k-filter-toolbar::after,
            .k-filter-lines .k-filter-item:last-child > * > .k-filter-toolbar::after {
                left: auto;
                right: -$filter-padding-x;
            }
        }
    }
}
