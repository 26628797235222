@include exports("wizard/layout") {
    .k-wizard {
        padding: $wizard-padding-y $wizard-padding-x;
        border-width: $wizard-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: none;
        font-size: $wizard-font-size;
        line-height: $wizard-line-height;
        font-family: $wizard-font-family;
        position: relative;
        color: inherit;
        background: none;
        display: flex;
        align-items: stretch;
        overflow: hidden;

        .k-wizard-steps {
            box-sizing: border-box;
            display: flex;
            overflow: hidden;
        }

        .k-wizard-step {
            box-sizing: border-box;
            padding: 0px; //calc((#{$wizard-content-padding-x} / 4) + 2px);
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: space-between;

            &:focus,
            &.k-focus {
                outline-width: 1px;
                outline-style: dotted;
                outline-offset: -1px;
            }
        }

        .k-wizard-content {
            box-sizing: border-box;
            overflow-y: auto;
            padding: $wizard-content-padding-y $wizard-content-padding-x;
        }

        .k-wizard-buttons {
            padding: $wizard-content-padding-y * 0.5 $wizard-content-padding-x;
            box-sizing: border-box;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            flex-shrink: 0;

            .k-button {
                margin-right: $wizard-buttons-margin-x;
            }

            .k-wizard-pager {
                vertical-align: middle;
            }
        }

        .k-wizard-buttons-right {
            .k-button:last-of-type {
                margin-right: 0;
            }
        }

        .k-stepper {
            .k-step-list-vertical {
                .k-step {
                    flex: 1 0 auto;
                }
            }

            .k-step-indicator {
                flex-shrink: 0;
            }
        }
    }

    .k-wizard-horizontal {
        flex-direction: column;

        .k-wizard-steps {
            margin-top: 0px;
        }

        .k-wizard-steps,
        .k-wizard-content {
            flex: 1 1 auto;
        }
    }

    .k-wizard-vertical {
        &.k-wizard-right {
            .k-stepper .k-step-list.k-step-list-vertical {
                padding: 8px 0px 8px 8px;
                .k-step {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
            }
            .k-wizard-steps {
                margin-left: 0px;
            }
        }

        &.k-wizard-left {
            .k-stepper .k-step-list.k-step-list-vertical {
                padding: 8px 8px 8px 0px;
                .k-step {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }
            .k-wizard-steps {
                margin-right: 0px;
            }
        }

        .k-stepper .k-step-list.k-step-list-vertical {
            flex-flow: column;

            .k-step {
                padding: 4px 8px;
                flex: 0 1 auto;
                min-height: auto;
                width: stretch;

                &::before, &::after {
                    display: none;
                }
                &:first-child {
                    padding-left: 8px;
                }
            }
        }

        .k-wizard-steps {
            flex: 1 0 0%;
            margin: 0px;
        }

        .k-wizard-content {
            flex: 1 1 0%;
        }

        .k-wizard-steps {
            margin-left: $wizard-content-padding-x;
        }
    }

    .k-wizard-left {
        flex-direction: row-reverse;

        .k-wizard-steps {
            margin-right: $wizard-content-padding-x;
            margin-left: 0;
        }
    }

}
