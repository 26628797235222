// Notification
$notification-padding-x: 16px !default;
$notification-padding-y: 16px !default;
$notification-border-width: 0px !default;
$notification-max-width: 480px !default;
$notification-border-radius: $kendo-border-radius-lg !default;

$notification-shadow: $popup-shadow !default;

$notification-font-family: $font-family !default;
$notification-font-size: $font-size-base !default;
$notification-line-height: $line-height !default;

$notification-icon-offset: null !default;
$notification-icon-spacing: $icon-spacing !default;

$notification-bg: $component-bg !default;
$notification-text: $component-text !default;
$notification-border: $component-border !default;

$notification-info-bg: $info-bg !default;
$notification-info-text: $info-txt !default;
$notification-info-border: $info !default;
$notification-success-bg: $success-bg !default;
$notification-success-text: $success-txt !default;
$notification-success-border: $success !default;
$notification-warning-bg: $warning-bg !default;
$notification-warning-text: $warning-txt !default;
$notification-warning-border: $warning !default;
$notification-error-bg: $error-bg !default;
$notification-error-text: $error-txt !default;
$notification-error-border: $error !default;


@function notification-theme( $colors ) {
    $_theme: ();

    // sass-lint:disable-block indentation
    @each $name, $color in $colors {
        $_theme: map-merge(( $name: (
            color: contrast-wcag( $color ),
            background-color: $color,
            border: $color,
        )), $_theme );
    }

    @return $_theme;
}

$kendo-notification-theme-colors: $kendo-theme-colors !default;
$kendo-notification-theme: notification-theme( $kendo-notification-theme-colors ) !default;
