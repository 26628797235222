// Pager
$pager-padding-x: map-get( $spacing, 2 ) !default;
$pager-padding-y: map-get( $spacing, 2 ) !default;
$pager-border-width: 1px !default;

$pager-font-family: $font-family !default;
$pager-font-size: $font-size !default;
$pager-line-height: $line-height !default;

$pager-bg: $header-bg !default;
$pager-text: $header-text !default;
$pager-border: $base-border !default;

$pager-focus-bg: null !default;
$pager-focus-shadow: $focused-shadow !default;

$pager-section-spacing: map-get( $spacing, 2 ) !default;

$pager-item-padding-x: $kendo-button-padding-y !default;
$pager-item-padding-y: $pager-item-padding-x !default;
$pager-item-border-width: 0px !default;
$pager-item-border-radius: 4px !default;
$pager-item-spacing: 4px !default;

$pager-item-bg: $kendo-button-bg !default;
$pager-item-text: $kendo-button-text !default;
$pager-item-border: null !default;

$pager-item-hover-bg: $kendo-button-hover-bg !default;
$pager-item-hover-text: $kendo-button-hover-text !default;
$pager-item-hover-border: null !default;

$pager-item-selected-bg: $kendo-button-active-bg !default;
$pager-item-selected-text: $kendo-button-active-text !default;
$pager-item-selected-border: null !default;

$pager-item-focus-opacity: null !default;
$pager-item-focus-bg: transparent !default;
$pager-item-focus-shadow: $kendo-button-focus-shadow !default;

$pager-number-padding-x: $kendo-input-padding-x !default;
$pager-number-padding-y: $kendo-input-padding-y !default;
$pager-number-border-width: $kendo-input-border-width !default;
$pager-number-border-radius: $pager-item-border-radius !default;
$pager-number-spacing: $pager-item-spacing !default;

$pager-number-bg: null !default;
$pager-number-text: $interactive !default;
$pager-number-border: transparent !default;

$pager-number-hover-opacity: null !default;
$pager-number-hover-bg: $hovered-bg !default;
$pager-number-hover-text: $pager-number-text !default;
$pager-number-hover-border: $pager-number-border !default;

$pager-number-selected-opacity: null !default;
$pager-number-selected-bg: $selected-bg !default;
$pager-number-selected-text: $selected-text !default;
$pager-number-selected-border: $pager-number-border !default;

$pager-number-focus-opacity: null !default;
$pager-number-focus-bg: transparent !default;
$pager-number-focus-shadow: $focused-shadow-outset !default;

$pager-input-width: 3em !default;
$pager-dropdown-width: 5em !default;
