@include exports("overlay/theme") {

    // Overlay
    .k-overlay {
        background-color: $overlay-bg;
    }

    .k-dialog .k-overlay {
        background-color: transparent;
    }

    @each $name, $color in $overlay-theme-colors {
        .k-overlay-#{$name} {
            background-color: $color;
        }
    }

}
