@include exports( "numerictextbox/theme" ) {

    // Numeric textbox
    .k-numeric-textbox.k-required, .k-numerictextbox.mri-k-show-validation, td.mri-k-show-validation > .k-numerictextbox {
        .k-input-inner {
            background-size: $kendo-input-font-size $kendo-input-font-size;
            background-repeat: no-repeat;
            background-position: calc(100% - #{$kendo-input-padding-x}) center;
        }
    }

    .k-numeric-textbox.k-required {
        .k-input-inner {
            padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
            background-image: $required-icon;
        }
    }

    .k-numerictextbox.mri-k-show-validation, td.mri-k-show-validation > .k-numerictextbox {
        // warning state
        &.k-warning .k-input-inner {
            padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
            background-image: $warning-icon;
        }

        // Invalid state
        &.k-invalid .k-input-inner,
        &.ng-invalid.ng-touched .k-input-inner,
        &.ng-invalid.ng-dirty .k-input-inner, 
        .k-invalid.k-input-inner {
            padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
            background-image: $invalid-icon;
        }

        // Valid state
        &.k-success .k-input-inner, 
        &.k-valid:not(.ng-pristine) .k-input-inner,
        &.ng-valid.ng-touched:not(.ng-pristine) .k-input-inner,
        &.ng-valid.ng-dirty:not(.ng-pristine) .k-input-inner,
        .k-valid.k-input-inner {
            padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
            background-image: $success-icon;
        }
    }

    .k-numerictextbox[readonly], .k-numerictextbox.k-readonly, .k-numerictextbox[disabled], .k-numerictextbox.k-disabled {
        .k-input-inner {
            padding-right: $kendo-input-padding-x;
            background-image: none;
        }
    }

    .k-numerictextbox.k-is-loading {
        position: relative;
        .k-input-inner {
            background-image: none;
            padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});;
        }

        .k-input-spinner {
            position: relative;
        }

        .k-input-spinner::before {
            position: absolute;
            top: 50%;
            left: -16px;
            display: inline-block;
            content: "";
            box-sizing: inherit;
            border-radius: 50%;
            border-width: 2px;
            border-style: solid;
            border-color: $loading-foreground-color;
            border-left-color: $loading-background-color;
            border-right-color: $loading-background-color;
            border-top-color: $loading-foreground-color;
            border-bottom-color: $loading-background-color;
            background-color: transparent;
            margin-top: -.5em;
            margin-left: -.5em;
            width: 1em;
            height: 1em;
            animation: k-loading-animation .5s linear infinite;
        }
        .k-input-spinner:empty::before {
            left: -8px;
        }
    }


    .align-right .k-numerictextbox,
    .align-right.k-numerictextbox {
        .k-numerictextbox[readonly], .k-numerictextbox.k-readonly, .k-numerictextbox[disabled], .k-numerictextbox.k-disabled {
            .k-input-inner {
                padding-right: $kendo-input-padding-x;
            }
        }

        &:not([readonly]):not(.k-readonly):not([disabled]):not(.k-disabled) {
            // required
            &.k-required .k-input-inner {
                padding-left: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});;
                padding-right: $kendo-input-padding-x;
                background-position: $kendo-input-padding-x center;
            }

            &.k-warning .k-input-inner {
                padding-left: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});;
                padding-right: $kendo-input-padding-x;
                background-position: $kendo-input-padding-x center;
            }
    
            // Invalid state
            &.k-invalid .k-input-inner,
            &.ng-invalid.ng-touched .k-input-inner,
            &.ng-invalid.ng-dirty .k-input-inner, 
            .k-invalid.k-input-inner {
                padding-left: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});;
                padding-right: $kendo-input-padding-x;
                background-position: $kendo-input-padding-x center;
            }
    
            // Valid state
            &.k-success .k-input-inner, 
            &.k-valid:not(.ng-pristine) .k-input-inner,
            &.ng-valid.ng-touched:not(.ng-pristine) .k-input-inner,
            &.ng-valid.ng-dirty:not(.ng-pristine) .k-input-inner,
            .k-valid.k-input-inner {
                padding-left: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});;
                padding-right: $kendo-input-padding-x;
                background-position: $kendo-input-padding-x center;
            }

            &.k-is-loading {
                position: relative;
                .k-input-inner {
                    padding-left: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});;
                    padding-right: $kendo-input-padding-x;
                }
        
                .k-input-spinner::before {
                    right: -16px;
                }
                .k-input-spinner:empty::before {
                    right: -8px;
                }
            }
        }
    }
}
