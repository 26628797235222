@include exports( "avatar/layout" ) {

    // Avatar
    .k-avatar {
        border-width: 0;
        border-style: solid;
        box-sizing: border-box;
        vertical-align: middle;
        display: inline-flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        overflow: hidden;

        *,
        *::before,
        *::after {
            box-sizing: border-box;
        }
    }


    // Avatar text
    .k-avatar-text,
    .k-avatar-initials {
        font-size: $kendo-avatar-font-size;
        font-family: $kendo-avatar-font-family;
        line-height: $kendo-avatar-line-height;
    }


    // Avatar icon
    .k-avatar-icon {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }


    // Avatar image
    .k-avatar-image {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            vertical-align: top;
        }
    }


    // Sizes
    @each $name, $size in $kendo-avatar-sizes {
        .k-avatar-#{$name} {
            width: $size;
            height: $size;
            flex-basis: $size;
        }
    }

    // Shapes
    .k-avatar-square {}

    // Bordered
    .k-avatar-bordered {
        border-width: $kendo-avatar-border-width;
    }


}

