@include exports("datepicker/layout") {

    // Date picker
    .k-datepicker {
        .k-input-button .k-icon { 
            &::before {
                width: $kendo-input-font-size-md;
                height: $kendo-input-font-size-md;
                mask: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.2501 1.1665H1.75008C1.42925 1.1665 1.16675 1.429 1.16675 1.74984V12.2498C1.16675 12.5707 1.42925 12.8332 1.75008 12.8332H12.2501C12.5709 12.8332 12.8334 12.5707 12.8334 12.2498V1.74984C12.8334 1.429 12.5709 1.1665 12.2501 1.1665ZM6.41675 5.83317V6.99984H4.95841V5.83317H6.41675ZM7.58342 5.83317H9.04175V6.99984H7.58342V5.83317ZM9.04175 8.1665V9.33317H7.58342V8.1665H9.04175ZM10.2084 8.1665H11.6667V9.33317H10.2084V8.1665ZM10.2084 6.99984V5.83317H11.6667V6.99984H10.2084ZM3.79175 5.83317V6.99984H2.33341V5.83317H3.79175ZM2.33341 8.1665H3.79175V9.33317H2.33341V8.1665ZM4.95841 8.1665H6.41675V9.33317H4.95841V8.1665ZM6.41675 10.4998V11.6665H4.95841V10.4998H6.41675ZM7.58342 10.4998H9.04175V11.6665H7.58342V10.4998ZM2.33341 10.4998H3.79175V11.6665H2.33341V10.4998Z' fill='black'/%3E%3C/svg%3E%0A");

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    /* IE10+ CSS styles go here */
                    mask: none;
                    background: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.2501 1.1665H1.75008C1.42925 1.1665 1.16675 1.429 1.16675 1.74984V12.2498C1.16675 12.5707 1.42925 12.8332 1.75008 12.8332H12.2501C12.5709 12.8332 12.8334 12.5707 12.8334 12.2498V1.74984C12.8334 1.429 12.5709 1.1665 12.2501 1.1665ZM6.41675 5.83317V6.99984H4.95841V5.83317H6.41675ZM7.58342 5.83317H9.04175V6.99984H7.58342V5.83317ZM9.04175 8.1665V9.33317H7.58342V8.1665H9.04175ZM10.2084 8.1665H11.6667V9.33317H10.2084V8.1665ZM10.2084 6.99984V5.83317H11.6667V6.99984H10.2084ZM3.79175 5.83317V6.99984H2.33341V5.83317H3.79175ZM2.33341 8.1665H3.79175V9.33317H2.33341V8.1665ZM4.95841 8.1665H6.41675V9.33317H4.95841V8.1665ZM6.41675 10.4998V11.6665H4.95841V10.4998H6.41675ZM7.58342 10.4998H9.04175V11.6665H7.58342V10.4998ZM2.33341 10.4998H3.79175V11.6665H2.33341V10.4998Z' fill='black'/%3E%3C/svg%3E%0A");
                }
            }
        }
    }
}
