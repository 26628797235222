@include exports("popup/layout") {

    .k-popup {
        @include border-radius( $popup-border-radius );
        margin: 0;
        padding: 0;
        border-width: $popup-border-width;
        border-style: solid;
        border-radius: $popup-border-radius;
        box-sizing: border-box;
        font-size: $popup-font-size;
        line-height: $popup-line-height;
        display: flex;
        flex-direction: column;
        align-items: stretch;

        .k-item {
            outline: none;
        }
    }

    .k-context-menu-popup > .k-popup {
        border-radius: 0;
    }

    .k-popup.k-list-container,
    .k-popup.k-colorpicker-popup {
        border-radius: 0;
    }

    .k-popup.k-menu-popup,
    .k-popup.k-context-menu,
    .k-popup.k-menu-group {
        border-radius: 0;
    }

    .k-animation-container {
        @include border-radius( $kendo-border-radius-md );
    }

    .k-animation-container-shown {
        overflow: visible;
    }

    .k-popup > .k-colorpalette {
        padding: $popup-content-padding-y $popup-content-padding-x;
    }

    // Transparent popup
    .k-popup.k-popup-transparent {
        border-width: 0;
        background-color: transparent;
    }

    // Flush popup
    .k-popup.k-popup-flush {
        padding: 0;
    }

    // Child components
    .k-popup > .k-widget,
    .k-popup > .k-coloreditor {
        border-width: 0;
    }

    .popup-content {
        padding: $padding-y $padding-x;
        max-width: $popup-max-width;
    }
}



@include exports( "common/decoration/shadow/legacy" ) {

    .k-shadow {
        box-shadow: $popup-shadow;
    }

}
