@include exports( "core/layout" ) {

    // Basic layout
    .k-hbox,
    .k-hstack {
        display: flex;
        flex-flow: row nowrap;
    }

    .k-vbox,
    .k-vstack {
        display: flex;
        flex-flow: column nowrap;
    }

    .k-flex,
    .k-spacer {
        flex: 1 0 0%;
    }


    // Stack layout
    .k-stack-layout {}


    // Flex layout
    .k-flex-layout {
        display: flex;
    }


    // Grid layout
    .k-grid-layout {
        display: grid;
    }


    // Separator
    .k-separator {
        width: auto;
        height: 0;
        border-width: 1px 0 0;
        border-style: solid;
        border-color: inherit;
        display: block;
        flex-grow: 0 !important; // sass-lint:disable-line no-important
        flex-shrink: 0 !important; // sass-lint:disable-line no-important
        align-self: stretch;
    }
    .k-separator-horizontal,
    .k-vstack > .k-separator {
        width: auto;
        height: 0;
        border-width: 1px 0 0;
    }
    .k-separator-vertical,
    .k-hstack > .k-separator {
        width: 0;
        height: auto;
        border-width: 0 0 0 1px;
    }

    hr.k-separator {
        margin: 0;
        padding: 0;
    }

}
