@include exports( "radio/theme" ) {

    // Radio
    .k-radio {
        @include fill(
            $kendo-radio-text,
            $kendo-radio-bg,
            $kendo-radio-border
        );
    }


    // Hover state
    .k-radio:hover,
    .k-radio.k-hover {
        @include fill(
            $kendo-radio-hover-text,
            $kendo-radio-hover-bg,
            $kendo-radio-hover-border
        );
    }


    // Focus state
    .k-radio:focus,
    .k-radio.k-focus {
        @include fill( $border: $kendo-radio-focus-border );
        @include box-shadow( $kendo-radio-focus-shadow );
    }


    // Disabled
    .k-radio:disabled,
    .k-radio.k-disabled {
        @include fill(
            $kendo-radio-disabled-text,
            $kendo-radio-disabled-bg,
            $kendo-radio-disabled-border
        );
    }
    .k-radio:checked:disabled,
    .k-radio.k-checked.k-disabled {
        @include fill(
            $kendo-radio-disabled-checked-text,
            $kendo-radio-disabled-checked-bg,
            $kendo-radio-disabled-checked-border
        );
    }

    //read-only
    .k-radio.k-readonly {
        @include fill(
            $kendo-input-readonly-text,
            $kendo-input-readonly-bg,
            $kendo-radio-border
        );
        opacity: 1;
        pointer-events: none;
    }
    
    // warning
    .k-radio.k-warning.mri-k-show-validation {
        @include fill( $border: $warning-border );

        &:focus,
        &.k-focus {
            @include box-shadow($focused-warning-shadow-outset);
        }
    }


    // Invalid
    .k-radio.k-invalid.mri-k-show-validation {
        @include fill( $border: $kendo-radio-invalid-border );

        &:focus,
        &.k-focus {
            @include box-shadow($focused-invalid-shadow-outset);
        }
    }

    .k-radio.k-success.mri-k-show-validation {
        &:focus,
        &.k-focus {
            @include box-shadow($focused-success-shadow-outset);
        }
    }


    // Ripple
    .k-ripple-container {
        .k-radio::after {
            background: $kendo-radio-checked-bg;
            opacity: $kendo-radio-ripple-opacity;
        }
    }

}
