@include exports("textbox/theme") {

    // Textbox
    .k-textbox.k-required, .k-textbox.mri-k-show-validation, td.mri-k-show-validation > .k-textbox {
        .k-input-inner {
            background-size: $kendo-input-font-size $kendo-input-font-size;
            background-repeat: no-repeat;
            background-position: calc(100% - #{$kendo-input-padding-x}) center;
        }
    }

    input.k-textbox.mri-k-show-validation, td.mri-k-show-validation > input.k-textbox {
        background-size: $kendo-input-font-size $kendo-input-font-size;
        background-repeat: no-repeat;
        background-position: calc(100% - #{$kendo-input-padding-x}) center;
    }

    .k-textbox.k-required {
        .k-input-inner {
            padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
            background-image: $required-icon;
        }
    }

    .k-textbox.mri-k-show-validation, td.mri-k-show-validation > .k-textbox {
        // warning
        &.k-warning {
            .k-input-inner {
                padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
                background-image: $warning-icon;
            }
        }
            
        // invalid
        &.k-invalid,
        &.ng-invalid.ng-touched,
        &.ng-invalid.ng-dirty {
            .k-input-inner {
                padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
                background-image: $invalid-icon;
            }

            &[showerroricon] {
                .k-input-inner {
                    padding-right: 8px !important;
                    background-image: none !important;
                }
            }
            
            span.k-text-error {
                background-image: $invalid-icon;
                background-size: 14px 14px;
                background-repeat: no-repeat;
                background-position: center;
                opacity: 1;
                &::before {
                    content: "";
                }
            }
        }

        // Valid
        &.k-success, &.k-valid:not(.ng-pristine),
        &.ng-valid.ng-touched:not(.ng-pristine),
        &.ng-valid.ng-dirty:not(.ng-pristine) {
            &:not([showsuccessicon]) {
                .k-input-inner {
                    padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
                    background-image: $success-icon;
                }

                .k-clear-value {
                    left: -24px;
                }
            }

            &[showsuccessicon] {
                .k-input-inner {
                    padding-right: 8px !important;
                    background-image: none !important;
                }
            }

            span.k-text-success {
                background-image: $success-icon;
                background-size: 14px 14px;
                background-repeat: no-repeat;
                background-position: center;
                opacity: 1;
                &::before {
                    content: "";
                }
            }
        }
    }

    input.k-textbox.mri-k-show-validation, td.mri-k-show-validation > input.k-textbox {
        // warning
        &.k-warning {
            padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
            background-image: $warning-icon;
        }
            
        // invalid
        &.k-invalid,
        &.ng-invalid.ng-touched,
        &.ng-invalid.ng-dirty {
            padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
            background-image: $invalid-icon;
        }

        // Valid
        &.k-success, &.k-valid:not(.ng-pristine),
        &.ng-valid.ng-touched:not(.ng-pristine),
        &.ng-valid.ng-dirty:not(.ng-pristine) {
            padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
            background-image: $success-icon;
        }
    }

    .k-textbox[readonly], .k-textbox.k-readonly, .k-textbox[disabled], .k-textbox.k-disabled {
        .k-input-inner {
            padding-right: $kendo-input-padding-x;
            background-image: none;
        }
    }


    .k-textbox.k-is-loading {
        position: relative;
        .k-input-inner {
            background-image: none;
            padding-right: calc((#{$kendo-input-padding-x} * 2) + #{$kendo-input-font-size});
        }

        .k-input-suffix {
            position: relative;
        }

        .k-input-suffix::before {
            position: absolute;
            top: 50%;
            left: -16px;
            display: inline-block;
            content: "";
            box-sizing: inherit;
            border-radius: 50%;
            border-width: 2px;
            border-style: solid;
            border-color: $loading-foreground-color;
            border-left-color: $loading-background-color;
            border-right-color: $loading-background-color;
            border-top-color: $loading-foreground-color;
            border-bottom-color: $loading-background-color;
            background-color: transparent;
            margin-top: -.5em;
            margin-left: -.5em;
            width: 1em;
            height: 1em;
            animation: k-loading-animation .5s linear infinite;
        }
        .k-input-suffix:empty::before {
            left: -16px;
        }
    }

    .k-input-wrap.k-is-loading {
        position: relative;
        width: 100%;
        font-size: $font-size-xs;
        display: inline-block;
        line-height: $kendo-input-line-height;
        &::after {
            position: absolute;
            top: 17px;
            right: 9px;
            display: inline-block;
            content: "";
            box-sizing: inherit;
            border-radius: 50%;
            border-width: 2px;
            border-style: solid;
            border-color: $loading-foreground-color;
            border-left-color: $loading-background-color;
            border-right-color: $loading-background-color;
            border-top-color: $loading-foreground-color;
            border-bottom-color: $loading-background-color;
            background-color: transparent;
            margin-top: -.5em;
            margin-left: -.5em;
            width: 1em;
            height: 1em;
            animation: k-loading-animation .5s linear infinite;
        }

        .k-textbox,
        .k-input.k-textbox {
            background-image: none;
            padding-right: 30px;
            position: relative;
        }
    }

    .k-textbox {
        .k-clear-value {
            position: relative;
        }
        &.mri-k-show-validation {
            .k-clear-value {
                right: -24px;
            }
        }
    }
}
