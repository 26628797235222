@include exports( "datetimepicker/layout" ) {
    .k-datetimepicker:not(span) {
        .k-input-button .k-icon { 
            &::before {
                width: $kendo-input-font-size-md;
                height: $kendo-input-font-size-md;
                mask: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6667 1.45817C11.6667 1.29709 11.5362 1.1665 11.3751 1.1665H1.45841C1.29733 1.1665 1.16675 1.29709 1.16675 1.45817V11.3748C1.16675 11.5359 1.29733 11.6665 1.45841 11.6665H5.54143C5.27735 11.3149 5.065 10.9223 4.91569 10.4998H4.66675V9.33317H4.67632C4.66997 9.23677 4.66675 9.13951 4.66675 9.0415C4.66675 8.74185 4.69687 8.44923 4.75426 8.1665H4.66675V6.99984H5.17136C5.58271 6.22165 6.22189 5.58247 7.00008 5.17111V4.6665H8.16675V4.75402C8.44947 4.69663 8.74209 4.6665 9.04175 4.6665C9.13976 4.6665 9.23701 4.66973 9.33342 4.67607V4.6665H10.5001V4.91545C10.9225 5.06476 11.3152 5.2771 11.6667 5.54118V1.45817ZM3.50008 8.1665H2.33341V6.99984H3.50008V8.1665ZM2.33341 9.33317H3.50008V10.4998H2.33341V9.33317ZM2.33341 5.83317V4.6665H3.50008V5.83317H2.33341ZM4.66675 4.6665H5.83342V5.83317H4.66675V4.6665Z' fill='black'/%3E%3Cpath d='M9.33342 6.99984C9.4945 6.99984 9.62508 7.13042 9.62508 7.2915V8.45817H10.2084C10.3695 8.45817 10.5001 8.58875 10.5001 8.74984V9.33317C10.5001 9.49425 10.3695 9.62484 10.2084 9.62484H8.75008C8.589 9.62484 8.45842 9.49425 8.45842 9.33317V7.2915C8.45842 7.13042 8.589 6.99984 8.75008 6.99984H9.33342Z' fill='black'/%3E%3Cpath d='M5.25008 9.0415C5.25008 6.94742 6.94767 5.24984 9.04175 5.24984C11.1358 5.24984 12.8334 6.94742 12.8334 9.0415C12.8334 11.1356 11.1358 12.8332 9.04175 12.8332C6.94767 12.8332 5.25008 11.1356 5.25008 9.0415ZM9.04175 6.4165C7.592 6.4165 6.41675 7.59176 6.41675 9.0415C6.41675 10.4913 7.592 11.6665 9.04175 11.6665C10.4915 11.6665 11.6667 10.4913 11.6667 9.0415C11.6667 7.59176 10.4915 6.4165 9.04175 6.4165Z' fill='black'/%3E%3C/svg%3E%0A");

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    /* IE10+ CSS styles go here */
                    mask: none;
                    background: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6667 1.45817C11.6667 1.29709 11.5362 1.1665 11.3751 1.1665H1.45841C1.29733 1.1665 1.16675 1.29709 1.16675 1.45817V11.3748C1.16675 11.5359 1.29733 11.6665 1.45841 11.6665H5.54143C5.27735 11.3149 5.065 10.9223 4.91569 10.4998H4.66675V9.33317H4.67632C4.66997 9.23677 4.66675 9.13951 4.66675 9.0415C4.66675 8.74185 4.69687 8.44923 4.75426 8.1665H4.66675V6.99984H5.17136C5.58271 6.22165 6.22189 5.58247 7.00008 5.17111V4.6665H8.16675V4.75402C8.44947 4.69663 8.74209 4.6665 9.04175 4.6665C9.13976 4.6665 9.23701 4.66973 9.33342 4.67607V4.6665H10.5001V4.91545C10.9225 5.06476 11.3152 5.2771 11.6667 5.54118V1.45817ZM3.50008 8.1665H2.33341V6.99984H3.50008V8.1665ZM2.33341 9.33317H3.50008V10.4998H2.33341V9.33317ZM2.33341 5.83317V4.6665H3.50008V5.83317H2.33341ZM4.66675 4.6665H5.83342V5.83317H4.66675V4.6665Z' fill='black'/%3E%3Cpath d='M9.33342 6.99984C9.4945 6.99984 9.62508 7.13042 9.62508 7.2915V8.45817H10.2084C10.3695 8.45817 10.5001 8.58875 10.5001 8.74984V9.33317C10.5001 9.49425 10.3695 9.62484 10.2084 9.62484H8.75008C8.589 9.62484 8.45842 9.49425 8.45842 9.33317V7.2915C8.45842 7.13042 8.589 6.99984 8.75008 6.99984H9.33342Z' fill='black'/%3E%3Cpath d='M5.25008 9.0415C5.25008 6.94742 6.94767 5.24984 9.04175 5.24984C11.1358 5.24984 12.8334 6.94742 12.8334 9.0415C12.8334 11.1356 11.1358 12.8332 9.04175 12.8332C6.94767 12.8332 5.25008 11.1356 5.25008 9.0415ZM9.04175 6.4165C7.592 6.4165 6.41675 7.59176 6.41675 9.0415C6.41675 10.4913 7.592 11.6665 9.04175 11.6665C10.4915 11.6665 11.6667 10.4913 11.6667 9.0415C11.6667 7.59176 10.4915 6.4165 9.04175 6.4165Z' fill='black'/%3E%3C/svg%3E%0A");
                }
            }
        }
    }

    span.k-datetimepicker {
        .k-input-button .k-icon.k-i-calendar { 
            &::before {
                width: $kendo-input-font-size-md;
                height: $kendo-input-font-size-md;
                mask: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.2501 1.1665H1.75008C1.42925 1.1665 1.16675 1.429 1.16675 1.74984V12.2498C1.16675 12.5707 1.42925 12.8332 1.75008 12.8332H12.2501C12.5709 12.8332 12.8334 12.5707 12.8334 12.2498V1.74984C12.8334 1.429 12.5709 1.1665 12.2501 1.1665ZM6.41675 5.83317V6.99984H4.95841V5.83317H6.41675ZM7.58342 5.83317H9.04175V6.99984H7.58342V5.83317ZM9.04175 8.1665V9.33317H7.58342V8.1665H9.04175ZM10.2084 8.1665H11.6667V9.33317H10.2084V8.1665ZM10.2084 6.99984V5.83317H11.6667V6.99984H10.2084ZM3.79175 5.83317V6.99984H2.33341V5.83317H3.79175ZM2.33341 8.1665H3.79175V9.33317H2.33341V8.1665ZM4.95841 8.1665H6.41675V9.33317H4.95841V8.1665ZM6.41675 10.4998V11.6665H4.95841V10.4998H6.41675ZM7.58342 10.4998H9.04175V11.6665H7.58342V10.4998ZM2.33341 10.4998H3.79175V11.6665H2.33341V10.4998Z' fill='black'/%3E%3C/svg%3E%0A");

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    /* IE10+ CSS styles go here */
                    mask: none;
                    background: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.2501 1.1665H1.75008C1.42925 1.1665 1.16675 1.429 1.16675 1.74984V12.2498C1.16675 12.5707 1.42925 12.8332 1.75008 12.8332H12.2501C12.5709 12.8332 12.8334 12.5707 12.8334 12.2498V1.74984C12.8334 1.429 12.5709 1.1665 12.2501 1.1665ZM6.41675 5.83317V6.99984H4.95841V5.83317H6.41675ZM7.58342 5.83317H9.04175V6.99984H7.58342V5.83317ZM9.04175 8.1665V9.33317H7.58342V8.1665H9.04175ZM10.2084 8.1665H11.6667V9.33317H10.2084V8.1665ZM10.2084 6.99984V5.83317H11.6667V6.99984H10.2084ZM3.79175 5.83317V6.99984H2.33341V5.83317H3.79175ZM2.33341 8.1665H3.79175V9.33317H2.33341V8.1665ZM4.95841 8.1665H6.41675V9.33317H4.95841V8.1665ZM6.41675 10.4998V11.6665H4.95841V10.4998H6.41675ZM7.58342 10.4998H9.04175V11.6665H7.58342V10.4998ZM2.33341 10.4998H3.79175V11.6665H2.33341V10.4998Z' fill='black'/%3E%3C/svg%3E%0A");
                }
            }
        }

        .k-input-button .k-icon.k-i-clock { 
            &::before {
                width: $kendo-input-font-size-md;
                height: $kendo-input-font-size-md;
                mask: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.8334 6.99984C12.8334 10.2215 10.2217 12.8332 7.00008 12.8332C3.77842 12.8332 1.16675 10.2215 1.16675 6.99984C1.16675 3.77818 3.77842 1.1665 7.00008 1.1665C10.2217 1.1665 12.8334 3.77818 12.8334 6.99984ZM2.33341 6.99984C2.33341 9.57717 4.42275 11.6665 7.00008 11.6665C9.57741 11.6665 11.6667 9.57717 11.6667 6.99984C11.6667 4.42251 9.57741 2.33317 7.00008 2.33317C4.42275 2.33317 2.33341 4.42251 2.33341 6.99984ZM9.33342 7.2915C9.33342 7.45192 9.20217 7.58317 9.04175 7.58317H7.29175H6.70842C6.548 7.58317 6.41675 7.45192 6.41675 7.2915V6.70817V3.7915C6.41675 3.63109 6.548 3.49984 6.70842 3.49984H7.29175C7.45217 3.49984 7.58342 3.63109 7.58342 3.7915V6.4165H9.04175C9.20217 6.4165 9.33342 6.54775 9.33342 6.70817V7.2915Z' fill='black'/%3E%3C/svg%3E%0A");

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    /* IE10+ CSS styles go here */
                    mask: none;
                    background: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.8334 6.99984C12.8334 10.2215 10.2217 12.8332 7.00008 12.8332C3.77842 12.8332 1.16675 10.2215 1.16675 6.99984C1.16675 3.77818 3.77842 1.1665 7.00008 1.1665C10.2217 1.1665 12.8334 3.77818 12.8334 6.99984ZM2.33341 6.99984C2.33341 9.57717 4.42275 11.6665 7.00008 11.6665C9.57741 11.6665 11.6667 9.57717 11.6667 6.99984C11.6667 4.42251 9.57741 2.33317 7.00008 2.33317C4.42275 2.33317 2.33341 4.42251 2.33341 6.99984ZM9.33342 7.2915C9.33342 7.45192 9.20217 7.58317 9.04175 7.58317H7.29175H6.70842C6.548 7.58317 6.41675 7.45192 6.41675 7.2915V6.70817V3.7915C6.41675 3.63109 6.548 3.49984 6.70842 3.49984H7.29175C7.45217 3.49984 7.58342 3.63109 7.58342 3.7915V6.4165H9.04175C9.20217 6.4165 9.33342 6.54775 9.33342 6.70817V7.2915Z' fill='black'/%3E%3C/svg%3E%0A");
                }
            }
        }
    }


    // Datetime container
    .k-datetime-container {}

    // Datetime selector
    .k-datetime-selector {
        display: flex;
        transition: transform .2s;
    }

    // Wrap
    .k-datetime-wrap {
        width: $datetime-width;
        overflow: hidden;

        .k-datetime-buttongroup {
            padding: $actions-padding-y $actions-padding-x;
        }

        .k-calendar,
        .k-timeselector {
            width: 100%;
            border-width: 0;
        }
    }

    // Inner wrap
    .k-datetime-calendar-wrap,
    .k-datetime-time-wrap {
        text-align: center;
        flex: 0 0 $datetime-width;
    }

    // Tabs
    .k-date-tab .k-datetime-selector {
        transform: translateX(0);
    }
    .k-time-tab .k-datetime-selector {
        transform: translateX(-100%);
    }

}

@include exports( "datetimepicker/layout/rtl" ) {

    .k-datetimepicker {
        &[dir="rtl"],
        .k-rtl & {
            .k-time-tab .k-datetime-selector {
                transform: translateX(100%);
            }
        }
    }

}
