@include exports( "textarea/layout" ) {

    // Textarea
    .k-textarea {
        .kendo-textarea-suffix {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            padding: 0;
        
            .k-button {
                border-radius: 0;
                padding: 0;
                width: $kendo-input-button-size;
                height: $kendo-input-button-size;
                border-width: 0;
                box-sizing: border-box;
                border-style: solid;
                display: flex;
                align-items: stretch;
                justify-content: center;
                flex: 0 0 auto;
                text-align: center;
                cursor: pointer;
            }
    
            > .k-icon {
                margin: 6px;
            }
        }

        &.\!k-flex-col {
            .k-input-suffix {
                flex-direction: row;
            }
        }
    
        &.\!k-flex-row {
            .k-input-suffix {
                flex-direction: column;
        
                & > *:first-child {
                    &.k-icon {
                        margin-top: 2px;
                    }
                }
                & > *:not(:first-child) {
                    height: if( $use-input-button-width, $kendo-input-button-size, auto );
                }
            }
        }
    }
}
