@include exports( "common/decoration/color" ) {

    // sass-lint:disable-block no-important
    // sass-lint:disable-block class-name-format
    // stylelint-disable block-opening-brace-space-before

    .k-text-base,
    .k-color-base {
        color: $base-text;
    }
    .\!k-text-base,
    .\!k-color-base {
        color: $base-text !important;
    }

    .k-color-inherit { color: inherit; }
    .\!k-color-inherit { color: inherit !important; }

    @each $name, $color in $kendo-theme-colors {
        .k-text-#{$name},
        .k-color-#{$name} {
            color: $color;
        }
        .\!k-text-#{$name},
        .\!k-color-#{$name} {
            color: $color !important;
        }
    }

}


@include exports( "common/decoration/background" ) {

    // sass-lint:disable-block no-important
    // sass-lint:disable-block class-name-format

    @each $name, $bg-color in $kendo-theme-colors {
        .k-bg-#{$name} {
            background-color: $bg-color;
        }
        .\!k-bg-#{$name} {
            background-color: $bg-color !important;
        }
    }

}
