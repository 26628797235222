@include exports( "popup/theme" ) {

    .k-popup {
        @include fill(
            $popup-text,
            $popup-bg,
            $popup-border
        );
        @include box-shadow( $popup-shadow );
    }
}
