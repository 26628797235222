$padding-x: 8px !default;
$padding-y: 8px !default;
$padding-x-lg: $padding-x * 2 !default;
$padding-y-lg: $padding-y * 2 !default;

$icon-spacing: $padding-x !default;

/// Border radius for all components.
$border-radius: 4px !default;
$kendo-border-radius-lg: 8px !default;


$nav-item-margin: $padding-x * 0.5 !default;
$nav-item-padding-x: $padding-x !default;
$nav-item-padding-y: $padding-y !default;

$header-cell-padding-x: $padding-x !default;
$cell-padding-x: $padding-x !default;

// Deprecated
$accent: #0D75B0 !default;
$primary: $accent !default;

$white: #ffffff !default;
$black: #000000 !default;
$bg-color: #ffffff !default;
$bg-color-light: #C2CFD3 !default;
$bg-color-lighter: #DFE7E8 !default;
$bg-color-lightest: #EFF3F3 !default;

/// The color of interactive elements.
/// Used for links and other interactive elements.
/// @group color-system
/// @type Color
$interactive: #10689d !default;
$interactive-lighter: #0582C3 !default;
$interactive-darker: #0d537e !default;

$loading-foreground-color: $interactive !default;
$loading-background-color: rgba(16,104,157,.25) !default;
$loading-foreground-color--dark: #9CD0E0 !default;
$loading-background-color--dark: rgba(156, 208, 224, 0.25) !default;

/// The color for informational messages and states.
/// @group color-system
/// @type Color
$info: #10689d !default;
$info-txt: #10689d !default;
$info-bg: #E6F4F5 !default;

/// The color for success messages and states.
/// @group color-system
/// @type Color
$success: #00775d !default;
$success-txt: #006A54 !default;
$success-bg: #E1F6F5 !default;

/// The color for warning messages and states.
/// @group color-system
/// @type Color
$warning: #D34501 !default;
$warning-txt: #B33706 !default;
$warning-bg: #FFE9C3 !default;

/// The color for error messages and states.
/// @group color-system
/// @type Color
$error: #BA2121 !default;
$error-txt: #BA2121 !default;
$error-bg: #FFE3E6 !default;

$text-color: #162029 !default;
$text-color-dark: #ffffff !default;
$text-color-muted: #607184 !default;

$heading-text: #044d66 !default;
$body-text: $text-color !default;
$component-text: $body-text !default;
$subtle-text: #394754 !default;
$disabled-text: #74889A !default;
$app-text: $body-text !default;


/// The background of the components' chrome area.
$base-bg: transparent !default; // $secondary
//$component-bg: $base-bg !default;

/// The text color of the components' chrome area.
$base-text: $text-color !default;
/// The gradient background of the components' chrome area.
$base-gradient: none !default; //$base-bg, darken( $base-bg, 2% ) !default;

/// The background of hovered items.
$hovered-bg: rgba(58, 157, 210, 0.1) !default; // tint $base .5
$hovered-solid-bg: #EBF5FA !default;
/// The gradient background of hovered items.
$hovered-gradient: none !default; //$hovered-bg, darken( $hovered-bg, 2% ) !default;

/// The background of non-interactive hovered items.
$hovered-static-bg: rgba(140, 160, 172, 0.1) !default;

/// The background of pressed items.
$pressed-bg: rgba(58, 157, 210, 0.2) !default;
$pressed-solid-bg: #D8EBF6 !default;

/// The background of selected items.
$selected-bg:  rgba(58, 157, 210, 0.2) !default;
/// The text color of selected items.
$selected-text: $base-text !default;

$border-color: #C6CED5 !default;
$button-border-color: #909FAE !default;
$component-border: #909FAE !default;

$invalid-border: #FF8482 !default;
$warning-border: #FE9F00 !default;

$focused-shadow-color: #3A9DD2 !default;
$focused-invalid-shadow-color: #FF504B !default;
$focused-warning-shadow-color: #F28100 !default;
$focused-success-shadow-color: #00AD82 !default;

$focused-shadow-width: 2px  !default;
$grid-focused-shadow-inset: inset 0 0 0 1px $focused-shadow-color !default;
$focused-shadow-inset: inset 0 0 0 $focused-shadow-width $focused-shadow-color !default;
$focused-shadow-outset: 0 0 0 $focused-shadow-width $focused-shadow-color !default;

// TODO: refactor
$focused-shadow: $focused-shadow-inset !default;
$primary-focused-shadow: $focused-shadow-inset !default;

// Validation
$grid-focused-invalid-shadow-inset: inset 0 0 0 1px $focused-invalid-shadow-color !default;
$focused-invalid-shadow-inset: inset 0 0 0 $focused-shadow-width $focused-invalid-shadow-color !default;
$focused-invalid-shadow-outset: 0 0 0 $focused-shadow-width $focused-invalid-shadow-color !default;

$grid-focused-warning-shadow-inset: inset 0 0 0 1px $focused-warning-shadow-color !default;
$focused-warning-shadow-inset: inset 0 0 0 $focused-shadow-width $focused-warning-shadow-color !default;
$focused-warning-shadow-outset: 0 0 0 $focused-shadow-width $focused-warning-shadow-color !default;

$grid-focused-success-shadow-inset: inset 0 0 0 1px $focused-success-shadow-color !default;
$focused-success-shadow-inset: inset 0 0 0 $focused-shadow-width $focused-success-shadow-color !default;
$focused-success-shadow-outset: 0 0 0 $focused-shadow-width $focused-success-shadow-color !default;

$invalid-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' id='critical-diamond'%3E%3Cpath fill='none' d='M0 0h48v48H0z'%3E%3C/path%3E%3Cpath fill='%23BA2121' fill-rule='evenodd' clip-rule='evenodd' d='M21.443 1.06a3.617 3.617 0 0 1 5.114 0l20.384 20.383a3.616 3.616 0 0 1 0 5.114L26.557 46.941a3.617 3.617 0 0 1-5.114 0L1.059 26.557a3.617 3.617 0 0 1 0-5.114L21.443 1.059zM24 32a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm3-18a3 3 0 1 0-6 0v11a3 3 0 1 0 6 0V14z'%3E%3C/path%3E%3C/svg%3E") !default;
$invalid-icon--dark: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' id='critical-diamond'%3E%3Cpath fill='none' d='M0 0h48v48H0z'%3E%3C/path%3E%3Cpath fill='%23FFBFC2' fill-rule='evenodd' clip-rule='evenodd' d='M21.443 1.06a3.617 3.617 0 0 1 5.114 0l20.384 20.383a3.616 3.616 0 0 1 0 5.114L26.557 46.941a3.617 3.617 0 0 1-5.114 0L1.059 26.557a3.617 3.617 0 0 1 0-5.114L21.443 1.059zM24 32a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm3-18a3 3 0 1 0-6 0v11a3 3 0 1 0 6 0V14z'%3E%3C/path%3E%3C/svg%3E") !default;
$warning-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' id='warning-triangle'%3E%3Cpath fill='none' d='M0 0h48v48H0z'%3E%3C/path%3E%3Cpath fill='%23d34501' fill-rule='evenodd' clip-rule='evenodd' d='M26.728 2.213l20.646 40.44c1.467 2.95.272 5.347-2.716 5.347H3.366C.378 48-.872 45.603.65 42.653l20.646-40.44c1.466-2.95 3.911-2.95 5.432 0zM24 14a3 3 0 0 1 3 3v11a3 3 0 1 1-6 0V17a3 3 0 0 1 3-3zm0 21a3 3 0 1 0 0 6 3 3 0 0 0 0-6z'%3E%3C/path%3E%3C/svg%3E") !default;
$warning-icon--dark: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' id='warning-triangle'%3E%3Cpath fill='none' d='M0 0h48v48H0z'%3E%3C/path%3E%3Cpath fill='%23FFCD7E' fill-rule='evenodd' clip-rule='evenodd' d='M26.728 2.213l20.646 40.44c1.467 2.95.272 5.347-2.716 5.347H3.366C.378 48-.872 45.603.65 42.653l20.646-40.44c1.466-2.95 3.911-2.95 5.432 0zM24 14a3 3 0 0 1 3 3v11a3 3 0 1 1-6 0V17a3 3 0 0 1 3-3zm0 21a3 3 0 1 0 0 6 3 3 0 0 0 0-6z'%3E%3C/path%3E%3C/svg%3E") !default;
$success-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' id='checkmark-circle'%3E%3Cpath fill='%2300775d' fill-rule='evenodd' clip-rule='evenodd' d='M24 0C10.7 0 0 10.7 0 24s10.7 24 24 24 24-10.7 24-24S37.3 0 24 0zm11.958 17.72a3 3 0 1 0-4.916-3.44l-9.725 13.89-3.86-5.513a3 3 0 1 0-4.915 3.44l6.317 9.025a3 3 0 0 0 4.916 0L35.958 17.72z'%3E%3C/path%3E%3C/svg%3E") !default;
$success-icon--dark: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' id='checkmark-circle'%3E%3Cpath fill='%23A7E2DA' fill-rule='evenodd' clip-rule='evenodd' d='M24 0C10.7 0 0 10.7 0 24s10.7 24 24 24 24-10.7 24-24S37.3 0 24 0zm11.958 17.72a3 3 0 1 0-4.916-3.44l-9.725 13.89-3.86-5.513a3 3 0 1 0-4.915 3.44l6.317 9.025a3 3 0 0 0 4.916 0L35.958 17.72z'%3E%3C/path%3E%3C/svg%3E") !default;
$required-icon: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5347 8.25L8.35132 7L10.5347 5.75C10.618 5.7 10.668 5.63333 10.6847 5.55C10.7013 5.46667 10.7013 5.36667 10.6513 5.3L10.318 4.71667C10.218 4.55 10.018 4.5 9.86799 4.6L7.68465 5.85V3.33333C7.68465 3.15 7.53465 3 7.35132 3H6.68465C6.50132 3 6.35132 3.15 6.35132 3.33333V5.85L4.16799 4.58333C4.01799 4.5 3.81799 4.55 3.71799 4.71667L3.38465 5.3C3.33465 5.38333 3.33465 5.46667 3.35132 5.55C3.36799 5.63333 3.43465 5.7 3.50132 5.75L5.68465 7L3.50132 8.25C3.33465 8.35 3.28465 8.55 3.38465 8.7L3.71799 9.28333C3.76799 9.36667 3.83465 9.41667 3.91799 9.43333C3.95132 9.43333 3.96799 9.45 4.00132 9.45C4.05132 9.45 4.11799 9.43333 4.16799 9.4L6.35132 8.15V10.6667C6.35132 10.85 6.50132 11 6.68465 11H7.35132C7.53465 11 7.68465 10.85 7.68465 10.6667V8.15L9.86799 9.4C9.91799 9.43333 9.98465 9.45 10.0347 9.45C10.068 9.45 10.0847 9.45 10.118 9.43333C10.2013 9.41667 10.268 9.35 10.318 9.28333L10.6513 8.7C10.7347 8.55 10.6847 8.35 10.5347 8.25Z' fill='%2310689D'/%3E%3C/svg%3E%0A") !default;
$info-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' id='information-circle'%3E%3Cpath fill='%2310689d' fill-rule='evenodd' clip-rule='evenodd' d='M24 0C10.7 0 0 10.7 0 24s10.7 24 24 24 24-10.7 24-24S37.3 0 24 0zm0 10a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm3 13a3 3 0 1 0-6 0v11a3 3 0 1 0 6 0V23z'%3E%3C/path%3E%3C/svg%3E") !default;

// Disabled mixin variables
$disabled-filter: none  !default; /// grayscale(.1) !default;
$disabled-opacity: .5 !default;

// List
$list-item-padding-x: $padding-x * 2 !default;
$list-item-padding-y: $padding-y * 0.5 !default;

$list-item-bg: $base-bg !default;

$list-item-focused-shadow: $focused-shadow-inset !default;

$list-container-no-data-text: $text-color-muted !default;

