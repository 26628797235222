@include exports("filter/theme") {

    .k-filter {
        color: $component-text;

        .k-filter-toolbar {
            .k-toolbar {
                border-color: $component-border;
            }
        }

        .k-filter-preview-field {
            color: $filter-preview-field-text;
        }

        .k-filter-preview-operator {
            color: $filter-preview-operator-text;
        }

        // The last selector targets the Angular rendering
        .k-filter-item::before,
        .k-filter-toolbar::before,
        .k-filter-lines .k-filter-item:last-child > .k-filter-toolbar::after,
        .k-filter-lines .k-filter-item:last-child > * > .k-filter-toolbar::after {
            background-color: $component-border;
        }
    }

}
