@include exports( "breadcrumb/theme" ) {

    // Breadcrumb
    .k-breadcrumb {
        @include fill(
            $breadcrumb-text,
            $breadcrumb-bg,
            $breadcrumb-border
        );

        &:focus,
        &.k-focus {
            @include box-shadow( $breadcrumb-focused-shadow );
        }
    }


    // Breadcrumb link
    .k-breadcrumb-link {
        @include fill(
            $breadcrumb-link-text,
            $breadcrumb-link-bg,
            $breadcrumb-link-border
        );

        // Hovered state
        &:hover,
        &.k-hover {
            @include fill(
                $breadcrumb-link-hovered-text,
                $breadcrumb-link-hovered-bg,
                $breadcrumb-link-hovered-border
            );
        }

        // Focused state
        &:focus,
        &.k-focus {
            @include fill(
                $breadcrumb-link-focused-text,
                $breadcrumb-link-focused-bg,
                $breadcrumb-link-focused-border
            );
            @include box-shadow( $breadcrumb-link-focused-shadow );
        }
    }


    // Breadcrumb root link
    .k-breadcrumb-root-link {
        @include fill(
            $breadcrumb-root-link-text,
            $breadcrumb-root-link-bg,
            $breadcrumb-root-link-border
        );

        &.k-disabled {
            opacity: 1;
        }

        // Hovered state
        &:hover,
        &.k-hover {
            @include fill(
                $breadcrumb-root-link-hovered-text,
                $breadcrumb-root-link-hovered-bg,
                $breadcrumb-root-link-hovered-border
            );
        }

        // Focused state
        &:focus,
        &.k-focus {
            @include fill(
                $breadcrumb-root-link-focused-text,
                $breadcrumb-root-link-focused-bg,
                $breadcrumb-root-link-focused-border
            );
            @include box-shadow( $breadcrumb-root-link-focused-shadow );
        }
    }

    // Breadcrumb last item
    .k-breadcrumb-last-item .k-breadcrumb-link {
        @include fill(
            $breadcrumb-root-link-text,
            $breadcrumb-root-link-bg,
            $breadcrumb-root-link-border
        );
        opacity: .9;

        // Hovered state
        &:hover {
            @include fill(
                $breadcrumb-root-link-hovered-text,
                $breadcrumb-root-link-hovered-bg,
                $breadcrumb-root-link-hovered-border
            );
        }

        // Focused state
        &:focus {
            @include fill(
                $breadcrumb-root-link-focused-text,
                $breadcrumb-root-link-focused-bg,
                $breadcrumb-root-link-focused-border
            );
            @include box-shadow( $breadcrumb-root-link-focused-shadow );
        }
    }
}
