@include exports("forms/layout") {

    // Common
    .k-form,
    .k-form-inline {
        font-size: $form-font-size;
        line-height: $form-line-height;
    
        .k-form-field {
            margin-bottom: $padding-y-lg;
            position: relative;
        }

        .k-form-legend, legend {
            margin: $form-legend-margin;
            padding: $form-legend-padding;
            border-width: $form-legend-border-width;
            border-style: $form-legend-border-style;
            width: $form-legend-width;
            font-size: $form-legend-font-size;
            font-family: $font-family-headings;
            text-align: left;
            font-weight: 600;
            line-height: 1;
            text-transform: $form-legend-text-transform;
        }

        .k-form-field-wrap {
            position: relative;
        }

        .k-input-container {
            display: inline-flex;
            flex-flow: row nowrap;
            > .k-checkbox, > .k-radio {
                flex-shrink: 0;
            }
            > *:not(span):not(label):not(.k-label):not(kendo-label):not(.k-checkbox):not(.k-radio) {
                width: auto;
            }
            > :not(:last-child) {
                margin-right: 8px !important;
            }
        }

        .k-input-container button {
            align-self: center;
            justify-self: left;
        }

    
        .k-input-list, .k-input-container {
            margin: 0;
            padding: 0;
            white-space: nowrap;
            align-items: flex-start !important;
        }
    
        .k-input-container > * {
            margin: 0 !important;
        }
    
        kendo-label + div > .k-input-container:not(li) > .k-checkbox, 
        kendo-label + div > .k-input-container:not(li) > .k-checkbox-label 
        {
            margin-top: 7px !important;
        }

        kendo-label[text=''] + div > .k-input-container:not(li) > .k-checkbox, 
        kendo-label[text=''] + div > .k-input-container:not(li) > .k-checkbox-label 
        {
            margin-top: 0 !important;
        }

        .k-input-list--vertical {
            padding-top: 7px ;
        }
        
        .k-input-list > li {
            padding: 0;
        }
    
        .k-input-list--horizontal {
            white-space: normal !important;
        }

        .k-input-list--horizontal > li {
            display: inline-flex;
            list-style: none;
            margin: 7px 16px 7px 0 ;
            .k-checkbox, .k-checkbox-label {
                margin: 0 !important;
            }
        }
    
        .k-input-list--vertical > li {
            list-style: none;
            &.k-input-container {
                display: flex !important;
                .k-checkbox, .k-checkbox-label {
                    margin: 0 !important;
                }
            }
            &:not(.k-input-container) {
                display: block; 
            }
            margin: 0 0 16px 0 ;
            &:last-child {
                margin-bottom: 0;
            }        
        }
    }


    // Fieldset
    .k-fieldset {
        margin: $fieldset-margin;
        border-width: 1px 0 0;
        border-style: solid;
        padding: 25px 0 0;

        > legend {
            margin-left: 0;
            padding: 0 $padding-x 0 0;
            text-transform: uppercase;
        }
    }


    // Label
    kendo-label > .k-label {
        margin: 0;
    }

    .k-label-optional {
        margin-left: $label-optional-margin-x;
        font-size: $label-optional-font-size;
        font-style: $label-optional-font-style;
        align-self: center;
    }

    .k-label-empty {
        &::before {
            content: "";
            display: inline-block;
        }
    }

    .k-input-label {
        margin-right: $horizontal-form-label-margin-x;
        z-index: 1;

        &:dir(rtl),
        .k-rtl &,
        &.k-rtl,
        [dir="rtl"] &,
        &[dir="rtl"] {
            margin-right: 0;
            margin-left: $horizontal-form-label-margin-x;
        }
    }


    // Vertical Form
    .k-form {
        border: 0;

        .k-form-field {
            display: flex;
            flex-direction: column;
            gap: $padding-y;
        }

        .k-form-field-inline {
            margin-bottom: $padding-y-lg;
        }

        .k-form-fieldset {
            margin: $form-fieldset-margin;
            padding: $form-fieldset-padding;
            border: 0;

            > * {
                &:first-child {
                    margin-top: 0;
                }
            }
        }

        .k-label, kendo-label {
            white-space: normal;
        }

        // Form group
        .k-form-group {
            padding: 0;
        }

        .k-form-field,
        .k-form-buttons,
        .k-daterangepicker .k-textbox-container {
            margin-top: $form-field-margin-top;
        }

        > *,
        .k-daterangepicker .k-textbox-container {
            &:first-child {
                margin-top: 0;
            }
        }


        // Form field
        .k-form-field-disabled {
            .k-label,
            .k-form-label {
                @include disabled( $disabled-styling );
            }
        }

        .k-alert-error {
            font-size: $font-size-sm;
            margin-top: $padding-y * 2;
        }

        .k-field-info {
            display: inline-block;
            font-size: $font-size-xs;
            line-height: 1;
            margin: 0 $padding-x;
        }

        .k-textbox,
        .k-textarea,
        .k-autocomplete,
        .k-combobox,
        .k-dropdown,
        .k-multiselect,
        .k-dateinput,
        .k-datepicker,
        .k-datetimepicker,
        .k-daterangepicker-wrap,
        .k-timepicker,
        .k-numerictextbox,
        .k-maskedtextbox,
        .k-textbox-container,
        .k-floating-label-container {
            display: flex;
        }
    }

    // Form Buttons Container
    .k-form-buttons {
        @extend .k-actions;
        justify-content: end;
        
        > * {
            margin-right: $form-button-margin-x;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    // Form Hint/Error Messages
    .k-form-hint {
        margin-top: $form-hint-margin-top;
        display: flex;
        font-size: $form-hint-font-size;
        font-style: $form-hint-font-style;
    }

    kendo-formfield > div,
    .k-form-field > div {
        position: relative;
    }

    .k-form-error {
        position: absolute;
        top: 38px;
        left: 1px;
        animation: fade-in-down .25s 0s cubic-bezier(0,1,1,1);
    }

    .k-text-start,
    .k-buttons-start {
        justify-content: flex-start;
    }

    .k-text-end,
    .k-buttons-end {
        justify-content: flex-end;
    }

    // Form Separator
    .k-form-separator {
        margin: $form-separator-margin;
        border-width: 1px 0 0;
        border-style: solid;
        display: block;
        flex: 0 0 auto;
    }

    // Horizontal Form
    .k-form-horizontal {
        @media only screen and (min-width: 640px) {
            .k-form-field {
                display: flex;
                align-items: flex-start;
                flex-direction: row;
                gap: $padding-x-lg;

                > .k-label,
                > kendo-label,
                > .k-form-label {
                    width: $inline-form-element-width;
                    text-align: right;
                    line-height: $line-height;
                    padding: calc( #{$padding-y} + #{$kendo-input-border-width} ) 0;

                    + * {
                        width: 100%;
                    }
                }

                .k-label-optional {
                    margin: 0;
                    align-self: inherit;
                }
            }

            .k-form-field-wrap {
                flex: 1 1 auto;
                min-width: 0;
                align-self: center;
            }

            .k-form-buttons {
                margin-top: 2 * $form-field-margin-top;
            }
        }
    }

    // RTL Adjustments
    .k-form {
        .text-right {
            text-align: right;

            > * {
                margin-left: 8px;
                
                &:first-child {
                    margin-left: 0;
                }
            }
        }

        [dir="rtl"],
        .k-rtl & {
            .k-form-buttons,
            &.k-form-buttons {
                > * {
                    margin-right: 0;
                    margin-left: $form-button-margin-x;

                    &:last-child {
                        margin-left: 0;
                    }
                }
            }

            .k-label-optional {
                margin-left: 0;
                margin-right: $label-optional-margin-x;
            }
        }
    }

    .k-form-horizontal {
        @media only screen and (min-width: 640px) {
            [dir="rtl"],
            .k-rtl & {
                &.k-form-field,
                .k-form-field {
                    > .k-label,
                    > kendo-label,
                    > .k-form-label {
                        margin-right: 0;
                        margin-left: $horizontal-form-label-margin-x;
                    }
                }
            }
        }
    }


    // Scheduler
    .k-form-inline {

        fieldset {
            /* border-width: 1px 0 0; */
            border-width:0;
            border-style: solid;
            margin: $form-spacer 0;
            padding: 0;

            &:first-child:first-of-type {
                margin-top: 0;
            }

            &:last-child:last-of-type {
                margin-bottom: 0;
            }
        }

        .k-form-field {
            @media only screen and (min-width: 640px) {
                display: flex;
                align-items: flex-start;
                text-align: left;

                > span:not(.k-widget), > .k-label, > kendo-label, > label {
                    width: $inline-form-element-width;
                    text-align: right;
                    line-height: $line-height;
                    padding: calc( #{$padding-y} + #{$kendo-input-border-width} ) 0;
                    padding-right: $padding-x-lg;
                    //align-self: center;
                }

                > *:not(span):not(label):not(.k-label):not(kendo-label) {
                    align-self: center;
                }

                > *:not(span):not(label):not(.k-label):not(kendo-label):not(.k-checkbox):not(.k-radio) {
                    flex: 1 1 auto;
                    min-width: 0;
                }

                .k-alert-error {
                    font-size: $font-size-sm;
                    margin-top: $padding-y * 2;
                }

                .k-field-info {
                    display: block;
                    font-size: $font-size-xs;
                    line-height: 1;
                    margin: 0;
                }

                // &:last-child {
                //    margin-bottom: 0;
                //}
            }
        }
    }

}

@include exports("edit-form/layout") {

    // Edit form
    .k-edit-form-container {
        width: 400px;
        min-width: 400px;
        border-color: inherit;
        position: relative;
    }


    // Action buttons
    .k-popup-edit-form > .k-actions,
    .k-edit-form-container .k-actions {
        margin: $spacer (-$window-inner-padding-x) (-$window-inner-padding-y);
    }


    .k-edit-label {
        margin: 0 0 $spacer-y 0;
        padding: calc( #{$kendo-input-padding-y} + #{$kendo-input-border-width} ) 0;
        width: 30%;
        line-height: $form-line-height;
        text-align: right;
        float: left;
        clear: both;
    }
    .k-edit-field {
        margin: 0 0 $spacer-y 0;
        width: 65%;
        float: right;
        clear: right;
        position: relative;

        &.k-no-editor {
            padding: calc( #{$kendo-input-padding-y} + #{$kendo-input-border-width} ) 0;
        }
    }

    .k-edit-field {

        > .k-widget,
        > .k-textbox,
        > .k-textarea {
            width: 100%;
            box-sizing: border-box;
        }
        > .k-colorpicker {
            width: auto;
        }

        input[type="radio"]:not(.k-radio),
        input[type="checkbox"]:not(.k-checkbox) {
            margin-right: ($spacer * 0.5);
        }

        .k-radio-label,
        .k-checkbox-label {
            margin-right: ($spacer * 0.5);
        }

        > .k-reset > li + li {
            margin-top: ($spacer * 0.5);
        }

        .k-reset .k-widget {
            margin: 0 ($spacer * 0.5) 0 $spacer;
        }
    }

}

