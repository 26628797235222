@include exports("tilelayout/layout") {

    .k-tilelayout {
        border-width: $tilelayout-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        display: grid;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        .k-card {
            @include box-shadow( none );
            border-width: $tilelayout-card-border-width;
            .k-card-title {
                font-size: 24px;
            }
        }
    }

    .k-tilelayout-item {
        position: relative;
        z-index: 0;
    }

    .k-layout-item-hint {
        @include border-radius( $tilelayout-hint-border-radius );
        border-width: $tilelayout-hint-border-width;
        border-style: solid;
        position: relative;

        &.k-layout-item-hint-resize {
            z-index: 3;
        }
    }

    .k-tilelayout-item-header.k-card-header {
        z-index: 0;
    }

    .k-tilelayout-item-body {
        min-height: 0;
    }

}
