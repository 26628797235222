@include exports("notification/theme") {
    $notification-shadow: $popup-shadow !default;

    // Theme
    .k-notification {
        @extend .k-text-selection !optional;
        @include fill(
            $notification-text,
            $notification-bg,
            $notification-border
        );
    }
    .k-notification-wrap {
        > .k-i-close {
            border-color: transparent !important; // sass-lint:disable-line no-important
            color: $kendo-button-text;
            background: none;
    
            // Hover state
            &:hover {
                @include fill(
                    $kendo-button-hover-text,
                    $kendo-button-hover-bg,
                    $kendo-button-hover-border,
                    $kendo-button-hover-gradient
                );
                }
    
            // Focused state
            &:focus {
                box-shadow: $kendo-button-focus-shadow;
            }
    
            // Active state
            &:active {
                @include fill(
                    $kendo-button-active-text,
                    $kendo-button-active-bg,
                    $kendo-button-active-border,
                    $kendo-button-active-gradient
                );
            }
    
        }
    }
    .k-notification-group .k-notification {
        box-shadow: $notification-shadow;
        display: flex;
    }

    .k-banner-container .k-notification-group .k-notification {
        box-shadow: none;
    }

    .k-notification-info {
        color: $notification-info-text;
        background-color: $notification-info-bg;
        border-bottom-color: $notification-info-border;

        .k-i-info {
            color: $notification-info-border;
        }
    }
    .k-notification-success {
        color: $notification-success-text;
        background-color: $notification-success-bg;
        border-bottom-color: $notification-success-border;

        .k-i-success {
            color: $notification-success-border;
        }
    }
    .k-notification-warning {
        color: $notification-warning-text;
        background-color: $notification-warning-bg;
        border-bottom-color: $notification-warning-border;

        .k-i-warning {
            color: $notification-warning-border;
        }
    }
    .k-notification-error {
        color: $notification-error-text;
        background-color: $notification-error-bg;
        border-bottom-color: $notification-error-border;

        .k-i-error {
            color: $notification-error-border;
        }
    }

}
