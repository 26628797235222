@include exports( "utils/pointer-events" ) {

    // Pointer-events documentation sourced from https://developer.mozilla.org/en-US/docs/Web/CSS/pointer-events.

    // @name k-pointer-events-none
    // @description This is equivalent to `pointer-events: none;`. The element is never the target of pointer events; however, pointer events may target its descendant elements if those descendants have pointer-events set to some other value. In these circumstances, pointer events will trigger event listeners on this parent element as appropriate on their way to/from the descendant during the event capture/bubble phases.
    // @group pointer-events

    // @name k-pointer-events-auto
    // @description This is equivalent to `pointer-events: auto;`. The element behaves as it would if the pointer-events property were not specified. In SVG content, this value and the value visiblePainted have the same effect.
    // @group pointer-events

    $utils-pointer-events: (
        none,
        auto
    ) !default;

    @if $utils-pointer-events {
        @each $pointer-events in $utils-pointer-events {
            // sass-lint:disable-block no-important
            .k-pointer-events-#{$pointer-events} { pointer-events: $pointer-events !important; }
        }
    }

}
