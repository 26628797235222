@include exports( "input/theme" ) {

    // Solid input
    .k-input {
        @include fill(
            $kendo-input-text,
            $kendo-input-bg,
            $kendo-input-border
        );

        .k-input {
            background-color: transparent !important;
        }

        // Hover
        &:hover,
        &.k-hover {
            @include fill(
                $kendo-input-hover-text,
                $kendo-input-hover-bg,
                $kendo-input-hover-border
            );
            @include box-shadow( $kendo-input-hover-shadow );
        }

        // Focus
        &:focus,
        &.k-focus {
            @include fill (
                $kendo-input-focus-text,
                $kendo-input-focus-bg,
                $kendo-input-focus-border
            );
            @include box-shadow( $kendo-input-focus-shadow );
        }

        &.k-readonly,
        &[readonly]:not([readonly=false]) {
            @include fill(
                $kendo-input-readonly-text,
                $kendo-input-readonly-bg,
                $kendo-input-readonly-border
            );

            .k-input-prefix, .k-input-suffix, .k-input-button, .k-input-spinner {
                display: none;
            }

            .k-chip-list {
                grid-gap: 0;
                gap: 0;
            }

            .k-chip {
                font-size: inherit;
                border: none;
                grid-gap: 0;
                gap: 0;

                &:not(:last-of-type) {
                    &::after {
                        content: ",";
                    }
                }

                .k-chip-actions {
                    display: none;
                }
            }
        }

        //Disabled
        &:disabled,
        &[disabled],
        &.k-disabled {
            @include fill(
                $kendo-input-disabled-text,
                $kendo-input-disabled-bg,
                $kendo-input-disabled-border,
                $kendo-input-disabled-gradient
            );
            @include box-shadow( $kendo-input-disabled-shadow );
        }

        &.mri-k-show-validation {
            // warning
            &.k-warning {
                @include fill( $border: $warning-border );

                .k-input-validation-icon {
                    color: $warning;
                }

                &:focus,
                &.k-focus {
                    @include box-shadow($focused-warning-shadow-outset);
                }
            }

            // Invalid
            &.k-invalid,
            &.ng-invalid.ng-touched,
            &.ng-invalid.ng-dirty {
                @include fill( $border: $invalid-border );

                .k-input-validation-icon {
                    background-image: $invalid-icon;
                    background-size: 14px 14px;
                    background-repeat: no-repeat;
                    background-position: center;
                    opacity: 1;
                    &::before {
                        content: "";
                    }
                }

                &:focus,
                &.k-focus {
                    @include box-shadow($focused-invalid-shadow-outset);
                }
            }

            &.k-success, &.k-valid:not(.ng-pristine),
            &.ng-valid.ng-touched:not(.ng-pristine),
            &.ng-valid.ng-dirty:not(.ng-pristine) {
                .k-input-validation-icon {
                    background-image: $success-icon;
                    background-size: 14px 14px;
                    background-repeat: no-repeat;
                    background-position: center;
                    opacity: 1;
                    &::before {
                        content: "";
                    }
                }

                &:focus,
                &.k-focus {
                    @include box-shadow($focused-success-shadow-outset);
                }
            }
        }
    }


    // Solid picker
    .k-picker {
        @include fill(
            $kendo-picker-text,
            $kendo-picker-bg,
            $kendo-picker-border,
            $kendo-picker-gradient
        );

        // Hover
        &:hover,
        &.k-hover {
            @include fill(
                $kendo-picker-hover-text,
                $kendo-picker-hover-bg,
                $kendo-picker-hover-border,
                $kendo-picker-hover-gradient
            );
            @include box-shadow( $kendo-picker-hover-shadow );
        }

        // Focus
        &:focus,
        &.k-focus {
            @include fill (
                $kendo-picker-focus-text,
                $kendo-picker-focus-bg,
                $kendo-picker-focus-border,
                $kendo-picker-focus-gradient
            );
            @include box-shadow( $kendo-picker-focus-shadow );
        }

        &.k-readonly,
        &[readonly]:not([readonly=false]) {
            @include fill(
                $kendo-input-readonly-text,
                $kendo-input-readonly-bg,
                $kendo-input-readonly-border
            );
            .k-input-button {
                display: none;
            }
        }

        //Disabled
        &:disabled,
        &[disabled],
        &.k-disabled {
            @include fill(
                $kendo-picker-disabled-text,
                $kendo-picker-disabled-bg,
                $kendo-picker-disabled-border,
                $kendo-picker-disabled-gradient
            );
            @include box-shadow( $kendo-picker-disabled-shadow );
        }

        &.mri-k-show-validation {
            // warning
            &.k-warning {
                @include fill( $border: $warning-border );

                .k-input-validation-icon {
                    color: $warning;
                }

                &:focus,
                &.k-focus {
                    @include box-shadow($focused-warning-shadow-outset);
                }
            }

            // Invalid
            &.k-invalid,
            &.ng-invalid.ng-touched,
            &.ng-invalid.ng-dirty {
                @include fill( $border: $invalid-border );

                .k-input-validation-icon {
                    color: $invalid-text;
                }

                &:focus,
                &.k-focus {
                    @include box-shadow( $kendo-input-invalid-shadow );
                }
            }
        
            &.k-success, &.k-valid:not(.ng-pristine),
            &.ng-valid.ng-touched:not(.ng-pristine),
            &.ng-valid.ng-dirty:not(.ng-pristine) {
                .k-input-validation-icon {
                    color: $success;
                }

                &:focus,
                &.k-focus {
                    @include box-shadow($focused-success-shadow-outset);
                }
            }
        }
    }

    // Outline
    .k-input-outline {
        @include fill (
            $kendo-input-outline-text,
            $kendo-input-outline-bg,
            $kendo-input-outline-border
        );

        & > .k-input-button {
            border-color: inherit;
        }

        & > .k-input-spinner {
            border-color: inherit;

            & > .k-spinner-increase,
            & > .k-spinner-decrease {
                border-color: inherit;
           }
        }

        // Hover
        &:hover,
        &.k-hover {
            @include fill (
                $kendo-input-outline-hover-text,
                $kendo-input-outline-hover-bg,
                $kendo-input-outline-hover-border
            );
        }

        // Focus
        &:focus,
        &.k-focus {
            @include fill (
                $kendo-input-outline-focus-text,
                $kendo-input-outline-focus-bg,
                $kendo-input-outline-focus-border
             );
            @include box-shadow ( $kendo-input-outline-focus-shadow );
        }

        &.mri-k-show-validation {
            // warning
            &.k-warning {
                @include fill( $border: $warning-border );

                .k-input-validation-icon {
                    color: $warning;
                }

                &:focus,
                &.k-focus {
                    @include box-shadow($focused-warning-shadow-outset);
                }
            }

            // Invalid
            &.k-invalid,
            &.ng-invalid.ng-touched,
            &.ng-invalid.ng-dirty {
                @include fill( $border: $invalid-border );

                .k-input-validation-icon {
                    color: $invalid-text;
                }

                &:focus,
                &.k-focus {
                    @include box-shadow($kendo-input-invalid-shadow);
                }
            }

            &.k-success, &.k-valid:not(.ng-pristine),
            &.ng-valid.ng-touched:not(.ng-pristine),
            &.ng-valid.ng-dirty:not(.ng-pristine) {
                .k-input-validation-icon {
                    color: $success;
                }

                &:focus,
                &.k-focus {
                    @include box-shadow($focused-success-shadow-outset);
                }
            }
        }
    }


    // Outline picker
    .k-picker-outline {
        @include fill (
            $kendo-picker-outline-text,
            $kendo-picker-outline-bg,
            $kendo-picker-outline-border
        );

        // Hover
        &:hover,
        &.k-hover {
            @include fill (
                $kendo-picker-outline-hover-text,
                $kendo-picker-outline-hover-bg,
                $kendo-picker-outline-hover-border
            );
        }

        // Focus
        &:focus,
        &.k-focus {
            @include fill (
                $kendo-picker-outline-focus-text,
                $kendo-picker-outline-focus-bg,
                $kendo-picker-outline-focus-border
            );
            @include box-shadow ( $kendo-picker-outline-focus-shadow );
        }

        &:focus:hover,
        &:focus.k-hover,
        &.k-focus:hover,
        &.k-focus.k-hover {
            @include fill (
                $kendo-picker-outline-hover-focus-text,
                $kendo-picker-outline-hover-focus-bg,
                $kendo-picker-outline-hover-focus-border
            );
        }

        &.mri-k-show-validation {
            // warning
            &.k-warning {
                @include fill( $border: $warning-border );

                .k-input-validation-icon {
                    color: $warning;
                }

                &:focus,
                &.k-focus {
                    @include box-shadow($focused-warning-shadow-outset);
                }
            }

            // Invalid
            &.k-invalid,
            &.ng-invalid.ng-touched,
            &.ng-invalid.ng-dirty {
                @include fill( $border: $invalid-border );

                .k-input-validation-icon {
                    color: $invalid-text;
                }

                &:focus,
                &.k-focus {
                    @include box-shadow($kendo-input-invalid-shadow);
                }
            }
        
            &.k-success, &.k-valid:not(.ng-pristine),
            &.ng-valid.ng-touched:not(.ng-pristine),
            &.ng-valid.ng-dirty:not(.ng-pristine) {
                .k-input-validation-icon {
                    color: $success;
                }

                &:focus,
                &.k-focus {
                    @include box-shadow($focused-success-shadow-outset);
                }
            }
        }
    }

    // Flat input
    .k-input-flat {
        @include fill (
            $kendo-input-flat-text,
            $kendo-input-flat-bg,
            $kendo-input-flat-border
        );

        // Hover
        &:hover,
        &.k-hover {
            @include fill (
                $kendo-input-flat-hover-text,
                $kendo-input-flat-hover-bg,
                $kendo-input-flat-hover-border
            );
        }

        // Focus
        &:focus,
        &.k-focus {
            @include fill (
                $kendo-input-flat-focus-text,
                $kendo-input-flat-focus-bg,
                $kendo-input-flat-focus-border
            );
            @include box-shadow( $kendo-input-flat-focus-shadow );
        }

        &.mri-k-show-validation {
            // warning
            &.k-warning {
                @include fill( $border: $warning-border );

                .k-input-validation-icon {
                    color: $warning;
                }

                &:focus,
                &.k-focus {
                    @include box-shadow($focused-warning-shadow-outset);
                }
            }

            // Invalid
            &.k-invalid,
            &.ng-invalid.ng-touched,
            &.ng-invalid.ng-dirty {
                @include fill( $border: $invalid-border );

                .k-input-validation-icon {
                    color: $invalid-text;
                }

                &:focus,
                &.k-focus {
                    @include box-shadow( $kendo-input-invalid-shadow );
                }
            }
        
            &.k-success, &.k-valid:not(.ng-pristine),
            &.ng-valid.ng-touched:not(.ng-pristine),
            &.ng-valid.ng-dirty:not(.ng-pristine) {
                .k-input-validation-icon {
                    color: $success;
                }

                &:focus,
                &.k-focus {
                    @include box-shadow($focused-success-shadow-outset);
                }
            }
        }
    }

    // Flat picker
    .k-picker-flat {
        @include fill (
            $kendo-picker-flat-text,
            $kendo-picker-flat-bg,
            $kendo-picker-flat-border
        );

        // Hover
        &:hover,
        &.k-hover {
            @include fill (
                $kendo-picker-flat-hover-text,
                $kendo-picker-flat-hover-bg,
                $kendo-picker-flat-hover-border
            );
        }

        // Focus
        &:focus,
        &.k-focus {
            @include fill (
                $kendo-picker-flat-focus-text,
                $kendo-picker-flat-focus-bg,
                $kendo-picker-flat-focus-border
            );
            @include box-shadow( $kendo-picker-flat-focus-shadow );
        }

        &:focus:hover,
        &:focus.k-hover,
        &.k-focus:hover,
        &.k-focus.k-hover {
            @include fill (
                $kendo-picker-flat-hover-focus-text,
                $kendo-picker-flat-hover-focus-bg,
                $kendo-picker-flat-hover-focus-border
            );
        }

        &.mri-k-show-validation {
            // warning
            &.k-warning {
                @include fill( $border: $warning-border );

                .k-input-validation-icon {
                    color: $warning;
                }

                &:focus,
                &.k-focus {
                    @include box-shadow($focused-warning-shadow-outset);
                }
            }

            // Invalid
            &.k-invalid,
            &.ng-invalid.ng-touched,
            &.ng-invalid.ng-dirty {
                @include fill( $border: $invalid-border );

                .k-input-validation-icon {
                    color: $invalid-text;
                }

                &:focus,
                &.k-focus {
                    @include box-shadow( $kendo-input-invalid-shadow );
                }
            }

            &.k-success, &.k-valid:not(.ng-pristine),
            &.ng-valid.ng-touched:not(.ng-pristine),
            &.ng-valid.ng-dirty:not(.ng-pristine) {
                .k-input-validation-icon {
                    color: $success;
                }

                &:focus,
                &.k-focus {
                    @include box-shadow($focused-success-shadow-outset);
                }
            }
        }
    }

    .k-input-spinner {
        &:hover,
        &.k-hover {
            background-color: $kendo-button-hover-bg;

            & > .k-spinner-increase,
            & > .k-spinner-decrease {
                background-color: transparent;
            }
        }

        // Active

        .k-spinner-increase,
        .k-spinner-decrease {
            &:active,
            &.k-active {
                background-color: $kendo-button-active-bg;
            }
        }
    }


    .k-input, .k-picker {
        .k-clear-value:hover,
        .k-clear-value.k-hover {
            color: $kendo-input-clear-value-hover-text;
            opacity: $kendo-input-clear-value-hover-opacity;
            background-color: $hovered-solid-bg;
        }
        .k-clear-value:active,
        .k-clear-value.k-active {
            background-color: $pressed-solid-bg;
        }
    }
}
