// Fill and outline
@mixin fill( $color: null, $bg: null, $border: null, $gradient: null ) {
    @if $border {
        border-color: $border;
    }
    @if $color {
        color: $color;
    }
    @if $bg {
        background-color: $bg;
    }
    @if $gradient {
        @include linear-gradient( $gradient );
    }
}
@mixin outline( $color: null ) {
    border-color: $color;
    color: $color;
    background-color: transparent;
    background-image: none;
}

@mixin background-image( $background-image: null ) {
    @if $background-image {
        background-image: url(#{$background-image});
    }
}
