// Menu
$menu-padding-x: 0px !default;
$menu-padding-y: 0px !default;
$menu-border-width: 0px !default;
$menu-font-family: $font-family !default;
$menu-font-size: $font-size !default;
$menu-line-height: $line-height !default;

$menu-bg: transparent !default;
$menu-text: $component-text !default;
$menu-border: null !default;
$menu-gradient: null !default;

$menu-item-padding-x: $nav-item-padding-x !default;
$menu-item-padding-y: $nav-item-padding-y !default;
$menu-item-spacing: $nav-item-margin !default;
$menu-item-icon-spacing: $icon-spacing !default;

$menu-item-bg: null !default;
$menu-item-text: null !default;
$menu-item-border: null !default;
$menu-item-gradient: null !default;

$menu-item-hover-bg: $kendo-button-hover-bg !default;
$menu-item-hover-text: $kendo-button-hover-text !default;
$menu-item-hover-border: null !default;
$menu-item-hover-gradient: null !default;

$menu-item-expanded-bg: $kendo-button-active-bg !default;
$menu-item-expanded-text: null !default;
$menu-item-expanded-border: null !default;
$menu-item-expanded-gradient: null !default;

$menu-item-focus-shadow: $focused-shadow-inset !default;

$menu-separator-spacing: map-get( $spacing, 1 ) !default;

$menu-scroll-button-bg: $component-bg !default;
$menu-scroll-button-text: $link-text !default;
$menu-scroll-button-border: $component-border !default;
$menu-scroll-button-gradient: null !default;

$menu-scroll-button-hover-bg: null !default;
$menu-scroll-button-hover-text: $link-hover-text !default;
$menu-scroll-button-hover-border: null !default;
$menu-scroll-button-hover-gradient: null !default;


// Menu popup

/// Horizontal padding of the menu popup.
/// @group menu
$kendo-menu-popup-padding-x: null !default;

/// Vertical padding of the menu popup.
/// @group menu
$kendo-menu-popup-padding-y: null !default;

/// Width of the border around the menu popup.
/// @group menu
$kendo-menu-popup-border-width: $popup-border-width !default;

/// Font sizes of the menu popup.
/// @group menu
$kendo-menu-popup-font-size: $font-size-md !default;
$kendo-menu-popup-font-size-sm: $font-size-md !default;
$kendo-menu-popup-font-size-md: $font-size-md !default;
$kendo-menu-popup-font-size-lg: $font-size-md !default;

/// Line heights used along with $font-size.
/// @group menu
$kendo-menu-popup-line-height: $line-height-md !default;
$kendo-menu-popup-line-height-sm: $line-height-md !default;
$kendo-menu-popup-line-height-md: $line-height-md !default;
$kendo-menu-popup-line-height-lg: $line-height-md !default;

/// The background of the menu popup.
/// @group menu
$kendo-menu-popup-bg: $popup-bg !default;
/// The text color of the menu popup.
/// @group menu
$kendo-menu-popup-text: $popup-text !default;
/// The border color of the menu popup.
/// @group menu
$kendo-menu-popup-border: $popup-border !default;
/// The background gradient of the menu popup.
/// @group menu
$kendo-menu-popup-gradient: null !default;


// Menu popup item

/// Horizontal padding of the menu item in popup.
/// @group menu
$kendo-menu-popup-item-padding-x: map-get( $spacing, 2 ) !default;
$kendo-menu-popup-item-padding-x-sm: map-get( $spacing, 2 ) !default;
$kendo-menu-popup-item-padding-x-md: map-get( $spacing, 2 ) !default;
$kendo-menu-popup-item-padding-x-lg: map-get( $spacing, 2 ) !default;

/// Vertical padding of the menu item in popup.
/// @group menu
$kendo-menu-popup-item-padding-y: map-get( $spacing, 1 ) !default;
$kendo-menu-popup-item-padding-y-sm: map-get( $spacing, 1 ) - map-get( $spacing, thin ) !default;
$kendo-menu-popup-item-padding-y-md: map-get( $spacing, 1 ) !default;
$kendo-menu-popup-item-padding-y-lg: map-get( $spacing, 1 ) + map-get( $spacing, thin ) !default;

/// The end padding of the menu item in popup.
/// @group menu
$kendo-menu-popup-item-padding-end: calc( #{$kendo-menu-popup-item-padding-x * 2} + #{$icon-size} ) !default;
$kendo-menu-popup-item-padding-end-sm: calc( #{$kendo-menu-popup-item-padding-x-sm * 2} + #{$icon-size} ) !default;
$kendo-menu-popup-item-padding-end-md: calc( #{$kendo-menu-popup-item-padding-x-md * 2} + #{$icon-size} ) !default;
$kendo-menu-popup-item-padding-end-lg: calc( #{$kendo-menu-popup-item-padding-x-lg * 2} + #{$icon-size} ) !default;

/// The start margin of the menu item expand icon.
/// @group menu
$kendo-menu-popup-item-icon-margin-start-sm: $kendo-menu-popup-item-padding-x-sm !default;
$kendo-menu-popup-item-icon-margin-start: $kendo-menu-popup-item-padding-x-md !default;
$kendo-menu-popup-item-icon-margin-start-lg: $kendo-menu-popup-item-padding-x-lg !default;

/// The end margin of the menu item expand icon.
/// @group menu
$kendo-menu-popup-item-icon-margin-end-sm: calc( -1 * (#{$kendo-menu-popup-item-padding-end-sm} - #{$kendo-menu-popup-item-padding-x-sm} * 0.5) ) !default;
$kendo-menu-popup-item-icon-margin-end: calc( -1 * (#{$kendo-menu-popup-item-padding-end-md} - #{$kendo-menu-popup-item-padding-x-md} * 0.5) ) !default;
$kendo-menu-popup-item-icon-margin-end-lg: calc( -1 * (#{$kendo-menu-popup-item-padding-end-lg} - #{$kendo-menu-popup-item-padding-x-lg} * 0.5) ) !default;

/// The spacing between the menu items in popup.
/// @group menu
$kendo-menu-popup-item-spacing: 0px !default;

/// The background of the menu item in popup.
/// @group menu
$kendo-menu-popup-item-bg: null !default;
/// The text color of the menu item in popup.
/// @group menu
$kendo-menu-popup-item-text: null !default;
/// The border color of the menu item in popup.
/// @group menu
$kendo-menu-popup-item-border: null !default;
/// The background gradient of the menu item in popup.
/// @group menu
$kendo-menu-popup-item-gradient: null !default;

/// The background of hovered menu item in popup.
/// @group menu
$kendo-menu-popup-item-hover-bg: $menu-item-hover-bg !default;
/// The text color of hovered menu item in popup.
/// @group menu
$kendo-menu-popup-item-hover-text: $menu-item-hover-text !default;
/// The border color of hovered menu item in popup.
/// @group menu
$kendo-menu-popup-item-hover-border: null !default;
/// The background gradient of hovered menu item in popup.
/// @group menu
$kendo-menu-popup-item-hover-gradient: null !default;

/// The background of expanded menu item in popup.
/// @group menu
$kendo-menu-popup-item-expanded-bg: $menu-item-expanded-bg !default;
/// The text color of expanded menu item in popup.
/// @group menu
$kendo-menu-popup-item-expanded-text: $menu-item-expanded-text !default;
/// The border color of expanded menu item in popup.
/// @group menu
$kendo-menu-popup-item-expanded-border: null !default;
/// The background gradient of expanded menu item in popup.
/// @group menu
$kendo-menu-popup-item-expanded-gradient: null !default;

/// The base shadow of focused menu item in popup.
/// @group menu
$kendo-menu-popup-item-focus-shadow: $menu-item-focus-shadow !default;
