// Upload
$upload-border-width: $kendo-input-border-width !default;
$upload-font-family: $kendo-input-font-family !default;
$upload-font-size: $kendo-input-font-size !default;
$upload-line-height: $kendo-input-line-height !default;
$upload-max-height: 300px !default;

$upload-bg: $kendo-input-bg !default;
$upload-text: $kendo-input-text !default;
$upload-border: $kendo-input-border !default;

$upload-dropzone-padding-x: $kendo-input-padding-x !default;
$upload-dropzone-padding-y: $kendo-input-padding-y !default;
$upload-dropzone-bg: $header-bg !default;
$upload-dropzone-text: $header-text !default;
$upload-dropzone-border: $upload-border !default;
$upload-dropzone-hover-bg: $hovered-bg !default;

$upload-status-text: $kendo-input-placeholder-text !default;
$upload-status-text-opacity: $kendo-input-placeholder-opacity !default;

$upload-item-padding-x: map-get( $spacing, 2 ) !default;
$upload-item-padding-y: map-get( $spacing, 2 ) !default;

$upload-multiple-items-spacing: 12px !default;

$upload-validation-font-size: 11px !default;
$upload-icon-spacing: $icon-spacing !default;
$upload-icon-color: $subtle-text !default;

$upload-item-image-width: 30px !default;
$upload-item-image-height: 30px !default;
$upload-item-image-border: 0px !default; // TODO: remove

$upload-group-icon-size: $icon-size-lg !default;

$upload-progress-thickness: 2px !default;
$upload-progress-bg: $info !default;
$upload-progress-text: $component-text !default;

$upload-success-bg: $success !default;
$upload-success-text: $success !default;

$upload-error-bg: $error !default;
$upload-error-text: $error !default;
$upload-error-border: $error !default;

$upload-focused-shadow: 0 0 0 2px rgba(0, 0, 0, .13) !default;
