@include exports("notification/layout") {

    // Base
    .k-notification-group {
        max-height: 100%;
        display: inline-flex;
        flex-flow: column-reverse wrap;
        position: fixed;
        left: unset !important;
        right: 50% !important;
        transform: translateX(50%);
        width: auto;
        z-index: 3;
        margin-left: unset !important;
    }

    .k-notification-container {
        margin: ($notification-padding-y * 0.5);
        display: inline-flex;
        vertical-align: top;

        &-animating {
            overflow: hidden;
        }
    }

    .k-notification {
        @include border-radius( $notification-border-radius );
        padding: $notification-padding-y $notification-padding-x;
        border-width: $notification-border-width;
        max-width: $notification-max-width;
        border-style: none;

        box-sizing: border-box;
        outline: 0;
        font-family: $notification-font-family;
        font-size: $notification-font-size;
        line-height: $notification-line-height;
        cursor: default;
        position: relative;
        display: block;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;
    }

    .k-notification-wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        > .k-icon {
            margin-top: $notification-icon-offset;
            margin-right: $notification-icon-spacing;
            flex: 0 0 auto;
        }

        .k-notification__primary-message {
            font-weight: bold;
            margin-right: .5em;
        }

        .k-notification__secondary-message {
            font-weight: normal;
        }

        > .k-i-close {
            @include border-radius($kendo-button-border-radius);
            margin-right: 0;
            margin-left: $notification-icon-spacing;
            flex: 0 0 auto;
            cursor: pointer;
            width: auto;
            padding: $kendo-button-padding-y;
        }

        .k-i-success, .k-i-info, .k-i-warning, .k-i-error {
            font-size: 24px;
        }

        > .k-notification-content {
            flex: 1 1 auto;
        }
    }

    // RTL
    .k-rtl .k-notification,
    [dir="rtl"] .k-notification {

        .k-notification-wrap {

            > .k-icon {
                margin-right: 0;
                margin-left: $notification-icon-spacing;
            }

            > .k-i-close {
                margin-left: 0;
                margin-right: $notification-icon-spacing;
            }
        }
    }

    // Banner style
    .k-banner-container {
        .k-notification-group {
            position: relative !important;
            display: block;
        }
    
        .k-notification-container {
            margin: 0;
            display: block;
        }
    
        .k-notification {
            border-radius: 0;
            border-width: 0;
            border-bottom-width: 2px;
            max-width: 100%;
            border-style: solid;
        }
    }
}
