@include exports( "listview/theme" ) {

    // Listview
    .k-listview {
        @include fill(
            $listview-text,
            $listview-bg,
            $listview-border
        );
    }


    // Listview content
    .k-listview-content {

        > .k-focus {
            @include fill(
                $listview-item-focus-text,
                $listview-item-focus-bg,
                $listview-item-focus-border
            );
            @include box-shadow( $listview-item-focus-shadow );
        }

        > .k-selected {
            @include fill(
                $listview-item-selected-text,
                $listview-item-selected-bg,
                $listview-item-selected-border
            );
        }

    }

}
