@import '@mri/kendo-theme/scss/_overrides.scss';

.k-radio.mri-k-rowselection-radio {
  border-color: #8ca0ac;
  color: #162029;
  background-color: #ffffff;

  border-radius: 50%;
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  -webkit-appearance: none;

  &:checked::before {
    border-radius: 50%;
    transform: scale(1) translate(-50%, -50%);
  }

  &::before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: currentColor;
    transform: scale(0) translate(-50%, -50%);
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    mask: none;
    -webkit-mask: none;
  }
}
