@include exports( "listbox/layout" ) {

    .k-listbox {
        width: $listbox-width;
        height: $listbox-default-height;
        border-width: 0;
        outline: 0;
        font-family: $listbox-font-family;
        font-size: $listbox-font-size;
        line-height: $listbox-line-height;
        vertical-align: top;
        background-color: transparent;
        display: inline-flex;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;

        .k-listbox-toolbar ul {
            display: flex;
        }

        .k-listbox-toolbar .k-button-icon.k-icon {
            &.k-i-arrow-n::before, &.k-i-arrow-60-up::before, &.k-i-caret-alt-up::before { content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.22912 11.3752C1.34579 11.5502 1.52079 11.6668 1.75412 11.6668H12.2541C12.4583 11.6668 12.6625 11.5502 12.7791 11.3752C12.8958 11.2002 12.8666 10.9668 12.75 10.7918L7.49995 2.62516C7.38329 2.421 7.20829 2.3335 7.00412 2.3335C6.79995 2.3335 6.62495 2.421 6.50829 2.596L1.25829 10.7627C1.14162 10.9377 1.14162 11.171 1.22912 11.3752Z' fill='%23162029'/%3E%3C/svg%3E%0A"); }
            &.k-i-arrow-s::before, &.k-i-arrow-60-down::before, &.k-i-caret-alt-down::before { content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.791 2.62516C12.6744 2.45016 12.4994 2.3335 12.266 2.3335H1.76602C1.56185 2.3335 1.35768 2.45016 1.24102 2.62516C1.12435 2.80016 1.15352 3.0335 1.27018 3.2085L6.52018 11.3752C6.63685 11.5502 6.81185 11.6377 7.01602 11.6377C7.22018 11.6377 7.39518 11.5502 7.51185 11.3752L12.7619 3.2085C12.8785 3.06266 12.8785 2.82933 12.791 2.62516Z' fill='%23162029'/%3E%3C/svg%3E%0A"); }
            &.k-i-arrow-e::before { @extend .k-i-arrow-60-right; }
            &.k-i-arrow-w::before { @extend .k-i-arrow-60-left; }
            &.k-i-arrow-double-60-right::before, &.k-i-caret-double-alt-right::before { content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.7375 6.53753L7.32083 2.42503C7.14583 2.30837 6.9125 2.30837 6.7375 2.39587C6.5625 2.51253 6.44583 2.68753 6.44583 2.92087V5.98337L0.904167 2.42503C0.729167 2.30837 0.495833 2.30837 0.291667 2.39587C0.116667 2.51253 0 2.68753 0 2.92087V11.175C0 11.3792 0.116667 11.5834 0.291667 11.7C0.379167 11.7584 0.466667 11.7584 0.583333 11.7584C0.7 11.7584 0.816667 11.7292 0.904167 11.6709L6.41667 8.11253V11.175C6.41667 11.3792 6.53333 11.5834 6.70833 11.7C6.79583 11.7584 6.88333 11.7584 7 11.7584C7.11667 11.7584 7.23333 11.7292 7.32083 11.6709L13.7375 7.55837C13.9125 7.4417 14 7.2667 14 7.06253C14 6.85837 13.9125 6.6542 13.7375 6.53753Z' fill='%23162029'/%3E%3C/svg%3E%0A"); }
            &.k-i-arrow-double-60-left::before, &.k-i-caret-double-alt-left::before { content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.7083 2.40777C13.5333 2.2911 13.3 2.32027 13.125 2.43693L7.58333 5.9661V2.93277C7.58333 2.7286 7.46667 2.52443 7.29167 2.40777C7.11667 2.2911 6.88333 2.32027 6.70833 2.43693L0.291667 6.54943C0.0875 6.6661 0 6.8411 0 7.04527C0 7.24943 0.0875 7.42443 0.2625 7.5411L6.67917 11.6536C6.76667 11.7119 6.88333 11.7411 7 11.7411C7.0875 11.7411 7.20417 11.7119 7.29167 11.6828C7.46667 11.5661 7.58333 11.3911 7.58333 11.1578V8.12443L13.0958 11.6828C13.1833 11.7411 13.3 11.7703 13.4167 11.7703C13.5042 11.7703 13.6208 11.7411 13.7083 11.7119C13.8833 11.5953 14 11.4203 14 11.1869V2.93277C14 2.69943 13.8833 2.52443 13.7083 2.40777Z' fill='%23162029'/%3E%3C/svg%3E%0A"); }
        }

        &.k-listbox-toolbar-left,
        &.k-listbox-toolbar-right {
            .k-listbox-toolbar ul {
                flex-direction: column;
            }

            .k-listbox-toolbar li + li {
                margin-top: $listbox-button-margin;
            }
        }

        &.k-listbox-toolbar-left {
            flex-direction: row;

            .k-listbox-toolbar {
                margin-right: $listbox-margin;
            }
        }

        &.k-listbox-toolbar-right {
            flex-direction: row-reverse;

            .k-listbox-toolbar {
                margin: 0px $listbox-margin;
                padding: 8px 0px;
            }
        }

        &.k-listbox-toolbar-top,
        &.k-listbox-toolbar-bottom {
            flex-direction: column;

            .k-listbox-toolbar ul {
                flex-direction: row;
            }

            .k-listbox-toolbar li + li {
                margin-left: $listbox-button-margin;
            }
        }

        &.k-listbox-toolbar-top {
            .k-listbox-toolbar {
                margin-bottom: $listbox-margin;
            }
        }

        &.k-listbox-toolbar-bottom {
            .k-listbox-toolbar {
                margin-top: $listbox-margin;
            }
        }

        .k-list-scroller {
            width: 100%;
            height: inherit;
            border-width: $listbox-border-width;
            border-style: solid;
            box-sizing: border-box;

            .k-list-scroller {
                border-width: 0;
            }

            .k-list {
                height: inherit;
                background: transparent;
            }
        }

        .k-drop-hint {
            border-top-width: $listbox-drop-hint-width;
            border-top-style: solid;
        }

        .k-ghost {
            opacity: .5;
        }

        .k-rtl &,
        &[dir="rtl"],
        [dir="rtl"] & {

            &.k-listbox-toolbar-left,
            &.k-listbox-toolbar-right {
                .k-listbox-toolbar {
                    transform: scaleX(-1);
                }
            }

            &.k-listbox-toolbar-left {
                .k-listbox-toolbar {
                    margin-right: 0;
                    margin-left: $listbox-margin;
                }
            }

            &.k-listbox-toolbar-right {
                .k-listbox-toolbar {
                    margin-right: $listbox-margin;
                    margin-left: 0;
                }
            }
        }
    }

    .k-item {
        cursor: default;
    }

}
