// Skeleton
$skeleton-text-transform: scale( 1, .6 ) !default;
$skeleton-text-border-radius: $kendo-border-radius-md !default;

$skeleton-rect-border-radius: 0 !default;

$skeleton-circle-border-radius: 9999px !default;

$skeleton-item-bg: rgba( $inverse, .2 ) !default;

$skeleton-wave-bg: rgba( black, .04 ) !default;
