@import '@mri/scss-all/scss/variables/_all.scss';
@import '../../../../../libs/shared/styles/src/lib/mri-kendo-custom.scss';

.k-outline.ag-k-button--dark {
  &,
  &:active,
  &:focus {
    color: $mri-light-text-color;
    border-color: $mri-light-line-color;
  }
  &:enabled:hover {
    background-color: transparentize($mri-light-interactive-color, 0.9);
    color: $mri-light-text-color;
    border-color: $mri-light-line-color;
  }
}

/**
  prevent grid row to increase in height due to long text triggering text wrapping
  this is critical when virtual scrolling has been enabled for grid

  TODO: Suggest as a bug fix to kendo
  Note: If kendo does not accept fix, add this as a workaround in @mri/kendo-theme
*/
kendo-grid[scrollable='virtual'] tbody td {
  white-space: nowrap;
}
/**
As scott suggested, the below style will fix
for content overflow outside from kendo popup
*/
.k-popup .popup-content {
  max-width: fit-content;
}
/**
Once kendo theme updated,
will remove below
*/
.k-list-container .k-nodata,
.k-list-container .k-no-data {
  font-weight: normal;
  color: #607184;
}
/**
Font size is different from screen width so updated
here
*/
.button-notification {
  font-size: $mri-font-size-base;
}

/**
** Disabled row color
*/
.k-grid tr.k-alt.grid-row-background--disabled,
.grid-row-background--disabled {
  background-color: mri-gray(10);
}
/**
Bg color not updated in that package,
so until update
*/
.k-progressbar {
  background-color: mri-gray(10);
}

/**
Disbaled steps color
*/
.k-step-disabled {
  opacity: 0.5;
}

/**
Override the width size to auto for drawer
*/
.mri-drawer {
  max-width: 600px;
  width: auto;
}
/**
Override the license notification banner
*/
.license-banner .k-notification-group {
  z-index: 1000;
}

// for fixing multiselect dropdown
kendo-multiselect > .k-input-values {
  @extend .k-multiselect-wrap;
  @extend .k-floatwrap;
}

//for fixing kendo grid
.k-grid th:first-child,
.k-grid td:first-child {
  padding-left: 32px;
}

.k-grid-header .k-header.k-sorted .k-link {
  display: flex;
}

.k-grid-header .k-header:first-child {
  .k-link {
    overflow: visible;
  }
}

//for fixing tab layout
.k-tabstrip-content:focus {
  outline-width: 0px;
}

//for fixing width of form field
.k-form-horizontal .k-form-field > kendo-label {
  width: 50%;
}

//for dropdown with filtering
.k-list-filter {
  padding: 8px;
  > .k-searchbox {
    @include box-shadow($kendo-picker-focus-shadow);
    flex-direction: row-reverse;
    > {
      kendo-icon-wrapper {
        margin: auto 4px;
      }
    }
  }
}

.k-upload .k-file-icon-wrapper {
  .k-file-icon {
    height: 32px;
    width: 32px;
    margin-right: 8px;
    > svg {
      height: 32px;
      width: 32px;
      color: #d6d6d6;
    }
  }
}

.k-upload kendo-upload-file-list-single-item {
  .k-file-summary {
    color: #00775d;
    font-size: smaller;
  }
  .k-file-info {
    flex: 1 0 0;
  }
}

.k-notification-group {
  z-index: 10000;
}

.k-notification {
  display: flex;
  gap: 1em;
}

.k-dialog-wrapper {
  .k-window-actions {
    margin: 0;
    padding: 16px;
  }
  .k-icon-wrapper-host {
    display: flex;
  }
  .k-window-title {
    line-height: 32px;
  }
}

// temp fix for kendo stepper icon, once found proper approach we can remove this
.k-stepper .k-step-indicator::after {
  content: none;
}

.k-stepper kendo-icon-wrapper {
  display: none;
}

// fix for splitbutton icon style
.k-button.k-split-button-arrow .k-button-icon::before {
  background-color: inherit;
}

//fix for required icon in text field
input.k-textbox.k-required.k-input:not([readonly], [disabled], .ng-invalid.ng-dirty, .ng-valid.ng-dirty) {
  padding-right: 25px;
  background-image: $required-icon;
}

// fix for kendo notification error icon
.k-notification-error > .k-icon-wrapper-host .k-icon::before {
  width: inherit;
  content: $invalid-icon;
}

// fix for kendo notification success icon
.k-notification-success > .k-icon-wrapper-host .k-icon::before {
  width: inherit;
  content: $success-icon;
}

/**
fix for stepper error label color
TODO: need to find proper fix
*/
.k-stepper .k-step-error .k-step-label,
.k-stepper .k-step-error:hover .k-step-label {
  color: inherit;
}

.k-dialog-content {
  white-space: pre-wrap;
}
