// Colorpalette
$colorpalette-font-family: $font-family !default;
$colorpalette-font-size: $font-size !default;
$colorpalette-line-height: 0 !default;

$colorpalette-tile-width: map-get( $spacing, 8 ) !default;
$colorpalette-tile-height: $colorpalette-tile-width !default;
$colorpalette-tile-focus-shadow: 0 0 0 2px $focused-shadow-color, inset 0 0 0 1px rgba( white, 1 ) !default;
$colorpalette-tile-hover-shadow: 0 0 0 2px #C4E1F1, inset 0 0 0 1px rgba( white, 1 ) !default;
$colorpalette-tile-selected-shadow: 0 0 0 2px #9CCEE8, inset 0 0 0 1px rgba( white, 1 ) !default;
