// Splitter
$splitter-border-width: 1px !default;
$splitter-font-family: $font-family !default;
$splitter-font-size: $font-size !default;
$splitter-line-height: $line-height !default;

$splitter-bg: $component-bg !default;
$splitter-text: $component-text !default;
$splitter-border: $component-border !default;

$splitter-splitbar-size: 8px !default;
$splitter-resize-icon-size: 10px !default;
$splitter-drag-handle-length: 20px !default;
$splitter-drag-handle-thickness: 2px !default;
$splitter-drag-icon-margin: 7px !default;

$splitbar-bg: $bg-color-lightest !default;
$splitbar-text: $base-text !default;

$splitbar-hover-bg: try-shade( $splitbar-bg, .5 ) !default;
$splitbar-hover-text: $splitbar-text !default;

$splitbar-selected-bg: $selected-bg !default;
$splitbar-selected-text: $selected-text !default;
