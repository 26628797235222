@include exports("toolbar/theme") {

    // Theme
    .k-toolbar {
        @include fill(
            $toolbar-text,
            $toolbar-bg,
            $toolbar-border,
            $toolbar-gradient
        );
        @include box-shadow( $toolbar-shadow );


        // Separator
        .k-separator {
            border-color: $toolbar-separator-border;
        }


        // Template item
        .k-toolbar-item:focus,
        .k-toolbar-item.k-focus {
            @include box-shadow( $kendo-button-focus-shadow );
        }


        // Button group
        .k-button-group {}

    }


    // Overflow container
    .k-overflow-container {

        .k-button {

            // Hover state
            &:hover,
            &.k-hover {
                color: $kendo-menu-popup-item-hover-text;
                background: $kendo-menu-popup-item-hover-bg;
            }

            // Button focus state
            &:focus,
            &.k-focus {
                box-shadow: $kendo-menu-popup-item-focus-shadow;
            }

            // Active state
            &:active,
            &.k-active {
                color: $kendo-menu-popup-item-hover-text;
                background: $kendo-menu-popup-item-hover-bg;
            }

            // Selected
            &.k-selected {
                color: $kendo-menu-popup-item-expanded-text;
                background: $kendo-menu-popup-item-expanded-bg;
            }

            // Disabled state
            &:disabled,
            &.k-disabled {
                color: inherit;
            }
        }

    }

}




@include exports("floating-toolbar/theme") {

    // sass-lint:disable-block class-name-format
    .k-floating-toolbar,
    .editorToolbarWindow.k-window-content {
        @include fill(
            $toolbar-text,
            $toolbar-bg,
            $toolbar-border,
            $toolbar-gradient
        );
    }

}
